import {createState, DevTools, useState} from '@hookstate/core'
import {IUser} from 'types/user'
import {api} from 'utils/api'
import {makeRequestRaw} from 'utils/request'
import {getAuth} from './auth'

const stateLabel = 'user'

export function fetchUser() {
    const auth = getAuth()

    return auth ? makeRequestRaw<IUser>({
        url: api.user.read,
        data: {
            id: Number(auth.userID)
        },
    }, auth) : null
}

const globalState = createState(fetchUser)

export function useUser() {
    const state = useState(globalState)

    return {
        user: state.promised || state.error ? null : state.get(),
        setUser: (user: IUser | null) => state.set(user),
    }
}

DevTools(globalState).label(stateLabel)
