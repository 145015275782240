import React, {PropsWithChildren,} from 'react'
import {Box, createStyles, IconButton} from '@material-ui/core'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Carousel, {RenderArrowProps, RenderPaginationProps} from 'react-elastic-carousel'
import {NavigateBefore, NavigateNext} from '@material-ui/icons'

const useStyles = makeStyles((theme) =>
    createStyles({
        sliderArrow: {
            margin: 'auto',
            display: 'none',
            [theme.breakpoints.up('mobile')]: {
                display: 'unset'
            }
        },
        paginationDot: {
            border: '1px solid rgba(0,0,0,0.125)',
            borderRadius: theme.spacing(1),
            margin: theme.spacing(.5),
            width: theme.spacing(1),
            height: theme.spacing(1),
            cursor: 'pointer',
            background: 'rgba(0,0,0,0.25)',
            transition: 'all 0.5s ease',
        },
        paginationDotActive: {
            borderColor: 'rgba(0,0,0,0.25)',
            background: 'rgba(0,0,0,0.5)'
        }
    })
)

interface Props {
    className?: string,
    breakpoints: { width: number, itemsToShow: number }[]
}

type CardSliderProps = PropsWithChildren<Props>

export function CardSlider(props: CardSliderProps) {
    const {sliderArrow, paginationDot, paginationDotActive} = useStyles()
    const theme = useTheme()

    const SliderArrow = ({type, onClick, isEdge}: RenderArrowProps) => {
        return ({// Switch-like case
            'PREV':
                <IconButton className={sliderArrow} onClick={onClick} disabled={isEdge}>
                    <NavigateBefore/>
                </IconButton>,
            'NEXT':
                <IconButton className={sliderArrow} onClick={onClick} disabled={isEdge}>
                    <NavigateNext/>
                </IconButton>
        }[type])
    }

    const PaginationDots = ({pages, activePage, onClick}: RenderPaginationProps) => {
        return (
            <Box display={'flex'} flexDirection="row" marginTop={1}>
                {pages.length > 1 ? pages.map(page => {
                    const isActivePage = activePage === page
                    return (
                        <Box
                            key={page}
                            className={`${paginationDot} ${isActivePage ? paginationDotActive : ''}`}
                            onClick={() => onClick(page.toString())}
                        />
                    )
                }) : null}
            </Box>
        )
    }

    return (
        <Carousel
            enableMouseSwipe={false}
            breakPoints={props.breakpoints}
            itemPadding={[theme.spacing(3)]}
            className={props.className}
            renderArrow={SliderArrow}
            renderPagination={PaginationDots}
            isRTL={false}
            children={props.children}
        />
    )
}
