import {Box, Typography} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {RichText} from 'components/misc/RichText'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useQueryParam} from 'utils/hooks'
import {useRequestEffect} from 'utils/request'
import {route} from 'utils/route'

interface ISearchResult {
    record: 'cause' | 'charity'
    id: number
    name: string
    summary: string | null
}

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            fontWeight: 'bold',
            fontSize: '1.5rem',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            }
        },
    }),
)

export function Search() {
    const page = useStatefulPageState()
    const {title} = useStyles()
    const {translate} = useTranslation()

    const query = useQueryParam('query')

    const [searchResults, setSearchResults] = useState<ISearchResult[]>()

    useRequestEffect<ISearchResult[]>({
        if: () => {
            if (!query || query.length < 3) {
                page.setErrorMessage('search_query_too_short')
                return false
            } else if (query.length > StringLength.XS) {
                page.setErrorMessage('search_query_too_long')
                return false
            } else {
                return true
            }
        },
        url: api.search.query,
        state: page,
        data: {
            query: query
        },
        onStart: () => setSearchResults(undefined),
        onSuccess: setSearchResults
    }, [query])

    return (
        <StatefulPage state={page}>
            <AppHelmet noIndex/>
            <Title titleKey={'search'}/>
            <Only when={{searchResults, if: searchResults?.length === 0}}>
                <RichText
                    size={'large'}
                    text={translate('no_results_for').replace('%query%', query ?? '')}
                />
            </Only>
            <Only when={{searchResults, if: searchResults?.length}}>{({searchResults}) =>
                <>
                    <RichText
                        size={'large'}
                        text={translate('showing_results_for').replace('%query%', query ?? '')}
                    />
                    <Box>{searchResults.map(({id, name, record, summary}) =>
                        <Box marginY={4} maxWidth={1280} key={id}>
                            <Typography
                                className={title}
                                component={Link}
                                to={{
                                    cause: route.to.cause(id).page,
                                    charity: route.to.charity(id).page,
                                }[record]}
                                children={name}
                            />
                            <Typography
                                children={summary}
                            />
                        </Box>
                    )}</Box>
                </>
            }</Only>
        </StatefulPage>
    )
}
