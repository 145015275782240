/* eslint-disable react/jsx-key */
import {Column} from '@material-table/core'
import {Box, Button, Table, TableBody, TableCell, TableRow} from '@material-ui/core'
import {EmailOutlined, Launch, PersonOutlined, PhoneOutlined} from '@material-ui/icons'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {CustomTable} from 'components/misc/CustomTable'
import {RichText} from 'components/misc/RichText'
import {SelectWithAll} from 'components/misc/SelectWithAll'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {Maybe, Only} from 'components/util/Only'
import {ACauseTypes, ICause, ICauseInfo, ICauseType} from 'types/cause'
import {IUser} from 'types/user'
import {useUser} from 'state/user'
import React, {cloneElement, ReactElement, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {formatDateTimeString} from 'utils/date'
import {useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {TranslationKey} from 'utils/TranslationKey'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {CompetitionInfoAdminDialog} from './components/CompetitionInfoAdminDialog'

interface DonationDetails {
    amount: number
}

interface EventDetails extends DonationDetails {
    ticket_count: number,
    ticket_price: number,
}

type IContribution = {
    project: ICauseType
    datetime: string,
    photo_text: string,
    image_path: string,
    cause: ICauseInfo,// Pick<ICause, 'id' | 'name'>,
    user?: Pick<IUser, 'id' | 'name' | 'email' | 'phone_number'>
    details?: DonationDetails | EventDetails
}

export function CharityContributions() {
    const {translate} = useTranslation()

    const page = useStatefulPageState()
    const {id} = useParams<{ id: string }>()

    const {user} = useUser()

    const [causeType, setCauseType] = useState<ICauseType | 'all'>('all')

    const [contributions, setContributions] = useState<IContribution[]>([])
    const [filteredContributions, setFilteredContributions] = useState<IContribution[]>([])

    const causeDefault:ICauseInfo={
        id: 0,
        name: ''
    }
    const InfoDefault:IContribution = {
        project: 'competition',
        datetime: '',
        photo_text: '',
        image_path: '',
        cause: causeDefault,
        user: undefined,
        details: undefined
    }

    const InfoDialogOpenState = useDialogOpenState<IContribution>(InfoDefault)

    useRequestEffect({
        state: page,
        if: !!id && !!Number(id),
        url: api.charity.read_contributions,
        data: {
            id: Number(id)
        },
        onSuccess: setContributions
    })

    useEffect(() => {
        setFilteredContributions(
            contributions
                .filter(contribution => causeType === 'all' || contribution.project === causeType)
                .sort((a, b) => a.datetime < b.datetime ? 1 : -1)
        )
    }, [contributions, causeType])

    return (
        <StatefulPage state={page}>
            <AppHelmet noIndex/>
            <AuthorizedContent>
                <CompetitionInfoAdminDialog 
                    openState={InfoDialogOpenState}
                    adminUser={user?.is_admin ? true : false}
                />
                <Title titleKey={'contributions'}/>
                <CustomTable
                    data={filteredContributions}
                    options={{
                        sorting: false,
                        search: false
                    }}
                    title={
                        <Box display={'flex'} paddingTop={2}>
                            <SelectWithAll
                                label="cause_type"
                                values={ACauseTypes as TranslationKey[]}
                                value={causeType}
                                setValue={setCauseType}
                                style={{
                                    minWidth: 128
                                }}
                            />
                        </Box>
                    }
                    columns={[
                        {
                            title: translate('date_and_time'), field: 'datetime', defaultSort: 'desc',
                            render: contribution => <RichText text={formatDateTimeString(contribution.datetime)}/>
                        },
                        {
                            title: translate('contribution_type'),
                            render: contribution => <RichText text={translate(contribution.project)}/>
                        },
                        {
                            title: translate('cause'),
                            render: contribution =>
                                <Box>
                                    <Button
                                        component={Link}
                                        to={route.to.cause(contribution.cause.id).page}
                                        variant={'outlined'}
                                        color={'secondary'}
                                        startIcon={<Launch style={{marginRight: 8}} fontSize={'small'}/>}
                                        children={contribution.cause.name}
                                    />
                                </Box>
                        },
                        {
                            title: translate('user_info'),
                            hidden: causeType === 'donation',
                            render: contribution =>
                            <Box>
                                <Only when={contribution.project == 'competition'}>
                                    <Only when={{user: contribution?.user}}>{({user}) =>
                                        <Table size="small">
                                            <TableBody>{
                                                ([
                                                    [<PersonOutlined/>, user.name],
                                                    [<EmailOutlined/>, user.email],
                                                    [<PhoneOutlined/>, user.phone_number],
                                                ]).map(([icon, value], index) =>
                                                    <TableRow key={index}>
                                                        <TableCell children={cloneElement(icon as ReactElement, {
                                                            fontSize: 'small',
                                                            color: 'secondary'
                                                        })}/>
                                                        <TableCell children={value}/>
                                                    </TableRow>
                                                )
                                            }</TableBody>
                                        </Table>
                                    }</Only>   
                                    {/* <Only when={user?.is_admin}>
                                        <Only when={{user: contribution?.user}}>{({user}) =>
                                            <Table size="small">
                                                <TableBody>{
                                                    ([
                                                        [<PersonOutlined/>, user.name],
                                                        [<EmailOutlined/>, user.email],
                                                        [<PhoneOutlined/>, user.phone_number],
                                                    ]).map(([icon, value], index) =>
                                                        <TableRow key={index}>
                                                            <TableCell children={cloneElement(icon as ReactElement, {
                                                                fontSize: 'small',
                                                                color: 'secondary'
                                                            })}/>
                                                            <TableCell children={value}/>
                                                        </TableRow>
                                                    )
                                                }</TableBody>
                                            </Table>
                                        }</Only>    
                                   </Only> */}
                                   {/* Only show supporter id info when user is not admin */}
                                   {/* <Only when={!user?.is_admin}>
                                        <Only when={{user: contribution?.user}}>{({user}) =>
                                            <p><b>{user.id}</b></p>
                                        }</Only>
                                   </Only> */}
                                </Only>
                                <Only when={contribution.project != 'competition'}>
                                    <Only when={{user: contribution?.user}}>{({user}) =>
                                        <Table size="small">
                                            <TableBody>{
                                                ([
                                                    [<PersonOutlined/>, user.name],
                                                    [<EmailOutlined/>, user.email],
                                                    [<PhoneOutlined/>, user.phone_number],
                                                ]).map(([icon, value], index) =>
                                                    <TableRow key={index}>
                                                        <TableCell children={cloneElement(icon as ReactElement, {
                                                            fontSize: 'small',
                                                            color: 'secondary'
                                                        })}/>
                                                        <TableCell children={value}/>
                                                    </TableRow>
                                                )
                                            }</TableBody>
                                        </Table>
                                    }</Only>
                                </Only>
                            </Box>
                        },
                        {
                            title: translate('contribution_info'),
                            hidden: ['activity', 'volunteering'].includes(causeType),
                            render: contribution =>
                            <Box>
                                <Only when={contribution.project == 'competition'}>
                                    <div className={'competition-details-wrap'}>
                                        <a href={contribution.image_path} target="_blank" rel="noreferrer"><img src={contribution.image_path} /></a>
                                        {/* <p>{contribution.photo_text}</p> */}
                                        <Button
                                            onClick={() => InfoDialogOpenState.open(contribution)}
                                            variant={'contained'}
                                            color="secondary"
                                            children={translate('details')}
                                        />
                                    </div>
                                </Only>
                                <Only when={['donation', 'event'].includes(contribution.project)}>
                                    <Table size="small">
                                        <TableBody>
                                            <Only when={contribution.project === 'event'}>
                                                <TableRow>
                                                    <TableCell children={translate('ticket_count')}/>
                                                    <TableCell
                                                        children={(contribution.details as Maybe<EventDetails>)?.ticket_count}
                                                    />
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell children={translate('ticket_price')}/>
                                                    <TableCell
                                                        children={`${(contribution.details as Maybe<EventDetails>)?.ticket_price} ₺`}
                                                    />
                                                </TableRow>
                                            </Only>
                                            <TableRow>
                                                <TableCell children={translate('amount')}/>
                                                <TableCell children={`${contribution.details?.amount} ₺`}/>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Only>
                            </Box>
                        },
                    ] as Column<IContribution>[]}
                />
                <Box marginY={2}/>
            </AuthorizedContent>
        </StatefulPage>
    )
}
