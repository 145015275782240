import {useTranslation} from '../../../state/translation'
import {Box, FormControl, FormControlLabel, FormLabel, Icon, Radio, RadioGroup} from '@material-ui/core'
import React from 'react'
import {StatefulDialogState} from '../../../components/foundation/StatefulDialog'
import {StringField} from 'components/foundation/EditableField'
import {FormField} from 'components/forms/FormField'
import {VpnKeyOutlined} from '@material-ui/icons'


export function SmsVerifyStep(props: {
    dialog: StatefulDialogState
    amount: number | null
    causeId: number
    sms_code: StringField
}) {
    const {translate} = useTranslation()

    return (
        <FormControl component="fieldset" className={'fc-w-100'}>
            <FormLabel
                component="legend"
                color={'primary'}
                children={translate('sms_confirmation_text')}
            />
            <Box marginTop={2}>
                <FormField
                    label={'confirmation_code'}
                    field={props.sms_code}
                    icon={<VpnKeyOutlined/>}
                />
            </Box>
        </FormControl>
    )
}
