import React from 'react'
import {Box, BoxProps, Divider} from '@material-ui/core'

export function CustomDivider(props: BoxProps) {
    return (
        <Box {...props}>
            <Divider style={{backgroundColor: props.color ?? 'rgba(0,0,0,0.5)', height: 0.5}}/>
        </Box>
    )

}
