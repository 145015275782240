import {Box, Fade} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {
    Delete,
    EmailOutlined,
    GetApp,
    Save,
    Subject,
    SubjectOutlined,
    TextFieldsOutlined,
    Visibility,
    VpnKeyOutlined,
    WebOutlined,
    YouTube
} from '@material-ui/icons'
import {ConfirmationDialog} from 'components/dialogs/ConfirmationDialog'
import {FormField} from 'components/forms/FormField'
import {ImageField} from 'components/forms/ImageField'
import {PhoneField} from 'components/forms/PhoneField'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {
    allFieldsDisabled,
    FieldModeContext,
    useImageBase64Field,
    useStringField,
    useYoutubeField
} from 'components/foundation/EditableField'
import {ResponsiveButton} from 'components/foundation/ResponsiveButton'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import {ICharity} from 'types/charity'
import React, {useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useAppSnackbar, useMobileSize} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {ValidateThat} from 'utils/ValidateThat'
import {CustomDivider} from '../../../components/misc/CustomDivider'
import {RichText} from '../../../components/misc/RichText'
import {useLang} from '../../../state/lang'
import {DocumentPicker} from './DocumentPicker'

const useStyles = makeStyles(({breakpoints, spacing}: Theme) =>
    createStyles({
        charityEditor: {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            width: '100%',
            [breakpoints.up('tablet')]: {
                maxWidth: breakpoints.values['tablet']
            }
        },
        documentPicker: {
            margin: `${spacing(2)}px auto`
        }
    })
)

export function CharityEditor() {
    const classes = useStyles()
    const {id} = useParams<{ id: string }>()
    const {makeRequest} = useRequest()
    const {showSnackbar} = useAppSnackbar()

    const page = useStatefulPageState()
    const deleteDialog = useDialogOpenState(undefined)
    const mobile = useMobileSize()

    const {user} = useUser()
    const {translate} = useTranslation()
    const {lang} = useLang()
    const history = useHistory()

    const image = useImageBase64Field()
    const name = useStringField(StringLength.SM)
    const summary = useStringField(StringLength.SM)
    const description = useStringField(StringLength.XL)
    const email = useStringField(StringLength.MD)
    const phone = useStringField(StringLength.XS)
    const youtubeID = useYoutubeField()
    const website = useStringField(StringLength.SM)
    const paynetSecretKey = useStringField(StringLength.SM)
    const localpayBranchId = useStringField(StringLength.SM)

    const contractBase64 = useStringField(StringLength.IRRELEVANT)
    const govFormBase64 = useStringField(StringLength.IRRELEVANT)

    const fields = [
        image, name, summary, description, email, phone, youtubeID, website,
        paynetSecretKey, localpayBranchId, contractBase64, govFormBase64
    ]

    const [charity, setCharity] = useState<ICharity>()

    useRequestEffect<ICharity>({
        url: api.charity.read,
        state: page,
        data: {
            id: Number(id)
        },
        onStart: () => {
            page.setShowChildren(false)
            page.clearMessages()
        },
        onSuccess: charity => {
            setCharity(charity)
            image.setInitialValue(charity.logo_url)
            name.setInitialValue(charity.name)
            summary.setInitialValue(charity.summary)
            description.setInitialValue(charity.description)
            email.setInitialValue(charity.email)
            phone.setInitialValue(charity.phone_number)
            youtubeID.setInitialValue(charity.youtube_id)
            website.setInitialValue(charity.website_url)
            paynetSecretKey.setInitialValue(charity.paynet_secret_key)
            localpayBranchId.setInitialValue(charity.localpay_branch_id)
            contractBase64.setDisabled(true)
            govFormBase64.setDisabled(true)

            page.setShowChildren(true)
        },
        onFail: resultCode => {
            if (['UNAUTHORIZED', 'CHARITY_NOT_FOUND'].includes(resultCode)) {
                page.setShowNotFound(true)
            } else {
                page.setUnexpectedFrontendError()
            }
        }
    }, [lang])

    async function onUpdate() {
        const fieldsAreValid = [
            name.validate(),
            email.validate(value => ValidateThat.emailIsValid(value), 'invalid_email_format'),
            phone.validate(value => ValidateThat.phoneNumberIsValid(value), 'invalid_phone_format_desc'),
            website.validate(value => ValidateThat.websiteIsValid(value), 'invalid_website_format_desc'),
        ].every(Boolean)

        if (!fieldsAreValid) {return}


        makeRequest({
            state: page,
            url: api.charity.update,
            data: {
                id: Number(id),
                ...(await image.box('image_base64')),
                ...name.box('name'),
                ...summary.box('summary'),
                ...description.box('description'),
                ...email.box('email'),
                ...phone.box('phone_number'),
                ...youtubeID.box('youtube_id'),
                ...website.box('website_url'),
                ...paynetSecretKey.box('paynet_secret_key'),
                ...localpayBranchId.box('localpay_branch_id'),
                ...contractBase64.box('contract_base64'),
                ...govFormBase64.box('gov_form_base64'),
            },
            onSuccess: () => page.setSuccessMessage('charity_edit_success'),
            onFail: resultCode => {
                if (resultCode === 'INVALID_IMAGE_TYPE') {
                    page.setErrorMessage('unsupported_image_format_desc')
                } else if (resultCode === 'INVALID_PAYNET_SECRET') {
                    page.setErrorMessage('invalid_paynet_secret')
                } else {page.setUnexpectedFrontendError()}
            }
        }).then()
    }

    function onDelete() {
        console.log('onDelete')
        makeRequest({
            url: api.charity.delete,
            if: !!charity,
            data: {
                id: charity?.id
            },
            onSuccess: () => {
                history.push(charity?.owner.id === user?.id ?
                    route.to.my_charities :
                    route.to.user(user?.id).charities
                )
            },
            onFinish: success => showSnackbar(
                success ? 'success' : 'error',
                success ? 'charity_delete_success' : 'charity_delete_fail'
            )
        }).then()
    }

    return (
        <StatefulPage
            state={page}
            successActions={
                <Box display={'flex'} justifyContent={'flex-end'}>
                    <Button
                        onClick={() => history.push(route.to.charity(id).page)}
                        startIcon={<Visibility/>}
                        color={'secondary'}
                    >
                        {translate('view')}
                    </Button>
                </Box>
            }
        >
            <AppHelmet noIndex/>
            <AuthorizedContent when={user?.is_admin || (charity?.owner.id === user?.id)}>
                <Fade in={true}>
                    <div className={classes.charityEditor}>
                        <ConfirmationDialog
                            severity={'warning'}
                            text={'delete_charity_confirm'}
                            openState={deleteDialog}
                            onOk={onDelete}
                        />
                        <Title titleKey={'charity_editor_title'}/>
                        <Only when={{charity}}>{({charity}) =>
                            <FieldModeContext.Provider value={'edit'}>
                                <ImageField
                                    preferredDimensions={'256x256'}
                                    aspectRatio={1}
                                    field={image}
                                />
                                <Box margin={1}/>
                                <FormField
                                    label={'charity_name'}
                                    field={name}
                                    icon={<TextFieldsOutlined/>}
                                />
                                <FormField
                                    label={'summary'}
                                    field={summary}
                                    icon={<Subject/>}
                                />
                                <FormField
                                    multiline
                                    label={'description'}
                                    field={description}
                                    icon={<SubjectOutlined/>}
                                />
                                <FormField
                                    label={'email_address'}
                                    field={email}
                                    icon={<EmailOutlined/>}
                                />
                                <PhoneField
                                    field={phone}
                                />
                                <FormField
                                    label={'youtube_id'}
                                    field={youtubeID}
                                    icon={<YouTube/>}
                                />
                                <FormField
                                    label={'website'}
                                    field={website}
                                    icon={<WebOutlined/>}
                                />
                                <FormField
                                    label={'paynet_secret_key'}
                                    field={paynetSecretKey}
                                    placeholder={'sck_...'}
                                    icon={<VpnKeyOutlined/>}
                                />
                                <FormField
                                    label={'localpay_branch_id'}
                                    field={localpayBranchId}
                                    icon={<VpnKeyOutlined/>}
                                />
                                <Box marginRight={6}>
                                    <CustomDivider marginY={2}/>
                                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <RichText text={translate('download_charity_contract')}/>
                                        <Button
                                            color={'secondary'}
                                            variant={'outlined'}
                                            startIcon={<GetApp/>}
                                            component={Link}
                                            target={'_blank'}
                                            to={{pathname: api.static.read('charity_contract.pdf')}}
                                            children={translate('download')}
                                        />
                                    </Box>
                                    <Box marginTop={2}/>
                                    <DocumentPicker
                                        className={classes.documentPicker}
                                        submitted={charity.contract_submitted}
                                        label={'upload_charity_contract'}
                                        documentBase64={contractBase64}
                                    />
                                    <DocumentPicker
                                        className={classes.documentPicker}
                                        submitted={charity.gov_form_submitted}
                                        label={'upload_gov_form'}
                                        documentBase64={govFormBase64}
                                    />
                                    <CustomDivider marginY={2}/>
                                </Box>
                                <Box display={'flex'} marginY={mobile ? 2 : 4}>
                                    <ResponsiveButton
                                        label={'delete'}
                                        icon={<Delete/>}
                                        onClick={() => deleteDialog.setOpen(true)}
                                        disabled={charity.status.name !== 'draft'}
                                        color={'secondary'}
                                        variant={'text'}
                                    />
                                    <Box flex={1}/>
                                    <ResponsiveButton
                                        label={'update'}
                                        icon={<Save/>}
                                        onClick={onUpdate}
                                        variant={'contained'}
                                        color="primary"
                                        disabled={allFieldsDisabled(fields)}
                                    />
                                    <Box marginX={3}/>
                                </Box>
                            </FieldModeContext.Provider>
                        }</Only>
                    </div>
                </Fade>
            </AuthorizedContent>
        </StatefulPage>
    )
}
