import {Box, Typography} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Email, Phone, WebOutlined} from '@material-ui/icons'
import {Anchor} from 'components/misc/Anchor'
import {IconWithText} from 'components/misc/IconWithText'
import {Only} from 'components/util/Only'
import {ICharity} from 'types/charity'
import React from 'react'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        contactInfo: {
            '& >*': {
                marginTop: spacing(1),
                marginBottom: spacing(1),
            }
        },
        link: {
            textDecoration: 'none',
            color: 'inherit',
            '&:hover': {
                textDecoration: 'underline',
            }
        },
    }),
)


export function SummaryAndContact(props: {
    charity: ICharity
}) {
    const {charity} = props
    const {contactInfo, link} = useStyles()

    return (
        <Box className={contactInfo}>
            <Typography>{charity.summary}</Typography>
            <Only when={{phone_number: charity.phone_number}}>{({phone_number}) =>
                <IconWithText
                    icon={<Phone/>}
                    text={
                        <Anchor
                            isPhone
                            className={link}
                            href={phone_number}
                        />
                    }
                />
            }</Only>
            <Only when={{email: charity.email}}>{({email}) =>
                <IconWithText
                    icon={<Email/>}
                    text={
                        <Anchor
                            isEmail
                            className={link}
                            href={email}
                        />
                    }
                />
            }</Only>
            <Only when={{website_url: charity?.website_url}}>{({website_url}) =>
                <IconWithText
                    icon={<WebOutlined/>}
                    text={
                        <Anchor
                            className={link}
                            href={website_url}
                        />
                    }/>
            }</Only>
        </Box>
    )
}