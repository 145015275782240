import {Box, Button, InputAdornment, TextField} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import {AccountCircleOutlined, Email, EmojiPeople, LocalHospital, Phone} from '@material-ui/icons'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Title} from 'components/misc/Title'
import {Only} from 'components/util/Only'
import {IUser} from 'types/user'
import React, {ReactElement, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useMobileSize} from 'utils/hooks'
import {useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {TranslationKey} from 'utils/TranslationKey'

export function UserDetails() {
    const page = useStatefulPageState()
    const {id} = useParams<{ id: string }>()
    const {translate} = useTranslation()
    const [user, setUser] = useState<IUser>()
    const {breakpoints} = useTheme()
    const mobile = useMobileSize()

    useEffect(() => {
        if (!id || isNaN(Number(id))) {
            page.setShowChildren(false)
            page.setShowNotFound(true)
        }
    }, [id])

    useRequestEffect<IUser>({
        state: page,
        url: api.user.read,
        data: {
            id: Number(id)
        },
        onSuccess: setUser
    })


    function InfoField(props: { label: TranslationKey, value: string, icon: ReactElement }) {
        return (
            <Box marginY={1}>
                <TextField
                    fullWidth
                    margin={'dense'}
                    contentEditable={false}
                    disabled={true}
                    variant="outlined"
                    label={translate(props.label)}
                    value={props.value}
                    InputProps={{
                        startAdornment: <InputAdornment position="start" children={props.icon}/>
                        ,
                    }}
                />
            </Box>
        )
    }

    return (
        <StatefulPage state={page}>
            <Only when={{user}}>{({user}) =>
                <Box maxWidth={breakpoints.values['mobile']} width={'100%'} margin={'auto'}>
                    <Title titleKey={'user_details'}/>
                    <InfoField label={'full_name'} value={user.name} icon={<AccountCircleOutlined/>}/>
                    <InfoField label={'email_address'} value={user.email} icon={<Email/>}/>
                    <InfoField label={'mobile_phone_number'} value={user.phone_number} icon={<Phone/>}/>
                    <Box
                        display={'flex'}
                        marginY={4}
                        justifyContent={'space-evenly'}
                        flexDirection={mobile ? 'column' : 'row'}
                    >
                        <Button
                            variant="outlined"
                            color={'secondary'}
                            size={'large'}
                            startIcon={<LocalHospital/>}
                            children={translate('user_charities')}
                            component={Link}
                            to={route.to.user(id).charities}
                        />
                        <Only when={mobile}>
                            <Box marginY={1}/>
                        </Only>
                        <Button
                            variant="outlined"
                            color={'secondary'}
                            size={'large'}
                            startIcon={<EmojiPeople/>}
                            children={translate('user_causes')}
                            component={Link}
                            to={route.to.user(id).causes}
                        />
                    </Box>
                </Box>
            }</Only>
        </StatefulPage>
    )
}
