import {Box} from '@material-ui/core'
import YouTube from 'react-youtube'
import React, {useState} from 'react'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Only} from '../util/Only'

const useStyles = makeStyles(() =>
    createStyles({
        youtubeContainer: {
            position: 'relative',
            width: '100%',
            height: 0,
            paddingBottom: '56.25%',
        },
        youtube: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
    }),
)

export function YoutubeEmbed(props: { id?: string | null }) {
    const {id} = props
    const {youtube, youtubeContainer} = useStyles()
    const [showYoutube, setShowYoutube] = useState(true)

    return (
        <Only when={{showYoutube, id}}>{({id}) =>
            <Box marginTop={2}>
                <YouTube
                    containerClassName={youtubeContainer}
                    className={youtube}
                    videoId={id}
                    onError={() => setShowYoutube(false)}
                />
            </Box>
        }</Only>
    )
}
