import {Backdrop, CircularProgress, CssBaseline} from '@material-ui/core'
import {MuiThemeProvider} from '@material-ui/core/styles'
import {GbbContainer} from 'components/foundation/GbbContainer'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import {SnackbarProvider} from 'notistack'
import React from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {BrowserRouter} from 'react-router-dom'
import {useRawTranslation} from 'state/translation'
import {Footer} from './footer/Footer'
import {NavigationHeader} from './navigation-header/NavigationHeader'
import {Routes} from './routes/Routes'
import {SocialHeader} from './social-header/SocialHeader'
import {theme} from './theme'
import {getVariable} from '../utils/environment'
import siteLogo from './navigation-header/site-logo.png'
export const App = () => {
    const {translation} = useRawTranslation()

    return (
        <BrowserRouter>
            <HelmetProvider>
                <CssBaseline/>
                <MuiThemeProvider theme={theme}>
                    <AppHelmet 
                        title={getVariable('PROJECT_NAME')} 
                        description="Gönülden Bağlıyız Web Sitesi"
                        image={window.location.origin + siteLogo}
                        image_width={'190'}
                        image_height={'56'}
                        themeColor={theme.palette.primary.main}/>
                    <Backdrop
                        open={!translation} appear={false}
                        style={{zIndex: theme.zIndex.drawer + 1, background: 'white'}}>
                        <Only when={!translation}>
                            <CircularProgress color="secondary"/>
                        </Only>
                    </Backdrop>
                    <Only when={!!translation}>
                        <SnackbarProvider autoHideDuration={3000}>
                            <SocialHeader/>
                            <NavigationHeader/>
                            <GbbContainer>
                                <Routes/>
                            </GbbContainer>
                            <Footer/>
                        </SnackbarProvider>
                    </Only>
                </MuiThemeProvider>
            </HelmetProvider>
        </BrowserRouter>
    )
}
