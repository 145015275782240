import {Box, Typography} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import {useTranslation} from 'state/translation'
import {EditableField, StringField} from '../foundation/EditableField'


const useStyles = makeStyles((theme) =>
    createStyles({
        phoneFieldContainer: {
            marginBottom: theme.spacing(2),
            height: theme.spacing(5),

            '& .special-label': {
                left: 10,
                color: 'rgba(0, 0, 0, 0.54)',
            }
        },
        phoneFieldInput: {
            width: '100% !important',
            height: 'inherit',
            transition: 'none !important',
            background: 'transparent',

            '&:focus': {
                borderColor: `${theme.palette.primary.main} !important`,
                boxShadow: 'none !important',
                borderWidth: 2
            },

            '&:disabled': {
                cursor: 'auto',
                color: 'rgba(0, 0, 0, 0.38)',

                borderColor: 'rgba(0, 0, 0, 0.38) !important',
            }

        },
        phoneNumberHintClass: {
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(.5),
            color: theme.palette.error.main,
            lineHeight: 1.66,
            letterSpacing: '0.03333em',
            fontSize: '0.75rem'
        },
    })
)


export function PhoneField(props: { className?: string, field: StringField }) {
    const {field} = props
    const {translate} = useTranslation()
    const {phoneFieldInput, phoneFieldContainer, phoneNumberHintClass} = useStyles()

    return (
        <EditableField field={field}>
            <Box className={props.className} width={'100%'}>
                <PhoneInput
                    disabled={field.disabled}
                    specialLabel={translate('mobile_phone_number')}
                    containerClass={phoneFieldContainer}
                    inputClass={phoneFieldInput}
                    value={field.value}
                    preserveOrder={['preferredCountries']}
                    country={'tr'}
                    preferredCountries={['tr', 'gb']}
                    enableSearch={true}
                    onChange={e => {
                        field.setValue(e)
                        field.clearHint()
                    }}
                />
                <Typography className={phoneNumberHintClass}>{field.hint}</Typography>
            </Box>
        </EditableField>
    )
}
