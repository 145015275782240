export enum StringLength {
    IRRELEVANT = 0,
    XXS = 6,
    XS = 30,
    SM = 100,
    MD = 255,
    LG = 1000,
    XL = 10000,
    TEXT = 65535,
}

export enum EResult {
    success = 'success',
    error = 'error'
}

export type IResult = keyof typeof EResult;
