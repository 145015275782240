import {Button, ButtonGroup, CircularProgress} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Only} from 'components/util/Only'
import {ICategory} from 'types/types'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequestEffect} from 'utils/request'
import {route} from 'utils/route'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        categoryBar: {
            display: 'flex',
            overflowX: 'auto',
        },
        buttonGroup: {
            padding: theme.spacing(2),
            whiteSpace: 'nowrap',
            margin: 'auto',
            '& >*': {
                border: 'none !important',
                fontSize: '1em',
                fontWeight: 'normal',
                [theme.breakpoints.up('tablet')]: {
                    fontSize: '1.2em',
                },
            }
        },
        loadingCircle: {
            margin: 'auto'
        },
    })
)

export function Categories() {
    const [categories, setCategories] = useState<ICategory[]>([])
    const {categoryBar, buttonGroup, loadingCircle} = useStyles()
    const [loading, setLoading] = useState(true)
    const {translate} = useTranslation()

    useRequestEffect<ICategory[]>({
        url: api.category.read_all,
        onStart: () => setLoading(true),
        onSuccess: setCategories,
        onFinish: () => setLoading(false)
    })

    return (
        <Only when={loading || categories.length > 0}>
            <div className={categoryBar}>{loading ?
                <CircularProgress color="secondary" className={loadingCircle}/> :
                <ButtonGroup className={buttonGroup} variant="text">{
                    categories.map((category) =>
                        <Button
                            key={category.name}
                            children={translate(category.name)}
                            size={'large'}
                            component={Link}
                            to={route.to.causes({category: category.name})}
                        />
                    )
                }</ButtonGroup>
            }</div>
        </Only>
    )
}
