import React, { useEffect} from 'react'
import {useRequest, useRequestEffect} from '../../../utils/request'
import {api} from '../../../utils/api'
import {RichText} from '../../../components/misc/RichText'
import {useTranslation} from '../../../state/translation'
import {ICauseType} from '../../../types/cause'
import {useAuth} from '../../../state/auth'
import {useHistory} from 'react-router-dom'
import {ResultData} from './ResultStep'
import {StatefulDialogState} from '../../../components/foundation/StatefulDialog'
import {Only} from '../../../components/util/Only'
import {StateMethods} from '@hookstate/core'
import {PaymentMethodType} from '../../../types/types'
import {StringField, ImageBase64Field} from 'components/foundation/EditableField'

enum SupportStep {
    Result,
    ContactInfo,
    Consent,
    PaymentMethod,
    SmsCheck,
    SmsCheckRequest,
    SmsVerify,
    SmsVerifyRequest,
    SmsCheckRequestAuth,
    SmsVerifyAuth,
    SmsVerifyRequestAuth,
    AuthPhoneCheck,
    TotalDonationCheck,
    AgeVerificationCheck,
    CompetitionInfo,
    LoadingCompetition,
    LoadingCustom,
    Loading
}

export function LoadingStepCompetition(props: {
    dialog: StatefulDialogState
    loadingNewPage: StateMethods<boolean>
    project: ICauseType
    photo_text: StringField
    image: ImageBase64Field
    currentStep:any
    causeId: number
    update: () => void
}) {

    const {translate} = useTranslation()
    const {auth} = useAuth()
    const history = useHistory()
    const {makeRequest} = useRequest()
    
    async function sendData() {
        makeRequest({
            state: props.dialog,
            url: api.cause.support.non_financial_competition,
            data: {
                cause_id: props.causeId,
                // ...(await props.image.box('image_base64')),
                image_base64: props.image.value,
                project: props.project,
                ...props.photo_text.box('photo_text'),
            },
            onSuccess: () => {
                const url = new URL(window.location.origin)
                url.searchParams.append(ResultData.result, 'success')
                url.searchParams.append(ResultData.project, props.project)

                props.update()

                history.push(url.search)
            },
            onFail: resultCode => {
                if (resultCode === 'INVALID_IMAGE_TYPE') {
                    props.dialog.setErrorMessage('competition_photo_error')
                    props.currentStep.set(SupportStep.CompetitionInfo)
                } else{
                    props.dialog.setUnexpectedFrontendError()
                }
            }
        }).then()
    }

    useEffect(()=>{
        sendData()
    }, [])
    
    return (
        <Only when={props.dialog.loading}>
            <RichText text={translate('processing_your_request')} />
        </Only>
    )
}

