import {DialogOpenState, StatefulDialog, useStatefulDialogState} from '../foundation/StatefulDialog'
import React from 'react'
import ReactJson from 'react-json-view'

export function JsonDialog(props: {
    openState: DialogOpenState,
    logObject: Record<string, unknown>
}) {
    const {openState, logObject} = props
    const dialog = useStatefulDialogState()

    return (
        <StatefulDialog
            state={dialog}
            openState={openState}
            content={(
                <>
                    <ReactJson
                        src={logObject}
                        iconStyle={'square'}
                        enableClipboard={false}
                        displayDataTypes={false}
                    />
                </>

            )}
        />
    )
}
