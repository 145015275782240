import {Box, Collapse, Fade, Switch, Typography} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Delete, Save, Subject, SubjectOutlined, TextFieldsOutlined, Visibility, YouTube} from '@material-ui/icons'
import {DateField} from 'components/forms/DateField'
import {DateTimeField} from 'components/forms/DateTimeField'
import {FormField} from 'components/forms/FormField'
import {ImageField} from 'components/forms/ImageField'
import {IntField} from 'components/forms/IntField'
import {SelectField} from 'components/forms/SelectField'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {
    allFieldsDisabled,
    BooleanField,
    FieldModeContext,
    NumberArrayField,
    useBooleanField,
    useImageBase64Field,
    useNumberArrayField,
    useNumberField,
    useStringField, useYoutubeField
} from 'components/foundation/EditableField'
import {ResponsiveButton} from 'components/foundation/ResponsiveButton'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {CustomDivider} from 'components/misc/CustomDivider'
import {RichObject, RichObjectSelector} from 'components/misc/RichObjectSelector'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import {ICertificate} from 'types/types'
import React, {PropsWithChildren, useState, useEffect} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useMobileSize} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {TranslationKey} from 'utils/TranslationKey'
import {useLang} from '../../../state/lang'
import {useAppSnackbar} from 'utils/hooks'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { propsAreEqual } from 'utils/general'
import cert_bg_tr from './media/cert_bg_tr.png'
import cert_bg_en from './media/cert_bg_en.png'
import DOMPurify from 'dompurify'

let content_en:any = ''
let content_tr:any = ''
let content_alt_en:any = ''
let content_alt_tr:any = ''
let cert_icon_path:any = ''

const useStyles = makeStyles(({breakpoints}) =>
    createStyles({
        certificateEditor: {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            width: '100%',
            [breakpoints.up('tablet')]: {
                maxWidth: breakpoints.values['tablet']
            }
        },
    })
)

const RichTextEditor = (props:any) => {
    const contentBlock = htmlToDraft(props.content)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(contentState)
    );

    const [convertedContent, setConvertedContent] = useState<any | null>(null);
    const handleEditorChange = (state:any) => {
        setEditorState(state);
        convertContentToHTML();
    };
    const convertContentToHTML = () => {
        const currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(currentContentAsHTML);
        content_en = currentContentAsHTML;
        props.changeFunc(content_en)
    };
    return (
        <div className={'editor-custom'}>
            <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                toolbar={{
                    options: ['inline', 'colorPicker', 'blockType', 'fontSize'],
                    inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline']
                    },
                    colorPicker: {
                        colors: ['rgb(253, 196, 0)', 'rgb(13, 157, 219)', 'rgb(246, 167, 28)', 'rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                          'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                          'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                          'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                          'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                          'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                    },
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 32, 34, 36, 40, 44, 48, 52, 56, 60, 64, 72, 96],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                }}
            />
        </div>
    );
};

const RichTextEditorTR = (props:any) => {
    const contentBlock = htmlToDraft(props.content)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(contentState)
    );

    const [convertedContent, setConvertedContent] = useState<any | null>(null);
    const handleEditorChange = (state:any) => {
        setEditorState(state);
        convertContentToHTML();
    };
    const convertContentToHTML = () => {
        const currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(currentContentAsHTML);
        content_tr = currentContentAsHTML;
        props.changeFunc(content_tr)
    };
    return (
        <div className={'editor-custom'}>
            <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                toolbar={{
                    options: ['inline', 'colorPicker', 'blockType', 'fontSize'],
                    inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline']
                    },
                    colorPicker: {
                        colors: ['rgb(253, 196, 0)', 'rgb(13, 157, 219)', 'rgb(246, 167, 28)', 'rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                          'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                          'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                          'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                          'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                          'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                    },
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 32, 34, 36, 40, 44, 48, 52, 56, 60, 64, 72, 96],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                }}
            />
        </div>
    );
};

const RichTextEditorAlt = (props:any) => {
    const contentBlock = htmlToDraft(props.content)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(contentState)
    );

    const [convertedContent, setConvertedContent] = useState<any | null>(null);
    const handleEditorChange = (state:any) => {
        setEditorState(state);
        convertContentToHTML();
    };
    const convertContentToHTML = () => {
        const currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(currentContentAsHTML);
        content_alt_en = currentContentAsHTML;
        props.changeFunc(content_alt_en)
    };
    return (
        <div className={'editor-custom'}>
            <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                toolbar={{
                    options: ['inline', 'colorPicker', 'blockType', 'fontSize'],
                    inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline']
                    },
                    colorPicker: {
                        colors: ['rgb(253, 196, 0)', 'rgb(13, 157, 219)', 'rgb(246, 167, 28)', 'rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                          'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                          'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                          'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                          'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                          'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                    },
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 32, 34, 36, 40, 44, 48, 52, 56, 60, 64, 72, 96],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                }}
            />
        </div>
    );
};

const RichTextEditorAltTR = (props:any) => {
    const contentBlock = htmlToDraft(props.content)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(contentState)
    );

    const [convertedContent, setConvertedContent] = useState<any | null>(null);
    const handleEditorChange = (state:any) => {
        setEditorState(state);
        convertContentToHTML();
    };
    const convertContentToHTML = () => {
        const currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(currentContentAsHTML);
        content_alt_tr = currentContentAsHTML;
        props.changeFunc(content_alt_tr)
    };
    return (
        <div className={'editor-custom'}>
            <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                toolbar={{
                    options: ['inline', 'colorPicker', 'blockType', 'fontSize'],
                    inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline']
                    },
                    colorPicker: {
                        colors: ['rgb(253, 196, 0)', 'rgb(13, 157, 219)', 'rgb(246, 167, 28)', 'rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                          'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                          'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                          'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                          'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                          'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                    },
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 32, 34, 36, 40, 44, 48, 52, 56, 60, 64, 72, 96],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                }}
            />
        </div>
    );
};

export function CertificateEditor() {
    const {certificateEditor} = useStyles()
    const {id} = useParams<{ id: string }>()
    const {makeRequest} = useRequest()

    const page = useStatefulPageState()

    const [certificate, setCertificate] = useState<ICertificate>()
    
    const {user} = useUser()
    const {translate} = useTranslation()
    const {lang} = useLang()
    const history = useHistory()
    const mobile = useMobileSize()
    const {showSnackbar} = useAppSnackbar()


    const image = useImageBase64Field()
    const name_en = useStringField(StringLength.SM)
    const name_tr = useStringField(StringLength.SM)
    const cert_group = useStringField(StringLength.XS)
    const status = useStringField(StringLength.XS)
    
    const group_options = [
        ['individual',translate('individual_donation')],
        ['gift',translate('gift')],
        ['org',translate('org')]
    ]

    const status_options = [
        ['1' ,translate('active')],
        ['0' ,translate('not_active')]
    ]

    const fields = [
        image, name_en, name_tr, cert_group, status
    ]
    
    useRequestEffect({
        url: api.certificate.read,
        state: page,
        data: {
            id: Number(id)
        },
        onStart: () => {
            page.setShowChildren(false)
            page.clearMessages()
        },
        onFinish: () => {
            page.setShowChildren(true)
        },
        onSuccess: (certificate: ICertificate) => {
            setCertificate(certificate)
            image.setInitialValue(certificate.icon_path)
            name_en.setInitialValue(certificate.name_en)
            name_tr.setInitialValue(certificate.name_tr)
            cert_group.setInitialValue(certificate.cert_group.toString())
            status.setInitialValue(certificate.status.toString())
            if(certificate.content_en){
                content_en = certificate.content_en
                setTextEn(content_en)
            }
            if(certificate.content_tr){
                content_tr = certificate.content_tr
                setTextTr(content_tr)
            }
            if(certificate.content_alt_en){
                content_alt_en = certificate.content_alt_en
                setTextAltEn(content_alt_en)
            }
            if(certificate.content_alt_tr){
                content_alt_tr = certificate.content_alt_tr
                setTextAltTr(content_alt_tr)
            }
            cert_icon_path = certificate.icon_path
            console.log("icon = " + cert_icon_path)
            page.setShowChildren(true)
        },
        onFail: resultCode => {
            if (resultCode === 'UNAUTHORIZED' || resultCode === 'RESOURCE_NOT_FOUND') {
                page.setShowNotFound(true)
            } else {
                page.setUnexpectedFrontendError()
            }
        }
    }, [lang])

    const [text_tr, setTextTr] = useState(content_tr)
    const changeTextTr = (text_val:any) => {
        setTextTr(text_val)
    }
    
    const [text_en, setTextEn] = useState(content_en)
    const changeTextEn = (text_val:any) => {
        setTextEn(text_val)
    }

    const [text_alt_tr, setTextAltTr] = useState(content_alt_tr)
    const changeTextAltTr = (text_val:any) => {
        setTextAltTr(text_val)
    }

    const [text_alt_en, setTextAltEn] = useState(content_alt_en)
    const changeTextAltEn = (text_val:any) => {
        setTextAltEn(text_val)
    }

    async function onUpdate() {
        const fieldsAreValid = [
            name_en.validate(value => value, 'this_field_is_required'),
            name_tr.validate(value => value, 'this_field_is_required'),
            cert_group.validate(value => value, 'this_field_is_required'),
            status.validate(value => value, 'this_field_is_required'),
        ].every(Boolean)

        if (!fieldsAreValid) {return}

        //show error message and return if html editors are empty
        if (!content_en || !content_tr || !content_alt_en || !content_alt_tr) {
            showSnackbar('error', 'certificate_content_required')
            return
        } 

        //show error message if hidden amount field contains {{$fee_quantity}}
        if(content_alt_en.includes('{{$fee_quantity}}') || content_alt_tr.includes('{{$fee_quantity}}')){
            showSnackbar('error', 'hidden_amount_warning')
            return
        }
    
        page.clearMessages()
        
        makeRequest({
            state: page,
            url: api.certificate.update,
            data: {
                id: Number(id),
                ...(await image.box('image_base64')),
                ...cert_group.box('certificate_group'),
                ...name_en.box('certificate_name_en'),
                ...name_tr.box('certificate_name_tr'),
                ...status.box('status'),
                content_en: content_en,
                content_tr: content_tr,
                content_alt_en: content_alt_en,
                content_alt_tr: content_alt_tr,
            },
            onSuccess: () => {
                page.setSuccessMessage('general_update_success')
            },
            onFail: resultCode => {
                if (resultCode === 'INVALID_IMAGE_TYPE') {page.setErrorMessage('unsupported_image_format_desc')} else {page.setUnexpectedFrontendError()}
            },
            // onFinish: () => {
            //     page.setSuccessMessage('general_update_success')
            // },
        }).then()
    }

    return (
        <>
            <AppHelmet noIndex/>
            <StatefulPage 
                state={page}
                successActions={
                    <Box display={'flex'} justifyContent={'flex-end'}>
                    </Box>
                }
            >
                <Fade in={true}>
                    <div className={certificateEditor}>   
                        <Title titleKey={'edit_certificate'}/>
                        <Only when={{certificate}}>{({certificate}) =>
                            <FieldModeContext.Provider value={'custom'}>  
                                <ImageField
                                    preferredDimensions={'250x250'}
                                    field={image}
                                />
                                <SelectField
                                    label={'status'}
                                    field={status}
                                    itemsAsTuples={status_options.map(option => [option[0], option[1]])}
                                />
                                <SelectField
                                    label={'certificate_group'}
                                    field={cert_group}
                                    itemsAsTuples={group_options.map(option => [option[0], option[1]])}
                                />
                                <FormField
                                    label={'certificate_name_tr'}
                                    field={name_tr}
                                    icon={<TextFieldsOutlined/>}
                                />
                                <FormField
                                    label={'certificate_name_en'}
                                    field={name_en}
                                    icon={<TextFieldsOutlined/>}
                                />
                                
                                <Box className={'admin-cert-preview-wrap'}>
                                    <div className={'admin-cert-bg-wrap'}>
                                        <img src={cert_bg_tr} className={'admin-cert-bg-img edit'} />
                                        <Only when={!!cert_icon_path}>
                                            <div className={'admin-cert-preview-img'}>
                                                <img src={cert_icon_path} width={100} style={{margin: 'auto'}}/>
                                            </div>
                                        </Only>
                                        <div className={'admin-cert-preview-parent edit'}>
                                            <div className={'admin-cert-preview-content'} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text_tr || '', {
                                                USE_PROFILES: { html: true },
                                            }) }}></div>
                                        </div>
                                    </div>
                                </Box>
                                <Box>
                                    <p style={{color : 'rgba(0, 0, 0, 0.54)'}}>{translate('certificate_content_tr')}</p>
                                </Box>
                                <RichTextEditorTR content={content_tr} changeFunc={changeTextTr} />

                                <Box className={'admin-cert-preview-wrap'}>
                                    <div className={'admin-cert-bg-wrap'}>
                                        <img src={cert_bg_tr} className={'admin-cert-bg-img edit'} />
                                        <Only when={!!cert_icon_path}>
                                            <div className={'cert-preview-img'}>
                                                <img src={cert_icon_path} width={100} style={{margin: 'auto'}}/>
                                            </div>
                                        </Only>
                                        <div className={'admin-cert-preview-parent edit'}>
                                            <div className={'admin-cert-preview-content'} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text_alt_tr || '', {
                                                USE_PROFILES: { html: true },
                                            }) }}></div>
                                        </div>
                                    </div>
                                </Box>
                                <Box> 
                                    <p style={{color : 'rgba(0, 0, 0, 0.54)'}}>{translate('certificate_content_alt_tr')}</p>
                                </Box>
                                <RichTextEditorAltTR content={content_alt_tr} changeFunc={changeTextAltTr}/>
                                
                                <Box className={'admin-cert-preview-wrap'}>
                                    <div className={'admin-cert-bg-wrap'}>
                                        <img src={cert_bg_en} className={'admin-cert-bg-img edit'} />
                                        <Only when={!!cert_icon_path}>
                                            <div className={'cert-preview-img'}>
                                                <img src={cert_icon_path} width={100} style={{margin: 'auto'}}/>
                                            </div>
                                        </Only>
                                        <div className={'admin-cert-preview-parent edit'}>
                                            <div className={'admin-cert-preview-content'} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text_en || '', {
                                                USE_PROFILES: { html: true },
                                            }) }}></div>
                                        </div>
                                    </div>
                                </Box>
                                <Box> 
                                    <p style={{color : 'rgba(0, 0, 0, 0.54)'}}>{translate('certificate_content_en')}</p>
                                </Box>
                                <RichTextEditor content={content_en} changeFunc={changeTextEn}/>
                                
                                <Box className={'admin-cert-preview-wrap'}>
                                    <div className={'admin-cert-bg-wrap'}>
                                        <img src={cert_bg_en} className={'admin-cert-bg-img edit'} />
                                        <Only when={!!cert_icon_path}>
                                            <div className={'cert-preview-img'}>
                                                <img src={cert_icon_path} width={100} style={{margin: 'auto'}}/>
                                            </div>
                                        </Only>
                                        <div className={'admin-cert-preview-parent edit'}>
                                            <div className={'admin-cert-preview-content'} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text_alt_en || '', {
                                                USE_PROFILES: { html: true },
                                            }) }}></div>
                                        </div>
                                    </div>
                                </Box>
                                <Box> 
                                    <p style={{color : 'rgba(0, 0, 0, 0.54)'}}>{translate('certificate_content_alt_en')}</p>
                                </Box>
                                <RichTextEditorAlt content={content_alt_en} changeFunc={changeTextAltEn}/>

                                <Box display={'flex'} alignSelf={'end'} marginY={mobile ? 2 : 4}>
                                    <ResponsiveButton
                                        label={'update'}
                                        icon={<Save/>}
                                        onClick={onUpdate}
                                        variant={'contained'}
                                        color="primary"
                                    />
                                    <Box marginX={3}/>
                                </Box>
                            </FieldModeContext.Provider>
                        }</Only>
                    </div>
                </Fade>
            </StatefulPage>
        </>
    )
}