import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {route} from 'utils/route'
import {AdminPanel} from 'pages/admin/AdminPanel'
import {Translation} from 'pages/admin/translation/Translation'
import {WebsiteManagement} from 'pages/admin/website-management/WebsiteManagement'
import {ReviewUsers} from 'pages/admin/ReviewUsers'
import {ReviewCharities} from 'pages/admin/ReviewCharities'
import {ReviewCauses} from 'pages/admin/ReviewCauses'
import {NotFound} from 'components/mini-pages/NotFound'
import {StatusManagement} from 'pages/admin/StatusManagement'
import {StatsManagement} from 'pages/admin/StatsManagement'
import {PaymentLogs} from 'pages/admin/PaymentLogs'
import {DonationReport} from 'pages/admin/DonationReport'
import {ProjectsCurrentReport} from 'pages/admin/ProjectsCurrentReport'
import {useUser} from 'state/user'
import {Dashboard} from 'pages/admin/Dashboard'
import {ReviewFeaturedCauses} from 'pages/admin/ReviewFeaturedCauses'
import {BannerManagement} from 'pages/admin/banner-management/BannerManagement'
import {CertificateManagement} from 'pages/admin/certificates/CertificateManagement'
import {AddCertificate} from 'pages/admin/certificates/AddCertificate'
//import {FourFiveCert} from 'pages/admin/certificates/FourFiveCert'
import {CertificateEditor} from 'pages/admin/certificates/CertificateEditor'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {AppHelmet} from 'components/util/AppHelmet'
import {UserDetails} from 'pages/user/UserDetails'
import {UserCharities} from 'pages/user/charities/UserCharities'
import {UserCauses} from 'pages/user/causes/UserCauses'

export function AdminRoutes() {
    const {user} = useUser()

    return (
        <>
            <AppHelmet noIndex/>
            <AuthorizedContent when={user?.is_admin}>
                <Switch>
                    <Route exact path={route.to.admin.page}> <AdminPanel/> </Route>
                    <Route exact path={route.to.admin.banner_management}> <BannerManagement/> </Route>
                    <Route exact path={route.to.admin.dashboard}> <Dashboard/> </Route>
                    <Route exact path={route.to.admin.status_management}> <StatusManagement/> </Route>
                    <Route exact path={route.to.admin.stats_management}> <StatsManagement/> </Route>
                    <Route exact path={route.to.admin.translation}> <Translation/> </Route>
                    <Route exact path={route.to.admin.website_management}> <WebsiteManagement/> </Route>
                    <Route exact path={route.to.admin.review_users}> <ReviewUsers/> </Route>
                    <Route exact path={route.to.admin.review_charities}> <ReviewCharities/> </Route>
                    <Route exact path={route.to.admin.review_causes}> <ReviewCauses/> </Route>
                    <Route exact path={route.to.admin.featured_causes}> <ReviewFeaturedCauses/> </Route>
                    <Route exact path={route.to.admin.payment_logs}> <PaymentLogs/> </Route>
                    
                    <Route exact path={route.to.admin.donation_report}> <DonationReport/> </Route>
                    <Route exact path={route.to.admin.projects_current_report}> <ProjectsCurrentReport/> </Route>
            
                    <Route exact path={route.to.admin.certificate_management}> <CertificateManagement/> </Route>
                    <Route exact path={route.to.admin.add_certificate}> <AddCertificate/> </Route>
                    {/* <Route exact path={route.to.admin.four_five_cert}> <FourFiveCert/> </Route> */}
                    <Route exact path={route.to.admin.certificate(':id').edit}> <CertificateEditor/> </Route>

                    <Route exact path={route.to.user(':id').page}> <UserDetails/> </Route>
                    <Route exact path={route.to.user(':id').charities}> <UserCharities/> </Route>
                    <Route exact path={route.to.user(':id').causes}> <UserCauses/> </Route>

                    <Route component={NotFound}/>
                </Switch>
            </AuthorizedContent>
        </>
    )
}
