import {Box, Button, Card, Typography, Divider, ListItemIcon, Menu, MenuItem} from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {
    Image,
    FileCopy,
    Facebook,
    Twitter,
    LinkedIn,
    Save,
    Share,
    FileCopyOutlined,
} from '@material-ui/icons'
import {useTranslation} from 'state/translation'
import {AppHelmet} from 'components/util/AppHelmet'
import {api} from 'utils/api'
import {Link, useParams} from 'react-router-dom'
import {TranslationKey} from 'utils/TranslationKey'
import {Only} from 'components/util/Only'
import {useRequestEffect, useRequest} from '../../utils/request'
import {CauseCard} from 'components/cards/CauseCard'
import {ISingleCertificate} from 'types/types'
import {ICause} from 'types/cause'
import {useLang} from '../../state/lang'
import {useMobileSize} from 'utils/hooks'
import {bindMenu, bindTrigger, usePopupState} from 'material-ui-popup-state/hooks'
import {useAppSnackbar} from 'utils/hooks'
// import Helmet from 'react-helmet'
import {LoadingOverlay} from 'components/foundation/LoadingOverlay'

let flag = false

export function Certificate() {
    const mobile = useMobileSize()
    const {translate} = useTranslation()
    const page = useStatefulPageState()
    const {id} = useParams<{ id: string }>()
    const [certificate, setCertificate] = useState<ISingleCertificate>()
    const [cause, setCause] = useState<ICause | undefined>(undefined)
    const {lang} = useLang()
    const popupState = usePopupState({variant: 'popover', popupId: 'avatar'})
    const popupStateAlt= usePopupState({variant: 'popover', popupId: 'avatar'})
    const {showSnackbar} = useAppSnackbar()
    const {makeRequest} = useRequest()

    const cur_url = window.location.href

    useRequestEffect({
        url: api.certificate.read_single_public,
        state: page,
        if: !!id,
        data: {
            id: id
        },
        onStart: () => {
            page.setShowCertNotFound(false)
            page.setShowChildren(false)
        },
        //onFinish: () => page.setShowChildren(true),
        onSuccess: (certificate: ISingleCertificate) => {
            //page.setShowChildren(true)
            setCertificate(certificate)
            getCause(certificate.cause_id)
        },
        onFail: () => {
            flag = true
            page.setShowCertNotFound(true)
        }
    }, [])

    function getCause (cause_id:string) {
        makeRequest({
            url: api.cause.read,
            state: page,
            if: !!cause_id,
            data: {
                id: Number(cause_id)
            },
            onSuccess: (cause: ICause) => {
                setCause(cause)
                flag = true
                page.setShowChildren(true)
            }
        }).then()
    }

    return (
        <LoadingOverlay loading={!flag}>
        <StatefulPage state={page}>
            <AppHelmet 
                title={translate('donation_certificate')} 
                description={translate('donation_certificate')}
                image={certificate?.image_path}
                image_width={'1124'}
                image_height={'794'}
            />
            
            <Box marginTop={2} className={'certificate-view-parent'}>
                <Only when={{certificate}}>{({certificate}) =>
                    <Box marginX={1} className={'certificate-image-wrap'}>
                        <img className={'certificate-main-image'} src={certificate?.image_path} style={{margin: 'auto'}}/>
                    </Box>
                }</Only>
                <Box marginX={1} className={'certificate-details-wrap'}>
                    <Only when={{cause}}>{({cause}) =>
                        <Box className={'custom-cause-wrap'}>
                            <CauseCard
                            className={'custom-cause-card'}
                            key={cause?.id}
                            mode={'normal'}
                            cause={cause}
                            />
                        </Box>
                    }</Only>
                    <Only when={{certificate}}>{({certificate}) =>
                        <Box className={'certificate-buttons-wrap'}>
                            <Button
                                className={'custom-certificate-btn first'}
                                size={'medium'}
                                variant={'outlined'}
                                color="secondary"
                                startIcon={<Save className={'cert-btn-icon'}/>}
                                {...bindTrigger(popupState)}
                                children={translate('download_alt')}
                            />
                            <Menu {...bindMenu(popupState)} keepMounted={true} className={'custom-dropdown-parent'}>
                                <a className={'custom-cert-link'} href={certificate.image_path} target="_blank" rel="noreferrer">
                                    <MenuItem
                                        key=".jpg"
                                        onClick={() => {
                                            popupState.close()
                                        }}
                                    >
                                        <ListItemIcon><Image/></ListItemIcon>
                                        .jpg
                                    </MenuItem>
                                </a>
                                <Divider/>
                                <a className={'custom-cert-link'} href={certificate.file_path} target="_blank" rel="noreferrer">
                                    <MenuItem
                                        key=".pdf"
                                        onClick={() => {
                                            popupState.close()
                                        }}
                                    >
                                        <ListItemIcon><FileCopy/></ListItemIcon>
                                        .pdf
                                    </MenuItem>
                                </a>
                            </Menu>
                            <Button
                                className={'custom-certificate-btn'}
                                size={'medium'}
                                variant={'outlined'}
                                color="secondary"
                                startIcon={<Share className={'cert-btn-icon'}/>}
                                {...bindTrigger(popupStateAlt)}
                                children={translate('share')}
                            />
                            <Menu {...bindMenu(popupStateAlt)} keepMounted={true} className={'custom-dropdown-parent'}>
                                <a className={'custom-cert-link'} href={"https://www.facebook.com/sharer/sharer.php?u=" + cur_url} target="_blank" rel="noreferrer">
                                    <MenuItem
                                        key="Facebook"
                                        onClick={() => {
                                            popupState.close()
                                        }}
                                    >
                                        <ListItemIcon><Facebook/></ListItemIcon>
                                        Facebook
                                    </MenuItem>
                                </a>
                                <Divider/>
                                <a className={'custom-cert-link'} href={"https://twitter.com/share?url=" + cur_url} target="_blank" rel="noreferrer">
                                    <MenuItem
                                        key="Twitter"
                                        onClick={() => {
                                            popupState.close()
                                        }}
                                    >
                                        <ListItemIcon><Twitter/></ListItemIcon>
                                        Twitter
                                    </MenuItem>
                                </a>
                                <Divider/>
                                <a className={'custom-cert-link'} href={"http://www.linkedin.com/shareArticle?mini=true&amp;url=" + cur_url} target="_blank" rel="noreferrer">
                                    <MenuItem
                                        key="LinkedIn"
                                        onClick={() => {
                                            popupState.close()
                                        }}
                                    >
                                        <ListItemIcon><LinkedIn/></ListItemIcon>
                                        LinkedIn
                                    </MenuItem>
                                </a>
                                <Divider/>
                                <MenuItem
                                    key={'copy_link'}
                                    onClick={() => {
                                        popupState.close()
                                        navigator.clipboard.writeText(cur_url)
                                        showSnackbar('success', 'cert_link_copied')
                                    }}
                                >
                                    <ListItemIcon><FileCopyOutlined/></ListItemIcon>
                                    {translate('copy_link')}
                                </MenuItem>
                            </Menu>
                        </Box>
                    }</Only>
                </Box>
            </Box>
        </StatefulPage>
        </LoadingOverlay>
    )
}