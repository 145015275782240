import React, {CSSProperties, PropsWithChildren} from 'react'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints'
import {Box, BoxProps} from '@material-ui/core'

export function ResponsiveGrid(props: PropsWithChildren<{
    className?: string,
    rowGap?: number,
    columnGap?: number,
    style?: CSSProperties,
    breakpointColumns: Partial<Record<Breakpoint, number>>
} & BoxProps>) {
    const {className, rowGap, columnGap, style, breakpointColumns, ...boxProps} = props

    const useStyles = makeStyles(({spacing, breakpoints}) =>
        createStyles({
            responsiveGrid: {
                display: 'grid',
                rowGap: spacing(rowGap ?? 4) + 'px',
                columnGap: spacing(columnGap ?? 4) + 'px',
                margin: 'auto',
                width: 'max-content',
                gridTemplateColumns: '1fr',
                [breakpoints.up('mobile')]: {
                    ...(breakpointColumns.mobile && {gridTemplateColumns: `repeat(${breakpointColumns.mobile},1fr)`})
                },
                [breakpoints.up('tablet')]: {
                    ...(breakpointColumns.tablet && {gridTemplateColumns: `repeat(${breakpointColumns.tablet},1fr)`})
                },
                [breakpoints.up('laptop')]: {
                    ...(breakpointColumns.laptop && {gridTemplateColumns: `repeat(${breakpointColumns.laptop},1fr)`})
                },
                [breakpoints.up('desktop')]: {
                    ...(breakpointColumns.desktop && {gridTemplateColumns: `repeat(${breakpointColumns.desktop},1fr)`})
                },
            },
        })
    )

    const {responsiveGrid} = useStyles()

    return (
        <Box className={[className, responsiveGrid].join(' ')} style={style} {...boxProps}>
            {props.children}
        </Box>
    )
}
