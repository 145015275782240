import {Fade, Typography} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {CheckCircleOutlined} from '@material-ui/icons'
import {AppHelmet} from 'components/util/AppHelmet'
import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {route} from 'utils/route'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxWidth: theme.breakpoints.values['tablet'],
            margin: `${theme.spacing(2)}px auto`,
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        icon: {
            margin: 'auto',
            color: 'green',
            height: 120,
            width: 120,
        },
        title: {
            color: '#555',
            fontWeight: 'bold',
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            textAlign: 'center',
        },
        desc: {
            color: '#888',
            textAlign: 'center',
        }
    }),
)

export function SignUpSuccess() {
    const {translate} = useTranslation()
    const {container, icon, title, desc} = useStyles()

    const history = useHistory()
    const {user} = useUser()

    useEffect(() => {
        if (user) {
            history.push(route.to.home)
        }
    }, [user])

    return (
        <>
            <AppHelmet noIndex/>
            <Fade in={true}>
                <div className={container}>
                    <CheckCircleOutlined className={icon}/>
                    <Typography variant={'h3'} className={title}>
                        {translate('sign_up_success_title')}
                    </Typography>
                    <Typography variant={'h6'} className={desc}>
                        {translate('sign_up_success_desc')}
                    </Typography>
                </div>
            </Fade>
        </>
    )
}
