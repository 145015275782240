import {Box, BoxProps, Typography} from '@material-ui/core'
import {AppHelmet} from 'components/util/AppHelmet'
import React from 'react'
import {useTranslation} from 'state/translation'
import {TranslationKey} from 'utils/TranslationKey'

export function Title(props: {
    titleKey: TranslationKey
} & Omit<BoxProps, 'children'>) {
    const {titleKey, ...boxProps} = props
    const {translate} = useTranslation()

    const title = translate(titleKey)

    return (
        <Box marginBottom={2} {...boxProps}>
            <AppHelmet title={title}/>
            <Typography
                variant={'h3'}
                align={'center'}
                style={{
                    fontWeight: 'bold',
                    userSelect: 'none',
                    color: '#333'
                }}
                children={title}
            />
        </Box>
    )
}
