import {Column} from '@material-table/core'
import {Box, Button, CircularProgress, TextField} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Add, Delete, Save} from '@material-ui/icons'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {ConfirmationDialog} from 'components/dialogs/ConfirmationDialog'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {CustomSelect} from 'components/misc/CustomSelect'
import {CustomTable} from 'components/misc/CustomTable'
import {Title} from 'components/misc/Title'
import {IStatus} from 'types/types'
import {ColorPicker} from 'material-ui-color'
import React, {useState} from 'react'
import {refreshTranslations, useRawTranslation, useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useAppSnackbar, useTabletSize} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'
import {TranslationKey} from 'utils/TranslationKey'
import {propsAreEqual} from 'utils/general'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        menuItem: {
            minWidth: spacing(10),
            paddingLeft: spacing(2),
            paddingRight: spacing(2),
        },
        colorButton: {
            '& button': {
                height: spacing(4),
                width: spacing(8),

                '& span,div': {
                    height: '100%',
                    width: '100%',
                },
            }
        }
    })
)

type StateProps = { color: string, is_public: boolean, is_donation_enabled: boolean, new: boolean }
type StatusState = StateProps & { loading: boolean }
type StatusWithState = IStatus & { state: StatusState }

export function StatusManagement() {
    const page = useStatefulPageState()
    const {menuItem, colorButton} = useStyles()
    const {translation} = useRawTranslation()
    const {translate} = useTranslation()
    const {showSnackbar} = useAppSnackbar()
    const tablet = useTabletSize()
    const {makeRequest} = useRequest()

    const confirmationDialog = useDialogOpenState<StatusWithState | undefined>(undefined)

    const [statuses, setStatuses] = useState<StatusWithState[]>([])

    const [newStatusName, setNewStatusName] = useState('')

    function updateStatusState(name: string, props: Partial<StatusState>) {
        setStatuses(statuses.map(it => it.name === name ? {...it, state: {...it.state, ...props}} : it))
    }

    function updateStatusProps(name: string, props: Partial<StatusWithState>) {
        setStatuses(statuses.map(it => it.name === name ? {...it, ...props} : it))
    }

    function deleteStatus(name: string) {
        setStatuses(statuses.filter(it => it.name !== name))
    }

    function YesNoSelect(props: {
        current_state: boolean,
        onItemSelect?: (item: { key: boolean, text: string }) => void
    }) {
        return (
            <CustomSelect
                variant={'outlined'}
                endIcon={<KeyboardArrowDownIcon/>}
                menuItemClassName={menuItem}
                onItemSelect={props.onItemSelect}
                items={[
                    {key: true, text: translate('yes')},
                    {key: false, text: translate('no')},
                ]}
                children={translate(props.current_state ? 'yes' : 'no')}
            />
        )
    }

    useRequestEffect<IStatus[]>({
        state: page,
        url: api.status.read_all,
        onSuccess: data => setStatuses(data.map(it => ({
            ...it,
            state: {
                new: false,
                loading: false,
                color: it.color,
                is_public: it.is_public,
                is_donation_enabled: it.is_donation_enabled
            }
        }))),
    })

    function onAddNewStatus() {
        setStatuses([...statuses, {
            name: newStatusName as TranslationKey,
            color: '#000000',
            is_public: false,
            is_system: false,
            is_donation_enabled: false,
            state: {
                new: true,
                loading: false,
                is_public: false,
                is_donation_enabled: false,
                color: '#000000'
            }
        }])

        setNewStatusName('')
    }

    function onSave(status: StatusWithState) {
        // return object only if the given property was changed
        const param = (prop: keyof Pick<StateProps, 'color' | 'is_public' | 'is_donation_enabled'>) =>
            status[prop] !== status.state[prop] ? {[prop]: status.state[prop]} : {}

        makeRequest({
            url: api.status.update,
            data: {
                name: status.name,
                ...param('color'),
                ...param('is_public'),
                ...param('is_donation_enabled'),
            },
            onStart: () => updateStatusState(status.name, {loading: true}),
            onFinish: success => {
                updateStatusProps(status.name, {
                    ...(success && status.state),
                    state: {
                        ...status.state,
                        loading: false
                    }
                })
                showSnackbar(success ? 'success' : 'error',
                    success ? 'status_update_success' : 'status_update_fail')
            },
        }).then()
    }

    function onCreate(status: StatusWithState) {
        makeRequest({
            url: api.status.create,
            data: {
                name: status.name,
                color: status.state.color,
                is_public: status.state.is_public,
                is_donation_enabled: status.state.is_donation_enabled
            },
            onStart: () => updateStatusState(status.name, {loading: true}),
            onSuccess: () => {
                showSnackbar('success', 'status_create_success')
                refreshTranslations()
            },
            onFail: () => showSnackbar('error', 'status_create_fail'),
            onFinish: success => {
                updateStatusProps(status.name, {
                    ...(success && status.state),
                    state: {
                        ...status.state,
                        loading: false,
                        ...(success && {new: false})
                    }
                })
            },
        }).then()
    }

    function onDelete(status?: StatusWithState) {
        if (!status) {
            return
        }

        if (status.state.new) {
            deleteStatus(status.name)
        } else {
            makeRequest({
                url: api.status.delete,
                data: {
                    name: status.name,
                },
                onStart: () => updateStatusState(status.name, {loading: true}),
                onFinish: success => {
                    if (success) {
                        deleteStatus(status.name)
                        refreshTranslations()
                    } else {
                        updateStatusState(status.name, {loading: false})
                    }

                    showSnackbar(
                        success ? 'success' : 'error',
                        success ? 'status_delete_success' : 'status_delete_fail'
                    )
                },
            }).then()
        }
    }

    return (
        <StatefulPage state={page}>
            <ConfirmationDialog
                openState={confirmationDialog}
                text={'delete_status_confirm'}
                onOk={onDelete}
            />
            <Title titleKey={'manage_statuses'}/>
            <CustomTable
                data={statuses}
                title={
                    <Box display={'flex'} flexDirection={tablet ? 'column' : 'row'} paddingTop={2}>
                        <TextField
                            margin={'dense'}
                            variant={'outlined'}
                            label={translate('status_key')}
                            inputProps={{maxLength: StringLength.XS}}
                            helperText={`${newStatusName.length}/${StringLength.XS}`}
                            value={newStatusName}
                            onChange={event => setNewStatusName(event.target.value)}
                        />
                        <Box margin={1}/>
                        <Button
                            variant={'outlined'}
                            color={'secondary'}
                            size={'large'}
                            startIcon={<Add/>}
                            style={{margin: 'auto'}}
                            disabled={
                                !!statuses.find(it => it.name === newStatusName) || // No same names
                                !!Object.keys(translation ?? {}).find(key => key === newStatusName) || // Not same as translation keys
                                newStatusName === ''
                            }
                            children={translate('new_status')}
                            onClick={onAddNewStatus}
                        />
                    </Box>
                }
                columns={[
                    {
                        title: translate('key'), field: 'name'
                    },
                    {
                        title: translate('color'),
                        render: data =>
                            <div className={colorButton}>
                                <ColorPicker
                                    palette={null}
                                    disableAlpha={true}
                                    hideTextfield={true}
                                    value={data.state.color}
                                    onChange={color => updateStatusState(data.name, {
                                        color: color.css.backgroundColor
                                    })}
                                />
                            </div>

                    },
                    {
                        title: translate('is_public'),
                        render: data =>
                            <CustomSelect
                                variant={'outlined'}
                                endIcon={<KeyboardArrowDownIcon/>}
                                menuItemClassName={menuItem}
                                onItemSelect={item => {
                                    if (data.state.is_public !== item.key) {
                                        updateStatusState(data.name, {is_public: item.key})
                                    }
                                }}
                                items={[
                                    {key: true, text: translate('yes')},
                                    {key: false, text: translate('no')},
                                ]}
                                children={translate(data.state.is_public ? 'yes' : 'no')}
                            />
                    },
                    {
                        title: translate('is_donation_enabled'),
                        render: data =>
                            <YesNoSelect
                                current_state={data.state.is_donation_enabled}
                                onItemSelect={item => {
                                    if (data.state.is_donation_enabled !== item.key) {
                                        updateStatusState(data.name, {is_donation_enabled: item.key})
                                    }
                                }}
                            />
                    },
                    {
                        title: translate('is_system'), field: 'is_system',
                        lookup: {false: translate('no'), true: translate('yes')}
                    }
                ] as Column<StatusWithState>[]}
                actions={[
                    status => {
                        const disabled = propsAreEqual(status, status.state,
                            ['is_donation_enabled', 'is_public', 'color']
                        )

                        return {
                            icon: () => status.state.loading ?
                                <CircularProgress color={'primary'} size={24}/> :
                                <Save color={disabled ? 'disabled' : 'secondary'}/>,
                            disabled: disabled || status.state.loading,
                            hidden: status.state.new,
                            onClick: () => onSave(status)
                        }
                    },
                    status => ({
                        icon: () => status.state.loading ?
                            <CircularProgress color={'primary'} size={24}/> :
                            <Add color={'secondary'}/>,
                        hidden: !status.state.new,
                        onClick: () => onCreate(status)
                    }),
                    status => ({
                        icon: () => status.state.loading ?
                            <CircularProgress color={'primary'} size={24}/> :
                            <Delete color={status.is_system ? 'disabled' : 'secondary'}/>,
                        disabled: status.is_system,
                        onClick: () => confirmationDialog.open(status)
                    })
                ]}
            />
        </StatefulPage>
    )
}
