import React, {CSSProperties, PropsWithChildren, useState} from 'react'
import {Card} from '@material-ui/core'

export function ElevatedCard(props: PropsWithChildren<{
    minElevation?: number,
    maxElevation?: number,
    className?: string,
    style?: CSSProperties,
}>) {
    const {children, className, maxElevation, minElevation, style} = props

    const [elevation, setElevation] = useState(minElevation ?? 1)

    return (
        <Card
            onMouseOver={() => setElevation(maxElevation ?? 4)}
            onMouseOut={() => setElevation(minElevation ?? 1)}
            {...{
                className,
                elevation,
                style,
                children,
            }}
        />
    )
}
