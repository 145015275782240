import {Box, Collapse, Fade, Switch, Typography} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Delete, Save, Subject, SubjectOutlined, GetApp, TextFieldsOutlined, Visibility, YouTube, ExpandLess, ExpandMore, KeyboardReturnTwoTone} from '@material-ui/icons'
import {DateField} from 'components/forms/DateField'
import {DateTimeField} from 'components/forms/DateTimeField'
import {FormField} from 'components/forms/FormField'
import {ImageField} from 'components/forms/ImageField'
import {IntField} from 'components/forms/IntField'
import {SelectField} from 'components/forms/SelectField'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {
    allFieldsDisabled,
    BooleanField,
    FieldModeContext,
    NumberArrayField,
    useBooleanField,
    useImageBase64Field,
    useNumberArrayField,
    useNumberField,
    useStringField, useYoutubeField
} from 'components/foundation/EditableField'
import {ResponsiveButton} from 'components/foundation/ResponsiveButton'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {CustomDivider} from 'components/misc/CustomDivider'
import {RichObject, RichObjectSelector} from 'components/misc/RichObjectSelector'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import {ICause} from 'types/cause'
import {ICharity} from 'types/charity'
import {ICategory} from 'types/types'
import React, {PropsWithChildren, useState, useEffect} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useMobileSize} from 'utils/hooks'
import {useRequest, useRequestEffect, useRequestCustom} from 'utils/request'
import {route} from 'utils/route'
import {TranslationKey} from 'utils/TranslationKey'
import {DeleteCauseDialog} from './components/DeleteCauseDialog'
import {useLang} from '../../../state/lang'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import DOMPurify from 'dompurify'
import {useAppSnackbar} from 'utils/hooks'
import {CauseDocumentPicker} from './CauseDocumentPicker'
import {RichText} from 'components/misc/RichText'


let html:any = ''

const useStyles = makeStyles(({breakpoints}) =>
    createStyles({
        causeEditor: {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            width: '100%',
            [breakpoints.up('tablet')]: {
                maxWidth: breakpoints.values['tablet']
            }
        },
    })
)

const RichTextEditor = (props:any) => {
    const contentBlock = htmlToDraft(props.description)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(contentState)
    );

    const [convertedContent, setConvertedContent] = useState<any | null>(null);
    const handleEditorChange = (state:any) => {
        setEditorState(state);
        convertContentToHTML();
    };
    const convertContentToHTML = () => {
        const currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(currentContentAsHTML);
        html = currentContentAsHTML;
    };
    return (
        <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            toolbar={{
                options: ['inline', 'colorPicker'],
                inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['bold', 'italic', 'underline']
                },
            }}
        />
    );
};

function CauseProject(props: PropsWithChildren<{
    is_active: BooleanField,
    label: TranslationKey
    description: TranslationKey
}>) {
    const {is_active, label, description, children} = props
    const {translate} = useTranslation()

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography children={translate(label)}/>
                <Switch
                    checked={is_active.value}
                    onChange={e => is_active.setValue(e.target.checked)}
                />
            </Box>
            <Typography variant={'caption'} children={translate(description)}/>
            <Collapse in={is_active.value} appear={false}>
                {children}
            </Collapse>
        </>
    )
}

export function CauseEditor() {
    const {causeEditor} = useStyles()
    const {id} = useParams<{ id: string }>()
    const {makeRequest} = useRequest()
    const {makeRequestCustom} = useRequestCustom()

    const page = useStatefulPageState()
    const deleteDialog = useDialogOpenState(undefined)

    const [cause, setCause] = useState<ICause>()
    const [categories, setCategories] = useState<ICategory[]>()

    const {user} = useUser()
    const {translate} = useTranslation()
    const {lang} = useLang()
    const history = useHistory()
    const mobile = useMobileSize()

    const SmsRules = DOMPurify.sanitize(translate('admin_sms_keywords_rules') || '', {
        USE_PROFILES: { html: true },
      });
    const show_rules = useBooleanField(false)
   
    const image = useImageBase64Field()
    const category = useStringField(StringLength.XS)
    const name = useStringField(StringLength.SM)
    const summary = useStringField(StringLength.SM)
    const description = useStringField(StringLength.XL)
    const youtubeID = useYoutubeField()
    const startDate = useStringField()
    const endDate = useStringField()
    const causeSponsors = useNumberArrayField([])
    const {showSnackbar} = useAppSnackbar()

    const activity = {
        is_active: useBooleanField(false),
        datetime: useStringField(),
        capacity: useNumberField(0),
    }
    const donation = {
        is_active: useBooleanField(false),
        goal: useNumberField(0),
        enable_invoice_initial: useBooleanField(false),
        enable_sms_initial: useBooleanField(false),
        keywords_tr_initial: useStringField(StringLength.SM),
        keywords_en_initial: useStringField(StringLength.SM),
        enable_invoice: useBooleanField(false),
        enable_sms: useBooleanField(false),
        keywords_tr: useStringField(StringLength.SM),
        keywords_en: useStringField(StringLength.SM),
    }
    const event = {
        is_active: useBooleanField(false),
        datetime: useStringField(),
        capacity: useNumberField(0),
        ticket_price: useNumberField(0),
    }
    const volunteering = {
        is_active: useBooleanField(false),
        capacity: useNumberField(0),
    }

    const competition = {
        is_active: useBooleanField(false),
        capacity: useNumberField(0),
        file_path: useStringField(),
    }

    const comp_file_path = useStringField()

    const fields = [
        image, category, name, summary, description, youtubeID, startDate, endDate, // Base
        ...Object.values(activity),
        ...Object.values(donation),
        ...Object.values(event),
        ...Object.values(volunteering),
        ...Object.values(competition),
    ]

    useRequestEffect({
        url: api.cause.read,
        state: page,
        data: {
            id: Number(id)
        },
        onStart: () => {
            page.setShowChildren(false)
            page.clearMessages()
        },
        onFinish: () => {
            page.setShowChildren(true)
        },
        onSuccess: (cause: ICause) => {
            setCause(cause)

            image.setInitialValue(cause.image_url)
            category.setInitialValue(cause.category)
            name.setInitialValue(cause.name)
            summary.setInitialValue(cause.summary)
            description.setInitialValue(cause.description)
            html = cause.description
            youtubeID.setInitialValue(cause.youtube_id)
            startDate.setInitialValue(cause.start_date)
            endDate.setInitialValue(cause.end_date)
            causeSponsors.setInitialValue(cause.sponsors.map(sponsor => sponsor.id))

            // Activity
            activity.is_active.setInitialValue(cause.projects.activity.is_active)
            activity.datetime.setInitialValue(cause.projects.activity.datetime)
            activity.capacity.setInitialValue(cause.projects.activity.capacity)

            // Donation
            donation.is_active.setInitialValue(cause.projects.donation.is_active)
            donation.goal.setInitialValue(cause.projects.donation.goal)
            donation.enable_invoice_initial.setInitialValueCustom(cause.projects.donation.enable_invoice_initial)
            donation.enable_sms_initial.setInitialValueCustom(cause.projects.donation.enable_sms_initial)
            donation.keywords_tr_initial.setInitialValueCustom(cause.projects.donation.keywords_tr_initial)
            donation.keywords_en_initial.setInitialValueCustom(cause.projects.donation.keywords_en_initial)
            donation.enable_invoice.setInitialValueCustom(cause.projects.donation.enable_invoice)
            donation.enable_sms.setInitialValueCustom(cause.projects.donation.enable_sms)
            donation.keywords_tr.setInitialValueCustom(cause.projects.donation.keywords_tr)
            donation.keywords_en.setInitialValueCustom(cause.projects.donation.keywords_en)
            

            // Event
            event.is_active.setInitialValue(cause.projects.event.is_active)
            event.datetime.setInitialValue(cause.projects.event.datetime)
            event.capacity.setInitialValue(cause.projects.event.capacity)
            event.ticket_price.setInitialValue(cause.projects.event.ticket_price)

            // Volunteering
            volunteering.is_active.setInitialValue(cause.projects.volunteering.is_active)
            volunteering.capacity.setInitialValue(cause.projects.volunteering.capacity)

            // Competition
            competition.is_active.setInitialValue(cause.projects.competition.is_active)
            competition.capacity.setInitialValue(cause.projects.competition.capacity)
            competition.file_path.setInitialValue(cause.projects.competition.file_path)

            comp_file_path.setInitialValue(cause.projects.competition.file_path)
            
            page.setShowChildren(true)
        },
        onFail: resultCode => {
            if (resultCode === 'UNAUTHORIZED' || resultCode === 'CAUSE_NOT_FOUND') {
                page.setShowNotFound(true)
            } else {
                page.setUnexpectedFrontendError()
            }
        }
    }, [lang])

    useRequestEffect({
        url: api.category.read_all,
        onSuccess: setCategories
    })

    function toggleSmsRules(){
        if(!show_rules.value){
            show_rules.setValue(true)
        }else{
            show_rules.setValue(false)
        }
    }
    
    const min_keyword_length = 3
    const max_keyword_length = 12
    const max_words = 5

    // function hasLowerCase(str:any) {
    //     return (/[a-z]/.test(str));
    // }

    async function onUpdate() {
        const fieldsAreValid = [
            name.validate(value => value, 'this_field_is_required'),
        ].every(Boolean)

        if (!fieldsAreValid) {return}

        if(user?.is_admin){ //admin submission
            //perform sms keyword checks here
            if(donation.enable_sms.value){//only perform checks if sms setting is active
                let words_en = donation.keywords_en.value
                let words_tr = donation.keywords_tr.value

                //remove spaces from whole string
                words_en = words_en.replace(/\s+/g,"")
                words_tr = words_tr.replace(/\s+/g,"")


                //check that word doesn't contain turkish characters, lowercase characters, special characters
                if(!(/^[A-Z0-9,]+$/.test(words_en)) || !(/^[A-Z0-9,]+$/.test(words_tr))){
                    showSnackbar('error', 'admin_sms_format_warning') 
                    return
                }
                    //split strings with commas to get array of strings
                const arr_en_orig = words_en.split(",")
                const arr_tr_orig = words_tr.split(",")
                    //filter arrays to remove empty indexes
                const arr_en = arr_en_orig.filter(function (el) {
                    return (el != null && el != '');
                });
                const arr_tr = arr_tr_orig.filter(function (el) {
                    return (el != null && el != '');
                })
                //console.log(arr_tr)

                // check if words are more than max words per language
                if(arr_tr.length > max_words || arr_en.length > max_words){
                    showSnackbar('error', 'admin_sms_format_warning') 
                    return
                }

                let length_check = true
                let num_check = true
                //check if all the words are in range of min and max keyword length
                //check if any of the supplied words are purely numerical
                arr_tr.forEach((word:any) => {
                    if(word.length < min_keyword_length || word.length > max_keyword_length){
                        length_check = false
                    }
                    if(/^\d+$/.test(word)){
                        num_check = false
                    }
                    
                });
                arr_en.forEach((word:any) => {
                    if(word.length < min_keyword_length || word.length > max_keyword_length){
                        length_check = false
                    }
                    if(/^\d+$/.test(word)){
                        num_check = false
                    }
                });
                if(!length_check || !num_check){
                    showSnackbar('error', 'admin_sms_format_warning') 
                    return
                }
            }
        }else{ //charity submission
            //perform sms keyword checks here
            if(donation.enable_sms_initial.value){//only perform checks if sms setting is active
                let words_en = donation.keywords_en_initial.value
                let words_tr = donation.keywords_tr_initial.value

                //remove spaces from whole string
                words_en = words_en.replace(/\s+/g,"")
                words_tr = words_tr.replace(/\s+/g,"")


                //check that word doesn't contain turkish characters, lowercase characters, special characters
                if(!(/^[A-Z0-9,]+$/.test(words_en)) || !(/^[A-Z0-9,]+$/.test(words_tr))){
                    showSnackbar('error', 'admin_sms_format_warning') 
                    return
                }
                    //split strings with commas to get array of strings
                const arr_en_orig = words_en.split(",")
                const arr_tr_orig = words_tr.split(",")
                    //filter arrays to remove empty indexes
                const arr_en = arr_en_orig.filter(function (el) {
                    return (el != null && el != '');
                });
                const arr_tr = arr_tr_orig.filter(function (el) {
                    return (el != null && el != '');
                })
                //console.log(arr_tr)

                // check if words are more than max words per language
                if(arr_tr.length > max_words || arr_en.length > max_words){
                    showSnackbar('error', 'admin_sms_format_warning') 
                    return
                }

                let length_check = true
                let num_check = true
                //check if all the words are in range of min and max keyword length
                //check if any of the supplied words are purely numerical
                arr_tr.forEach((word:any) => {
                    if(word.length < min_keyword_length || word.length > max_keyword_length){
                        length_check = false
                    }
                    if(/^\d+$/.test(word)){
                        num_check = false
                    }
                    
                });
                arr_en.forEach((word:any) => {
                    if(word.length < min_keyword_length || word.length > max_keyword_length){
                        length_check = false
                    }
                    if(/^\d+$/.test(word)){
                        num_check = false
                    }
                });
                if(!length_check || !num_check){
                    showSnackbar('error', 'admin_sms_format_warning') 
                    return
                }
            }
        }
        page.clearMessages()

        makeRequestCustom({
            state: page,
            url: api.cause.update,
            data: {
                id: Number(id),
                ...(await image.box('image_base64')),
                ...category.box('category', value => value !== ''),
                ...name.box('name'),
                ...summary.box('summary'),
                description: html,
                //...description.box('description'),
                ...startDate.box('start_date'),
                ...endDate.box('end_date'),
                ...youtubeID.box('youtube_id'),
                ...causeSponsors.box('sponsors'),
                projects: {
                    ...[
                        ['activity', activity],
                        ['donation', donation],
                        ['event', event],
                        ['volunteering', volunteering],
                        ['competition', competition],
                    ].reduce((projectsObj, [key, project]) => ({
                        ...projectsObj,
                        // What is going on here
                        [key as string]: Object.entries(project).reduce((obj, [key, value]) => ({
                            ...obj, ...value.box(key)
                        }), {})
                    }), {})
                }
            },
            onSuccess: () => page.setSuccessMessage('cause_edit_success'),
            onFailCustom: cdata => {
                if (cdata[0] === 'INVALID_IMAGE_TYPE') {
                    page.setErrorMessage('unsupported_image_format_desc')
                } else if(cdata[0] === 'EMAIL_EXISTS'){
                    
                    let msg_custom = translate('admin_sms_keyword_warning')
                    const fail_words = cdata[1].replace('Bad Request: ','')
                    msg_custom += fail_words
                    console.log(msg_custom)
                    page.setErrorMessageCustom(msg_custom) //The following keywords have been used by another project, please replace them with different words :
                } else {
                    page.setUnexpectedFrontendError()
                }
                // if (resultCode === 'INVALID_IMAGE_TYPE') {
                //     page.setErrorMessage('unsupported_image_format_desc')
                // } else if(resultCode === 'EMAIL_EXISTS'){
                //     console.log(resultCode)
                //     let msg_custom = translate('admin_sms_keyword_warning')
                //     msg_custom += ''
                //     //page.setErrorMessageCustom(msg_custom) //The following keywords have been used by another project, please replace them with different words :
                // } else {
                //     page.setUnexpectedFrontendError()
                // }
            }
        }).then()
        
    }

    return (
        <>
            <AppHelmet noIndex/>
            <StatefulPage
                state={page}
                successActions={
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            startIcon={<Visibility/>}
                            component={Link}
                            to={route.to.cause(id).page}
                            color={'secondary'}
                            children={translate('view')}
                        />
                    </Box>
                }
            >
                <AuthorizedContent when={user?.is_admin || (cause?.owner.id === user?.id)}>
                    <Fade in={true}>
                        <div className={causeEditor}>
                            <DeleteCauseDialog
                                cause_id={Number(id)}
                                openState={deleteDialog}
                                onSuccessExit={() => history.push(route.to.user(user?.id).causes)}
                            />
                            <Title titleKey={'cause_editor_title'}/>
                            <Only when={{cause}}>{({cause}) =>
                                <FieldModeContext.Provider value={'edit'}>
                                    <ImageField
                                        aspectRatio={16 / 9}
                                        preferredDimensions={'1280x720'}
                                        field={image}
                                    />
                                    <FormField
                                        label={'cause_name'}
                                        field={name}
                                        icon={<TextFieldsOutlined/>}
                                    />
                                    <SelectField
                                        label={'category'}
                                        field={category}
                                        items={categories?.map(cat => cat.name)}
                                    />
                                    <FormField
                                        label={'summary'}
                                        field={summary}
                                        icon={<Subject/>}
                                    />
                                    {/* <FormField
                                        multiline
                                        label={'description'}
                                        field={description}
                                        icon={<SubjectOutlined/>}
                                    /> */}
                                    <Box> 
                                        <p style={{color : 'rgba(0, 0, 0, 0.54)'}}>{translate('description')}</p>
                                    </Box>
                                    <RichTextEditor description={html}/>
                                    <FormField
                                        label={'youtube_id'}
                                        field={youtubeID}
                                        icon={<YouTube/>}
                                    />

                                    <DateField
                                        label={'start_date'}
                                        field={startDate}/>
                                    <DateField
                                        label={'end_date'}
                                        field={endDate}/>

                                    <CustomDivider marginY={2}/>

                                    <CharitySelector
                                        title={'cause_sponsors'}
                                        idArrayField={causeSponsors}
                                    />

                                    <CustomDivider marginY={2}/>

                                    {/* Projects */}

                                    <CauseProject
                                        is_active={activity.is_active}
                                        label={'activity_project'}
                                        description={'activity_project_description'}
                                    >
                                        <IntField label={'capacity'} field={activity.capacity}/>
                                        <DateTimeField label={'activity_date'} field={activity.datetime}/>
                                    </CauseProject>

                                    <CustomDivider marginY={1}/>

                                    <CauseProject
                                        is_active={donation.is_active}
                                        label={'donation_project'}
                                        description={'donation_project_description'}
                                    >
                                        <IntField label={'goal'} field={donation.goal}/>
                                        <Only when={user?.is_admin}>
                                            {/* Charity Settings*/}
                                            <p className='charity-sms-heading'>{translate('project_charity_kktcell_settings')}</p>
                                            <Box className='charity-sms-wrap'>
                                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Typography children={translate('charity_enable_invoice_donations')}/>
                                                    <Only when={donation.enable_invoice_initial.value}>
                                                        <p className='view-value-yes'>{translate('yes')}</p>
                                                    </Only>
                                                    <Only when={!donation.enable_invoice_initial.value}>
                                                        <p className='view-value-no'>{translate('no')}</p>
                                                    </Only>
                                                </Box>
                                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Typography children={translate('charity_enable_sms_donations')}/>
                                                    <Only when={donation.enable_sms_initial.value}>
                                                        <p className='view-value-yes'>{translate('yes')}</p>
                                                    </Only>
                                                    <Only when={!donation.enable_sms_initial.value}>
                                                        <p className='view-value-no'>{translate('no')}</p>
                                                    </Only>
                                                </Box>
                                                <Only when={donation.enable_sms_initial.value}>
                                                    <div>   
                                                        <p className='sms-custom-label'>{translate('admin_sms_keywords_tr')}</p>
                                                        <div className='sms-custom-content'>{donation.keywords_tr_initial.value}</div>
                                                    </div>
                                                    <div>   
                                                        <p className='sms-custom-label'>{translate('admin_sms_keywords_en')}</p>
                                                        <div className='sms-custom-content'>{donation.keywords_en_initial.value}</div>
                                                    </div>
                                                </Only>
                                            </Box>
                                            {/* Actual Settings - Admin*/}
                                            <p className='admin-sms-heading'>{translate('project_actual_kktcell_settings')}</p>
                                            <Box className='admin-sms-wrap'>
                                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Typography children={translate('admin_enable_invoice_donations')}/>
                                                    <Switch
                                                        checked={donation.enable_invoice.value}
                                                        onChange={e => donation.enable_invoice.setValue(e.target.checked)}
                                                    />
                                                </Box>
                                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Typography children={translate('admin_enable_sms_donations')}/>
                                                    <Switch
                                                        checked={donation.enable_sms.value}
                                                        onChange={e => donation.enable_sms.setValue(e.target.checked)}
                                                    />
                                                </Box>
                                                <Only when = {donation.enable_sms.value}>
                                                    <Box marginTop={1} marginBottom={2}>
                                                        <Only when={!show_rules.value}>
                                                            <Button
                                                                variant={'contained'}
                                                                style={{backgroundColor: '#0d9ddb', color: '#fff'}}
                                                                children={translate('admin_sms_keywords_text')}
                                                                endIcon={<ExpandMore className={'admin-sms-btn-icon'}/>}
                                                                onClick={toggleSmsRules}
                                                            />
                                                        </Only>
                                                        <Only when={show_rules.value}>
                                                            <Button
                                                                variant={'contained'}
                                                                style={{backgroundColor: '#0d9ddb', color: '#fff'}}
                                                                children={translate('admin_sms_keywords_text')}
                                                                endIcon={<ExpandLess className={'admin-sms-btn-icon'}/>}
                                                                onClick={toggleSmsRules}
                                                            />
                                                        </Only>
                                                    </Box>
                                                    <Only when={show_rules.value}>
                                                        <Typography style={{fontWeight: 'bold'}}>
                                                            { <span dangerouslySetInnerHTML={{ __html: SmsRules }} /> }
                                                        </Typography>   
                                                    </Only>
                                                    <Box marginTop={2}>
                                                        <FieldModeContext.Provider value={'custom'}>
                                                            <FormField
                                                                label={'admin_sms_keywords_tr'}
                                                                field={donation.keywords_tr}
                                                                icon={<TextFieldsOutlined/>}
                                                            />
                                                            <FormField
                                                                label={'admin_sms_keywords_en'}
                                                                field={donation.keywords_en}
                                                                icon={<TextFieldsOutlined/>}
                                                            />
                                                        </FieldModeContext.Provider>
                                                    </Box>
                                                </Only>
                                            </Box>
                                        </Only>
                                        <Only when={!user?.is_admin}>
                                            {/* Charity Settings*/}
                                            <p className='charity-sms-heading'>{translate('project_charity_kktcell_settings')}</p>
                                            <Box className='charity-sms-wrap'>
                                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Typography children={translate('charity_enable_invoice_donations')}/>
                                                    <Switch
                                                        checked={donation.enable_invoice_initial.value}
                                                        onChange={e => donation.enable_invoice_initial.setValue(e.target.checked)}
                                                    />
                                                </Box>
                                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Typography children={translate('charity_enable_sms_donations')}/>
                                                    <Switch
                                                        checked={donation.enable_sms_initial.value}
                                                        onChange={e => donation.enable_sms_initial.setValue(e.target.checked)}
                                                    />
                                                </Box>
                                                <Only when = {donation.enable_sms_initial.value}>
                                                    <Box marginTop={1} marginBottom={2}>
                                                        <Only when={!show_rules.value}>
                                                            <Button
                                                                variant={'contained'}
                                                                style={{backgroundColor: '#0d9ddb', color: '#fff'}}
                                                                children={translate('admin_sms_keywords_text')}
                                                                endIcon={<ExpandMore className={'admin-sms-btn-icon'}/>}
                                                                onClick={toggleSmsRules}
                                                            />
                                                        </Only>
                                                        <Only when={show_rules.value}>
                                                            <Button
                                                                variant={'contained'}
                                                                style={{backgroundColor: '#0d9ddb', color: '#fff'}}
                                                                children={translate('admin_sms_keywords_text')}
                                                                endIcon={<ExpandLess className={'admin-sms-btn-icon'}/>}
                                                                onClick={toggleSmsRules}
                                                            />
                                                        </Only>
                                                    </Box>
                                                    <Only when={show_rules.value}>
                                                        <Typography style={{fontWeight: 'bold'}}>
                                                            { <span dangerouslySetInnerHTML={{ __html: SmsRules }} /> }
                                                        </Typography>   
                                                    </Only>
                                                    <Box marginTop={2}>
                                                        <FieldModeContext.Provider value={'custom'}>
                                                            <FormField
                                                                label={'charity_sms_keywords_tr'}
                                                                field={donation.keywords_tr_initial}
                                                                icon={<TextFieldsOutlined/>}
                                                            />
                                                            <FormField
                                                                label={'charity_sms_keywords_en'}
                                                                field={donation.keywords_en_initial}
                                                                icon={<TextFieldsOutlined/>}
                                                            />
                                                        </FieldModeContext.Provider>
                                                    </Box>
                                                </Only>
                                            </Box>
                                            {/* Actual Settings - Admin*/}
                                            <p className='admin-sms-heading'>{translate('project_actual_kktcell_settings')}</p>
                                            <Box className='admin-sms-wrap'>
                                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Typography children={translate('charity_info_invoice_donations')}/>
                                                    <Only when={donation.enable_invoice.value}>
                                                        <p className='view-value-yes'>{translate('yes')}</p>
                                                    </Only>
                                                    <Only when={!donation.enable_invoice.value}>
                                                        <p className='view-value-no'>{translate('no')}</p>
                                                    </Only>
                                                </Box>
                                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Typography children={translate('charity_info_sms_donations')}/>
                                                    <Only when={donation.enable_sms.value}>
                                                        <p className='view-value-yes'>{translate('yes')}</p>
                                                    </Only>
                                                    <Only when={!donation.enable_sms.value}>
                                                        <p className='view-value-no'>{translate('no')}</p>
                                                    </Only>
                                                </Box>
                                                <Only when={donation.enable_sms.value}>
                                                    <div>   
                                                        <p className='sms-custom-label'>{translate('admin_sms_keywords_tr')}</p>
                                                        <div className='sms-custom-content'>{donation.keywords_tr.value}</div>
                                                    </div>
                                                    <div>   
                                                        <p className='sms-custom-label'>{translate('admin_sms_keywords_en')}</p>
                                                        <div className='sms-custom-content'>{donation.keywords_en.value}</div>
                                                    </div>
                                                </Only>
                                            </Box>
                                        </Only>
                                    </CauseProject>
                                    
                                    <CustomDivider marginY={1}/>

                                    <CauseProject
                                        is_active={event.is_active}
                                        label={'event_project'}
                                        description={'event_project_description'}
                                    >
                                        <IntField label={'capacity'} field={event.capacity}/>
                                        <DateTimeField label={'event_date'} field={event.datetime}/>
                                        <IntField label={'ticket_price'} field={event.ticket_price}/>
                                    </CauseProject>

                                    <CustomDivider marginY={1}/>

                                    <CauseProject
                                        is_active={volunteering.is_active}
                                        label={'volunteering_project'}
                                        description={'volunteering_project_description'}
                                    >
                                        <IntField label={'capacity'} field={volunteering.capacity}/>
                                    </CauseProject>

                                    <CustomDivider marginY={1}/>
                                    
                                    <CauseProject
                                        is_active={competition.is_active}
                                        label={'competition_project'}
                                        description={'competition_project_description'}
                                    >
                                        <IntField label={'capacity'} field={competition.capacity}/>
                                        <Box marginTop={2}/>
                                        <CauseDocumentPicker
                                            className={'admin-comp-file-wrap'}
                                            label={'upload_competition_file'}
                                            documentBase64={competition.file_path}
                                        />
                                        <Only when={!!comp_file_path.value}>
                                            <Box display={'flex'} justifyContent={'end'} alignItems={'center'} className={'admin-comp-download-wrap'}>
                                                <RichText text={translate('competition_file')}/>
                                                <Button
                                                    color={'secondary'}
                                                    variant={'outlined'}
                                                    startIcon={<GetApp/>}
                                                    component={Link}
                                                    target={'_blank'}
                                                    className={'admin-comp-download-btn'}
                                                    to={{pathname: comp_file_path.value}}
                                                    children={translate('download')}
                                                />
                                            </Box>
                                        </Only>
                                    </CauseProject>

                                    <Box display={'flex'} marginY={mobile ? 2 : 4}>
                                        <ResponsiveButton
                                            label={'delete'}
                                            icon={<Delete/>}
                                            onClick={() => deleteDialog.setOpen(true)}
                                            disabled={cause.status.name !== 'draft'}
                                            color={'secondary'}
                                            variant={'text'}
                                        />
                                        <Box flex={1}/>
                                        <ResponsiveButton
                                            label={'update'}
                                            icon={<Save/>}
                                            onClick={onUpdate}
                                            variant={'contained'}
                                            color="primary"
                                            // disabled={allFieldsDisabled(fields)}
                                        />
                                        <Box marginX={3}/>
                                    </Box>
                                </FieldModeContext.Provider>
                            }</Only>
                        </div>
                    </Fade>
                </AuthorizedContent>
            </StatefulPage>
        </>
    )
}

function CharitySelector(props: {
    title: TranslationKey,
    idArrayField: NumberArrayField
}) {
    const {title, idArrayField} = props

    const [charities, setCharities] = useState<ICharity[]>([])

    useRequestEffect<ICharity[]>({
        url: api.charity.read_public,
        onSuccess: setCharities,
    })


    return (
        <RichObjectSelector
            title={title}
            imageRatio={1}
            idArrayField={idArrayField}
            objects={charities.map<RichObject>(charity => ({
                id: charity.id,
                name: charity.name,
                image_src: charity.logo_url
            }))}
        />
    )
}
