import React from 'react'
import {AppBar, createStyles} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {BannerCarousel} from './BannerCarousel'
import {BannerCarouselStatic} from './BannerCarouselStatic'


const useStyles = makeStyles(({spacing, breakpoints}) =>
    createStyles({
        shadowBar: {
            height: spacing(3),
            zIndex: 100,
            background: 'white',
            position: 'relative'
        },
        banner: {
            width: '100%',
            display: 'flex',
            height: 'auto',
            flexDirection: 'column',
            [breakpoints.up('tablet')]: {
                flexDirection: 'row',
                height: 300,
            }
        },
        banner_carousel: {
            width: '100%',
            height: 300,
            [breakpoints.up('tablet')]: {
                width: '66%',
                height: '100%',
            }
        },
        banner_static: {
            width: '100%',
            height: '300',
            [breakpoints.up('tablet')]: {
                width: '34%',
                height: '100%',
            }
        }
    })
)

export function Banner(props: { vidOne: string, vidTwo: string, posOne: string, posTwo: string}) {
    const {shadowBar, banner, banner_carousel, banner_static} = useStyles()
    const {vidOne, vidTwo, posOne, posTwo} = props
    return (
        <>
            <AppBar className={shadowBar} position={'static'} elevation={1}/>
            <Box className={banner}>
                <BannerCarousel className={banner_carousel}/>
                <BannerCarouselStatic className={banner_static} videoOne={vidOne} videoTwo={vidTwo} posterOne={posOne} posterTwo={posTwo}/>
            </Box>
        </>
    )
}
