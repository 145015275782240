import {Box, Button} from '@material-ui/core'
import {VpnKeyOutlined, VpnKeyTwoTone} from '@material-ui/icons'
import {FormField} from 'components/forms/FormField'
import {useStringField} from 'components/foundation/EditableField'
import {DialogOpenState, StatefulDialog, useStatefulDialogState, StatefulDialogState} from 'components/foundation/StatefulDialog'
import {CustomDivider} from 'components/misc/CustomDivider'
import React from 'react'
import {useTranslation} from 'state/translation'
import {fetchUser, useUser} from 'state/user'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useRequest} from 'utils/request'
import {SupportDialogData} from 'pages/cause/support-dialog/SupportDialog'
import {StringField} from 'components/foundation/EditableField'

export function VerifyPhoneDialogAlt(props: { 
    openState: DialogOpenState 
    SupportDialogOpenState: DialogOpenState<SupportDialogData>
    SupportDialog: StatefulDialogState
    currentStep:any
    cur_user:any
    contract_id:StringField
}) {
    const {openState} = props
    const {translate} = useTranslation()
    const {setUser, user} = useUser()
    const {makeRequest} = useRequest()

    const dialog = useStatefulDialogState()

    const sms_code = useStringField(StringLength.XXS)
    const fields = [sms_code]

    const onSubmit = () => makeRequest({
        if: [
            sms_code.validate(value => value, 'this_field_is_required'),
        ].every(Boolean),
        url: api.user.check_verification_code,
        state: dialog,
        data: {
            id: user?.id,
            phone: user?.phone_number,
            ...sms_code.box('confirmation_code'),
        },
        onSuccess: () => {
            onClose()
            props.SupportDialogOpenState.setOpen(true)
            props.SupportDialog.setSuccessMessageCustom('phone_verify_success')
            fetchUser()?.then(setUser).then(completeSubmit)
            
        },
        onFail: resultCode => {
            onClose()
            props.SupportDialogOpenState.setOpen(true)
            props.SupportDialog.setErrorMessage('verify_phone_error_msg')
        },
        onFinish: () => {
            sms_code.setValue('')
        }
    })

    function completeSubmit(){
        props.cur_user.is_phone_verified = true
        //props.cur_user = user
        //console.log("new user contract id = " + user?.contract_id)
        props.cur_user.contract_id = user?.contract_id
        props.contract_id.setValue(props.cur_user.contract_id)
        //console.log("contract id = " + props.contract_id.value)
        // onClose()
        // props.SupportDialogOpenState.setOpen(true)
        // props.SupportDialog.setSuccessMessageCustom('phone_verify_success')
        //console.log("contract id = " + props.contract_id.value)
    }

    function onClose() {
        openState.setOpen(false)
    }

    return (
        <StatefulDialog
            state={dialog}
            openState={openState}
            titleKey={'sms_confirmation_text'}
            content={
                <div>
                    <FormField
                        label={'confirmation_code'}
                        type={'text'}
                        field={sms_code}
                        icon={<VpnKeyOutlined/>}
                        onEnter={onSubmit}
                    />
                </div>
            }
            buttons={
                <Box display={'flex'}>
                    <Button onClick={onClose} color="primary" variant={'outlined'}>
                        {translate('cancel')}
                    </Button>
                    <Box marginX={1}/>
                    <Button onClick={onSubmit} color="primary" variant={'contained'}>
                        {translate('continue')}
                    </Button>
                </Box>
            }
            fields={fields}
        />
    )
}
