import {DateTime, SystemZone} from 'luxon'
import {getLang} from 'state/lang'

export function formatDateString(date: string | null) {
    return date ?
        DateTime.fromSQL(date, {zone: 'UTC'})
            .setLocale(getLang())
            .setZone(SystemZone.instance)
            .toFormat('LLLL dd, yyyy')
        : null
}

export function formatDateTimeString(datetime: string | null) {
    return datetime ?
        DateTime.fromSQL(datetime, {zone: 'UTC'})
            .setLocale(getLang())
            .setZone(SystemZone.instance)
            .toFormat('LLLL dd, yyyy @ HH:mm')
        : null
}