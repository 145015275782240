import {Column} from '@material-table/core'
import {Box, Button} from '@material-ui/core'
import {Add, Delete, Edit} from '@material-ui/icons'
import {ConfirmationDialog} from 'components/dialogs/ConfirmationDialog'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {CustomTable} from 'components/misc/CustomTable'
import {ImageWithFallback} from 'components/misc/ImageWithFallback'
import {RichText} from 'components/misc/RichText'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {ICause} from 'types/cause'
import {IHomeBanner} from 'types/types'
import React, {useState} from 'react'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useUpdater} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'
import {EditBannerSliderDialog} from './EditBannerSliderDialog'
import {useLang} from '../../../state/lang'

export function BannerManagement() {
    const page = useStatefulPageState()
    const {translate} = useTranslation()
    const {lang} = useLang()

    const {makeRequest} = useRequest()

    const deleteDialogOpenState = useDialogOpenState<IHomeBanner | undefined>(undefined)
    const editDialogOpenState = useDialogOpenState<IHomeBanner | undefined>(undefined)

    const [causes, setCauses] = useState<ICause[]>([])
    const [slides, setSlides] = useState<IHomeBanner[]>([])

    const {update, updateFlag} = useUpdater()

    function onAdd() {
        makeRequest({
            state: page,
            url: api.home.banner.create,
            onSuccess: update
        }).then()
    }

    function deleteBannerSlide(slide?: IHomeBanner) {
        makeRequest({
            state: page,
            if: !!slide,
            url: api.home.banner.delete,
            data: {
                banner_id: slide?.id
            },
            onSuccess: update
        }).then()
    }

    useRequestEffect<IHomeBanner[]>({
        state: page,
        url: api.home.banner.read,
        onSuccess: setSlides,
    }, [updateFlag])

    useRequestEffect<ICause[]>({
        state: page,
        url: api.cause.read_public,
        onSuccess: setCauses,
    }, [lang, updateFlag])

    return (
        <StatefulPage state={page}>
            <AppHelmet title={translate('banner_management')}/>
            <EditBannerSliderDialog
                openState={editDialogOpenState}
                causes={causes}
                onEdited={update}
            />
            <ConfirmationDialog
                openState={deleteDialogOpenState}
                text={'banner_slide_delete_confirmation'}
                onOk={deleteBannerSlide}
            />
            <Title titleKey={'banner_management'}/>
            <CustomTable
                data={slides}
                title={(
                    <Box>
                        <Button
                            startIcon={<Add/>}
                            color={'secondary'}
                            variant={'outlined'}
                            children={translate('add')}
                            onClick={onAdd}
                        />
                    </Box>
                )}
                columns={[
                    {
                        title: translate('logo'),
                        render: slide =>
                            <ImageWithFallback
                                src={slide.image_path}
                                height={128}
                                width={128}
                                style={{
                                    objectFit: 'contain'
                                }}
                            />
                    },
                    {
                        title: translate('title'),
                        render: slide => <RichText size={'huge'} bold text={translate(slide.title_key)}/>
                    },
                    {
                        title: translate('actions'),
                        render: slide => (
                            <Box display={'flex'}>
                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    startIcon={<Edit/>}
                                    children={translate('edit')}
                                    onClick={() => editDialogOpenState.open(slide)}
                                />
                                <Box margin={1}/>
                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    startIcon={<Delete/>}
                                    children={translate('delete')}
                                    onClick={() => deleteDialogOpenState.open(slide)}
                                />
                            </Box>
                        )
                    }
                ] as Column<IHomeBanner>[]}
            />
        </StatefulPage>
    )
}
