import {Column, MTableCell, MTableToolbar} from '@material-table/core'
import {Box, Checkbox, FormControlLabel} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {CustomTable} from 'components/misc/CustomTable'
import {IRawTranslations} from 'types/types'
import React, {useState} from 'react'
import {LanguageKey} from 'state/lang'
import {useLangs} from 'state/langs'
import {useRawTranslation, useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useAppSnackbar} from 'utils/hooks'
import {useRequest} from 'utils/request'
import {TranslationKey} from 'utils/TranslationKey'

const useStyles = makeStyles(() =>
    createStyles({
        cellStyle: {
            width: 'auto !important',
            color: 'gray !important',

            '& div': {
                width: 'auto !important',
                minHeight: '1.5rem',
                color: 'black !important',
            }
        },
    }),
)

export function TranslationTable() {
    const {translate} = useTranslation()
    const {translation, translationState} = useRawTranslation()
    const {cellStyle} = useStyles()
    const {makeRequest} = useRequest()
    const {showSnackbar} = useAppSnackbar()
    const {langs} = useLangs()
    const [editableLanguages, setEditableLanguages] = useState<Record<string, boolean>>(
        Object.entries(langs ?? {}).reduce((obj, [code, lang]) => ({...obj, [code]: lang.is_enabled}), {})
    )

    const columns: Column<IRawTranslations>[] = [
        {title: translate('key'), field: 'key', editable: 'never'},
        ...(Object.entries(langs ?? {}).map(([code, lang]) => ({
            title: lang.name,
            field: code,
            hidden: !editableLanguages[code]
        })))
    ]


    return (
        <CustomTable
            title={translate('choose_langs_to_edit')}
            columns={columns}
            data={Object.entries(translation ?? {}).map(([key, langs]) => ({key: key as TranslationKey, ...langs}))}
            components={{
                Cell: props => <MTableCell  {...props} className={cellStyle}/>,
                Toolbar: props => (
                    <>
                        <MTableToolbar {...props} />
                        <Box paddingX={3}>{
                            Object.keys(langs ?? {}).map(code =>
                                <FormControlLabel
                                    key={code}
                                    control={
                                        <Checkbox
                                            name={code}
                                            checked={editableLanguages[code]}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setEditableLanguages({
                                                    ...editableLanguages,
                                                    [event.target.name]: event.target.checked
                                                })
                                            }}/>
                                    }
                                    label={langs?.[code as LanguageKey].name}
                                />
                            )
                        }</Box>
                    </>
                ),
            }}
            cellEditable={{
                isCellEditable: () => true,
                onCellEditApproved: async (newValue, oldValue, rowData, columnDef) => {
                    const key = rowData.key
                    const lang = columnDef.field as LanguageKey

                    await makeRequest<number>({
                        url: api.translation.update_key,
                        data: {
                            key: key,
                            lang: lang,
                            value: newValue
                        },
                        onSuccess: () => {
                            showSnackbar('success', 'translation_update_success')
                            translationState.ornull?.[key][lang].set(newValue)
                        },
                        onFail: () => showSnackbar('error', 'translation_update_fail'),
                        onError: () => showSnackbar('error', 'translation_update_fail'),
                    })
                }
            }}
        />
    )
}
