import React from 'react'
import {StatefulPage, useStatefulPageState} from '../../components/foundation/StatefulPage'
import {useTranslation} from '../../state/translation'
import {AppHelmet} from '../../components/util/AppHelmet'
import {useStringField} from '../../components/foundation/EditableField'
import {StringLength} from '../../utils/enums'
import {FormField} from '../../components/forms/FormField'
import {VpnKeyOutlined} from '@material-ui/icons'
import {Box, Button, Container} from '@material-ui/core'
import {useRequest} from '../../utils/request'
import {api} from '../../utils/api'
import {useAppSnackbar, useQueryParam} from '../../utils/hooks'
import {Title} from '../../components/misc/Title'
import {useHistory} from 'react-router-dom'
import {route} from '../../utils/route'

export function PaynetCharityRegistration() {
    const page = useStatefulPageState()
    const {translate} = useTranslation()
    const {makeRequest} = useRequest()
    const {showSnackbar} = useAppSnackbar()
    const history = useHistory()

    const secretKey = useStringField(StringLength.SM)

    const token = useQueryParam('token')

    function onSubmit() {
        makeRequest({
            url: api.charity.update_paynet_secret,
            state: page,
            if: secretKey.validate(),
            data: {
                token,
                ...secretKey.box('secret_key')
            },
            onSuccess: () => {
                history.push(route.to.home)
                showSnackbar('success', 'success')
            },
            onFail: code => page.setErrorMessage(code === 'INVALID_TOKEN'
                ? 'invalid_access_token'
                : 'unexpected_frontend_error'
            )
        }).then()
    }

    return (
        <>
            <AppHelmet noIndex/>
            <StatefulPage state={page}>
                <Title titleKey={'paynet_charity_registration'}/>
                <Container maxWidth={'xs'}>
                    <Box display={'flex'} flexDirection={'column'} mt={4}>
                        <FormField
                            label={'paynet_secret_key'}
                            field={secretKey}
                            placeholder={'sck_...'}
                            icon={<VpnKeyOutlined/>}
                        />
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={onSubmit}
                            children={translate('submit')}
                            style={{
                                marginLeft: 'auto'
                            }}
                        />
                    </Box>

                </Container>
            </StatefulPage>
        </>
    )
}