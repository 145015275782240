import {createState, DevTools, useState} from '@hookstate/core'
import {attachPersistence} from 'utils/state'

const stateLabel = 'lang'

export enum Language {
    el = 'el',
    en = 'en',
    ru = 'ru',
    tr = 'tr',
}

export type LanguageKey = keyof typeof Language

const globalState = createState<LanguageKey>('tr')

attachPersistence(globalState, stateLabel)

export const getLang = () => globalState.value

export function useLang() {
    const state = useState(globalState)

    return {
        lang: state.get(),
        setLang: state.set,
    }
}

DevTools(globalState).label(stateLabel)
