import {createStyles, LinearProgress, Theme, withStyles} from '@material-ui/core'
import React from 'react'
import {makeStyles} from '@material-ui/core/styles'

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: theme.palette.primary.main,
        },
    }),
)(LinearProgress)

const useStyles = makeStyles(() =>
    createStyles({
        percentage: {
            width: 'min-content',
            marginLeft: 'auto'
        },
        percentageProgress: {
            display: 'flex',
            flexDirection: 'column'
        }
    })
)

export function PercentageProgress(props: { className?: string, current: number | null, total: number | null }) {
    const {percentage, percentageProgress} = useStyles()
    const {className, current, total} = props

    const percent = Math.min(100 * (current ?? 0) / (total ? total : 1), 100)

    return (
        <div className={[percentageProgress, className].join(' ')}>
            <div
                className={percentage}
                style={{marginRight: `calc(100% - ${percent}%)`}}
                children={`%${Math.floor(percent).toFixed(0)}`}
            />
            <BorderLinearProgress variant="determinate" value={percent}/>
        </div>
    )
}
