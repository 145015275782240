import {Column} from '@material-table/core'
import {Box, Button} from '@material-ui/core'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Delete, Visibility} from '@material-ui/icons'
import {CustomTable} from 'components/misc/CustomTable'
import {RichText} from 'components/misc/RichText'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {IUserTransactions} from 'types/types'
import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {useAuth} from 'state/auth'
import {api} from 'utils/api'
import {formatDateTimeString} from 'utils/date'
import {useUpdater} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {TranslationKey} from 'utils/TranslationKey'
import {Only} from 'components/util/Only'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {ConfirmationDialog} from 'components/dialogs/ConfirmationDialog'

let cur_url = ''
export function UserTransactions() {
    cur_url = window.location.href
    const {translate} = useTranslation()
    const page = useStatefulPageState()

    const [transactions, setTransactions] = useState<IUserTransactions[]>([])
    const {update, updateFlag} = useUpdater()

    const {makeRequest} = useRequest()
    const {user} = useUser()
    const {auth} = useAuth()

    const deleteCertificateOpenState = useDialogOpenState<IUserTransactions | undefined>(undefined)

    useRequestEffect<IUserTransactions[]>({
        state: page,
        url: api.user.read_transactions,
        if: !!auth?.userID,
        data: {
            user_id: auth?.userID
        },
        onSuccess: data => {
            page.clearMessages()
            setTransactions(data)
            if(cur_url.includes("delete")){
                page.setSuccessMessage('delete_single_certificate_success')
                //newly added - fixes delete message showing after deleting a certificate and clicking refresh button
                cur_url = cur_url.replace("?delete=success","")
                history.pushState('','',cur_url)
            }
        }
    }, [updateFlag])

    function deleteCertificate(transaction?: IUserTransactions) {
        makeRequest({
            state: page,
            if: !!transaction && !!auth?.userID,
            url: api.user.delete_certificate,
            data: {
                id: transaction?.id,
                user_id: auth?.userID
            },
            onStart: () =>{
                page.clearMessages()
                cur_url = cur_url.replace("?delete=success","")
                history.pushState('','',cur_url)
            },
            onSuccess: () => {
                history.pushState('','',cur_url+"?delete=success")
            }
        }).then(update)
    }

    
    return (
        <StatefulPage state={page}>
            <AppHelmet noIndex/>
            <AuthorizedContent>
                <ConfirmationDialog
                    openState={deleteCertificateOpenState}
                    text={'delete_single_certificate_confirm'}
                    onOk={deleteCertificate}
                />
                <Title titleKey={'my_transactions'}/>
                <Box marginBottom={2}>
                    <CustomTable
                        data={transactions}
                        title={
                            <Button
                                color={'primary'}
                                variant={'contained'}
                                children={translate('refresh')}
                                onClick={update}
                            />
                        }
                        columns={[
                            {title: 'ID', field: 'reference_no'},
                            {
                                title: translate('date_and_time'),
                                field: 'supported_datetime',
                                defaultSort: 'desc',
                                render: data => <RichText text={formatDateTimeString(data.supported_datetime)}/>
                            },
                            {
                                title: translate('description'),
                                render: data =>
                                    translate(`transaction_desc_${data.type}` as TranslationKey)
                                        .replace('%amount%', `${data.amount}`)
                            },
                            {
                                title: translate('payment_method'),
                                render: data => (
                                    <Box>
                                        <Only when={data.payment_method == 'kktcell_sms'}> <RichText text={translate('kktcell_sms_payment_method')}/></Only>
                                        <Only when={data.payment_method == 'kktcell_invoice'}> <RichText text={translate('kktcell_invoice_payment_method')}/></Only>
                                        <Only when={data.payment_method != 'kktcell_sms' && data.payment_method != 'kktcell_invoice'}> <RichText textTransform={'capitalize'} text={data.payment_method}/></Only>
                                    </Box>
                                    
                                )
                            },
                            {
                                title: translate('in_whose_name'),
                                render: data => (
                                    <Box>
                                        <Only when={data.donation_type == 'gift'}> <RichText text={data.receiver_name}/></Only>
                                        <Only when={data.donation_type != 'gift'}> <RichText text={data.user_name}/></Only>
                                    </Box>
                                )
                            },
                            {
                                title: translate('certificate'),
                                render: data => (
                                    <Box>
                                        <Only when={Number(data.send_certificate) == 1 && data.certificate_deleted != 1 && !!data.certificate_slug  && !!data.image_path && !!data.file_path}>
                                            <Button
                                                variant="outlined"
                                                color={'secondary'}
                                                children={translate('view_certificate')}
                                                component={Link}
                                                to={route.to.certificate(data.certificate_slug).page}
                                            />
                                        </Only>
                                    </Box>
                                )
                            },
                            {
                                title: '',
                                render: data => (
                                    <Box>
                                        <Only when={Number(data.send_certificate) == 1 && data.certificate_deleted != 1 && !!data.certificate_slug  && !!data.image_path && !!data.file_path}>
                                            <Button
                                                className = {'delete-cert-btn'}
                                                variant="outlined"
                                                color={'secondary'}
                                                startIcon={<Delete/>}
                                                onClick={() => deleteCertificateOpenState.open(data)}
                                            />
                                            </Only>
                                    </Box>
                                )
                            },
                            {
                                title: translate('is_confirmed'),
                                render: data => <RichText text={translate(data.is_confirmed ? 'yes' : 'no')}/>
                            },
                            {
                                title: translate('cause'),
                                render: data => <Link to={route.to.cause(data.cause_id).page}>{data.cause_name}</Link>
                            },

                        ] as Column<IUserTransactions>[]}
                    />
                </Box>
            </AuthorizedContent>
        </StatefulPage>
    )
}
