import {createState, DevTools, useState} from '@hookstate/core'
import {ITranslation} from 'types/types'
import {api} from 'utils/api'
import {makeRequestRaw} from 'utils/request'
import {attachPersistence} from 'utils/state'
import {TranslationKey} from 'utils/TranslationKey'
import {useLang} from './lang'

const stateLabel = 'translation'

const globalState = createState<ITranslation | null>(fetchRawTranslations)

attachPersistence(globalState, stateLabel)

export function fetchRawTranslations() {
    return makeRequestRaw<ITranslation>({
        url: api.translation.read,
    })
}

export function refreshTranslations() {
    fetchRawTranslations().then(globalState.set)
}

export function useRawTranslation() {
    const state = useState(globalState)

    return {
        translationState: state,
        translation: state.promised || state.error ? null : state.get(),
        setTranslation: state.set,
        refreshTranslations: () => fetchRawTranslations().then(state.set)
    }
}

export function useTranslation() {
    const {translation} = useRawTranslation()
    const {lang} = useLang()


    function translate(key: TranslationKey): string;
    function translate(key: TranslationKey | null): string | null;
    function translate(key: TranslationKey | undefined): string | undefined;
    function translate(key: unknown): string | null | undefined {
        const defaultLang = 'tr'

        if (key === null) {
            return null
        } else if (key === undefined) {
            return undefined
        }

        return (
            translation?.[key as TranslationKey]?.[lang] ??
            translation?.[key as TranslationKey]?.[defaultLang] ??
            `[${key}]`
        )
    }

    return {
        translate: translate
    }
}

DevTools(globalState).label(stateLabel)
