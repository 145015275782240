import {DependencyList, useEffect} from 'react'
import {Auth, setAuth, useAuth} from 'state/auth'
import {useLang} from 'state/lang'
import {StatefulComponent} from './hooks'
import {ResultCode} from './ResultCode'

export interface IResponse<T = null> {
    result_code: ResultCode,
    message: string,
    data: T
}

export interface RequestProps<T> {
    if?: boolean | (() => boolean)
    method?: 'post' | 'get'
    state?: StatefulComponent
    url: string
    data?: Record<string, unknown> | null
    onStart?: () => void
    onSuccess?: (data: T) => void
    onFail?: (resultCode: ResultCode) => void
    onFailCustom?: (arr: string[]) => void
    onError?: () => void
    onFinish?: (success: boolean) => void
}

export async function makeRequestRaw<T>(
    props: RequestProps<T>,
    auth?: Auth | null,
    lang?: string,
    ctrl?: AbortController,
) {
    if (props.if !== undefined) {
        if (typeof props.if === 'boolean') {
            if (!props.if) {
                return null
            }
        } else if (!props.if()) {
            return null
        }
    }

    let success = false
    let result = null
    try {
        props.state?.clearMessages()
        props.state?.startLoading()
        props.onStart?.()

        const response = await fetch(props.url, {
            method: props.method ?? 'post',
            headers: {
                ...(auth && ({'Authorization': `Bearer ${auth?.token}`})),
                'Accept-Language': lang ?? 'en'
            },
            body: props.data ? JSON.stringify(props.data) : undefined,
            signal: ctrl?.signal
        })
        const json = await response.json() as IResponse<T>

        switch (response.status) {
            case 200:
                result = json.data
                props.onSuccess?.(json.data)
                success = true
                break
            case 400:
                if (json.result_code === 'INVALID_TOKEN') {
                    setAuth(null)
                }

                if (props.onFail) {
                    props.onFail?.(json.result_code)
                } else if (props.state) {
                    if (json.result_code === 'UNAUTHORIZED') {
                        props.state.setErrorMessage('unauthorized_page')
                    } else {
                        props.state.setUnexpectedFrontendError()
                    }
                }
                break
            default:
                if (props.onError) {
                    props.onError?.()

                } else if (props.state) {
                    props.state?.setUnexpectedBackendError()
                }
        }

    } catch (e) {
        if (!ctrl?.signal.aborted) {
            props.onError?.()
        }
    } finally {
        if (!ctrl?.signal.aborted) {
            props.onFinish?.(success)
            props.state?.stopLoading()
        }
    }
    return result
}

export async function makeRequestRawCustom<T>(
    props: RequestProps<T>,
    auth?: Auth | null,
    lang?: string,
    ctrl?: AbortController,
) {
    if (props.if !== undefined) {
        if (typeof props.if === 'boolean') {
            if (!props.if) {
                return null
            }
        } else if (!props.if()) {
            return null
        }
    }

    let success = false
    let result = null
    try {
        props.state?.clearMessages()
        props.state?.startLoading()
        props.onStart?.()

        const response = await fetch(props.url, {
            method: props.method ?? 'post',
            headers: {
                ...(auth && ({'Authorization': `Bearer ${auth?.token}`})),
                'Accept-Language': lang ?? 'en'
            },
            body: props.data ? JSON.stringify(props.data) : undefined,
            signal: ctrl?.signal
        })
        const json = await response.json() as IResponse<T>

        switch (response.status) {
            case 200:
                result = json.data
                props.onSuccess?.(json.data)
                success = true
                break
            case 400:
                if (json.result_code === 'INVALID_TOKEN') {
                    setAuth(null)
                }

                if (props.onFailCustom) {
                    const arr:string[] = []
                    arr[0] = json.result_code
                    arr[1] = json.message
                    props.onFailCustom?.(arr)
                } else if (props.state) {
                    if (json.result_code === 'UNAUTHORIZED') {
                        props.state.setErrorMessage('unauthorized_page')
                    } else {
                        props.state.setUnexpectedFrontendError()
                    }
                }
                break
            default:
                if (props.onError) {
                    props.onError?.()

                } else if (props.state) {
                    props.state?.setUnexpectedBackendError()
                }
        }

    } catch (e) {
        if (!ctrl?.signal.aborted) {
            props.onError?.()
        }
    } finally {
        if (!ctrl?.signal.aborted) {
            props.onFinish?.(success)
            props.state?.stopLoading()
        }
    }
    return result
}

/**
 * Custom hook that returns functions for making requests,
 * with authentication token taken from {@link useAuth} hook
 */
export function useRequest() {
    const {auth} = useAuth()
    const {lang} = useLang()

    return {
        makeRequest: <T>(props: RequestProps<T>, ctrl?: AbortController) => makeRequestRaw<T>(props, auth, lang, ctrl),
    }
}

export function useRequestCustom() {
    const {auth} = useAuth()
    const {lang} = useLang()

    return {
        makeRequestCustom: <T>(props: RequestProps<T>, ctrl?: AbortController) => makeRequestRawCustom<T>(props, auth, lang, ctrl),
    }
}

/**
 * Makes a single request inside the {@link useEffect} hook
 * @see useRequest
 */
export function useRequestEffect<T>(props: RequestProps<T>, deps: DependencyList = []) {
    const {makeRequest} = useRequest()
    useEffect(() => {
        const ctrl = new AbortController()
        makeRequest<T>(props, ctrl).then()
        return () => ctrl.abort()
    }, deps)
}
