import {Column} from '@material-table/core'
import {Box, Button, CircularProgress, FormControlLabel, Switch, TextField} from '@material-ui/core'
import {Add, Delete, Save} from '@material-ui/icons'
import {ConfirmationDialog} from 'components/dialogs/ConfirmationDialog'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {CustomTable} from 'components/misc/CustomTable'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {IStat} from 'types/types'
import React, {ChangeEvent, useState} from 'react'
import {refreshTranslations, useRawTranslation, useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useAppSnackbar, useTabletSize} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'
import {TranslationKey} from 'utils/TranslationKey'
import {propsAreEqual} from 'utils/general'

type StateProps = { value: string, new: boolean }
type StatusState = StateProps & { loading: boolean }
type StatusWithState = IStat & { state: StatusState }

const SHOW_STATS_TO_PUBLIC = 'show_stats_to_public'

export function StatsManagement() {
    const page = useStatefulPageState()
    const {translation} = useRawTranslation()
    const {translate} = useTranslation()
    const {showSnackbar} = useAppSnackbar()
    const tablet = useTabletSize()
    const {makeRequest} = useRequest()

    const dialog = useDialogOpenState<StatusWithState | undefined>(undefined)
    const [stats, setStats] = useState<StatusWithState[]>([])
    const [showToPublic, setShowToPublic] = useState<boolean>(false)
    const [toggleEnabled, setToggleEnabled] = useState<boolean>(true)

    const [newStatKey, setNewStatKey] = useState('')

    function updateStatState(desc_key: string, props: Partial<StatusState>) {
        setStats(stats.map(it => it.desc_key === desc_key ? {...it, state: {...it.state, ...props}} : it))
    }

    function updateStatProps(desc_key: string, props: Partial<StatusWithState>) {
        setStats(stats.map(it => it.desc_key === desc_key ? {...it, ...props} : it))
    }

    function deleteStat(desc_key: string) {
        setStats(stats.filter(it => it.desc_key !== desc_key))
    }

    useRequestEffect<IStat[]>({
        url: api.home.stats.read_all,
        state: page,
        onFinish: () => page.setLoading(false),
        onSuccess: (stats) => setStats(stats.map(it => ({
            ...it,
            state: {
                new: false,
                loading: false,
                value: it.value,
            }
        }))),
    })
    useRequestEffect<{ show_stats_to_public: boolean }>({
        state: page,
        url: api.settings.read,
        data: {
            name: SHOW_STATS_TO_PUBLIC
        },
        onSuccess: (data) => setShowToPublic(data[SHOW_STATS_TO_PUBLIC]),
    })


    function handleShowToPublic(event: ChangeEvent<HTMLInputElement>) {
        setShowToPublic(event.target.checked)

        makeRequest({
            url: api.settings.update,
            data: {
                name: SHOW_STATS_TO_PUBLIC,
                value: event.target.checked,
            },
            onStart: () => setToggleEnabled(false),
            onError: () => setShowToPublic(!event.target.checked),
            onFinish: () => setToggleEnabled(true)
        }).then()
    }

    function onDelete(stat?: StatusWithState) {
        if (!stat) {
            return
        }

        if (stat.state.new) {
            deleteStat(stat.desc_key)
        } else {
            return makeRequest({
                url: api.home.stats.delete,
                data: {
                    desc_key: stat.desc_key,
                },
                onStart: () => updateStatState(stat.desc_key, {loading: true}),
                onFinish: success => {
                    if (success) {
                        deleteStat(stat.desc_key)
                        refreshTranslations()
                    } else {
                        updateStatState(stat.desc_key, {loading: false})
                    }

                    showSnackbar(
                        success ? 'success' : 'error',
                        success ? 'item_delete_success' : 'item_delete_fail'
                    )
                },
            })
        }
    }

    return (
        <StatefulPage state={page}>
            <AppHelmet title={translate('manage_home_stats')}/>
            <ConfirmationDialog
                openState={dialog}
                severity={'warning'}
                text={'delete_home_stat_confirmation'}
                onOk={onDelete}
            />
            <Title titleKey={'manage_home_stats'}/>
            <CustomTable
                data={stats}
                title={
                    <Box>
                        <Box display={'flex'} flexDirection={tablet ? 'column' : 'row'} paddingTop={2}>
                            <TextField
                                margin={'dense'}
                                variant={'outlined'}
                                label={translate('desc_key')}
                                inputProps={{maxLength: StringLength.SM}}
                                helperText={`${newStatKey.length}/${StringLength.SM}`}
                                value={newStatKey}
                                onChange={event => setNewStatKey(event.target.value)}
                            />
                            <Box margin={1}/>
                            <Button
                                variant={'outlined'}
                                color={'secondary'}
                                size={'large'}
                                startIcon={<Add/>}
                                style={{margin: 'auto'}}
                                disabled={
                                    !!stats.find(it => it.desc_key === newStatKey) || // No same names
                                    !!Object.keys(translation ?? {}).find(key => key === newStatKey) || // Not same as translation keys
                                    newStatKey === ''
                                }
                                children={translate('new_stat')}
                                onClick={() => setStats([...stats, {
                                    desc_key: newStatKey as TranslationKey,
                                    value: 'new value',
                                    state: {
                                        new: true,
                                        loading: false,
                                        value: 'new value',
                                    }
                                }])}
                            />
                        </Box>
                        <FormControlLabel
                            disabled={!toggleEnabled}
                            label={translate('show_stats_to_public')}
                            labelPlacement="start"
                            control={
                                <Switch
                                    checked={showToPublic}
                                    onChange={handleShowToPublic}
                                    name={'show_stats_to_public'}
                                />
                            }
                        />
                    </Box>
                }
                columns={[
                    {title: translate('key'), field: 'desc_key', editable: 'never'},
                    {title: translate('value'), editable: 'always', render: data => data.state.value},
                ] as Column<StatusWithState>[]}
                actions={[
                    stat => {
                        const disabled = propsAreEqual(stat, stat.state, ['value'])

                        return {
                            icon: () => stat.state.loading ?
                                <CircularProgress color={'primary'} size={24}/> :
                                <Save color={disabled ? 'disabled' : 'secondary'}/>,
                            disabled: disabled || stat.state.loading,
                            hidden: stat.state.new,
                            onClick: () => {
                                // return object only if the given property was changed
                                const param = (prop: keyof Pick<StateProps, 'value'>) =>
                                    stat[prop] !== stat.state[prop] ? {[prop]: stat.state[prop]} : {}

                                makeRequest({
                                    url: api.home.stats.create,
                                    data: {
                                        desc_key: stat.desc_key,
                                        ...param('value'),
                                    },
                                    onStart: () => updateStatState(stat.desc_key, {loading: true}),
                                    onFinish: success => {
                                        updateStatProps(stat.desc_key, {
                                            ...(success && stat.state),
                                            state: {
                                                ...stat.state,
                                                loading: false
                                            }
                                        })
                                        showSnackbar(success ? 'success' : 'error',
                                            success ? 'status_update_success' : 'status_update_fail')
                                    },
                                }).then()
                            }
                        }
                    },
                    stat => ({
                        icon: () => stat.state.loading ?
                            <CircularProgress color={'primary'} size={24}/> :
                            <Add color={'secondary'}/>,
                        hidden: !stat.state.new,
                        onClick: () => {
                            makeRequest({
                                url: api.home.stats.create,
                                data: {
                                    desc_key: stat.desc_key,
                                    value: stat.state.value,
                                },
                                onStart: () => updateStatState(stat.desc_key, {loading: true}),
                                onSuccess: () => {
                                    showSnackbar('success', 'item_create_success')
                                    refreshTranslations()
                                },
                                onFail: () => showSnackbar('error', 'item_create_fail'),
                                onFinish: success => {
                                    updateStatProps(stat.desc_key, {
                                        ...(success && stat.state),
                                        state: {
                                            ...stat.state,
                                            loading: false,
                                            ...(success && {new: false})
                                        }
                                    })
                                },
                            }).then()
                        }
                    }),
                    stat => ({
                        icon: () => stat.state.loading ?
                            <CircularProgress color={'primary'} size={24}/> :
                            <Delete color={'secondary'}/>,
                        onClick: () => dialog.open(stat)
                    })
                ]}
                cellEditable={{
                    onCellEditApproved: async (newValue, oldValue, rowData) => {
                        updateStatState(rowData.desc_key, {value: newValue})
                    },
                    isCellEditable: () => true
                }}
            />
        </StatefulPage>
    )
}
