import React, {CSSProperties, KeyboardEvent, useState} from 'react'
import {createStyles, Divider, IconButton, InputBase, makeStyles, Paper} from '@material-ui/core'
import {ArrowForward, Clear, Search} from '@material-ui/icons'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {StringLength} from 'utils/enums'
import {useAppSnackbar} from 'utils/hooks'
import {route} from 'utils/route'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            background: '#FFFFFF22',
            width: '100%',
            maxWidth: spacing(50),
            color: 'white'
        },
        input: {
            marginLeft: spacing(1),
            flex: 1,
            color: 'rgba(255,255,255,0.9)',
        },
        iconButton: {
            padding: spacing(1),
            fontSize: '2.5rem',
            color: 'rgba(255,255,255,0.9)',
            transition: 'color 0.25s ease'
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
)

export function SearchBar(props: {
    className?: string
    style?: CSSProperties
}) {
    const {className, style} = props
    const classes = useStyles()
    const {showSnackbar} = useAppSnackbar()
    const history = useHistory()
    const {translate} = useTranslation()

    const [elevation, setElevation] = useState(0)
    const [searchQuery, setSearchQuery] = useState('')
    const styles = {
        clear_btn:{
          opacity: searchQuery.length === 0 ? "0" : "1",
        }
    }

    const onClear = () => setSearchQuery('')

    function onSearch() {
        const trimmedQuery = searchQuery.trim()
        if (trimmedQuery.length < 3) {
            showSnackbar('error', 'search_query_too_short')
        } else {
            history.push(route.to.search(trimmedQuery))
        }
    }

    function onKeyPress(event: KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault()
            onSearch()
        }
    }

    return (
        <Paper
            component="form"
            className={[classes.root, className].join(' ')}
            elevation={elevation}
            style={style}
        >
            <Search className={classes.iconButton}/>
            <InputBase
                className={classes.input}
                value={searchQuery}
                onChange={event => setSearchQuery(event.target.value)}
                placeholder={translate('search')}
                name={'search'}
                inputProps={{maxLength: StringLength.XS}}
                onFocus={() => setElevation(2)}
                onBlur={() => setElevation(0)}
                onKeyPress={onKeyPress}
            />
            <IconButton
                className={classes.iconButton}
                style={styles.clear_btn}
                children={<Clear/>}
                onClick={onClear}/>
            <Divider className={classes.divider} orientation="vertical"/>
            <IconButton
                className={classes.iconButton}
                onClick={onSearch}
                children={<ArrowForward/>}
            />
        </Paper>
    )
}