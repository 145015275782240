import {getVariable} from './environment'

const apiHost = getVariable('BACKEND')

export const api = {
    article: {
        read: apiHost + 'article/read',
        update: apiHost + 'article/update',
    },
    auth: {
        change_password: apiHost + 'auth/change-password',
        facebook_login: apiHost + 'auth/facebook-login',
        login: apiHost + 'auth/login',
        logout: apiHost + 'auth/logout',
        register: apiHost + 'auth/register',
    },
    category: {
        read_all: apiHost + 'category/read-all'
    },
    cause: {
        create: apiHost + 'cause/create',
        delete: apiHost + 'cause/delete',
        delete_competition_submission: apiHost + 'cause/delete-competition-submission',
        read: apiHost + 'cause/read',
        read_four_five_launch: apiHost + 'cause/read-four-five-launch',
        read_all: apiHost + 'cause/read-all',
        read_completed: apiHost + 'cause/read-completed',
        read_expired: apiHost + 'cause/read-expired',
        read_paginated: apiHost + 'cause/read-paginated', // TODO: Make use of it?
        read_public: apiHost + 'cause/read-public',
        read_public_active: apiHost + 'cause/read-public-active',
        read_support: apiHost + 'cause/read-support',
        update: apiHost + 'cause/update',
        update_featured: apiHost + 'cause/update-featured',
        update_status: apiHost + 'cause/update-status',
        support: {
            financial: apiHost + 'cause/support/financial',
            non_financial: apiHost + 'cause/support/non-financial',
            non_financial_competition: apiHost + 'cause/support/non-financial-competition',
            financial_invoice: apiHost + 'cause/support/financial-invoice',
            financial_invoice_auth: apiHost + 'cause/support/financial-invoice-auth',
            four_five_manual: apiHost + 'cause/support/four-five-manual'
        }
    },
    certificate: {
        create: apiHost + 'certificate/create',
        read_all: apiHost + 'certificate/read-all',
        read_four_five_launch: apiHost + 'certificate/read-four-five-launch',
        read: apiHost + 'certificate/read',
        read_active: apiHost + 'certificate/read-active',
        update: apiHost + 'certificate/update',
        delete: apiHost + 'certificate/delete',
        read_single_public: apiHost + 'certificate/read-single-public',
    },
    charity: {
        create: apiHost + 'charity/create',
        read: apiHost + 'charity/read',
        read_all: apiHost + 'charity/read-all',
        read_causes: apiHost + 'charity/read-causes',
        read_public: apiHost + 'charity/read-public',
        read_public_report: apiHost + 'charity/read-public-report',
        read_public_for_user: apiHost + 'charity/read-public-for-user',
        read_doc: apiHost + 'charity/read-doc',
        read_contributions: apiHost + 'charity/read-contributions',
        send_to_paynet: apiHost + 'charity/send-to-paynet',
        update: apiHost + 'charity/update',
        update_paynet_secret: apiHost + 'charity/update-paynet-secret',
        update_status: apiHost + 'charity/update-status',
        delete: apiHost + 'charity/delete',
    },
    dashboard: {
        read: apiHost + 'dashboard/read'
    },
    faq: {
        read: apiHost + 'faq/read'
    },
    featured_causes: {
        read: apiHost + 'featured-causes/read',
        update: apiHost + 'featured-causes/update',
    },
    home: {
        banner: {
            create: apiHost + 'home/banner/create',
            read: apiHost + 'home/banner/read',
            read_active: apiHost + 'home/banner/read-active',
            update: apiHost + 'home/banner/update',
            delete: apiHost + 'home/banner/delete',
        },
        stats: {
            create: apiHost + 'home/stats/create',
            read_all: apiHost + 'home/stats/read-all',
            read_public: apiHost + 'home/stats/read-public',
            update: apiHost + 'home/stats/update',
            delete: apiHost + 'home/stats/delete',
        },
        page: apiHost + 'home/read',
        update: apiHost + 'home/update',
    },
    invitation: {
        create: apiHost + 'invitation/create'
    },
    language: {
        read_all: apiHost + 'language/read-all',
        update_enabled: apiHost + 'language/update-enabled',
    },
    search: {
        query: apiHost + 'search/query'
    },
    service: {
        email: {
            activate: apiHost + 'service/email/activate',
            resend_activation: apiHost + 'service/email/resend-activation'
        },
        password: {
            forgot_password: apiHost + 'service/password/forgot-password',
            reset: apiHost + 'service/password/reset',
        },
        payment: {
            confirm: apiHost + 'service/payment/confirm',
            error: apiHost + 'service/payment/error',
            read_logs: apiHost + 'service/payment/read_logs',
            success: apiHost + 'service/payment/success',
        }
    },
    settings: {
        read: apiHost + 'settings/read',
        update: apiHost + 'settings/update',
    },
    social: {
        read: apiHost + 'social/read',
        update: apiHost + 'social/update',
    },
    static: {
        read: (file: string) => `${apiHost}static/read?file=${file}`
    },
    status: {
        create: apiHost + 'status/create',
        read_all: apiHost + 'status/read-all',
        update: apiHost + 'status/update',
        delete: apiHost + 'status/delete',
    },
    translation: {
        read: apiHost + 'translation/read',
        update_key: apiHost + 'translation/update-key'
    },
    user: {
        read: apiHost + 'user/read',
        read_all: apiHost + 'user/read-all',
        update: apiHost + 'user/update',
        update_status: apiHost + 'user/update_status',
        read_charities: apiHost + 'user/read-charities',
        read_causes: apiHost + 'user/read-causes',
        read_transactions: apiHost + 'user/read-transactions',
        delete_account: apiHost + 'user/delete-account',
        delete_certificates: apiHost + 'user/delete-certificates',
        delete_certificate: apiHost + 'user/delete-certificate',
        account_phone_verify: apiHost + 'user/account-phone-verify',
        check_verification_code: apiHost + 'user/check-verification-code',
        otp_phone_verify: apiHost + 'user/otp-phone-verify',
        otp_phone_verify_auth: apiHost + 'user/otp-phone-verify-auth',
        check_otp_code: apiHost + 'user/check-otp-code',
        auth_phone_check: apiHost + 'user/auth-phone-check',
        total_donation_check: apiHost + 'user/total-donation-check',
        age_verification_check: apiHost + 'user/age-verification-check',
    },
    news: {
        read_public: apiHost + 'news/read-public'
    },
    donation: {
        read_donation_report: apiHost + 'donation/read-donation-report',
        read_projects_current_report: apiHost + 'donation/read-projects-current-report',
        read_donation_report_charity: apiHost + 'donation/read-donation-report-charity',
        read_projects_current_report_charity: apiHost + 'donation/read-projects-current-report-charity'
    },
}
