import {IconButton, Tooltip} from '@material-ui/core'
import Button, {ButtonProps} from '@material-ui/core/Button'
import React, {ReactElement} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useMobileSize} from 'utils/hooks'
import {TranslationKey} from 'utils/TranslationKey'
import {Only} from '../util/Only'

export function ResponsiveButton(props: {
    label: TranslationKey
    icon: ReactElement
    to?: string
} & Pick<ButtonProps, 'onClick' | 'disabled' | 'variant' | 'color'>) {
    const mobile = useMobileSize()
    const {translate} = useTranslation()

    return (
        <>
            <Only when={mobile}>
                <IconButton
                    disabled={props.disabled}
                    onClick={props.onClick}
                    color={props.color}
                    {...(props.to && {component: Link, to: props.to})}
                >
                    <Tooltip title={translate(props.label)} children={props.icon}/>
                </IconButton>
            </Only>
            <Only when={!mobile}>
                <Button
                    disabled={props.disabled}
                    onClick={props.onClick}// Delete dialog
                    variant={props.variant}
                    startIcon={props.icon}
                    color={props.color}
                    children={translate(props.label)}
                    {...(props.to && {component: Link, to: props.to})}
                    style={{
                        margin: 'auto'
                    }}
                />
            </Only>
        </>
    )
}
