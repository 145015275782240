import {Container} from '@material-ui/core'
import React, {PropsWithChildren} from 'react'

export function GbbContainer(props: PropsWithChildren<unknown>) {
    return (
        <Container
            maxWidth="xl"
            style={{
                padding: 0
            }}
            children={<>{props.children}</>}
        />
    )
}
