import {createState, DevTools, useState} from '@hookstate/core'
import {api} from 'utils/api'
import {makeRequestRaw} from 'utils/request'
import {LanguageKey} from './lang'

const stateLabel = 'langs'

export type Langs = Record<LanguageKey, { name: string, is_enabled: boolean }>

export function fetchLanguages() {
    return makeRequestRaw<Langs>({
        url: api.language.read_all,
    })
}

const globalState = createState(fetchLanguages)

export function useLangs() {
    const state = useState(globalState)

    return {
        langs: state.promised || state.error ? null : state.get(),
        setLangs: (lang: Langs) => state.set(lang),
    }
}

DevTools(globalState).label(stateLabel)
