import {Divider, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core'
import React, {CSSProperties, Dispatch, SetStateAction} from 'react'
import {useTranslation} from 'state/translation'
import {useMobileSize} from 'utils/hooks'
import {TranslationKey} from 'utils/TranslationKey'

export function SelectWithAll<T>(props: {
    label: TranslationKey,
    values?: TranslationKey[]
    value: T,
    setValue: Dispatch<SetStateAction<T>>,
    style?: CSSProperties
}) {
    const {translate} = useTranslation()
    const mobile = useMobileSize()

    return (
        <FormControl variant="outlined" style={props.style}>
            <InputLabel>{translate(props.label)}</InputLabel>
            <Select
                value={props.value}
                onChange={event => props.setValue(event.target.value as T)}
                label={translate(props.label)}
                margin={mobile ? 'none' : 'dense'}
            >
                <MenuItem value="all" children={translate('all')}/>
                <Divider/>
                {props.values?.map(it =>
                    <MenuItem key={it} value={it} children={translate(it)}/>
                )}
            </Select>
        </FormControl>
    )
}