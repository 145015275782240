import {Box, withStyles} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {Facebook} from '@material-ui/icons'
import {Only} from 'components/util/Only'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {useAuth} from 'state/auth'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {useRequest} from 'utils/request'
import {route} from 'utils/route'
import {StatefulDialog, useDialogOpenState, useStatefulDialogState} from '../foundation/StatefulDialog'
import FacebookLogin, {ReactFacebookLoginProps} from 'react-facebook-login-typed'
import {getVariable} from 'utils/environment'
import {RichText} from '../misc/RichText'
import {IAuth} from './LoginDialog'

type IAuthLogin = IAuth & {
    operation: 'login'
}

type IAuthRegistration = IAuth & {
    operation: 'registration'
    password: string
}

export function FacebookButtonDialog(props: {
    onClick?: () => void
}) {
    const {translate} = useTranslation()
    const history = useHistory()

    const {setAuth} = useAuth()
    const {makeRequest} = useRequest()
    const {setUser} = useUser()

    const facebookDialog = useStatefulDialogState()
    const openState = useDialogOpenState(undefined)

    const onFacebookAuth: ReactFacebookLoginProps['callback'] = (response) => {
        facebookDialog.setLoading(false)

        if ('id' in response) {
            makeRequest<IAuthLogin | IAuthRegistration>({
                url: api.auth.facebook_login,
                state: facebookDialog,
                data: {
                    access_token: response.accessToken,
                },
                onSuccess: r => {
                    if (r.operation === 'login') {
                        facebookDialog.setSuccessMessage(() => () =>
                            translate('welcome_user_placeholder').replace('%s', r.user.name)
                        )
                    } else {
                        let message = translate('welcome_user_facebook_placeholder').replace('%name%', r.user.name)
                        message = message.replace('%email%', r.user.email)
                        message = message.replace('%pass%', r.password)

                        facebookDialog.setSuccessMessage(() => () => message)
                    }

                    setAuth({
                        token: r.token,
                        userID: r.user.id
                    })
                    setUser(r.user)
                    history.push(route.to.home)
                }
            }).then()
        }
    }

    return (
        <>
            <FacebookLogin
                appId={getVariable('FACEBOOK_APP_ID')}
                autoLoad={false}
                cookie={false}
                version={'12.0'}
                fields="name,email,picture"
                scope="public_profile,email"
                disableMobileRedirect={true}
                callback={onFacebookAuth}
                onFailure={console.error}
                render={(renderProps) => (
                    <FacebookButton
                        onClick={event => {
                            props.onClick?.()
                            openState.setOpen(true)
                            facebookDialog.setLoading(true)
                            renderProps.onClick(event) // Notes: Crashes with ad-blocker
                        }}
                        startIcon={<Facebook/>}
                        children={translate('login_with_facebook')}
                    />
                )}
            />
            <StatefulDialog
                state={facebookDialog}
                openState={openState}
                maxWidth={'xs'}
                preventClosingWhileLoading={true}
                titleKey={'login_with_facebook'}
                content={
                    <Box>
                        <Only when={facebookDialog.loading}>
                            <RichText text={translate('processing_your_request')}/>
                        </Only>
                    </Box>
                }
                buttons={
                    <Box flex={1} display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            onClick={() => openState.setOpen(false)}
                            color="primary"
                            variant={'contained'}
                            children={translate('ok')}
                        />
                    </Box>
                }
            />
        </>
    )
}

const FacebookButton = withStyles(() => ({
    root: {
        color: '#FFFFFF',
        backgroundColor: '#1877F2',
        '&:hover': {
            backgroundColor: '#125DBF',
        },
    },
}))(Button)
