import React, {PropsWithChildren} from 'react'

export const Anchor = ({className, href, newTab, isEmail, isPhone, children}: PropsWithChildren<{
    className?: string
    href?: string
    newTab?: boolean
    isEmail?: boolean
    isPhone?: boolean
}>) =>
    <a
        className={className}
        href={`${isEmail ? 'mailto:' : ''}${isPhone ? 'tel:+' : ''}${href}`}
        {...(newTab && {
            target: href,
            rel: 'noopener'
        })}
        children={children ?? `${isPhone ? '+' : ''}${href}`}
    />
