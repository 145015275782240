import React, {CSSProperties, MouseEventHandler, PropsWithChildren} from 'react'
import IconButton from '@material-ui/core/IconButton'
import {createStyles, makeStyles} from '@material-ui/core/styles'

type SizeType = 'large' | 'normal' | 'small'

const useStyles = (size: SizeType) => makeStyles(({spacing}) =>
    createStyles({
        socialButton: {
            margin: 'auto 0',
            padding: {
                small: spacing(0.5),
                normal: spacing(1),
                large: spacing(1.5),
            }[size]
        }
    })
)()


export function SocialButton(props: PropsWithChildren<{
    link?: string | null,
    onClick?: MouseEventHandler,
    size: SizeType,
    style?: CSSProperties
}>) {
    const {link, onClick, size} = props
    const {socialButton} = useStyles(size)

    return (
        <IconButton
            className={socialButton}
            style={props.style}
            children={props.children}
            {...(link && {href: link, target: '_blank', rel: 'noopener'})}
            {...(onClick && {onClick})}
        />
    )
}
