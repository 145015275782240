import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip
} from '@material-ui/core'
import {Add, ArrowDropDown, ArrowDropUp, Delete} from '@material-ui/icons'
import PopupState from 'material-ui-popup-state'
import {bindMenu, bindTrigger} from 'material-ui-popup-state/es'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'state/translation'
import {TranslationKey} from 'utils/TranslationKey'
import {NumberArrayField} from '../foundation/EditableField'
import {ImageWithFallback} from './ImageWithFallback'
import {RichText} from './RichText'
import {Only} from '../util/Only'

export interface RichObject {
    id: number,
    name: string
    image_src: string | null,
}

export function RichObjectSelector(props: {
    title: TranslationKey,
    imageRatio: number,
    idArrayField: NumberArrayField,
    objects: RichObject[],
    sortable?: boolean
}) {
    const {title, imageRatio, objects, idArrayField, sortable} = props
    const {translate} = useTranslation()

    const iconSize = 24

    const [selectedObjects, setSelectedObjects] = useState<RichObject[]>([])
    const [selectableObjects, setSelectableObjects] = useState<RichObject[]>([])

    useEffect(() => {
        setSelectedObjects(
            idArrayField.value
                .map(id => objects.find(o => o.id === id) as RichObject)
                .filter(it => it !== undefined)
        )
        setSelectableObjects(
            objects.filter(object =>
                !idArrayField.value.includes(object.id)
            )
        )
    }, [idArrayField])

    function onObjectSelected(objectID: number) {
        idArrayField.setValue([...idArrayField.value, objectID])
    }

    function onObjectDeleted(objectID: number) {
        idArrayField.setValue(idArrayField.value.filter(it => it !== objectID))
    }

    function onObjectMoved(index: number, direction: 'up' | 'down') {
        const offset = direction === 'up' ? -1 : +1
        const arrayCopy = idArrayField.value.slice()
        const temp = arrayCopy[index]
        arrayCopy[index] = arrayCopy[index + offset]
        arrayCopy[index + offset] = temp
        idArrayField.setValue(arrayCopy)
        console.debug('New array: ' + arrayCopy)
    }

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <RichText bold text={translate(title)} margin="auto"/>
            <Table size="small">
                <TableBody>
                    {selectedObjects.map((object, i) =>
                        <TableRow key={object.id}>
                            <Only when={sortable}>
                                <TableCell children={i + 1}/>
                                <TableCell>
                                    <Box display={'flex'} flexDirection={'column'}>
                                        <IconButton
                                            size={'small'}
                                            style={{margin: 'auto'}}
                                            disabled={i === 0}
                                            onClick={() => onObjectMoved(i, 'up')}
                                        >
                                            <ArrowDropUp/>
                                        </IconButton>
                                        <IconButton
                                            size={'small'}
                                            style={{margin: 'auto'}}
                                            disabled={i === selectedObjects.length - 1}
                                            onClick={() => onObjectMoved(i, 'down')}
                                        >
                                            <ArrowDropDown/>
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </Only>
                            <TableCell>
                                <ImageWithFallback
                                    width={2 * iconSize * imageRatio}
                                    height={2 * iconSize}
                                    src={object.image_src}
                                />
                            </TableCell>
                            <TableCell children={object.name}/>
                            <TableCell>
                                <Tooltip title={translate('delete')}>
                                    <IconButton onClick={() => onObjectDeleted(object.id)} children={<Delete/>}/>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <Box marginTop={1}/>
            <PopupState variant="popover" popupId="object-selector-popup">{popupState =>
                <>
                    <Button
                        children={translate('add')}
                        startIcon={<Add/>}
                        variant="contained"
                        color="secondary"
                        style={{marginRight: 'auto'}}
                        disabled={selectableObjects.length === 0}
                        {...bindTrigger(popupState)}
                    />
                    <Menu {...bindMenu(popupState)}>{
                        selectableObjects.map(object =>
                            <MenuItem key={object.id} value={object.id} onClick={() => {
                                popupState.close()
                                onObjectSelected(object.id)
                            }}>
                                <Box display={'flex'}>
                                    <ImageWithFallback
                                        width={iconSize * imageRatio}
                                        height={iconSize}
                                        src={object.image_src}
                                    />
                                    <RichText marginLeft={8} text={object.name}/>
                                </Box>
                            </MenuItem>
                        )
                    }</Menu>
                </>
            }</PopupState>
        </Box>
    )
}
