import {Search} from 'pages/search/Search'
import React, {useEffect} from 'react'
import ReactGA from 'react-ga4'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {route} from 'utils/route'
import {Home} from 'pages/home/Home'
import {Cause} from 'pages/cause/Cause'
import {Certificate} from 'pages/user/Certificate'
import {CauseEditor} from 'pages/cause/editor/CauseEditor'
import {Charity} from 'pages/charity/Charity'
import {CharityEditor} from 'pages/charity/editor/CharityEditor'
import {SignUp} from 'pages/sign-up/SignUp'
import {ActivateEmail} from 'pages/activate-email/ActivateEmail'
import {UserCharities} from 'pages/user/charities/UserCharities'
import {UserCauses} from 'pages/user/causes/UserCauses'
import {UserSettings} from 'pages/user/settings/UserSettings'
import {CausesWithFilters} from 'pages/causes/CausesWithFilters'
import {Charities} from 'pages/charities/Charities'
import {HowItWorks} from 'pages/how-it-works/HowItWorks'
import {ResetPassword} from 'pages/reset-password/ResetPassword'
import {NotFound} from 'components/mini-pages/NotFound'
import {AdminRoutes} from './AdminRoutes'
import {FAQ} from 'pages/faq/FAQ'
import {News} from 'pages/news/News'
import {MyDonationReport} from 'pages/charity/MyDonationReport'
import {MyProjectsCurrentReport} from 'pages/charity/MyProjectsCurrentReport'
import {AboutUs} from 'pages/about-us/AboutUs'
import {PrivacyAndSecurity} from 'pages/privacy-and-security/PrivacyAndSecurity'
import {CharityContributions} from 'pages/charity/CharityContributions'
import {UserTransactions} from 'pages/user/UserTransactions'
import {SignUpSuccess} from 'pages/sign-up/SignUpSuccess'
import {getVariable} from '../../utils/environment'
import {PaynetCharityRegistration} from 'pages/paynet-charity-registration/PaynetCharityRegistration'
import {CompletedCauses} from '../../pages/causes/CompletedCauses'
import {ExpiredCauses} from '../../pages/causes/ExpiredCauses'
import {FourFiveCert} from 'pages/admin/certificates/FourFiveCert'

export const Routes = () => {
    const location = useLocation()

    useEffect(() => {
        ReactGA.initialize(getVariable('GA_MEASUREMENT_ID'))
    }, [])


    useEffect(() => {
        if (ReactGA.isInitialized) {
            ReactGA.send('pageview')
        }
    }, [location.pathname])

    return (
        <Switch>
            <Route exact path={[route.to.root, route.to.index]}> <Redirect to={route.to.home}/> </Route>
            <Route exact path={route.to.about_us}> <AboutUs/> </Route>
            <Route exact path={route.to.cause(':id').page}> <Cause/> </Route>
            <Route exact path={route.to.cause(':id').edit}> <CauseEditor/> </Route>
            <Route exact path={route.to.charity(':id').page}> <Charity/> </Route>
            <Route exact path={route.to.charity(':id').edit}> <CharityEditor/> </Route>
            <Route exact path={route.to.charity(':id').contributions}> <CharityContributions/> </Route>
            <Route exact path={route.to.faq}> <FAQ/> </Route>
            <Route exact path={route.to.news}> <News/> </Route>
            <Route exact path={route.to.home}> <Home/> </Route>
            <Route exact path={route.to.sign_up.page}> <SignUp/> </Route>
            <Route exact path={route.to.sign_up.success}> <SignUpSuccess/> </Route>
            <Route exact path={route.to.activate_email(':code')}> <ActivateEmail/> </Route>
            <Route exact path={route.to.my_charities}> <UserCharities/> </Route>
            <Route exact path={route.to.my_causes}> <UserCauses/> </Route>
            <Route exact path={route.to.my_transactions}> <UserTransactions/> </Route>
            <Route exact path={route.to.user_settings}> <UserSettings/> </Route>
            <Route exact path={route.to.causes()}> <CausesWithFilters/> </Route>
            <Route exact path={route.to.completed_causes}> <CompletedCauses/> </Route>
            <Route exact path={route.to.expired_causes}> <ExpiredCauses/> </Route>
            <Route exact path={route.to.charities}> <Charities/> </Route>
            <Route exact path={route.to.how_it_works}> <HowItWorks/> </Route>
            <Route exact path={route.to.reset_password(':code')}> <ResetPassword/> </Route>
            <Route exact path={route.to.paynet_charity_registration}> <PaynetCharityRegistration/> </Route>
            <Route exact path={route.to.privacy_and_security}> <PrivacyAndSecurity/> </Route>
            <Route exact path={route.to.search()}> <Search/> </Route>
            <Route exact path={route.to.certificate(':id').page}> <Certificate/> </Route>

            <Route exact path={route.to.my_donation_report}> <MyDonationReport/> </Route>
            <Route exact path={route.to.my_projects_current_report}> <MyProjectsCurrentReport/> </Route>

            <Route exact path={route.to.four_five_cert}> <FourFiveCert/> </Route>

            <Route path={[route.to.admin.page, route.to.user(':id').page]}> <AdminRoutes/> </Route>
            <Route component={NotFound}/>
        </Switch>
    )
}
