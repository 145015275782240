import {Box} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {ResponsiveGrid} from 'components/foundation/ResponsiveGrid'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {SelectWithAll} from 'components/misc/SelectWithAll'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import {ACauseState, ACauseTypes, ICause, ICauseState, ICauseType} from 'types/cause'
import {ICategory} from 'types/types'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useQueryParam, useScrollToTopEffect} from 'utils/hooks'
import {route} from 'utils/route'
import {TranslationKey} from 'utils/TranslationKey'
import {useRequestEffect} from '../../utils/request'
import {useLang} from '../../state/lang'
import {CauseGridWithPagination} from './CauseGridWithPagination'

const useStyles = makeStyles(({breakpoints, spacing}) =>
    createStyles({
        filterBox: {
            width: '100% !important',
            maxWidth: breakpoints.values['mobile'],
            [breakpoints.up('tablet')]: {
                maxWidth: breakpoints.values['tablet'],
            }
        },
        causeCard: {
            width: spacing(35),
        },
        pagination: {
            margin: 'auto'
        }
    })
)

export function CausesWithFilters() {
    const classes = useStyles()
    const {translate} = useTranslation()
    const {lang} = useLang()
    const page = useStatefulPageState()

    const type = useQueryParam('type') ?? 'all'
    const state = useQueryParam('state') ?? 'active'
    const categoryParam = useQueryParam('category') ?? 'all'
    const hideFiltersParam = useQueryParam('hide_filters') ?? ''

    const [categories, setCategories] = useState<ICategory[]>()
    const [causes, setCauses] = useState<ICause[]>([])
    const [filteredCauses, setFilteredCauses] = useState<ICause[]>([])
    const [causeType, setCauseType] = useState<ICauseType | 'all'>('all')
    const [category, setCategory] = useState('all')
    const [causeState, setCauseState] = useState<ICauseState | 'all'>('all')
    const [hideFilters, setHideFilters] = useState<boolean>(false)

    useScrollToTopEffect()

    useRequestEffect({
        url: api.cause.read_public_active,
        state: page,
        onSuccess: setCauses
    }, [lang])

    useRequestEffect({
        url: api.category.read_all,
        onSuccess: (data: ICategory[]) => {
            setCategories(data)
            if (data.map<string>(it => it.name).includes(categoryParam)) {
                setCategory(categoryParam)
            }
        }
    })

    useEffect(() => {
        const whitelistedType = ACauseTypes.includes(type) ? type : 'all'
        setCauseType(whitelistedType as ICauseType | 'all')

        const whitelistedState = ACauseState.includes(state) ? state : 'all'
        setCauseState(whitelistedState as ICauseState | 'all')

        const whitelistedHideFilters = ['true', 'false'].includes(hideFiltersParam) ? hideFiltersParam : 'false'
        setHideFilters(whitelistedHideFilters === 'true')

        setCategory(categoryParam)
    }, [type, state, categoryParam])

    useEffect(() => {
        setFilteredCauses(causes
            .filter(cause => causeType === 'all' || cause.projects[causeType].is_active)
            .filter(cause => category === 'all' || category === cause.category)
            .filter(cause => causeState === 'all' || causeState === cause.state)
        )
    }, [causes, causeType, category, causeState])

    return (
        <StatefulPage state={page}>
            <AppHelmet
                description={translate('all_causes_meta_desc')}
                canonicalRoute={route.to.causes()}
            />
            <Only when={{categories}}>{({categories}) =>
                <Box display={'flex'} flexDirection={'column'}>
                    <Title titleKey={'all_causes'}/>
                    <Only when={!hideFilters}>
                        <ResponsiveGrid
                            className={classes.filterBox}
                            rowGap={2}
                            breakpointColumns={{
                                tablet: 2,
                            }}>
                            <SelectWithAll
                                label="cause_type"
                                values={ACauseTypes as TranslationKey[]}
                                value={causeType}
                                setValue={setCauseType}/>
                            <SelectWithAll
                                label="category"
                                values={categories?.map(it => it.name)}
                                value={category}
                                setValue={setCategory}/>
                        </ResponsiveGrid>
                        <Box marginTop={4}/>
                    </Only>
                    <CauseGridWithPagination causes={filteredCauses}/>
                </Box>
            }</Only>
        </StatefulPage>
    )
}
