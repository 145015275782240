import React, { useEffect, useState } from 'react'
import { useTranslation } from 'state/translation'
import { DialogOpenState, StatefulDialog, useStatefulDialogState } from '../../../components/foundation/StatefulDialog'
import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { ECauseType, ICause, ICauseType } from '../../../types/cause'
import { useHookstate } from '@hookstate/core'
import { LoadingStep } from './LoadingStep'
import { LoadingStepCustom } from './LoadingStepCustom'
import { ResultData, ResultStep } from './ResultStep'
import { ConsentStep } from './ConsentStep'
import { PaymentMethodStep } from './PaymentMethodStep'
import { ContactInfoStep } from './ContactInfoStep'
import { AuthPhoneCheckStep } from './AuthPhoneCheckStep'
import { SmsCheckStep } from './SmsCheckStep'
import { SmsCheckPost } from './SmsCheckPost'
import { SmsVerifyStep } from './SmsVerifyStep'
import { SmsVerifyPost } from './SmsVerifyPost'
import { SmsCheckPostAuth } from './SmsCheckPostAuth'
import { SmsVerifyStepAuth } from './SmsVerifyStepAuth'
import { SmsVerifyPostAuth } from './SmsVerifyPostAuth'
import { TotalDonationCheckStep } from './TotalDonationCheckStep'
import { AgeVerificationStep } from './AgeVerificationStep'
import { CompetitionInfoStep } from './CompetitionInfoStep'
import { LoadingStepCompetition } from './LoadingStepCompetition'
import { useAuth } from '../../../state/auth'
import { Only } from '../../../components/util/Only'
import { useStringField, useImageBase64Field, useNumberField } from '../../../components/foundation/EditableField'
import { EResult, StringLength } from '../../../utils/enums'
import { useHistory } from 'react-router-dom'
import { useMobileSize, useQueryParam } from '../../../utils/hooks'
import { ValidateThat } from '../../../utils/ValidateThat'
import { PaymentMethodType } from '../../../types/types'
import { useRequest, useRequestEffect } from 'utils/request'
import { api } from 'utils/api'
import { ICertificate } from 'types/types'
import { IUser } from 'types/user'
import { useLang } from 'state/lang'
import {useAppSnackbar, useAppSnackbarCustom} from 'utils/hooks'
import {VerifyPhoneDialogAlt} from 'pages/user/settings/components/VerifyPhoneDialogAlt'
import {ConfirmationDialog} from 'components/dialogs/ConfirmationDialog'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'


let d_type_old = ''
let d_type = ''
//let cert_type_old = ''
//let cert_type = ''
let filtered_certificates:any
//let selected_certificate:any //hold selected certificate so we can get its content and update preview accordingly
let cur_user: any = ''
//let preview_content: any = '' //hold preview content to send to child component

enum SupportStep {
    Result,
    ContactInfo,
    Consent,
    PaymentMethod,
    SmsCheck,
    SmsCheckRequest,
    SmsVerify,
    SmsVerifyRequest,
    SmsCheckRequestAuth,
    SmsVerifyAuth,
    SmsVerifyRequestAuth,
    AuthPhoneCheck,
    TotalDonationCheck,
    AgeVerificationCheck,
    CompetitionInfo,
    LoadingCompetition,
    LoadingCustom,
    Loading
}

type DialogAction = 'show_result' | 'support_cause'

export interface SupportDialogData {
    project: ICauseType,
    action: DialogAction
    amount: number | null // Volunteering & Activity don't have amount
}

export function SupportDialog(props: {
    openState: DialogOpenState<SupportDialogData>,
    cause: ICause
    causeId: number
    causeName: string
    causeOwner: string
    causeNameAlt: string
    causeOwnerAlt: string
    update: () => void
}) {
    
    const { openState, causeId, causeName, causeOwner, causeNameAlt, causeOwnerAlt, cause, update } = props
    const { action, project, amount } = openState.data
    
    const {makeRequest} = useRequest()

    const { auth } = useAuth()
    const { lang } = useLang()
    const { translate } = useTranslation()
    const history = useHistory()
    const mobile = useMobileSize()

    const dialog = useStatefulDialogState()
    const {showSnackbar} = useAppSnackbar()
    const {showSnackbarCustom} = useAppSnackbarCustom()

    const initialStep: SupportStep = {
        'show_result': SupportStep.Result,
        'support_cause': {
            //'donation': auth ? SupportStep.PaymentMethod : SupportStep.ContactInfo,
            'donation': SupportStep.ContactInfo,
            'event': SupportStep.Consent,
            'volunteering': SupportStep.Consent,
            'activity': SupportStep.Consent,
            'competition': SupportStep.Consent,
        }[project],
    }[action]

    const currentStep = useHookstate<SupportStep>(initialStep)

    useEffect(() => {
        if (openState.isOpen) {
            currentStep.set(initialStep)
        }
    }, [openState.isOpen])

    // Result step
    const projectParamRaw = useQueryParam(ResultData.project)
    const projectParam = useHookstate<ECauseType | null>(null)

    const resultParamRaw = useQueryParam(ResultData.result)
    const resultParam = useHookstate<EResult | null>(null)

    useEffect(() => {
        if (projectParamRaw && Object.values(ECauseType).includes(projectParamRaw as ECauseType)) {
            projectParam.set(projectParamRaw as ECauseType)
        }

        if (resultParamRaw && Object.values(EResult).includes(resultParamRaw as EResult)) {
            resultParam.set(resultParamRaw as EResult)
        }
    }, [projectParamRaw, resultParamRaw])

    useEffect(() => {
        // Delay execution to avoid setting state in render
        openState.setOpen(!!projectParam.value && !!resultParam.value)
        currentStep.set(SupportStep.Result)
    }, [projectParam.value, resultParam.value])



    // Contact info step
    const sendCertificate = useHookstate<boolean>(false)
    const name = useStringField(StringLength.XS)
    const email = useStringField(StringLength.MD)
    const receiver_name = useStringField(StringLength.XS)
    const receiver_email = useStringField(StringLength.MD)
    const donation_type = useStringField(StringLength.XS)
    const cert_lang = useStringField(StringLength.XS)
    const show_amount = useStringField(StringLength.XS)
    const cert_id = useStringField()
    const userInfo = {
        name: name.value,
        email: email.value,
        receiver_name: receiver_name.value,
        receiver_email: receiver_email.value,
        donation_type: donation_type.value,
        show_amount: show_amount.value,
        cert_lang: cert_lang.value,
        cert_id: cert_id.value,
    }

    //invoice donation 
    const ref_no = useStringField(StringLength.XS)

    //--invoice non logged in
    const sms_phone = useStringField(StringLength.XS)
    const sms_code = useStringField(StringLength.XXS)
    
    //--invoice logged in
    const contract_id = useStringField(StringLength.XS)

    // Competition info step
    const photo_text = useStringField(StringLength.XL)
    const image = useImageBase64Field()
    const dob = useStringField()

    //get current user details, set some input defaults on finish
    useRequestEffect<IUser>({
        url: api.user.read,
        if: !!auth?.userID,
        data: {
            id: auth?.userID
        },
        onSuccess: user => {
            cur_user = user

        },
        onFinish: () => {
            //populate name and email of user if logged in - disable email input
            if (auth) {
                name.setInitialValueCustom(cur_user.name)

                email.setInitialValueCustom(cur_user.email)
                email.setDisabled(true)

                cert_lang.setInitialValueCustom(cur_user.lang)

                //for logged in invoice donation
                sms_phone.setInitialValueCustom(cur_user.phone_number)
                contract_id.setInitialValueCustom(cur_user.contract_id)

                //for competition 
                dob.setInitialValueCustom(cur_user.dob)

            }
        }
    }, [])

    //get certificates and set other input defaults
    const [certificates, setCertificates] = useState<ICertificate[]>([])
    useRequestEffect<ICertificate[]>({
        url: api.certificate.read_active,
        onSuccess: setCertificates,
        onFinish: () => {
            donation_type.setInitialValueCustom('individual')
            show_amount.setInitialValueCustom('1')
            if (!auth) {
                cert_lang.setInitialValueCustom(lang)
            }
        }
    })

    //change select options according to donation type value

    useEffect(() => {
        //change select options according to donation type value, empty user name if donation_type is set to org
        d_type = donation_type.value
        if (d_type_old != d_type) {
            cert_id.setValue('')
            d_type_old = d_type
            filtered_certificates = []
            for (let i = 0; i < certificates.length; i++) {
                if (certificates[i].cert_group.toString() == donation_type.value.toString() ) {
                    filtered_certificates.push(certificates[i])
                    if(!cert_id.value && donation_type.value.toString() == 'individual'){
                        cert_id.setInitialValueCustom(certificates[i].id)
                        // if(!selected_certificate){
                        //     selected_certificate = certificates[i]
                        //     //set preview content
                        //     if(cert_lang.value === "tr"){
                        //         if(show_amount.value === '1'){
                        //             preview_content = selected_certificate.content_tr
                        //         }else{
                        //             preview_content = selected_certificate.content_alt_tr
                        //         }
                        //     }else if(cert_lang.value === "en"){
                        //         if(show_amount.value === '1'){
                        //             preview_content = selected_certificate.content_en
                                    
                        //         }else{
                        //             preview_content = selected_certificate.content_alt_en
                        //         }
                        //     }
                        // }
                    }
                }
            }
            //console.log(filtered_certificates)
            //console.log(certificates)
            //console.log("-------------------")

            // console.log(d_type)
            // console.log(d_type_old)
            // console.log(donation_type.value)
            // console.log("-------------------")

            //empty user name if donation_type is set to org and reset to user's name if logged in
            if(d_type === 'org'){
                name.setInitialValueCustom('')
            }else{
                if(auth){
                    name.setInitialValueCustom(cur_user.name)
                }
            }
        }

        //set selected_certificate
        // cert_type = cert_id.value
        // if(!cert_type){
        //     selected_certificate = ''
        //     preview_content = ''
        // }else if(cert_type_old != cert_type){
        //     cert_type_old = cert_type
        //     for (let i = 0; i < certificates.length; i++) {
        //         if(certificates[i].id == cert_id.value){
        //             selected_certificate = certificates[i]
        //             //set preview content
        //             if(cert_lang.value === "tr"){
        //                 if(show_amount.value === '1'){
        //                     preview_content = selected_certificate.content_tr
        //                 }else{
        //                     preview_content = selected_certificate.content_alt_tr
        //                 }
        //             }else if(cert_lang.value === "en"){
        //                 if(show_amount.value === '1'){
        //                     preview_content = selected_certificate.content_en
                            
        //                 }else{
        //                     preview_content = selected_certificate.content_alt_en
        //                 }
        //             }
        //             break
        //         }
        //     }
        // }

        //set preview content
        // if(selected_certificate){
        //     if(cert_lang.value === "tr"){
        //         if(show_amount.value === '1'){
        //             preview_content = selected_certificate.content_tr
        //         }else{
        //             preview_content = selected_certificate.content_alt_tr
        //         }
        //     }else if(cert_lang.value === "en"){
        //         if(show_amount.value === '1'){
        //             preview_content = selected_certificate.content_en
                    
        //         }else{
        //             preview_content = selected_certificate.content_alt_en
        //         }
        //     }
        // }
        //console.log(preview_content)
    }, [donation_type, cert_lang, cert_id, show_amount, name, email, receiver_name, receiver_email])

    // Payment method step
    const paymentMethod = useHookstate<PaymentMethodType>('paynet')

    function onClose() {
        if (!loadingNewPage.value) {
            history.push(window.location.pathname)
            openState.close()
        }
    }

    // Loading step
    const loadingNewPage = useHookstate<boolean>(false)

    function onBack() {
        if (currentStep.value === initialStep) {
            onClose()
        } else if ([SupportStep.Loading, SupportStep.PaymentMethod, SupportStep.LoadingCustom, SupportStep.SmsCheck, SupportStep.SmsCheckRequest, SupportStep.SmsVerify, SupportStep.SmsVerifyRequest, SupportStep.SmsCheckRequestAuth, SupportStep.SmsVerifyAuth, SupportStep.SmsVerifyRequestAuth, SupportStep.CompetitionInfo, SupportStep.LoadingCompetition, SupportStep.AgeVerificationCheck].includes(currentStep.value)) {
            currentStep.set(initialStep)
            dialog.clearMessages()
        } else {
            console.error('SupportDialog.onBack: Unexpected state transition')
        }
    }

    function onContinue() {
        dialog.clearMessages()
        sms_code.setValue('')
        if (currentStep.value === SupportStep.ContactInfo) {
            let valid = false;
            if (donation_type.value == 'gift') {
                valid = !sendCertificate.value || ValidateThat.allFieldsAreTrue(
                    name.validateCustom(value => value, 'this_field_is_required'),
                    email.validateCustom(ValidateThat.emailIsValid, 'invalid_email_format'),
                    receiver_name.validateCustom(value => value, 'this_field_is_required'),
                    receiver_email.validateCustom(ValidateThat.emailIsValid, 'invalid_email_format'),
                    donation_type.validateCustom(value => value, 'this_field_is_required'),
                    show_amount.validateCustom(value => value, 'this_field_is_required'),
                    cert_lang.validateCustom(value => value, 'this_field_is_required'),
                    cert_id.validateCustom(value => value, 'this_field_is_required'),
                )
            } else {
                valid = !sendCertificate.value || ValidateThat.allFieldsAreTrue(
                    name.validateCustom(value => value, 'this_field_is_required'),
                    email.validateCustom(ValidateThat.emailIsValid, 'invalid_email_format'),
                    donation_type.validateCustom(value => value, 'this_field_is_required'),
                    show_amount.validateCustom(value => value, 'this_field_is_required'),
                    cert_lang.validateCustom(value => value, 'this_field_is_required'),
                    cert_id.validateCustom(value => value, 'this_field_is_required'),
                )
            }
            // const valid = !sendCertificate.value || ValidateThat.allFieldsAreTrue(
            //     name.validate(),
            //     email.validate(ValidateThat.emailIsValid, 'invalid_email_format'),
            //     //receiver_name.validate(),
            //     //receiver_email.validate(ValidateThat.emailIsValid, 'invalid_email_format'),
            //     donation_type.validate(),
            //     show_amount.validate(),
            //     cert_lang.validate(),
            //     cert_id.validate(),
            // )

            if (valid) {
                currentStep.set(SupportStep.PaymentMethod)
            }
        } else if ([SupportStep.Loading, SupportStep.LoadingCustom, SupportStep.LoadingCompetition, SupportStep.Result, SupportStep.TotalDonationCheck, SupportStep.AgeVerificationCheck].includes(currentStep.value)) {
            onClose()
        } else if (currentStep.value === SupportStep.PaymentMethod) {
            if(paymentMethod.value === 'kktcell_invoice'){
                if(!!amount && !!cause.projects.donation.min_web_amount && !!cause.projects.donation.max_web_amount){
                    //check min and max value and show error if not in range
                    if(amount < cause.projects.donation.min_web_amount){
                        //onClose()
                        const min_error = translate('donation_amount_min_warning') + " " + cause.projects.donation.min_web_amount + " TL"
                        //showSnackbarCustom('error', min_error)
                        dialog.setErrorMessageCustom(min_error)
                        setTimeout(() => {
                            onClose()
                        }, 4000);
                        return
                    }
                    else if(amount > cause.projects.donation.max_web_amount){
                        //onClose()
                        const max_error = translate('donation_amount_max_warning') + " " + cause.projects.donation.max_web_amount + " TL"
                        //showSnackbarCustom('error', max_error)
                        dialog.setErrorMessageCustom(max_error)
                        setTimeout(() => {
                            onClose()
                        }, 4000);
                        return
                    }
                }
                
                if(auth){
                    //redirect to account settings if user's phone number is not verified
                    if(!cur_user.is_phone_verified){
                        
                        //onClose()
                        dialog.setErrorMessage('phone_unverified_warning')
                        // setTimeout(() => {
                        //     window.location.href = '/user-settings';
                        // }, 2000);
                        //open verify phone dialog here and do the verification on same page
                        setTimeout(() => {
                            onClose()
                            verifyPhoneOpenState.open(cur_user)
                        }, 2000);
                        
                    }else{
                        currentStep.set(SupportStep.AuthPhoneCheck)
                    }
                }else{
                    currentStep.set(SupportStep.SmsCheck)
                }
                
            }else{
                currentStep.set(SupportStep.Loading)
            }
        } else if (currentStep.value === SupportStep.SmsCheck) {
            let valid_invoice = false;
            valid_invoice = ValidateThat.allFieldsAreTrue(
                sms_phone.validateCustom(value => value, 'this_field_is_required'),
                sms_phone.validate(ValidateThat.phoneNumberIsValid, 'invalid_phone_format_desc')
            )
            
            //send sms here and on success redirect to next step, on error set error message and close
            if(valid_invoice){
                sms_code.setValue('')
                currentStep.set(SupportStep.SmsCheckRequest)
            }
            
        }   else if (currentStep.value === SupportStep.SmsVerify) {
            let valid_confirm = false;
            valid_confirm = ValidateThat.allFieldsAreTrue(sms_code.validateCustom(value => value, 'this_field_is_required'))
            if(valid_confirm){
                currentStep.set(SupportStep.SmsVerifyRequest)
            }
        }else if (currentStep.value === SupportStep.Consent) {
            if (project === 'event') {
                currentStep.set(SupportStep.PaymentMethod)
            } else if(project === 'competition') {
                if(!cur_user.dob){
                    //show error message and redirect to user settings
                    dialog.setErrorMessage('competition_enter_age_warning')
                    setTimeout(() => {
                        window.location.href = '/user-settings?cause='+cause.id;
                    }, 3000);
                }else{
                    currentStep.set(SupportStep.AgeVerificationCheck)
                }
                
            } else {
                currentStep.set(SupportStep.Loading)
            }
        } 
        else if(currentStep.value === SupportStep.SmsVerifyAuth) {
            let valid_confirm = false;
            valid_confirm = ValidateThat.allFieldsAreTrue(sms_code.validateCustom(value => value, 'this_field_is_required'))
            if(valid_confirm){
                currentStep.set(SupportStep.SmsVerifyRequestAuth)
            }
        } 
        else if (currentStep.value === SupportStep.CompetitionInfo) {
            const valid = ValidateThat.allFieldsAreTrue(
                image.validateCustom(value => value, 'this_field_is_required'),
                photo_text.validateCustom(value => value, 'this_field_is_required'),
            )

            if (valid) {
                currentStep.set(SupportStep.LoadingCompetition)
            }
        }
        else {
            console.error('SupportDialog.onContinue: Unexpected state transition')
        }
    }

    //verify phone sub module

    const verifyPhoneDialogOpenState = useDialogOpenState(undefined)
    const verifyPhoneOpenState = useDialogOpenState<IUser | undefined>(undefined)
    

    function sendVerifySms(cur_user?: IUser) {
        makeRequest({
            state: dialog,
            if: !!cur_user,
            url: api.user.account_phone_verify,
            data: {
                id: cur_user?.id,
                phone: cur_user?.phone_number
            },
            onStart: () => {
                openState.setOpen(true)
            },
            onSuccess: () => {
                openState.setOpen(false)
                //open sms phone dialog here
                verifyPhoneDialogOpenState.setOpen(true)
            },
            onFail: () => {
                dialog.setErrorMessage('verify_phone_error_msg')
            },
            // onFinish: () => {
            //     openState.setOpen(false)
            // },
        }).then()
    }

    return (
        <Box>
            <VerifyPhoneDialogAlt 
                openState={verifyPhoneDialogOpenState} 
                SupportDialogOpenState={openState} 
                SupportDialog={dialog} 
                currentStep={currentStep} 
                cur_user={cur_user} 
                contract_id={contract_id} 
            />
            <ConfirmationDialog
                openState={verifyPhoneOpenState}
                text={'phone_verify_confirmation'}
                onOk={sendVerifySms}
            />
        
            <StatefulDialog
                state={dialog}
                openState={openState}
                showCloseButton={true}
                fullScreen={mobile}
                titleKey={project}
                maxWidth={'lg'}
                onExit={onClose}
                content={
                    {
                        [SupportStep.Result]: <ResultStep {...{ resultParam, projectParam }} />,
                        [SupportStep.ContactInfo]: <ContactInfoStep {...{ sendCertificate, name, email, receiver_name, receiver_email, donation_type, show_amount, cert_lang, cert_id, causeName, causeNameAlt, causeOwner, causeOwnerAlt, amount, filtered_certificates}} />,
                        [SupportStep.Consent]: <ConsentStep />,
                        [SupportStep.PaymentMethod]: <PaymentMethodStep {...{ cause, paymentMethod, project }} />,
                        [SupportStep.SmsCheck]: <SmsCheckStep {...{ amount, dialog, causeId, sms_phone }} />,
                        [SupportStep.SmsCheckRequest]: <SmsCheckPost paymentMethod={paymentMethod.value} {...{ project, amount, dialog, causeId, sms_phone, ref_no, currentStep }} />,
                        [SupportStep.SmsCheckRequestAuth]: <SmsCheckPostAuth paymentMethod={paymentMethod.value} {...{ project, amount, dialog, causeId, sms_phone, ref_no, currentStep }} />,
                        [SupportStep.SmsVerify]: <SmsVerifyStep {...{ amount, dialog, causeId, sms_code }} />,
                        [SupportStep.SmsVerifyRequest]: <SmsVerifyPost 
                            paymentMethod={paymentMethod.value}
                            sendCertificate={sendCertificate.value}
                            {...{
                                amount,
                                dialog,
                                project,
                                causeId,
                                userInfo,
                                sms_code,
                                sms_phone,
                                ref_no,
                                update,
                                loadingNewPage
                            }} 
                        />,
                        [SupportStep.SmsVerifyAuth]: <SmsVerifyStepAuth {...{ amount, dialog, causeId, sms_code }} />,
                        [SupportStep.SmsVerifyRequestAuth]: <SmsVerifyPostAuth 
                            paymentMethod={paymentMethod.value}
                            sendCertificate={sendCertificate.value}
                            {...{
                                amount,
                                dialog,
                                project,
                                causeId,
                                userInfo,
                                sms_code,
                                sms_phone,
                                contract_id,
                                ref_no,
                                update,
                                loadingNewPage
                            }} 
                        />,
                        [SupportStep.Loading]: <LoadingStep
                            paymentMethod={paymentMethod.value}
                            sendCertificate={sendCertificate.value}
                            {...{
                                amount,
                                dialog,
                                project,
                                causeId,
                                userInfo,
                                update,
                                loadingNewPage
                            }}
                        />,
                        [SupportStep.AuthPhoneCheck]: <AuthPhoneCheckStep {...{ dialog, openState, verifyPhoneDialogOpenState, sms_phone, contract_id, currentStep, cur_user }} />,
                        [SupportStep.TotalDonationCheck]: <TotalDonationCheckStep {...{ dialog, openState, cause, currentStep, cur_user }} />,
                        [SupportStep.AgeVerificationCheck]: <AgeVerificationStep {...{ dialog, openState, cause, dob, currentStep, cur_user }} />,
                        [SupportStep.CompetitionInfo]: <CompetitionInfoStep {...{ photo_text, image }} />,
                        [SupportStep.LoadingCompetition]: <LoadingStepCompetition 
                            {...{
                                dialog,
                                project,
                                causeId,
                                photo_text,
                                image,
                                currentStep,
                                update,
                                loadingNewPage
                            }}
                        />,
                        [SupportStep.LoadingCustom]: <LoadingStepCustom
                            paymentMethod={paymentMethod.value}
                            sendCertificate={sendCertificate.value}
                            {...{
                                amount,
                                dialog,
                                project,
                                causeId,
                                userInfo,
                                sms_phone,
                                contract_id,
                                update,
                                loadingNewPage
                            }}
                        />
                    }[currentStep.value]
                }
                buttons={
                    <Box display={'flex'} justifyContent={'flex-start'} flex={1}>
                        <Only when={currentStep.value !== SupportStep.Result}>
                            <Button
                                variant={'outlined'}
                                disabled={
                                    dialog.loading ||
                                    loadingNewPage.value ||
                                    currentStep.value === SupportStep.Result
                                }
                                color="primary"
                                onClick={onBack}
                                children={translate('back')}
                            />
                        </Only>
                        <Box marginX={1} />
                        <Button
                            variant={'contained'}
                            disabled={dialog.loading || loadingNewPage.value}
                            color="primary"
                            onClick={onContinue}
                            children={translate('continue')}
                        />
                    </Box>
                }
            />
        </Box>
    )
}

