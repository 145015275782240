/* eslint-disable react/jsx-key */
import {Box} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {
    AssignmentTurnedIn,
    BarChart,
    Dashboard as DashboardIcon,
    EmojiPeople,
    FeaturedVideo,
    Home,
    LocalHospital,
    Payment,
    People,
    Translate,
    Receipt,
    ViewList,
    ViewCarousel
} from '@material-ui/icons'
import {AdminCard} from 'components/cards/AdminCard'
import {ResponsiveGrid} from 'components/foundation/ResponsiveGrid'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import React, {ReactElement} from 'react'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {useScrollToTopEffect} from 'utils/hooks'
import {route} from 'utils/route'
import {TranslationKey} from 'utils/TranslationKey'

const useStyles = makeStyles(({breakpoints}) =>
    createStyles({
        administration: {
            width: '100%',
            maxWidth: '100%',
            [breakpoints.up('mobile')]: {
                maxWidth: breakpoints.values.mobile
            },
            [breakpoints.up('tablet')]: {
                maxWidth: breakpoints.values.tablet
            },
            [breakpoints.up('laptop')]: {
                maxWidth: breakpoints.values.laptop
            },
        },
    }),
)

export function AdminPanel() {
    const {translate} = useTranslation()
    const {administration} = useStyles()
    const {user} = useUser()
    const page = useStatefulPageState()

    useScrollToTopEffect()

    const cards: [string, ReactElement, TranslationKey][] = [
        [route.to.admin.dashboard, <DashboardIcon/>, 'dashboard'],
        [route.to.admin.donation_report, <ViewList/>, 'donation_report'],
        [route.to.admin.projects_current_report, <ViewList/>, 'projects_current_report'],
        [route.to.admin.review_causes, <EmojiPeople/>, 'review_causes'],
        [route.to.admin.review_charities, <LocalHospital/>, 'review_charities'],
        [route.to.admin.review_users, <People/>, 'review_users'],
        [route.to.admin.featured_causes, <FeaturedVideo/>, 'review_featured_causes'],
        [route.to.admin.certificate_management, <Receipt/>, 'manage_certificates'],
        [route.to.admin.banner_management, <ViewCarousel/>, 'banner_management'],
        [route.to.admin.website_management, <Home/>, 'manage_website'],
        [route.to.admin.stats_management, <BarChart/>, 'manage_home_stats'],
        [route.to.admin.status_management, <AssignmentTurnedIn/>, 'manage_statuses'],
        [route.to.admin.translation, <Translate/>, 'translation'],
    ]

    if (user?.is_super_admin) {
        cards.push([route.to.admin.payment_logs, <Payment/>, 'payment_logs'])
    }

    return (
        <>
            <AppHelmet title={translate('administration')}/>
            <StatefulPage state={page}>
                <Box className={administration} marginX={'auto'}>
                    <Title titleKey={'administration'}/>
                    <ResponsiveGrid
                        maxWidth={'100%'}
                        width={'100%'}
                        padding={4}
                        breakpointColumns={{
                            mobile: 2,
                            tablet: 3,
                        }}
                        children={cards.map(([to, icon, title]) =>
                            <AdminCard
                                to={to}
                                key={to}
                                icon={icon}
                                title={translate(title)}
                            />
                        )}
                    />
                </Box>
            </StatefulPage>
        </>
    )
}
