import {Box, Typography} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {ResponsiveGrid} from 'components/foundation/ResponsiveGrid'
import {Only} from 'components/util/Only'
import {IStat} from 'types/types'
import React, {useState} from 'react'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequestEffect} from 'utils/request'
import {responsivePadding} from 'utils/styles'

const useStyles = makeStyles(({palette, spacing, breakpoints}) =>
    createStyles({
        statsClass: {
            ...responsivePadding({spacing, breakpoints}),
            backgroundColor: palette.secondary.main,
            color: 'white',
            textAlign: 'center',
            whiteSpace: 'nowrap',
        },
        grid: {
            paddingTop: spacing(4),
            paddingBottom: spacing(4),
            [breakpoints.up('mobile')]: {
                paddingTop: spacing(8),
                paddingBottom: spacing(8),
            },
            [breakpoints.up('tablet')]: {
                paddingTop: spacing(12),
                paddingBottom: spacing(12),
            },
            [breakpoints.up('laptop')]: {
                paddingTop: spacing(16),
                paddingBottom: spacing(16),
            },
        },
        title: {
            letterSpacing: '0.1em',
            fontWeight: 'bold',
        },

        description: {
            textTransform: 'uppercase',
        },
    })
)

function Stat(props: { className?: string, stat: IStat }) {
    const {className, stat} = props
    const {title, description} = useStyles()
    const {translate} = useTranslation()

    return (
        <Box className={className}>
            <Typography className={title} variant={'h3'}>{stat.value}</Typography>
            <Typography className={description}>{translate(stat.desc_key)}</Typography>
        </Box>
    )
}

export const Stats = () => {
    const {statsClass, grid} = useStyles()

    const [stats, setStats] = useState<IStat[]>([])
    useRequestEffect<IStat[]>({
        url: api.home.stats.read_public,
        onSuccess: setStats
    }, [])

    return (
        <Only when={stats.length > 0}>
            <Box className={statsClass}>
                <ResponsiveGrid
                    className={grid}
                    breakpointColumns={{
                        'desktop': Math.min(4, stats.length),
                        'tablet': Math.min(2, stats.length),
                        'mobile': Math.min(1, stats.length)
                    }}
                    children={
                        stats.map((stat, index) =>
                            <Stat
                                key={index}
                                stat={stat}
                            />
                        )
                    }
                />
            </Box>
        </Only>
    )
}
