import {InputAdornment, TextField} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {TextFieldProps} from '@material-ui/core/TextField/TextField'
import React, {ReactElement} from 'react'
import {useTranslation} from 'state/translation'
import {TranslationKey} from 'utils/TranslationKey'
import {EditableField, StringField} from '../foundation/EditableField'
import {Only} from '../util/Only'

const useStyles = makeStyles(({palette}) =>
    createStyles({
        root: {
            '& > .Mui-focused': {
                '& .MuiInputAdornment-root': {
                    color: palette.primary.main,
                },
            },
            '& > .Mui-disabled': {
                '& .MuiInputAdornment-root': {
                    color: 'unset',
                },
            },
            '& .MuiInputAdornment-root': {
                color: palette.secondary.main,
                transition: 'color 0.25s ease',
                cursor: 'auto',
            },
        },
    }),
)

export function FormField(props: {
    label: TranslationKey
    icon?: ReactElement
    name?: string
    type?: string
    field: StringField
    onEnter?: () => void
} & TextFieldProps) {
    const {translate} = useTranslation()
    const {root} = useStyles()
    const {label, icon, name, type, field, onEnter, style, ...textFieldProps} = props

    return (
        <EditableField field={field}>
            <TextField
                {...textFieldProps}
                fullWidth
                disabled={field.disabled}
                margin="dense"
                variant="outlined"
                className={root}
                label={translate(label)}
                helperText={field.hint ?? `${field.value.length}/${field.maxLength}`}
                error={Boolean(field.hint)}
                type={type ?? 'text'}
                value={field.value}
                inputProps={{maxLength: field.maxLength}}
                onChange={e => {
                    field.setValue(e.target.value)
                    field.clearHint()
                }}
                FormHelperTextProps={{
                    style: {textAlign: 'end', marginBottom: 8},
                }}
                InputProps={{
                    startAdornment: (
                        <Only when={!!icon}>
                            <InputAdornment position="start" children={icon}/>
                        </Only>
                    ),
                    name: name,
                    ...textFieldProps.InputProps
                }}
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                        onEnter?.()
                    }
                }}
                style={{
                    margin: 0,
                    ...style
                }}
            />
        </EditableField>
    )
}
