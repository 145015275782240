import {ICauseState, ICauseType} from 'types/cause'

export const route = {
    to: {
        about_us: '/about-us',
        activate_email: (code?: unknown) => `/activate-email/${code}`,
        admin: {
            banner_management: '/admin/banner-management',
            dashboard: '/admin/dashboard',
            featured_causes: '/admin/featured-causes',
            page: '/admin',
            payment_logs: '/admin/payment-logs',
            donation_report: '/admin/donation-report',
            projects_current_report: '/admin/projects-current-report',
            review_charities: '/admin/review-charities',
            review_causes: '/admin/review-causes',
            review_users: '/admin/review-users',
            status_management: '/admin/status-management',
            stats_management: '/admin/stats-management',
            translation: '/admin/translation',
            website_management: '/admin/website-management',
            certificate_management: '/admin/certificate-management',
            add_certificate: '/admin/add-certificate',
            //four_five_cert: '/admin/add-4-5g',
            certificate: (id?: unknown) => ({
                edit: `/admin/certificate/${id}/edit`,
            }),
        },
        causes: (params?: {
            type?: ICauseType
            category?: string
            state?: ICauseState
            hide_filters?: boolean
        }) => {
            const {type, category, state, hide_filters} = params ?? {
                type: undefined, category: undefined, state: undefined, hide_filters: undefined
            }
            const searchParams = new URLSearchParams()
            if (type) {
                searchParams.append('type', type)
            }

            if (category) {
                searchParams.append('category', category)
            }

            if (state) {
                searchParams.append('state', state)
            }

            if (hide_filters) {
                searchParams.append('hide_filters', hide_filters ? 'true' : 'false')
            }

            const questionMark = (Array.from(searchParams).length > 0) ? '?' : ''
            return `/causes${questionMark}${searchParams.toString()}`
        },
        cause: (id?: unknown) => ({
            page: `/cause/${id}`,
            edit: `/cause/${id}/edit`,
        }),
        certificate: (id?: unknown) => ({
            page: `/certificate/${id}`,
        }),
        charities: '/charities',
        charity: (id?: unknown) => ({
            page: `/charity/${id}`,
            edit: `/charity/${id}/edit`,
            contributions: `/charity/${id}/contributions`,
        }),
        completed_causes: '/completed-causes',
        expired_causes: '/expired-causes',
        faq: '/faq',
        news: '/news',
        four_five_cert: '/add-4-5g',
        home: '/home',
        how_it_works: '/how-it-works',
        index: '/index.html',
        my_charities: '/my-charities',
        my_causes: '/my-causes',
        my_donation_report: '/my-charities-donation-report',
        my_projects_current_report: '/my-projects-current-report',
        my_transactions: '/my-transactions',
        paynet_charity_registration: '/paynet-charity-registration',
        privacy_and_security: '/privacy-and-security',
        reset_password: (code?: unknown) => `/reset-password/${code}`,
        root: '/',
        search: (query?: string) => '/search' + (query ? `?query=${query}` : ''),
        sign_up: {
            page: '/sign-up',
            success: '/sign-up/success'
        },
        user: (id?: unknown) => ({
            page: `/user/${id}`,
            charities: `/user/${id}/charities`,
            causes: `/user/${id}/causes`,
        }),
        user_settings: '/user-settings',
        volunteering: '/volunteering',
    }
}
