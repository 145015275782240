import {StringField} from '../../../components/foundation/EditableField'
import {TranslationKey} from '../../../utils/TranslationKey'
import {useTranslation} from '../../../state/translation'
import {useHookstate} from '@hookstate/core'
import React, {ChangeEvent} from 'react'
import {Box} from '@material-ui/core'
import {RichText} from '../../../components/misc/RichText'
import {Only} from '../../../components/util/Only'
import Button from '@material-ui/core/Button'
import {Publish} from '@material-ui/icons'

export function CauseDocumentPicker(props: {
    className?: string
    documentBase64: StringField
    label: TranslationKey
}) {
    const {className, documentBase64, label} = props
    const {translate} = useTranslation()

    const documentFile = useHookstate<File | undefined>(undefined)

    function onContractSelected(e: ChangeEvent<HTMLInputElement>) {
        const file = e.target?.files?.[0]

        if (!file) {
            return
        }

        documentFile.set(file)

        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            let base64 = reader.result as string
            base64 = base64.replace(/^data:application\/pdf;base64,/, '')
            documentBase64.setValue(base64)
            documentBase64.setDisabled(false)
        }
    }


    return (
        <Box {...{className}}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <RichText text={translate(label)}/>
                <Only
                    when={{file: documentFile.value}}
                    children={({file}) =>
                        <RichText
                            size={'small'}
                            text={`${translate('selected')}: ${file.name}`}
                        />
                    }
                />
                <Button
                    variant="contained"
                    component="label"
                    color={'secondary'}
                    startIcon={<Publish/>}
                >
                    {translate('upload')}
                    <input
                        hidden
                        type={'file'}
                        accept={'application/pdf'}
                        onChange={onContractSelected}
                    />
                </Button>
            </Box>
        </Box>
    )
}