import {useTranslation} from '../../../state/translation'
import React, {useState, useEffect} from 'react'
import {StringField, ImageBase64Field} from '../../../components/foundation/EditableField'
import {Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@material-ui/core'
import { Subject } from '@material-ui/icons'
import {Only} from '../../../components/util/Only'
import {FormField} from '../../../components/forms/FormField'
import {SelectField} from 'components/forms/SelectField'
import {AccountCircleOutlined, EmailOutlined} from '@material-ui/icons'
import {StateMethods} from '@hookstate/core'
import {useLang} from 'state/lang'
import {ImageFieldCustom} from 'components/forms/ImageFieldCustom'

export function CompetitionInfoStep(props: {
    photo_text: StringField
    image: ImageBase64Field
}) {
    const {translate} = useTranslation()

    return (
        <Box className={'competition-module-wrap'}>
            <FormControl component="fieldset" className={'fc-w-100'}>
                <p className={'comp-photo-rules'}>{translate('competition_photo_rules')}</p>
                <ImageFieldCustom
                    field={props.image}
                    disableDelete={true}
                    disableCropper={true}
                />
                <Box margin={1}/>
                <FormField
                    multiline={true}
                    label={'competition_photo_desc'}
                    field={props.photo_text}
                    icon={<Subject/>}
                />
            </FormControl>
        </Box>
    )
}   