import {AppBar, Box} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {GbbContainer} from 'components/foundation/GbbContainer'
import React from 'react'
import {useBodyStyle} from 'utils/hooks'
import {responsivePadding} from 'utils/styles'
import {LanguagePicker} from './LanguagePicker'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appbar: {
            zIndex: theme.zIndex.appBar + 1,
        },
        root: {
            ...responsivePadding(theme),
            background: theme.palette.primary.main,
            minHeight: theme.spacing(4.5)
        }
    })
)

export function SocialHeader() {
    const {appbar, root} = useStyles()

    return (
        <AppBar
            className={appbar}
            {...useBodyStyle()}
            elevation={2}
            position="static"
        >
            <GbbContainer>
                <Box className={root} display={'flex'} paddingY={0.5}>
                    <Box display={'flex'} flex={1} justifyContent={'flex-end'}>
                        <Box flex={1} marginRight={2}/>
                        <LanguagePicker/>
                    </Box>
                </Box>
            </GbbContainer>
        </AppBar>
    )
}
