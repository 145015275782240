import {useHookstate} from '@hookstate/core'
import {Box, FormControlLabel, FormGroup, Switch} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {DialogOpenState, StatefulDialog, useStatefulDialogState} from 'components/foundation/StatefulDialog'
import React, {ChangeEvent, useEffect} from 'react'
import {LanguageKey} from 'state/lang'
import {useLangs} from 'state/langs'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequest} from 'utils/request'
import {cloneObject} from 'utils/general'

export function LanguageToggleDialog(props: {
    openState: DialogOpenState
}) {
    const {openState} = props
    const dialog = useStatefulDialogState()
    const {translate} = useTranslation()
    const {makeRequest} = useRequest()

    const {langs, setLangs} = useLangs()
    const langsState = useHookstate(cloneObject(langs))

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        langsState.ornull?.[event.target.name as LanguageKey].is_enabled.set(event.target.checked)
    }
    useEffect(() => {
        if (openState.isOpen) {
            langsState.set(cloneObject(langs))
        }
    }, [openState.isOpen])

    const onUpdate = () => makeRequest({
        if: () => {
            if (langsState.keys?.some(code => langsState.ornull?.[code].is_enabled.value)) {
                return true
            } else {
                dialog.setErrorMessage('at_least_one_lang')
                return false
            }
        },
        url: api.language.update_enabled,
        state: dialog,
        data: langsState.keys?.reduce((obj, code) => ({...obj, [code]: langsState.value?.[code].is_enabled}), {}),
        onStart: dialog.clearMessages,
        onSuccess: () => {
            dialog.setSuccessMessage('enabled_lang_change_success')
            if (langsState.value) {
                setLangs(cloneObject(langsState.value))
            } else {
                console.error('LanguageToggleDialog.onUpdate.onSuccess: Undefined \'langsState.value\'')
            }
        }
    })

    return <StatefulDialog
        state={dialog}
        openState={openState}
        titleKey={'change_enabled_languages'}
        content={
            <FormGroup>{
                Object.entries(langsState.value ?? {}).map(([code, lang]) =>
                    <FormControlLabel
                        key={code}
                        label={lang.name}
                        control={
                            <Switch
                                checked={lang.is_enabled}
                                onChange={handleChange}
                                name={code}
                            />
                        }
                    />
                )
            }</FormGroup>
        }
        buttons={
            <Box display={'flex'}>
                <Button onClick={openState.close} variant={'outlined'} color="primary">
                    {translate('cancel')}
                </Button>
                <Box marginX={1}/>
                <Button onClick={onUpdate} variant={'contained'} color="primary">
                    {translate('update')}
                </Button>
            </Box>
        }
    />
}
