import {Box, Button, Checkbox, FormControlLabel} from '@material-ui/core'
import {ConfirmationNumber, Event, GetApp} from '@material-ui/icons'
import {IntField} from 'components/forms/IntField'
import {useNumberField} from 'components/foundation/EditableField'
import {CustomDivider} from 'components/misc/CustomDivider'
import {PercentageProgress} from 'components/misc/PercentageProgress'
import {RichText} from 'components/misc/RichText'
import {Only} from 'components/util/Only'
import {ICause, ICauseType} from 'types/cause'
import React from 'react'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {formatDateTimeString} from 'utils/date'
import {TranslationKey} from 'utils/TranslationKey'
import {getFormattedNumber} from 'utils/general'
import {useAppSnackbar} from 'utils/hooks'
import {useLang} from 'state/lang'
import {Link} from 'react-router-dom'
import {route} from 'utils/route'
import { useHookstate } from '@hookstate/core'
import {LoginDialog} from 'components/dialogs/LoginDialog'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'

export function ProjectAction(props: {
    when: boolean,
    cause: ICause,
    disabled?: boolean,
    title: TranslationKey,
    currentValue: number,
    totalValue: number,
    currentText: TranslationKey,
    totalText: TranslationKey,
    buttonText: TranslationKey,
    statusText?: TranslationKey,
    sign: string,
    donation_check: boolean,
    age_check: boolean,
    withAmountInput: boolean,
    datetime?: string | null,
    ticketPrice?: number | null,
    formatNumber?: boolean
    project: ICauseType,
    onAction?: (amount: number | null) => void
}) {
    const {datetime, ticketPrice} = props
    const {translate} = useTranslation()
    const amount = useNumberField(0)
    const {showSnackbar} = useAppSnackbar()
    const {user} = useUser()
    const {lang} = useLang()
    const loginDialog = useDialogOpenState(undefined)
    const currentValueNormalized = props.project === 'event' && props?.currentValue >= props.totalValue
        ? translate('all') : props.currentValue


    const isZeroValue = props.withAmountInput && !amount.value
    const hasAvailableTickets = props.currentValue + (amount?.value ?? 0) <= props.totalValue

    const statusText = (() => {
        if (user) {
            if (props.cause.state === 'active') {
                return props.statusText
            } else {
                return 'cause_not_active'
            }
        } else {
            if (props.project === 'donation') {
                // Allow donations for anonymous users - only if active
                if (props.cause.state === 'active') {
                    return props.statusText
                } else {
                    return 'cause_not_active'
                }
            } else {
                return 'you_need_to_be_logged_in'
            }
        }
    })()

    const comp_checked = useHookstate<boolean>(false);

    function set_checked(){
        if(comp_checked.value){
            comp_checked.set(false)
        }else{
            comp_checked.set(true)
        }
    }

    function checkAmount() {
        if(props.project === 'donation' || props.project === 'event'){
            if(!amount.value){
                showSnackbar('error', 'amount_zero_error')
            }else{
                return props.onAction?.(amount.value);
            }
        }else if(props.project === 'competition'){
            if(props.cause.projects.competition.file_path){
                if(!comp_checked.value){
                    showSnackbar('error', 'competition_notice_checkbox')
                }else{
                    return props.onAction?.(amount.value);
                }
            }else{
                return props.onAction?.(amount.value);
            }
        }
        
        else{
            return props.onAction?.(amount.value);
        }
    }

    const text_arr = translate('frontend_sms_first_text').split("XXXX")

    return (
        <Only when={props.when}>
            <LoginDialog openState={loginDialog}/>
            <Box border={'1px solid lightgray'} borderRadius={4} padding={1} marginY={2}>
                <RichText bold size={'large'} text={translate(props.title)} width="100%" textAlign="center"/>
                <CustomDivider color={'lightgray'} marginY={1}/>
                <Only when={{datetime}}>{({datetime}) =>
                    <Box display={'flex'} marginY={1}>
                        <Event color={'secondary'}/>
                        <RichText text={translate('event_date')} marginLeft={8}/>
                        <Box flex={1}/>
                        <RichText text={formatDateTimeString(datetime)} bold/>
                    </Box>
                }</Only>
                <Only when={{ticketPrice}}>{({ticketPrice}) =>
                    <Box display={'flex'} marginY={1}>
                        <ConfirmationNumber color={'secondary'}/>
                        <RichText text={translate('ticket_price')} marginLeft={8}/>
                        <Box flex={1}/>
                        <RichText text={`${ticketPrice} TL`} bold/>
                    </Box>
                }</Only>
                <Only when={props.project != 'competition'}>
                    <PercentageProgress current={props.currentValue} total={props.totalValue}/>
                    <Box display={'flex'} justifyContent={'space-between'} marginTop={1}>
                        <RichText text={translate(props.currentText)}/>
                        <RichText text={translate(props.totalText)}/>
                    </Box>
                
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <RichText
                            bold
                            text={`${props.formatNumber ? getFormattedNumber(currentValueNormalized) : currentValueNormalized} ${props.sign}`}
                        />
                        <RichText
                            bold
                            text={`${props.formatNumber ? getFormattedNumber(props.totalValue) : props.totalValue} ${props.sign}`}/>
                    </Box>
                </Only>

                <Only when={(!!user && props.project === 'competition' && ((!!props.statusText && props.statusText != 'already_participated_cause') || !props.statusText) ) || (!user && props.project === 'competition')}>
                    {/*Non Logged in*/}
                    <Only when={!user}>
                        <Box className={'comp-notice-wrap'}>
                            <h2>{translate('competition_notice_title')}</h2>
                            <ul>
                                <li><Link to={route.to.sign_up.page}>{translate('competition_notice_one')}</Link><span>❌</span></li>
                                <li><Button className={'comp-login-btn'} onClick={() => loginDialog.setOpen(true)}>{translate('competition_notice_two')}</Button><span>❌</span></li>
                                <li><a href="https://www.gonuldenbagliyiz.biz/cause/145">{translate('competition_notice_three')}</a><span>❌</span></li>
                                <li>{translate('competition_notice_four')}<span>❌</span></li>
                            </ul>
                        </Box>
                    </Only>
                    
                    {/*Logged in*/}
                    <Only when={!!user && !props.disabled}>
                        <Box className={'comp-notice-wrap'}>
                            <h2>{translate('competition_notice_title')}</h2>
                            <ul>
                                <li>{translate('competition_notice_one')} <span>✅</span></li>
                                <li>{translate('competition_notice_two')}<span>✅</span></li>
                                
                                <Only when={props.donation_check}>
                                    <li>{translate('competition_notice_three')}<span>✅</span>
                                    </li>
                                </Only>
                                <Only when={!props.donation_check}>
                                    <li><a href="https://www.gonuldenbagliyiz.biz/cause/145">{translate('competition_notice_three')}</a><span>❌</span></li>
                                </Only>
                                <Only when={!!props.cause.projects.competition.file_path}>
                                    <li>{translate('competition_notice_four')}<span></span></li>
                                </Only>
                            </ul>
                            <Only when={!!props.cause.projects.competition.file_path}>
                                <div className={'comp-checkbox-wrap'}>
                                    <Checkbox
                                        checked={comp_checked.value}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            set_checked()
                                        }}/>
                                    <span className={'comp-check-desc'}>{translate('competition_specifications_check')}</span>
                                </div>
                            </Only>
                        </Box>
                    </Only>
                </Only>
                <Box marginTop={2} display={'flex'} justifyContent={'flex-end'}>
                    {
                        statusText
                            ? <RichText
                                text={translate(statusText)}
                                color={'gray'}
                                marginRight={'auto'}
                                fontSize={14}
                            /> : <Only when={props.withAmountInput}>
                                <IntField
                                    label={'amount'} field={amount} totalSign={props.sign}
                                    style={{margin: 'auto'}}/>
                                <Box margin={1}/>
                            </Only>

                    }
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        size={'large'}
                        style={{marginTop: 'auto', marginLeft: 8, whiteSpace: 'nowrap'}}
                        // disabled={
                        //     props.disabled ||
                        //     !!statusText ||
                        //     isZeroValue ||
                        //     (props.project === 'event' && !hasAvailableTickets)
                        // }
                        disabled={
                            props.disabled ||
                            !!statusText ||
                            (props.project === 'event' && !hasAvailableTickets) || (props.project === 'competition' && !props.donation_check)
                        }
                        children={translate(props.buttonText)}
                        onClick={checkAmount}
                        //onClick={() => props.onAction?.(amount.value)}
                    />
                </Box>
            </Box>
            <Only when={props.project === 'donation' && props.cause.projects.donation.enable_sms && props.cause.state === 'active' }>
                <Only when={lang === 'tr'}>
                    <Only when={!!props.cause.projects.donation.keywords_tr}>
                        <Box className={'sms-info-box'}>
                            <p className={'sms-info-first'}>{text_arr[0]}{<span className={'sms-info-number'}>{props.cause.projects.donation.short_number}</span>}{text_arr[1]}</p>
                            <p className={'sms-info-words'}>{translate('project_code') + " : " + props.cause.projects.donation.keywords_tr?.replace(/,/g,", ")}</p>
                            <p className={'sms-info-second'}>{<span className={'sms-info-example'}>{translate('example')} : </span>}{props.cause.projects.donation.keywords_tr?.split(",")[0]} {"50 "}{<span className={'sms-info-emoji'}>➡ ✉️ ➡</span>} {props.cause.projects.donation.short_number}</p>
                            <p className={'sms-info-third'}>{translate('frontend_sms_third_text')}</p>
                        </Box>
                    </Only>
                </Only>
                <Only when={lang === 'en'}>
                    <Only when={!!props.cause.projects.donation.keywords_en}>
                        <Box className={'sms-info-box'}>    
                            <p className={'sms-info-first'}>{text_arr[0]}{<span className={'sms-info-number'}>{props.cause.projects.donation.short_number}</span>}{text_arr[1]}</p>
                            <p className={'sms-info-words'}>{translate('project_code') + " : " + props.cause.projects.donation.keywords_en?.replace(/,/g,", ")}</p>
                            <p className={'sms-info-second'}>{<span className={'sms-info-example'}>{translate('example')} : </span>}{props.cause.projects.donation.keywords_en?.split(",")[0]} {"50 "}{<span className={'sms-info-emoji'}>➡ ✉️ ➡</span>} {props.cause.projects.donation.short_number}</p>
                            <p className={'sms-info-third'}>{translate('frontend_sms_third_text')}</p>
                        </Box>
                    </Only>
                </Only>
            </Only>
            
            <Only when={props.project === 'competition' && props.cause.state === 'active' && !!props.cause.projects.competition.file_path}>
                <Box className={'comp-download-wrap'}>
                    <p>{translate('download_competition_file_desc')}</p>
                    <Button
                        color={'secondary'}
                        variant={'outlined'}
                        startIcon={<GetApp/>}
                        component={Link}
                        target={'_blank'}
                        className={'comp-download-btn'}
                        to={{pathname: props.cause.projects.competition.file_path}}
                        children={translate('download')}
                    />
                </Box>
            </Only>
            
        </Only>
    )
}
