import {useTranslation} from '../../../state/translation'
import {Alert} from '@material-ui/lab'
import React from 'react'

export function ConsentStep() {
    const {translate} = useTranslation()

    return (
        <Alert
            severity={'info'}
            children={translate('cause_support_confirmation')}
        />
    )
}

