import {Box, IconButton, Tooltip} from '@material-ui/core'
import {Edit, Restore} from '@material-ui/icons'
import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'state/translation'
import {StringLength} from 'utils/enums'
import {getCroppedImg, IPixelCrop} from 'utils/image_util'
import {TranslationKey} from 'utils/TranslationKey'
import {ValidateThat} from 'utils/ValidateThat'
import {Only} from '../util/Only'
import {extractYoutubeId} from '../../utils/general'

export const FieldModeContext = createContext<'create' | 'edit' | 'custom'>('create')
export const useFieldMode = () => ({mode: useContext(FieldModeContext)})

export function useField<T>(initial: T) {
    const [value, setValue] = useState(initial)
    const [backupValue, setBackupValue] = useState(initial)
    const [hint, setHint] = useState<TranslationKey>()
    const [disabled, setDisabled] = useState(false)
    const {translate} = useTranslation()

    function validate(predicate: (value: T) => unknown, hintKey?: TranslationKey) {
        if (!disabled && !predicate(value)) {
            if (hintKey) {
                setHint(hintKey)
            }
            return false
        }
        setHint(undefined)
        return true
    }

    function box(key: string, predicate: (value: T) => boolean = () => true) {
        return !disabled && predicate(value) ? {[key]: value} : {}
    }


    function setInitialValue(value?: T | null) {
        if (value !== null && value !== undefined) {
            setValue(value)
            setBackupValue(value)
            setDisabled(true)
        }
    }

    function setInitialValueCustom(value?: T | null) {
        if (value !== null && value !== undefined) {
            setValue(value)
            setBackupValue(value)
            setDisabled(false)
        }
    }
    
    return {
        value,
        backupValue,
        hint: hint ? translate(hint) : undefined,
        disabled,

        setValue,
        setInitialValue,
        setInitialValueCustom,
        setHint,
        setDisabled,

        validate,
        box,
        save: () => setBackupValue(value),
        restore: () => setValue(backupValue),

        clearValue: () => setValue(initial),
        clearHint: () => setHint(undefined),
    }
}

export function useStringField(maxLength: StringLength = StringLength.IRRELEVANT, initial = '') {
    const field = useField<string>(initial)

    function validate(
        predicate: (value: string) => unknown = ValidateThat.stringIsNotEmpty,
        hintKey: TranslationKey = 'this_field_is_required'
    ) {
        field.setValue(field.value.trim())
        return field.validate(value => predicate(value.trim()), hintKey)
    }

    function validateCustom(
        predicate: (value: string) => unknown = ValidateThat.stringIsNotEmpty,
        hintKey: TranslationKey = 'this_field_is_required'
    ) {
        field.setValue(field.value.toString().trim())
        return field.validate(value => predicate(value.toString().trim()), hintKey)
    }

    return {
        ...field,
        maxLength,
        validate,
        validateCustom
    }
}

export type StringField = ReturnType<typeof useStringField>;

export function useImageBase64Field(initial?: string) {
    const imageField = useStringField(StringLength.IRRELEVANT, initial)
    const [pixelCrop, setPixelCrop] = useState<IPixelCrop>({
        width: 0,
        height: 0,
        x: 0,
        y: 0
    })

    const validate = (predicate: (value: string) => unknown = it => it.startsWith('data:image'),
        hintKey: TranslationKey = 'error') => {
        return imageField.validate(value => predicate(value), hintKey)
    }

    /**
     * Do not forget to await the result since it returns a Promise<br>
     * <code>...(await field.box('image_base64'))</code>
     * @param key
     * @param skipCropping
     */
    async function box(key: string, skipCropping = false) {
        const image = skipCropping ? imageField.value : await getCroppedImg(imageField.value, pixelCrop)
        return imageField.disabled || (imageField.backupValue === image) ? {} : {[key]: image}
    }

    return {
        ...imageField,
        box,
        validate,
        pixelCrop,
        setPixelCrop,
    }
}

export type ImageBase64Field = ReturnType<typeof useImageBase64Field>;


export function useNumberField(initial: number | null = null) {
    return useField(initial)
}

export type NumberField = ReturnType<typeof useNumberField>

export function useBooleanField(initial?: boolean) {
    const field = useField(initial)

    useEffect(() => {
        field.setDisabled(field.value === field.backupValue)
    }, [field.value])

    return field
}

export type BooleanField = ReturnType<typeof useBooleanField>


export function useNumberArrayField(initial: number[]) {
    const field = useField(initial)

    useEffect(() => {
        field.setDisabled(field.value === field.backupValue)
    }, [field.value])

    return field
}

export type NumberArrayField = ReturnType<typeof useNumberArrayField>


export function useYoutubeField(initial?: string) {
    const field = useStringField(StringLength.MD, initial)

    function box(key: string) {
        if (field.disabled) {
            return {}
        }

        return {[key]: extractYoutubeId(field.value)}
    }

    return {
        ...field,
        box
    }
}

// export type YoutubeField = ReturnType<typeof useYoutubeField>

export type Field = StringField | NumberField | BooleanField | ImageBase64Field


export function validateNonEmptyFields(
    translate: (key: TranslationKey) => string,
    fields: Field[]
): boolean {
    let fieldsAreValid = true
    fields.forEach(field => {
        if (!field.value) {
            field.setHint('this_field_is_required')
            fieldsAreValid = false
        }
    })
    return fieldsAreValid
}

export function clearFieldValues(fields?: Field[]) {
    fields?.forEach(field => field.clearValue())
}

export function clearFieldHints(fields?: Field[]) {
    fields?.forEach(field => field.clearHint())
}

export function allFieldsDisabled(fields: Field[]) {
    return fields.every(field => field.disabled)
}

export function EditableField(props: PropsWithChildren<{
    className?: string,
    marginTop?: number,
    field: StringField | NumberField | ImageBase64Field
}>) {
    const {className, marginTop, field, children} = props
    const [buttonType, setButtonType] = useState<'edit' | 'restore'>('edit')
    const {mode} = useFieldMode()
    const {translate} = useTranslation()

    if(mode==='custom'){
        field.setDisabled(false)
    }
    useEffect(() => {
        setButtonType(field.disabled ? 'edit' : 'restore')
    }, [field.disabled])

    function onEditClick() {
        if (buttonType === 'restore') {
            field.restore()
        }

        field.setDisabled(!field.disabled)
    }

    return (
        <Box display={'flex'} width={'100%'}>
            <Box className={className} width={'100%'}>
                {children}
            </Box>
            <Only when={mode === 'edit'}>
                <Box display="flex" marginTop={marginTop ?? 0} marginLeft={1}>
                    <IconButton
                        onClick={onEditClick}
                        style={{width: 40, height: 40}}>
                        <Box display={'flex'}>
                            <Tooltip title={translate(buttonType)} placement={'right'}>
                                {
                                    buttonType === 'edit' ?
                                        <Edit/> :
                                        <Restore/>
                                }
                            </Tooltip>
                        </Box>
                    </IconButton>
                </Box>
            </Only>
        </Box>
    )
}
