import {Theme} from '@material-ui/core'
import {CreateCSSProperties} from '@material-ui/core/styles/withStyles'
import {BaseCSSProperties} from '@material-ui/styles/withStyles/withStyles'

export function responsivePadding({spacing, breakpoints}: Pick<Theme, 'spacing' | 'breakpoints'>,
    multiplier = 1,
    minPadding = spacing(2)): CreateCSSProperties {
    const defaultPadding = spacing(25) * multiplier
    return {
        paddingLeft: minPadding,
        paddingRight: minPadding,
        [breakpoints.up('mobile')]: {
            paddingLeft: defaultPadding / 4.0,
            paddingRight: defaultPadding / 4.0,
        },
        [breakpoints.up('tablet')]: {
            paddingLeft: defaultPadding / 2.0,
            paddingRight: defaultPadding / 2.0,
        },
        [breakpoints.up('desktop')]: {
            paddingLeft: defaultPadding,
            paddingRight: defaultPadding,
        },
    }
}

export function textShadow(): BaseCSSProperties {
    return {
        textShadow: '4px 1px 10px #5d5d5d',
    }
}
