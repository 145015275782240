import {Box} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {CauseCard} from 'components/cards/CauseCard'
import {ResponsiveGrid} from 'components/foundation/ResponsiveGrid'
import {useStatefulPageState} from 'components/foundation/StatefulPage'
import {RichText} from 'components/misc/RichText'
import {ICause} from 'types/cause'
import React, {useState} from 'react'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequestEffect} from 'utils/request'
import {TranslationKey} from 'utils/TranslationKey'
import {Only} from '../../../components/util/Only'
import {useLang} from '../../../state/lang'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        grid: {
            columnGap: spacing(2),
        },
        causeCard: {
            width: spacing(35),
        }
    }),
)

function CauseGrid(props: {
    title: TranslationKey
    causes: ICause[]
}) {
    const {title, causes} = props
    const {grid, causeCard} = useStyles()
    const {translate} = useTranslation()

    return (
        <>
            <RichText bold size={'huge'} textAlign={'center'} text={translate(title)} marginBottom={8}/>
            <ResponsiveGrid
                className={grid}
                breakpointColumns={{
                    'tablet': 2,
                    'laptop': 3,
                }}
                children={
                    causes.map(cause =>
                        <CauseCard
                            key={cause.id}
                            cause={cause}
                            className={causeCard}
                            mode={'normal'}
                        />
                    )
                }
            />
        </>
    )
}

export function ActiveCauses(props: { charity_id: number }) {
    const {charity_id} = props
    const {lang} = useLang()

    const page = useStatefulPageState()

    const [activeCauses, setActiveCauses] = useState<ICause[]>([])
    const [completedCauses, setCompletedCauses] = useState<ICause[]>([])

    useRequestEffect<ICause[]>({
        state: page,
        url: api.charity.read_causes,
        data: {
            charity_id: Number(charity_id)
        },
        onSuccess: allCauses => {
            setActiveCauses(allCauses.filter(cause => cause.state === 'active'))
            setCompletedCauses(allCauses.filter(cause => cause.state === 'completed'))
        }
    }, [lang])

    return (
        <Box>
            <Only when={!page.loading}>
                <Only when={activeCauses.length > 0}>
                    <CauseGrid title={'active_causes'} causes={activeCauses}/>
                </Only>
                <Only when={completedCauses.length > 0}>
                    <CauseGrid title={'completed_causes'} causes={completedCauses}/>
                </Only>
            </Only>
        </Box>
    )
}
