import {Box, Typography, useTheme} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {EmailOutlined} from '@material-ui/icons'
import {GbbContainer} from 'components/foundation/GbbContainer'
import {Anchor} from 'components/misc/Anchor'
import {RichText} from 'components/misc/RichText'
import React from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useBodyStyle, useMobileSize} from 'utils/hooks'
import {route} from 'utils/route'
import {responsivePadding} from 'utils/styles'
import kktcell_logo from './kktcell-logo.png'
import kktcell_logo_en from './kktcell-logo-en.png'
import {getVariable} from '../../utils/environment'
import {useLang} from 'state/lang'
import {Only} from 'components/util/Only'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            boxSizing: 'border-box',
            backgroundColor: '#EAF7FF',
        },
        footerContent: {
            ...responsivePadding(theme, 1.25, 32),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(2),

            color: '#414141',

            '& a': {
                color: '#414141',

                // color: 'black',
                textDecoration: 'none'
            },
            '& a:hover': {
                textDecoration: 'underline',
            },
            '& li': {
                fontSize: '1.1em',
                marginTop: 8,
            },
            '& ul': {
                listStyleType: 'none',
                paddingLeft: 0
            }
        },
        sectionTitle: {
            textTransform: 'uppercase',
        },
    })
)

export function Footer() {
    const {footer, footerContent, sectionTitle} = useStyles()
    const {translate} = useTranslation()
    const {spacing} = useTheme()
    const mobile = useMobileSize()
    const {lang} = useLang()
    return (
        <footer className={footer} {...useBodyStyle()}>
            <GbbContainer>
                <div className={footerContent}>
                    <div>
                        <Box display="flex" flexWrap="wrap">
                            <Box paddingRight={12} marginBottom={2}>
                                <Typography className={sectionTitle} variant="h6" children={translate('sitemap')}/>
                                <ul>
                                    <li><Link to={route.to.about_us}>{translate('about_us')}</Link></li>
                                    <li><Link to={route.to.how_it_works}>{translate('how_it_works')}</Link></li>
                                    <li><Link to={route.to.charities}>{translate('charities')}</Link></li>
                                    <li><Link
                                        to={route.to.causes({state: 'active'})}>{translate('select_project')}</Link>
                                    </li>
                                    <li><Link to={route.to.causes({
                                        state: 'active',
                                        type: 'donation'
                                    })}>{translate('donate')}</Link></li>
                                    <li><Link
                                        to={route.to.privacy_and_security}>{translate('privacy_and_security')}</Link>
                                    </li>
                                </ul>
                            </Box>
                            <Box paddingRight={12} marginBottom={2}>
                                <Typography className={sectionTitle} variant="h6" children={translate('projects')}/>
                                <ul>
                                    <li>
                                        <Link
                                            to={route.to.completed_causes}
                                            children={translate('projects_at_goal')}
                                        />
                                    </li>
                                    <li>
                                        <Link
                                            to={route.to.expired_causes}
                                            children={translate('expired_projects')}
                                        />
                                    </li>
                                </ul>
                            </Box>
                            <Box marginBottom={2} maxWidth={spacing(40)}>
                                <Typography className={sectionTitle} variant="h6" children={translate('contact')}/>
                                <ul>
                                    <li>{translate('contact_desc')}</li>
                                    <li style={{display: 'flex'}}>
                                        <EmailOutlined/>
                                        <Box marginLeft={1}/>
                                        <Anchor isEmail href={getVariable('SUPPORT_EMAIL')}/>
                                    </li>
                                </ul>
                            </Box>
                        </Box>
                    </div>
                    {/* <Box display={'flex'} marginY={1}>
                        <img src={kktcell_logo} alt={'kktcell logo'} width={256} style={{margin: 'auto'}}/>
                    </Box> */}
                    <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
                        <RichText text={translate('copyright_notice')} padding={8} alignSelf={'flex-end'}/>
                        <Box flex={1} 
                        style={mobile ? {
                            display: 'none',
                        } : {
                            
                        }}></Box>
                        <Only when={lang === 'tr'}>
                            <Box display={'flex'} >
                                <img src={kktcell_logo} alt={'kktcell logo'} width={256} style={{margin: 'auto'}}/>
                            </Box>
                        </Only>
                        <Only when={lang === 'en'}>
                            <Box display={'flex'} >
                                <img src={kktcell_logo_en} alt={'kktcell logo'} width={256} style={{margin: 'auto'}}/>
                            </Box>
                        </Only>
                    </Box>
                </div>
            </GbbContainer>
        </footer>
    )
}
