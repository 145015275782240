import {useTranslation} from '../../../state/translation'
import {Box, FormControl, FormControlLabel, FormLabel, Icon, Radio, RadioGroup} from '@material-ui/core'
import React from 'react'
import localpay_logo from './media/localpay_logo.png'
import paynet_logo from './media/paynet_logo.png'
import credit_card_logo from './media/credit_card_logo.png'
import kktcell_logo from './media/kktcell_logo.png'
import {RichText} from '../../../components/misc/RichText'
import {StateMethods} from '@hookstate/core'
import {TranslationKey} from '../../../utils/TranslationKey'
import {isAndroid, isIOS} from 'react-device-detect'
import {Only} from '../../../components/util/Only'
import {useMobileSize} from '../../../utils/hooks'
import {isInProduction} from '../../../utils/environment'
import {ICause} from '../../../types/cause'
import {PaymentMethodType} from '../../../types/types'
import {ICauseType} from '../../../types/cause'

export function PaymentMethodStep(props: {
    cause: ICause
    project: ICauseType
    paymentMethod: StateMethods<PaymentMethodType>
}) {
    const {cause, paymentMethod, project} = props
    const {translate} = useTranslation()
    const mobile = useMobileSize()

    // In development we want to test the mobile payment flow, so we check only screen size
    const isDevMobile = !isInProduction() && mobile

    // In production we check the actual device type
    const isProdMobile = isInProduction() && (isAndroid || isIOS)

    return (
        <FormControl component="fieldset">
            <FormLabel
                component="legend"
                color={'primary'}
                children={translate('payment_method_desc')}
            />
            <RadioGroup
                value={paymentMethod.value}
                onChange={e => paymentMethod.set(e.target.value as PaymentMethodType)}
            >
                <Only when={cause.charity.payment_methods.paynet}>
                    <FormControlLabelWithIcon
                        value={'paynet'}
                        img={credit_card_logo}
                        alt={'credit card logo'}
                        desc={'payment_method_paynet_desc'}
                    />
                </Only>
                <Only when={cause.charity.payment_methods.localpay && (isDevMobile || isProdMobile)}>
                    <FormControlLabelWithIcon
                        value={'localpay'}
                        img={localpay_logo}
                        alt={'localpay logo'}
                        desc={'payment_method_localpay_desc'}
                    />
                </Only>
                {/*KKTCELL invoice payment is only visible when support type is donation and if current project has invoice donations enabled*/}
                <Only when={cause.projects.donation.enable_invoice && project == 'donation'}> 
                    <FormControlLabelWithIcon
                        value={'kktcell_invoice'}
                        img={kktcell_logo}
                        alt={'kktcell logo'}
                        desc={'payment_method_kktcell_invoice_desc'}
                    />
                </Only>
            </RadioGroup>
        </FormControl>
    )
}

function FormControlLabelWithIcon(props: {
    alt: string
    img: string
    value: PaymentMethodType
    desc: TranslationKey
}) {
    const {translate} = useTranslation()

    return (
        <FormControlLabel
            control={<Radio/>}
            value={props.value}
            label={
                <Box display={'flex'} alignItems={'center'} marginY={1}>
                    <Icon
                        style={{
                            display: 'flex',
                            width: 48,
                            height: 48
                        }}
                    >
                        <img
                            alt={props.alt}
                            src={props.img}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%'
                            }}
                        />
                    </Icon>
                    <Box marginLeft={1}/>
                    <RichText text={translate(props.desc)}/>
                </Box>
            }
        />
    )
}
