import {Box} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {CharityCard} from 'components/cards/CharityCard'
import {ResponsiveGrid} from 'components/foundation/ResponsiveGrid'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import {ICharity} from 'types/charity'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useUser} from 'state/user'
import {useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {useLang} from 'state/lang'
import {Pagination} from '@material-ui/lab'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        charityCard: {
            width: spacing(32),
        },
        pagination: {
            margin: 'auto'
        }
    })
)

const CARDS_PER_PAGE = 6

export function Charities() {
    const classes = useStyles()
    const {translate} = useTranslation()
    const {lang} = useLang()

    const page = useStatefulPageState()

    const {user} = useUser()
    
    const [charities, setCharities] = useState<ICharity[]>([])
    const [paginatedCharities, setPaginatedCharities] = useState<ICharity[]>([])

    const [pageNumber, setPageNumber] = useState(1)

    useRequestEffect<ICharity[]>({
        url: api.charity.read_public,
        state: page,
        onSuccess: setCharities,
    }, [lang])

    useEffect(() => {
        const start = (pageNumber - 1) * CARDS_PER_PAGE
        const end = start + CARDS_PER_PAGE

        setPaginatedCharities(charities.slice(start, end))
    }, [charities, pageNumber])

    return (
        <>
            <AppHelmet
                description={translate('all_charities_meta_desc')}
                canonicalRoute={route.to.charities}
            />
            <StatefulPage state={page}>
                <Only when={{charities}}>{({charities}) =>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Title titleKey={'all_charities'}/>
                        <ResponsiveGrid
                            breakpointColumns={{
                                mobile: 1,
                                tablet: 2,
                                laptop: 3,
                            }}
                            children={paginatedCharities.map(charity =>
                                <CharityCard
                                    key={charity.id}
                                    className={classes.charityCard}
                                    charity={charity}
                                    mode={user?.is_admin? 'normal_admin' : 'normal'}
                                />
                            )}
                        />
                        <Box display={'flex'} marginY={4}>
                            <Pagination
                                className={classes.pagination}
                                count={Math.ceil(charities.length / CARDS_PER_PAGE)}
                                page={pageNumber}
                                onChange={(_, value) => setPageNumber(value)}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Box>
                    </Box>
                }</Only>
            </StatefulPage>
        </>
    )
}
