import {
    Box,
    Button,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Chip,
    createStyles,
    Divider,
    Typography
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import {ICause} from 'types/cause'
import {ButtonBack, ButtonNext, CarouselProvider, Slide, Slider} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import React from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useMobileSize} from 'utils/hooks'
import {route} from 'utils/route'
import {TranslationKey} from 'utils/TranslationKey'
import {getFormattedNumber} from 'utils/general'
import {ImageWithFallback} from '../misc/ImageWithFallback'
import {OverflowText} from '../misc/OverflowText'
import {PercentageProgress} from '../misc/PercentageProgress'
import {RichText} from '../misc/RichText'
import {Only} from '../util/Only'
import {ElevatedCard} from './ElevatedCard'

const useStyles = makeStyles(() =>
    createStyles({
        causeCard: {
            display: 'flex',
            flexDirection: 'column'
        },
        media: {
            height: 168,
        },
        slider: {
            minHeight: 125,
            outline: 'none',
            '& *': {
                outline: 'none',
            }
        }
    })
)

function ProjectSlide(props: {
    when: boolean,
    index: number,
    currentValue: number | null,
    totalValue: number | null,
    title: TranslationKey,
    currentText: TranslationKey,
    totalText: TranslationKey,
    sign?: string,
    formatNumber?: boolean
}) {
    const {translate} = useTranslation()
    const {currentText, currentValue, index, totalText, totalValue, when, title, sign, formatNumber} = props

    function getFormattedValue(value: number | null) {
        const number = (formatNumber ? getFormattedNumber(value) : value) ?? 0
        return `${number} ${sign ?? ''}`
    }

    return (
        <Only when={when}>
            <Slide index={index}>
                <Box paddingX={2}>
                    <Typography
                        children={<b>{translate(title)}</b>}
                        style={{width: '100%', textAlign: 'center'}}
                    />
                    <Only when={title != 'competition'}>
                        <PercentageProgress
                            current={currentValue}
                            total={totalValue}/>
                        <Box display={'flex'} justifyContent={'space-between'} marginTop={1}>
                            <Typography children={translate(currentText)}/>
                            <Typography children={translate(totalText)}/>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <RichText bold text={getFormattedValue(currentValue)}/>
                            <RichText bold text={getFormattedValue(totalValue)}/>
                        </Box>
                    </Only>
                </Box>
            </Slide>
        </Only>
    )
}


export function CauseCard(props: {
    className?: string,
    cause: ICause,
    mode: 'normal' | 'detailed'
}) {
    const {className, cause, mode} = props
    const classes = useStyles()
    const {translate} = useTranslation()
    const mobileSize = useMobileSize()

    const totalSlides = Object.values(cause.projects).reduce((total, project) => total + +project.is_active, 0)

    let index = 0

    return (
        <ElevatedCard className={[className, classes.causeCard].join(' ')}>
            <CardActionArea component={Link} to={route.to.cause(cause.id).page}>
                <CardMedia
                    className={classes.media}
                    children={<ImageWithFallback src={cause.image_url}/>}
                />
                <Divider/>
            </CardActionArea>
            <CardContent style={{flex: 1}}>
                <RichText textAlign={'center'} text={cause.charity.name}/>
                <Divider/>
                <RichText bold textAlign={'center'} text={cause.name}/>
                <OverflowText lines={3} variant="body2" color="textSecondary" children={cause.summary}/>
            </CardContent>
            <CarouselProvider
                naturalSlideWidth={2.25}
                dragEnabled={false}
                touchEnabled={false}
                naturalSlideHeight={1}
                totalSlides={totalSlides}
                infinite={true}
            >
                <Box paddingBottom={1}>
                    <Only when={mode === 'detailed'}>
                        <Divider/>
                        <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'} margin={1}>
                            <Typography>{translate('status')}:</Typography>
                            <Chip
                                size={mobileSize ? 'small' : 'medium'}
                                label={translate(cause.status.name)}
                                style={{
                                    color: 'white',
                                    background: cause.status.color
                                }}
                            />
                        </Box>
                    </Only>
                    <Divider/>
                </Box>
                <Slider className={classes.slider}>
                    <ProjectSlide
                        when={cause.projects.activity.is_active}
                        index={index++}
                        currentValue={cause.projects.activity.count}
                        totalValue={cause.projects.activity.capacity}
                        title={'activity'}
                        currentText={'activity_current_text'}
                        totalText={'activity_total_text'}
                        sign={translate('people')}
                    />
                    <ProjectSlide
                        when={cause.projects.donation.is_active}
                        index={index++}
                        currentValue={cause.projects.donation.sum}
                        totalValue={cause.projects.donation.goal}
                        title={'donation'}
                        currentText={'donation_current_text'}
                        totalText={'donation_total_text'}
                        sign={'TL'} //₺
                        formatNumber={true}
                    />
                    <ProjectSlide
                        when={cause.projects.event.is_active}
                        index={index++}
                        currentValue={cause.projects.event.count}
                        totalValue={cause.projects.event.capacity}
                        title={'event'}
                        currentText={'event_current_text'}
                        totalText={'event_total_text'}
                        sign={translate('tickets')}
                    />
                    <ProjectSlide
                        when={cause.projects.volunteering.is_active}
                        index={index++}
                        currentValue={cause.projects.volunteering.count}
                        totalValue={cause.projects.volunteering.capacity}
                        title={'volunteering'}
                        currentText={'volunteering_current_text'}
                        totalText={'volunteering_total_text'}
                        sign={translate('people')}
                    />
                    <ProjectSlide
                        when={cause.projects.competition.is_active}
                        index={index++}
                        currentValue={cause.projects.competition.count}
                        totalValue={cause.projects.competition.capacity}
                        title={'competition'}
                        currentText={'competition_current_text'}
                        totalText={'competition_total_text'}
                        sign={translate('people')}
                    />
                </Slider>
                <Only when={totalSlides > 1}>
                    <Box display={'flex'} justifyContent={'space-between'} paddingX={2} paddingBottom={1}>
                        <Button
                            component={ButtonBack}
                            color={'secondary'}
                            size={'small'}
                            disabled={totalSlides <= 1}
                            startIcon={<ChevronLeft/>}
                            children={translate('previous')}
                        />
                        <Button
                            component={ButtonNext}
                            color={'secondary'}
                            size={'small'}
                            disabled={totalSlides <= 1}
                            endIcon={<ChevronRight/>}
                            children={translate('next')}
                        />
                    </Box>
                </Only>
            </CarouselProvider>
            <Divider/>
            <CardActions>
                <Button
                    color="primary"
                    component={Link}
                    to={route.to.cause(cause.id).page}
                    children={translate('show_more')}
                />
                <Only when={mode === 'detailed'}>
                    <Button
                        color="primary"
                        component={Link}
                        to={route.to.cause(cause.id).edit}
                        children={translate('edit')}
                    />
                </Only>
            </CardActions>
        </ElevatedCard>
    )
}
