import {Button, createStyles, Theme, Typography} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {route} from 'utils/route'
import {textShadow} from 'utils/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        volunteer: {
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            alignItems: 'center',
            '& >*': {
                color: '#EEE'
            }
        },
        title: {
            marginTop: theme.spacing(8),
            textTransform: 'uppercase',
            ...textShadow(),
        },
        subtitle: {
            marginTop: theme.spacing(2),
            ...textShadow(),
        },
        button: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(8),
        },

    })
)

export const Volunteer = (props: { backgroundURL: string }) => {
    const {volunteer, title, subtitle, button} = useStyles()
    const {translate} = useTranslation()
    const {backgroundURL} = props

    const history = useHistory()

    return (
        <Box
            className={volunteer} display={'flex'}
            style={{backgroundImage: `url(${backgroundURL})`}}
        >
            <Typography className={title} variant="h3" children={translate('be_a_volunteer')}/>
            <Typography className={subtitle} variant="h5" children={translate('banner_volunteer_subtitle')}/>
            <Button
                className={button}
                color={'inherit'}
                variant={'outlined'}
                size={'large'}
                children={translate('join_us_now')}
                onClick={() => history.push(route.to.causes({type: 'volunteering'}))}
            />
        </Box>
    )
}
