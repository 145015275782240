import {InputAdornment, TextField} from '@material-ui/core'
import React, {CSSProperties} from 'react'
import {useTranslation} from 'state/translation'
import {TranslationKey} from 'utils/TranslationKey'
import {EditableField, NumberField} from '../foundation/EditableField'

export function IntField(props: {
    label: TranslationKey,
    field: NumberField,
    totalSign?: string,
    style?: CSSProperties,
}) {
    const {translate} = useTranslation()
    const {label, field, totalSign, style} = props

    return (
        <EditableField field={field}>
            <TextField
                // type={'number'}
                disabled={field.disabled}
                fullWidth={true}
                margin={'dense'}
                error={Boolean(field.hint)}
                helperText={field.hint ?? undefined}
                label={translate(label)}
                variant={'outlined'}
                value={field.value}
                inputMode={'numeric'}
                style={style}
                FormHelperTextProps={{
                    style: {textAlign: 'end', marginBottom: 8},
                }}
                onChange={e => {
                    const value = +e.target.value
                    if (value >= 0) {
                        field.setValue(value)
                    } else if (e.target.value === '') {
                        field.setValue(0)
                    }

                    field.clearHint()
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end" children={totalSign}/>
                }}
            />
        </EditableField>
    )
}
