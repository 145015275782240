import {Column} from '@material-table/core'
import {Box, Button, CircularProgress, Typography} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Edit, Save, Visibility} from '@material-ui/icons'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {CauseProjects} from 'components/misc/CauseProjects'
import {CustomSelect} from 'components/misc/CustomSelect'
import {CustomTable} from 'components/misc/CustomTable'
import {Title} from 'components/misc/Title'
import {ICause} from 'types/cause'
import {IStatus} from 'types/types'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useAppSnackbar} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {TranslationKey} from 'utils/TranslationKey'
import {useLang} from '../../state/lang'

const useStyles = makeStyles(() =>
    createStyles({
        ownerLink: {
            color: 'black',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    })
)

type CauseState = { loading: boolean, status: TranslationKey }
type CauseWithState = ICause & { _state: CauseState }

export function ReviewCauses() {
    const page = useStatefulPageState()
    const {ownerLink} = useStyles()
    const {translate} = useTranslation()
    const {lang} = useLang()
    const {showSnackbar} = useAppSnackbar()
    const {makeRequest} = useRequest()

    const [causes, setCauses] = useState<CauseWithState[]>([])
    const [statusMap, setStatusMap] = useState<Record<string, IStatus>>({})

    function updateCauseState(id: number, props: Partial<CauseState>) {
        setCauses(causes.map(it => it.id === id ? {...it, _state: {...it._state, ...props}} : it))
    }

    function updateCauseProps(id: number, props: Partial<CauseWithState>) {
        setCauses(causes.map(it => it.id === id ? {...it, ...props} : it))
    }

    useRequestEffect<IStatus[]>({
        state: page,
        url: api.status.read_all,
        onSuccess: statuses => setStatusMap(
            statuses.reduce((obj, status) => (
                {...obj, [status.name]: status}
            ), {})
        )
    })
    useRequestEffect<ICause[]>({
        state: page,
        url: api.cause.read_all,
        onSuccess: causes => setCauses(causes.map(it => ({
            ...it,
            _state: {
                loading: false,
                status: it.status.name,
            }
        })))
    }, [lang])

    return (
        <StatefulPage state={page}>
            <Title titleKey={'review_causes'}/>
            <CustomTable
                data={causes}
                columns={[
                    {title: translate('cause_name'), render: data => <Typography children={data.name}/>},
                    {
                        title: translate('charity_name'),
                        customFilterAndSearch: (filter: string, data) =>
                            data.charity.name.toLowerCase().includes(filter.toLowerCase()),
                        render: data =>
                            <Typography
                                component={Link}
                                className={ownerLink}
                                to={route.to.charity(data.charity.id).page}
                                children={data.charity.name}
                            />
                    },
                    {
                        title: translate('projects'),
                        render: data => <CauseProjects projects={data.projects}/>
                    },
                    {
                        title: translate('status'), field: 'status',
                        render: data => {
                            const status = statusMap[data._state.status]

                            return (
                                <CustomSelect
                                    variant="outlined"
                                    endIcon={<KeyboardArrowDownIcon/>}
                                    style={{color: status?.color}}
                                    items={Object.values(statusMap).map(it => ({
                                        key: it.name,
                                        text: translate(it.name),
                                        color: it.color
                                    }))}
                                    onItemSelect={item => updateCauseState(data.id, {status: item.key})}
                                    children={status ? translate(status.name) : undefined}
                                />
                            )
                        }
                    },
                    {
                        title: translate('details'),
                        render: data => (
                            <Box display={'flex'}>
                                <Button
                                    variant="outlined"
                                    color={'secondary'}
                                    startIcon={<Visibility/>}
                                    children={translate('view')}
                                    component={Link}
                                    to={route.to.cause(data.id).page}
                                />
                                <Box marginX={1}/>
                                <Button
                                    variant="outlined"
                                    color={'secondary'}
                                    startIcon={<Edit/>}
                                    children={translate('edit')}
                                    component={Link}
                                    to={route.to.cause(data.id).edit}
                                />
                            </Box>
                        )
                    },
                ] as Column<CauseWithState>[]}
                actions={[
                    data => {
                        const disabled = data.status.name === data._state.status

                        return {
                            icon: () => data._state.loading ?
                                <CircularProgress color={'primary'} size={24}/> :
                                <Save color={disabled ? 'disabled' : 'secondary'}/>,
                            disabled: disabled || data._state.loading,
                            onClick: () => {
                                makeRequest({
                                    url: api.cause.update_status,
                                    data: {
                                        id: Number(data.id),
                                        status: data._state.status
                                    },
                                    onStart: () => updateCauseState(data.id, {loading: true}),
                                    onSuccess: () => showSnackbar('success', 'cause_update_success'),
                                    onFail: resultCode => {
                                        const errorMessage = resultCode === 'STATUS_CANNOT_BE_DRAFT'
                                            ? 'new_status_cannot_be_draft' : 'cause_update_fail'
                                        showSnackbar('error', errorMessage)
                                    },
                                    onError: () => showSnackbar('error', 'cause_update_fail'),
                                    onFinish: success => {
                                        updateCauseProps(data.id, {
                                            ...(success && {status: statusMap[data._state.status]}),
                                            _state: {
                                                ...data._state,
                                                loading: false
                                            }
                                        })
                                    },
                                }).then()
                            }
                        }
                    }
                ]}
            />
        </StatefulPage>
    )
}
