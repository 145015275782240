import React, {ReactNode} from 'react'

export type Maybe<T> = T | undefined | null

type OrNull<T> = {
    [P in keyof T]: T[P] | undefined | null
}

export function Only<T>(props: {
    when: OrNull<T> | boolean | undefined
    log?: boolean
    children: ReactNode | ((when: T) => ReactNode)
}) {
    const {when, children} = props

    if (when === undefined) {
        return null
    } else if (typeof when === 'boolean') {
        if (!when) {
            return null
        }
    } else {
        for (const key in when) {
            if (!when[key]) {
                return null
            }
        }
    }

    return <>{children instanceof Function ? children(when as T) : children}</>
}
