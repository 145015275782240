import {Box, Button, CardActions, Chip, Divider, Typography} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {PanoramaOutlined} from '@material-ui/icons'
import {ICharity} from 'types/charity'
import React from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useMobileSize} from 'utils/hooks'
import {route} from 'utils/route'
import {OverflowText} from '../misc/OverflowText'
import {RichText} from '../misc/RichText'
import {Only} from '../util/Only'
import {ElevatedCard} from './ElevatedCard'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        card: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        logo: {
            width: '100%',
            height: spacing(15),
            objectFit: 'contain',
            color: 'gray',
        },
    }),
)

export function CharityCard(props: {
    className?: string,
    charity: ICharity,
    mode: 'normal' | 'detailed' | 'normal_admin'
}) {
    const {className, charity, mode} = props
    const {card, logo} = useStyles()

    const {translate} = useTranslation()
    const mobileSize = useMobileSize()

    return (
        <ElevatedCard className={className}>
            <Box className={card}>
                <Box padding={1}>{charity.logo_url ?
                    <img className={logo} src={charity.logo_url} alt={'charity_logo'} draggable="false"/> :
                    <PanoramaOutlined className={logo}/>
                }</Box>
                <Box display={'flex'} flexDirection={'column'} height={'100%'} margin={1}>
                    <RichText bold textAlign={'center'} text={charity.name}/>
                    <Box flex={1} marginY={1}/>
                    <OverflowText lines={2} children={charity.summary}/>
                    <Only when={mode === 'detailed'}>
                        <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'} margin={1}>
                            <Typography>{translate('status')}:</Typography>
                            <Chip
                                size={mobileSize ? 'small' : 'medium'}
                                label={translate(charity.status.name)}
                                style={{
                                    color: 'white',
                                    background: charity.status.color
                                }}
                            />
                        </Box>
                    </Only>
                </Box>
                <Divider/>
                <CardActions>
                    <Button
                        color="primary"
                        component={Link}
                        to={route.to.charity(charity.id).page}
                        children={translate('view')}
                    />
                    <Only when={mode === 'detailed'}>
                        <Button
                            color="primary"
                            component={Link}
                            to={route.to.charity(charity.id).edit}
                            children={translate('edit')}
                        />
                        <Button
                            color="primary"
                            component={Link}
                            to={route.to.charity(charity.id).contributions}
                            children={translate('contributions')}
                        />
                    </Only>
                    <Only when={mode === 'normal_admin'}>
                        <Button
                            color="primary"
                            component={Link}
                            to={route.to.charity(charity.id).contributions}
                            children={translate('contributions')}
                        />
                    </Only>
                </CardActions>
            </Box>
        </ElevatedCard>
    )
}
