import {DateTimePicker} from '@material-ui/pickers'
import {format} from 'date-fns'
import React from 'react'
import {useTranslation} from 'state/translation'
import {useMobileSize} from 'utils/hooks'
import {TranslationKey} from 'utils/TranslationKey'
import {EditableField, StringField} from '../foundation/EditableField'
import {DateContextProvider} from '../misc/DateContextProvider'

export function DateTimeField(props: {
    className?: string,
    label: TranslationKey,
    field: StringField
}) {
    const {translate} = useTranslation()
    const {className, label, field} = props
    const mobileSize = useMobileSize()

    return (
        <EditableField field={field}>
            <DateContextProvider>
                <DateTimePicker
                    className={className}
                    label={translate(label)}
                    fullWidth={true}
                    margin={'dense'}
                    clearable={true}
                    clearLabel={translate('clear')}
                    okLabel={translate('ok')}
                    cancelLabel={translate('cancel')}
                    inputVariant="outlined"
                    disabled={field.disabled}
                    variant={mobileSize ? 'dialog' : 'inline'}
                    format="MMMM dd, yyyy @ HH:mm"
                    value={field.value ? field.value : null}
                    onChange={date => {
                        field.setValue(date ? format(date, 'yyyy-MM-dd HH:mm') : '')
                        field.clearHint()
                    }}
                />
            </DateContextProvider>
        </EditableField>
    )
}
