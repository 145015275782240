import {useState} from '@hookstate/core'
import {Button, createStyles, IconButton, Typography} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import {IHomeBanner} from 'types/types'
import {ButtonBack, ButtonNext, CarouselProvider, DotGroup, Slide, Slider} from 'pure-react-carousel'
import React, {useLayoutEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {textShadow} from 'utils/styles'

const useStyles = makeStyles(({palette}) =>
    createStyles({
        carousel: {
            position: 'relative'
        },
        slider: {
            backgroundColor: palette.secondary.main,
            outline: 'none',
            '& *': {
                outline: 'none',
            }
        },
    })
)

export function BannerCarousel(props: {
    className?: string,
}) {
    const {className} = props
    const {carousel, slider} = useStyles()
    const {translate} = useTranslation()
    const {spacing} = useTheme()

    const wrapperRef = useRef<HTMLElement>()
    const dimensions = useState({width: 0, height: 0})
    const slideIndex = useState(0)

    const slides = useState<IHomeBanner[]>([])

    function updateSize() {
        const rect = wrapperRef.current?.getBoundingClientRect()

        dimensions.width.set(rect?.width ?? 0)
        dimensions.height.set(rect?.height ?? 0)
    }

    useLayoutEffect(() => {
        updateSize()
        window.addEventListener('resize', updateSize)
        return () => window.removeEventListener('resize', updateSize)
    }, [])

    useRequestEffect<IHomeBanner[]>({
        url: api.home.banner.read_active,
        onSuccess: slides.set
    })

    const NavButton = (props: { type: 'prev' | 'next' }) =>
        <IconButton
            size={'small'}
            component={{
                // Produces findDOMNode warning
                'prev': ButtonBack,
                'next': ButtonNext,
            }[props.type]}
            children={{
                'prev': <ChevronLeft style={{fontSize: '2.5rem'}}/>,
                'next': <ChevronRight style={{fontSize: '2.5rem'}}/>,
            }[props.type]}
            style={{
                margin: 'auto',
                color: 'white',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                ...({
                    'prev': {left: spacing(1)},
                    'next': {right: spacing(1)},
                }[props.type])
            }}
        />

    let index = 0
    return (
        <Box className={className} {...{ref: wrapperRef}}>
            <CarouselProvider
                className={carousel}
                naturalSlideWidth={dimensions.width.value}
                naturalSlideHeight={dimensions.height.value}
                dragEnabled={false}
                touchEnabled={false}
                totalSlides={slides.length}
                infinite={true}
                currentSlide={slideIndex.value}
                isPlaying={true}
            >
                <Slider className={slider}>{
                    slides.map(slide =>
                        <Slide index={index++} key={index}>
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                background: `url(${slide.image_path.value})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                objectFit: 'cover',
                                padding: `0 ${spacing(10)}px`
                            }}>
                                <Typography
                                    variant={'h5'}
                                    children={translate(slide.subtitle_key.value)}
                                    style={{
                                        fontWeight: 'lighter',
                                        color: 'white',
                                        ...textShadow()
                                    }}
                                />
                                <Typography
                                    variant={'h3'}
                                    children={translate(slide.title_key.value)}
                                    style={{
                                        fontWeight: 'bold',
                                        color: 'white',
                                        ...textShadow()
                                    }}
                                />
                                <Box marginY={2}/>
                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    component={Link}
                                    to={route.to.cause(slide.cause_id.value).page}
                                    children={translate(slide.button_key.value)}
                                    style={{marginRight: 'auto'}}
                                />
                            </Box>
                        </Slide>
                    )
                }
                </Slider>
                <NavButton type={'prev'}/>
                <NavButton type={'next'}/>
                <DotGroup
                    style={{
                        display: 'flex',
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bottom: spacing(2)
                    }}
                    renderDots={renderProps =>
                        Array(renderProps.totalSlides).fill(1).map((_, index) =>
                            <div
                                key={index}
                                onClick={() => slideIndex.set(index)}
                                style={{
                                    borderRadius: spacing(1),
                                    margin: spacing(.5),
                                    width: spacing(1.5),
                                    height: spacing(1.5),
                                    cursor: 'pointer',
                                    transition: 'all 0.5s ease',
                                    background: `rgba(255,255,255,${renderProps.currentSlide === index ? 0.9 : 0.5})`,
                                    border: `1px solid rgba(0,0,0,${renderProps.currentSlide === index ? 0.75 : 0.5})`,
                                    // borderColor: 'rgba(0,0,0,0.25)',
                                }}
                            />
                        )
                    }
                />
            </CarouselProvider>
        </Box>
    )
}
