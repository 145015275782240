import {Box, Button, CircularProgress, Divider, ListItemIcon, Menu, MenuItem} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {
    AccountCircle,
    EmojiPeople,
    ExitToApp,
    KeyboardArrowDown,
    LocalHospital,
    Receipt,
    Security,
    ViewList,
    FormatListBulleted,
    Settings
} from '@material-ui/icons'
import {Only} from 'components/util/Only'
import {bindMenu, bindTrigger, usePopupState} from 'material-ui-popup-state/hooks'
import React, {ReactNode, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {useAuth} from 'state/auth'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {useMobileSize} from 'utils/hooks'
import {useRequest} from 'utils/request'
import {route} from 'utils/route'
import {TranslationKey} from 'utils/TranslationKey'

const useStyles = makeStyles(() =>
    createStyles({
        buttonIcon: {
            fontSize: '32px !important'
        },
        button: {
            color: 'rgba(255,255,255,0.9)',
            fontSize: '1.1rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
)

export function UserButton(props: { showDrawer?: (show: boolean) => void }) {
    const {buttonIcon, button} = useStyles()
    const {showDrawer} = props
    const history = useHistory()
    const mobile = useMobileSize()
    const {translate} = useTranslation()

    const {makeRequest} = useRequest()
    const {setAuth} = useAuth()
    const {user, setUser} = useUser()

    const popupState = usePopupState({variant: 'popover', popupId: 'avatar'})
    const [showSpinner, setShowSpinner] = useState(false)

    const navItems: { route: string, label: TranslationKey, icon: ReactNode }[] = [
        {
            route: route.to.my_charities,
            label: 'my_charities',
            icon: <LocalHospital/>
        },
        {
            route: route.to.my_causes,
            label: 'my_causes',
            icon: <EmojiPeople/>
        },
        {
            route: route.to.my_donation_report,
            label: 'my_charity_donation_report',
            icon: <ViewList/>
        },
        {
            route: route.to.my_projects_current_report,
            label: 'my_projects_current_report',
            icon: <FormatListBulleted/>
        },
        {
            route: route.to.my_transactions,
            label: 'my_transactions',
            icon: <Receipt/>
        },
        {
            route: route.to.user_settings,
            label: 'settings',
            icon: <Settings/>
        }
    ]

    function UserMenuItem(props: {
        label: TranslationKey,
        icon?: ReactNode,
        to?: string,
        onClick?: () => void
    }) {
        const {translate} = useTranslation()
        const {icon, label, to, onClick} = props

        return (
            <MenuItem
                key={label}
                {...(to && {component: Link, to: to})}
                onClick={() => {
                    popupState.close()
                    showDrawer?.(false)
                    onClick?.()
                }}
            >
                <ListItemIcon>{icon}</ListItemIcon>
                {translate(label)}
            </MenuItem>

        )
    }

    const onLogout = () => makeRequest({
        url: api.auth.logout,
        onStart: () => setShowSpinner(true),
        onFinish: () => {
            setAuth(null)
            setUser(null)
            setShowSpinner(false)

            history.push(route.to.home)
        }
    })


    return (
        <Only when={{user}}>{({user}) =>
            <Box display={'flex'} alignItems={'flex-end'}>
                {showSpinner ? <CircularProgress size={32}/> :
                    <Button
                        className={button}
                        size={mobile ? 'large' : 'medium'}
                        variant="text"
                        color="inherit"
                        endIcon={<KeyboardArrowDown/>}
                        startIcon={<AccountCircle className={buttonIcon}/>}
                        {...bindTrigger(popupState)}
                        children={!mobile && translate('user')}
                    />
                }
                <Menu {...bindMenu(popupState)} keepMounted={true}>
                    {/* Wrapped in <divs> as a ForwardRef hotfix.
					Source: https://github.com/mui-org/material-ui/issues/15903#issuecomment-723807594*/}
                    <div>
                        <Only when={user.is_admin}>
                            <UserMenuItem
                                label={'administration'}
                                icon={<Security/>}
                                to={route.to.admin.page}
                            />
                            <Divider/>
                        </Only>
                    </div>
                    {navItems.map(({icon, label, route}) =>
                        <div key={label}>
                            <UserMenuItem
                                label={label}
                                icon={icon}
                                to={route}
                            />
                        </div>
                    )}
                    <Divider/>
                    <UserMenuItem
                        label={'logout'}
                        icon={<ExitToApp/>}
                        onClick={onLogout}
                    />
                </Menu>
            </Box>
        }</Only>
    )
}
