import {Box, Typography} from '@material-ui/core'
import {EventOutlined, LabelOutlined} from '@material-ui/icons'
import {ResponsiveBox} from 'components/misc/ResponsiveBox'
import {RichText} from 'components/misc/RichText'
import {Only} from 'components/util/Only'
import {ICause} from 'types/cause'
import React, {cloneElement} from 'react'
import {useTranslation} from 'state/translation'
import {formatDateString} from 'utils/date'
import {SocialShare} from './SocialShare'

function InfoRow(props: {
    icon: JSX.Element,
    label: string,
    value?: string | null,
}) {
    const {icon, label, value} = props
    return (
        <Only when={{value}}>{({value}) =>
            <tr>
                <td style={{display: 'flex'}}>{cloneElement(icon, {color: 'secondary'})}</td>
                <td><RichText text={`${label}:`}/></td>
                <td><RichText text={`${value}`} bold/></td>
            </tr>
        }</Only>
    )
}

export function ExtraInfo(props: {
    className?: string,
    cause: ICause
}) {
    const {className, cause} = props
    const {translate} = useTranslation()

    return (
        <ResponsiveBox breakpoint={'laptop'} className={className}>
            <table>
                <tbody>
                    <InfoRow
                        icon={<EventOutlined/>}
                        label={translate('start_date')}
                        value={formatDateString(cause.start_date)}
                    />
                    <InfoRow
                        icon={<EventOutlined/>}
                        label={translate('end_date')}
                        value={formatDateString(cause.end_date)}
                    />
                    <InfoRow
                        icon={<LabelOutlined/>}
                        label={translate('category')}
                        value={translate(cause.category)}
                    />
                </tbody>
            </table>
            <Box margin={1} flex={1}/>
            <Box>
                <Typography style={{fontSize: '0.9rem'}}>
                    {translate('share') + ':'}
                </Typography>
                <SocialShare cause={cause}/>
            </Box>
        </ResponsiveBox>

    )
}
