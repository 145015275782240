import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Article} from 'components/foundation/Article'
import React from 'react'
import logo_bg from './logo_bg.png'

const useStyles = makeStyles(({typography}) =>
    createStyles({
        aboutUs: {
            fontSize: typography.fontSize * 1.25,
            textAlign: 'justify',
            background: `url(${logo_bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain'
        }
    })
)

export function AboutUs() {
    const {aboutUs} = useStyles()

    return <Article resourceId={'about_us'} mdClassName={aboutUs}/>
}
