import ReCaptchaV2 from 'react-google-recaptcha'
import {getVariable} from '../../utils/environment'
import React, {ForwardedRef, forwardRef, RefObject} from 'react'
import {StatefulComponent} from '../../utils/hooks'
import {useLang} from '../../state/lang'
import {Box} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'

/**
 * Usage
 * -----
 * **Declare reference**<br>
 * `const reCaptcha = useRef<ReCaptchaV2>(null)`
 *
 * **Assign reference**<br>
 * `<ReCaptchaInvisible ref={reCaptcha}/>`
 *
 * **Use reference**<br>
 * `const reCaptchaToken = await getReCaptchaToken(reCaptcha, state)`
 *
 * **Send token**<br>
 * `recaptcha_token: reCaptchaToken`
 */
export async function getReCaptchaToken(ref: RefObject<ReCaptchaV2>, state: StatefulComponent) {
    // Show a loading overlay to prevent double-clicks
    state.startLoading()

    // But cancel it after a while in case reCaptcha was dismissed
    setTimeout(state.stopLoading, 3000)

    ref.current?.reset()

    return ref?.current?.executeAsync()
}

const useStyles = makeStyles(() =>
    createStyles({
        recaptcha: {
            '& > div > div': {
                display: 'flex',

                '& .grecaptcha-badge': {
                    marginLeft: 'auto',
                    marginRight: '0',
                }
            }
        },
    }),
)

export const ReCaptchaInvisible = forwardRef(
    function ReCaptchaBox(props, ref: ForwardedRef<ReCaptchaV2>) {
        const {lang} = useLang()
        const {recaptcha} = useStyles()

        return (
            <Box className={recaptcha} marginY={2}>
                <ReCaptchaV2
                    ref={ref}
                    sitekey={getVariable('RECAPTCHA_SITE_KEY')}
                    size={'invisible'}
                    badge={'inline'}
                    hl={lang}
                />
            </Box>
        )
    }
)
