import {Column} from '@material-table/core'
import {Box, Button, CircularProgress, Popover, Typography} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Clear, Edit, GetApp, PictureAsPdf, Save, Send, Visibility} from '@material-ui/icons'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {CustomSelect} from 'components/misc/CustomSelect'
import {CustomTable} from 'components/misc/CustomTable'
import {ImageWithFallback} from 'components/misc/ImageWithFallback'
import {Title} from 'components/misc/Title'
import {ICharity} from 'types/charity'
import {IStatus} from 'types/types'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useAppSnackbar} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {TranslationKey} from 'utils/TranslationKey'
import {useLang} from '../../state/lang'
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state'
import {RichText} from '../../components/misc/RichText'
import {openInNewTab} from '../../utils/general'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        ownerLink: {
            color: 'black',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        documentsDownloadGrid: {
            padding: spacing(2),
            display: 'grid',
            grid: 'auto-flow / auto auto auto',
            gap: spacing(2),
            alignItems: 'center',
        }
    })
)

type CharityState = { loading: boolean, status: TranslationKey }
type CharityWithState = ICharity & { state: CharityState }

export function ReviewCharities() {
    const page = useStatefulPageState()
    const {ownerLink} = useStyles()
    const {translate} = useTranslation()
    const {lang} = useLang()
    const {showSnackbar} = useAppSnackbar()
    const {makeRequest} = useRequest()

    const [charities, setCharities] = useState<CharityWithState[]>([])
    const [statusMap, setStatusMap] = useState<Record<string, IStatus>>({})


    function updateCharityState(id: number, props: Partial<CharityState>) {
        setCharities(charities.map(it => it.id === id ? {...it, state: {...it.state, ...props}} : it))
    }

    function updateCharityProps(id: number, props: Partial<CharityWithState>) {
        setCharities(charities.map(it => it.id === id ? {...it, ...props} : it))
    }

    useRequestEffect<IStatus[]>({
        state: page,
        url: api.status.read_all,
        onSuccess: statuses => setStatusMap(
            statuses.reduce((obj, status) => (
                {...obj, [status.name]: status}
            ), {})
        ),
    })

    useRequestEffect<ICharity[]>({
        state: page,
        url: api.charity.read_all,
        onSuccess: charities => setCharities(charities.map(it => ({
            ...it, state: {
                loading: false,
                status: it.status.name,
            }
        }))),
    }, [lang])

    return (
        <StatefulPage state={page}>
            <Title titleKey={'review_charities'}/>
            <CustomTable
                data={charities}
                columns={[
                    {
                        title: translate('logo'),
                        render: data =>
                            <ImageWithFallback
                                src={data.logo_url}
                                alt={'charity logo'}
                                style={{objectFit: 'contain', height: 48, maxWidth: 96}}
                            />
                    },
                    {
                        title: translate('charity_name'), field: 'name'
                    },
                    {
                        title: translate('owner'),
                        customFilterAndSearch: (filter: string, data) =>
                            data.owner.name.toLowerCase().includes(filter.toLowerCase()),
                        render: data =>
                            <Typography
                                component={Link}
                                className={ownerLink}
                                to={route.to.user(data.owner.id).page}
                                children={data.owner.name}
                            />
                    },
                    {
                        title: translate('status'), field: 'status',
                        render: data => {
                            const status = statusMap[data.state.status]

                            return (
                                <CustomSelect
                                    variant="outlined"
                                    endIcon={<KeyboardArrowDownIcon/>}
                                    style={{color: status?.color}}
                                    items={Object.values(statusMap).map(it => ({
                                        key: it.name,
                                        text: translate(it.name),
                                        color: it.color
                                    }))}
                                    onItemSelect={item => updateCharityState(data.id, {status: item.key})}
                                    children={status && translate(status.name)}
                                />
                            )
                        }
                    },
                    {
                        title: translate('documents'),
                        render: (charity) => <CharityDocumentsPopup {...{charity}}/>
                    },
                    {
                        title: translate('details'),
                        render: data => (
                            <Box display={'flex'}>
                                <Button
                                    variant="outlined"
                                    color={'secondary'}
                                    startIcon={<Visibility/>}
                                    children={translate('view')}
                                    component={Link}
                                    to={route.to.charity(data.id).page}
                                />
                                <Box marginX={1}/>
                                <Button
                                    variant="outlined"
                                    color={'secondary'}
                                    startIcon={<Edit/>}
                                    children={translate('edit')}
                                    component={Link}
                                    to={route.to.charity(data.id).edit}
                                />
                                <Box marginX={1}/>
                                <Button
                                    variant="outlined"
                                    color={'secondary'}
                                    startIcon={<Edit/>}
                                    children={translate('contributions')}
                                    component={Link}
                                    to={route.to.charity(data.id).contributions}
                                />
                            </Box>
                        )
                    },
                ] as Column<CharityWithState>[]}
                actions={[
                    data => {
                        const disabled = data.status.name === data.state.status

                        return {
                            icon: () => data.state.loading ?
                                <CircularProgress color={'primary'} size={24}/> :
                                <Save color={disabled ? 'disabled' : 'secondary'}/>,
                            disabled: disabled || data.state.loading,
                            onClick: () => {
                                makeRequest({
                                    url: api.charity.update_status,
                                    data: {
                                        id: Number(data.id),
                                        status: data.state.status
                                    },
                                    onStart: () => updateCharityState(data.id, {loading: true}),
                                    onSuccess: () => showSnackbar('success', 'charity_edit_success'),
                                    onFail: resultCode => {
                                        const errorMessage = resultCode === 'STATUS_CANNOT_BE_DRAFT'
                                            ? 'new_status_cannot_be_draft' : 'charity_edit_fail'
                                        showSnackbar('error', errorMessage)
                                    },
                                    onError: () => showSnackbar('error', 'cause_update_fail'),
                                    onFinish: success => {
                                        updateCharityProps(data.id, {
                                            status: {
                                                ...data.status,
                                                name: success ? data.state.status : data.status.name
                                            },
                                            state: {
                                                ...data.state,
                                                loading: false
                                            }
                                        })
                                    },
                                }).then()
                            }
                        }
                    }
                ]}
            />
        </StatefulPage>
    )
}

function CharityDocumentsPopup(props: {
    charity: ICharity
}) {
    const {charity} = props
    const classes = useStyles()
    const {translate} = useTranslation()
    const {makeRequest} = useRequest()

    function DocumentButton(props: {
        submitted: boolean
        doc: 'contract' | 'gov_form'
    }) {
        const {submitted, doc} = props

        return (
            <Button
                variant={'outlined'}
                color={'secondary'}
                disabled={!submitted}
                startIcon={submitted ? <GetApp/> : <Clear/>}
                children={translate(submitted ? 'submitted' : 'not_found')}
                onClick={() => {
                    makeRequest<{ url: string }>({
                        url: api.charity.read_doc,
                        data: {
                            charity_id: charity.id,
                            doc: doc,
                        },
                        onSuccess: ({url}) => openInNewTab(url)
                    }).then()
                }}
            />
        )
    }

    function ForwardToPaynetButton() {
        const {showSnackbar} = useAppSnackbar()

        const state = useStatefulPageState()

        return (
            <Button
                startIcon={<Send/>}
                variant={'outlined'}
                disabled={!charity.gov_form_submitted || state.loading}
                color={'secondary'}
                children={translate('send_to_paynet')}
                onClick={() =>
                    makeRequest({
                        url: api.charity.send_to_paynet,
                        state,
                        data: {id: charity.id},
                        onFinish: ok => showSnackbar(ok ? 'success' : 'error', ok ? 'success' : 'error')
                    })
                }
            />
        )
    }

    return (
        <PopupState variant="popover" popupId={`doc-popup-${charity.id}`}>
            {(popupState) => (
                <div>
                    <Button
                        {...bindTrigger(popupState)}
                        variant="outlined"
                        color="secondary"
                        startIcon={<PictureAsPdf/>}
                        children={translate('view')}
                    />
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <Box className={classes.documentsDownloadGrid}>
                            <RichText text={translate('contract')}/>
                            <DocumentButton submitted={charity.contract_submitted} doc={'contract'}/>
                            <div/>
                            <RichText text={translate('gov_form')}/>
                            <DocumentButton submitted={charity.gov_form_submitted} doc={'gov_form'}/>
                            <ForwardToPaynetButton/>
                        </Box>
                    </Popover>
                </div>
            )}
        </PopupState>
    )
}
