import {Box, CircularProgress, Typography} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {AppHelmet} from 'components/util/AppHelmet'
import React from 'react'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequestEffect} from 'utils/request'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spinnerBox: {
            margin: 'auto',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(10)
        },
        textBox: {
            margin: 'auto',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(10)
        },
    }),
)

export function ActivateEmail() {
    const {spinnerBox, textBox} = useStyles()
    const {translate} = useTranslation()
    const page = useStatefulPageState()

    const {code} = useParams<{ code: string }>()

    useRequestEffect({
        url: api.service.email.activate,
        state: page,
        data: {
            code: code
        },
        onSuccess: () => page.setSuccessMessage('email_activation_success'),
        onFail: resultCode => {
            if (resultCode === 'INVALID_ACTIVATION_CODE') {
                page.setErrorMessage('invalid_email_activation_code')
            } else {
                page.setUnexpectedFrontendError()
            }
        },
        onFinish: () => page.setShowChildren(false)

    })

    return (
        <>
            <AppHelmet noIndex/>
            <StatefulPage state={page}>
                <Box display={'flex'} flexDirection={'column'}>
                    <Box className={spinnerBox}>
                        <CircularProgress size={50} color={'secondary'}/>
                    </Box>
                    <Box className={textBox}>
                        <Typography variant={'h5'}>
                            {translate('activating_your_account') + '…'}
                        </Typography>
                    </Box>
                </Box>
            </StatefulPage>
        </>
    )
}
