import {EResult} from '../../../utils/enums'
import {Alert} from '@material-ui/lab'
import React from 'react'
import {ECauseType} from '../../../types/cause'
import {StateMethods} from '@hookstate/core'
import {TranslationKey} from '../../../utils/TranslationKey'
import {useTranslation} from '../../../state/translation'

export enum ResultData {
    result = 'result',
    project = 'project',
}

export function ResultStep(props: {
    resultParam: StateMethods<EResult | null>
    projectParam: StateMethods<ECauseType | null>
}) {
    const {translate} = useTranslation()
    const {resultParam, projectParam} = props

    const successText = ('project_action_result_' + projectParam.value) as TranslationKey
    const resultText = translate(resultParam.value === 'success' ? successText : 'project_action_result_fail')

    return (
        <Alert severity={resultParam.value ?? 'warning'} children={resultText}/>
    )
}

