import {createState} from '@hookstate/core'
import {Persistence} from '@hookstate/persistence'

export function attachPersistence(state: ReturnType<typeof createState>, stateLabel: string) {
    try {
        state.attach(Persistence(stateLabel))
    } catch (e) {
        try {
            localStorage.removeItem(stateLabel)
            state.attach(Persistence(stateLabel))
        } catch (e) {
            console.error(e)
        }
    }
}