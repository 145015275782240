import {Box, Button} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Add} from '@material-ui/icons'
import {CauseCard} from 'components/cards/CauseCard'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {ResponsiveGrid} from 'components/foundation/ResponsiveGrid'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import {ICause} from 'types/cause'
import React from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {useAuth} from 'state/auth'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {NewCauseDialog} from './components/NewCauseDialog'
import {useHookstate} from '@hookstate/core'
import {useLang} from 'state/lang'
import {useUpdater} from '../../../utils/hooks'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        causeCard: {
            width: spacing(35),
        }
    }),
)

export function UserCauses() {
    const {causeCard} = useStyles()
    const {translate} = useTranslation()
    const {lang} = useLang()
    const {id} = useParams<{ id: string }>()
    const {pathname} = useLocation()
    const {auth} = useAuth()
    const {updateFlag, update} = useUpdater()

    const newCauseDialog = useDialogOpenState(undefined)
    const page = useStatefulPageState()

    const causes = useHookstate<ICause[]>([])

    const mode = pathname === route.to.my_causes ? 'user' : 'admin'

    useRequestEffect<ICause[]>({
        url: api.user.read_causes,
        state: page,
        data: {
            user_id: Number(mode === 'user' ? auth?.userID : id)
        },
        onSuccess: causes.set,
        onFail: resultCode => {
            if (resultCode === 'USER_NOT_FOUND' || resultCode === 'UNAUTHENTICATED') {
                page.setShowNotFound(true)
            } else {
                page.setUnexpectedFrontendError()
            }
        }
    }, [lang, updateFlag])

    return (
        <StatefulPage state={page}>
            <AppHelmet noIndex/>
            <AuthorizedContent>
                <Title titleKey={mode === 'user' ? 'my_causes' : 'user_causes'}/>
                <Only when={mode === 'user'}>
                    <NewCauseDialog openState={newCauseDialog} onSuccess={update}/>
                    <Box display="flex" margin={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            size={'large'}
                            style={{margin: 'auto'}}
                            startIcon={<Add/>}
                            onClick={() => newCauseDialog.setOpen(true)}
                            children={translate('create_new')}
                        />
                    </Box>
                </Only>
                <ResponsiveGrid
                    breakpointColumns={{
                        'laptop': 3,
                        'tablet': 2,
                    }}
                    children={
                        causes.value.map(cause =>
                            <CauseCard
                                key={cause.id}
                                cause={cause}
                                className={causeCard}
                                mode={'detailed'}
                            />
                        )
                    }
                />
            </AuthorizedContent>
        </StatefulPage>
    )
}
