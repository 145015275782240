import {Box} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {Alert} from '@material-ui/lab'
import {DialogOpenState, StatefulDialog, useStatefulDialogState} from 'components/foundation/StatefulDialog'
import React from 'react'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequest} from 'utils/request'

export function DeleteCauseDialog(props: {
    cause_id: number,
    openState: DialogOpenState,
    onSuccessExit?: () => void
}) {
    const {cause_id, openState, onSuccessExit} = props
    const dialog = useStatefulDialogState()
    const {makeRequest} = useRequest()

    const {translate} = useTranslation()

    const onDelete = () => makeRequest({
        url: api.cause.delete,
        state: dialog,
        data: {
            id: Number(cause_id)
        },
        onSuccess: () => dialog.setSuccessMessage('cause_delete_success')
    })


    const onClose = () => openState.setOpen(false)

    return (
        <StatefulDialog
            titleKey={'confirm_action'}
            state={dialog}
            openState={openState}
            content={
                <Alert severity={'warning'}>
                    {translate('delete_cause_confirm')}
                </Alert>
            }
            buttons={
                <Box display={'flex'}>
                    <Button onClick={onClose} variant={'outlined'} color="primary">
                        {translate('cancel')}
                    </Button>
                    <Box marginX={1}/>
                    <Button onClick={onDelete} variant={'contained'} color={'primary'}>
                        {translate('delete')}
                    </Button>
                </Box>
            }
            onExit={() => {
                if (dialog.successMessage) {
                    onSuccessExit?.()
                }
            }}
        />
    )
}
