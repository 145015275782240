import {Box, Button, useTheme} from '@material-ui/core'
import {VpnKeyOutlined} from '@material-ui/icons'
import {FormField} from 'components/forms/FormField'
import {useStringField, validateNonEmptyFields} from 'components/foundation/EditableField'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import React from 'react'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useRequest} from 'utils/request'
import {ValidateThat} from 'utils/ValidateThat'

export function ResetPassword() {
    const page = useStatefulPageState()
    const {translate} = useTranslation()
    const theme = useTheme()
    const {makeRequest} = useRequest()

    const {code} = useParams<{ code: string; }>()

    const password = useStringField(StringLength.SM)
    const passwordConfirm = useStringField(StringLength.SM)

    function validateFields() {
        let fieldsAreValid = validateNonEmptyFields(translate, [password, passwordConfirm])

        if (!ValidateThat.passwordIsStrong(password.value)) {
            fieldsAreValid = false
            password.setHint('weak_password_desc')
        }

        if (password.value && passwordConfirm.value && (password.value !== passwordConfirm.value)) {
            fieldsAreValid = false
            passwordConfirm.setHint('passwords_dont_match')
        }

        return fieldsAreValid
    }

    function onReset() {
        makeRequest({
            url: api.service.password.reset,
            state: page,
            if: validateFields(),
            data: {
                code: code,
                password: password.value
            },
            onStart: () => page.clearMessages(),
            onSuccess: () => {
                page.setShowChildren(false)
                page.setSuccessMessage('password_reset_success')
            },
            onFail: resultCode => {
                if (resultCode === 'INVALID_RESET_CODE') {
                    page.setErrorMessage('invalid_password_reset_code')
                } else {
                    page.setUnexpectedFrontendError()
                }
            }
        }).then()
    }

    return (
        <StatefulPage state={page}>
            <AppHelmet noIndex/>
            <Box
                marginX={'auto'}
                marginY={2}
                width={'100%'}
                maxWidth={theme.breakpoints.values.mobile}
            >
                <form id={'reset-password'}>
                    <Title titleKey={'reset_password'} marginBottom={2}/>
                    <input id={'username'} hidden={true} type={'text'} autoComplete={'username'}/>
                    <FormField
                        type={'password'}
                        label={'password'}
                        autoComplete={'new-password'}
                        icon={<VpnKeyOutlined/>}
                        field={password}
                    />
                    <FormField
                        type={'password'}
                        label={'confirm_password'}
                        autoComplete={'new-password'}
                        icon={<VpnKeyOutlined/>}
                        field={passwordConfirm}
                        onEnter={onReset}
                    />
                    <Box marginY={2}/>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        children={translate('reset')}
                        style={{float: 'right'}}
                        onClick={onReset}
                    />
                </form>
            </Box>
        </StatefulPage>
    )
}
