import React, {PropsWithChildren} from 'react'
import {useAuth} from 'state/auth'
import {useUser} from 'state/user'
import {NotFound} from '../mini-pages/NotFound'

/**
 * Components that renders its children if the user is
 * logged in and is either admin or satisfies the provided condition
 */
export function AuthorizedContent(props: PropsWithChildren<{
    when?: boolean
}>) {
    const {children, when} = props
    const {user} = useUser()
    const {auth} = useAuth()

    return auth && user && (when === undefined || when) ? <>{children}</> : <NotFound/>
}
