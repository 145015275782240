import {responsiveFontSizes, createTheme} from '@material-ui/core/styles'

declare module '@material-ui/core/styles/createBreakpoints' {
    interface BreakpointOverrides {
        mobile: true;
        tablet: true;
        laptop: true;
        desktop: true;
    }
}

export const theme = responsiveFontSizes(createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
            // Breakpoints above are a temp fix until responsive font for custom breakpointColumns is fixed
            mobile: 480,
            tablet: 768,
            laptop: 1024,
            desktop: 1280
        },
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        },
    },
    palette: {
        primary: {
            main: '#f6a71c',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main: '#0d9ddb',
        },
        error: {
            main: '#f44336'
        }
    },
}))