import {createState, DevTools, useState} from '@hookstate/core'
import {Persistence} from '@hookstate/persistence'

const stateLabel = 'auth'

export interface Auth {
    token: string
    userID: number
}

const globalState = createState<Auth | null>(null)
globalState.attach(Persistence(stateLabel))

export const getAuth = () => globalState.get()
export const setAuth = (auth: Auth | null) => globalState.set(auth)

export function useAuth() {
    const state = useState(globalState)

    return {
        auth: state.value,
        setAuth: (auth: Auth | null) => state.set(auth),
    }
}

DevTools(globalState).label(stateLabel)
