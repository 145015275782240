import {Box, Card, Typography} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Delete, PanoramaOutlined, Publish} from '@material-ui/icons'
import React, {CSSProperties, useCallback, useState} from 'react'
import Cropper from 'react-easy-crop'
import ImageUploading, {ImageListType} from 'react-images-uploading'
import {useTranslation} from 'state/translation'
import {IPixelCrop} from 'utils/image_util'
import {TranslationKey} from 'utils/TranslationKey'
import {EditableField, ImageBase64Field} from '../foundation/EditableField'
import {ImageWithFallback} from '../misc/ImageWithFallback'
import {RichText} from '../misc/RichText'
import {Only} from '../util/Only'

const useStyles = makeStyles(({spacing, palette, breakpoints}: Theme) =>
    createStyles({
        imageArea: {
            flexDirection: 'column',
            [breakpoints.up('mobile')]: {
                flexDirection: 'row',
            }
        },
        hint: {
            paddingLeft: spacing(2),
            paddingTop: spacing(.5),
            color: palette.error.main,
            lineHeight: 1.66,
            letterSpacing: '0.03333em',
            fontSize: '0.75rem'
        },
        imageCard: {
            position: 'relative',
            width: '100%',
            height: '512px',
        }
    })
)


export function ImageField(props: {
    field: ImageBase64Field
    aspectRatio?: number,
    disableCropper?: boolean,
    className?: string,
    disableDelete?: boolean,
    label?: TranslationKey,
    preferredDimensions?: string,
    background?: CSSProperties['background']
}) {
    const {translate} = useTranslation()
    const {imageArea, hint, imageCard} = useStyles()
    const [images, setImages] = React.useState<ImageListType>([])

    const [crop, setCrop] = useState({x: 0, y: 0})
    const [zoom, setZoom] = useState(1)

    function onImageChange(imageList: ImageListType) {
        setImages(imageList)
        props.field.setValue(imageList[0]?.dataURL ?? '')
        if (!imageList[0]?.dataURL) {
            props.field.setValue('')
        }
    }

    const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels: IPixelCrop) => {
        props.field.setPixelCrop(croppedAreaPixels)
    }, [props.field])


    return (
        <EditableField field={props.field}>
            <Box className={props.className} width={'100%'} marginY={1}>
                <ImageUploading
                    value={images}
                    onChange={onImageChange}
                    multiple={false}
                    acceptType={['jpg', 'jpeg', 'png', 'svg']}
                    maxFileSize={5 * 1024 * 1024 /* 5 MB */}
                >
                    {({onImageUpload, onImageUpdate, onImageRemove}) =>
                        <Box marginY={1}>
                            <Only when={{label: props.label}}>{({label}) =>
                                <Typography variant={'subtitle2'} children={translate(label)}/>
                            }</Only>
                            <Only when={!!props.field.value}>
                                <Box display={'flex'} className={imageArea}>
                                    <Box flex={1}>
                                        <Card className={imageCard}>{
                                            props.disableCropper ?
                                                <ImageWithFallback
                                                    src={props.field.value}
                                                    style={{
                                                        background: props.background,
                                                        objectFit: 'contain'
                                                    }}
                                                /> :
                                                <Cropper
                                                    image={props.field.value}
                                                    crop={crop}
                                                    zoom={zoom}
                                                    zoomSpeed={0.1}
                                                    aspect={props.aspectRatio ?? 1}
                                                    onCropChange={setCrop}
                                                    onCropComplete={onCropComplete}
                                                    onZoomChange={setZoom}
                                                />
                                        }</Card>
                                    </Box>
                                    <Box margin={1}/>
                                    <Box display="flex" flexDirection={'column'}>
                                        <Button
                                            fullWidth={true}
                                            disabled={props.field.disabled}
                                            startIcon={<Publish/>}
                                            variant={'contained'}
                                            color={'primary'}
                                            onClick={() => onImageUpdate(0)}
                                            children={translate('upload')}
                                        />
                                        <Box marginY={1}/>
                                        <Button
                                            fullWidth={true}
                                            disabled={props.field.disabled || props.disableDelete}
                                            startIcon={<Delete/>}
                                            variant={'outlined'}
                                            color={'secondary'}
                                            onClick={() => onImageRemove(0)}
                                            children={translate('remove')}
                                        />
                                    </Box>
                                </Box>
                            </Only>
                            <Only when={!props.field.value}>
                                <Box display="flex" justifyContent={'space-between'} className={imageArea}>
                                    <Card style={{flex: 1, height: 300}}>
                                        <PanoramaOutlined style={{
                                            color: 'gray',
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '100%'
                                        }}/>
                                    </Card>
                                    <Box margin={1}/>
                                    <Box>
                                        <Button
                                            disabled={props.field.disabled}
                                            variant={'contained'}
                                            color={'primary'}
                                            style={{margin: 'auto', flex: 1}}
                                            onClick={onImageUpload}
                                            children={translate('upload')}
                                        />
                                    </Box>
                                </Box>
                            </Only>
                        </Box>
                    }
                </ImageUploading>
                <Only when={props.preferredDimensions}>
                    <RichText
                        size={'small'}
                        text={`${translate('preferred_image_dimensions')} ${(props.preferredDimensions)}`}
                    />
                </Only>
                <RichText
                    size={'small'}
                    text={`${translate('max_image_size')} 5MB`}
                />
                <Typography className={hint}>{props.field.hint}</Typography>
            </Box>
        </EditableField>
    )
}
