import {Theme, useMediaQuery} from '@material-ui/core'
import useMutationObserver from '@rooks/use-mutation-observer'
import {useSnackbar, VariantType} from 'notistack'
import {DependencyList, useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {TranslationKey} from './TranslationKey'

export type Message = (() => string) | TranslationKey
export type MessageCustom = (() => string) | string

export function useStatefulComponent() {
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState<Message>()
    const [successMessageCustom, setSuccessMessageCustom] = useState<Message>()
    const [successMessageString, setSuccessMessageString] = useState<string>()
    const [infoMessage, setInfoMessage] = useState<Message>()
    const [warnMessage, setWarnMessage] = useState<Message>()
    const [errorMessage, setErrorMessage] = useState<Message>()
    const [errorMessageCustom, setErrorMessageCustom] = useState<string>()
    const {translate} = useTranslation()

    function toString(message?: Message) {
        if (message) {
            return message instanceof Function ? message() : translate(message)
        } else {
            return undefined
        }
    }

    return {
        loading: loading,
        successMessage: toString(successMessage),
        successMessageCustom: toString(successMessageCustom),
        successMessageString: successMessageString,
        infoMessage: toString(infoMessage),
        warnMessage: toString(warnMessage),
        errorMessage: toString(errorMessage),
        errorMessageCustom: errorMessageCustom,

        setLoading: setLoading,
        setSuccessMessage: setSuccessMessage,
        setSuccessMessageCustom: setSuccessMessageCustom,
        setSuccessMessageString: setSuccessMessageString,
        setInfoMessage: setInfoMessage,
        setWarnMessage: setWarnMessage,
        setErrorMessage: setErrorMessage,
        setErrorMessageCustom: setErrorMessageCustom,

        // Special cases
        startLoading: () => setLoading(true),
        stopLoading: () => setLoading(false),

        setUnexpectedFrontendError: () => setErrorMessage('unexpected_frontend_error'),
        setUnexpectedBackendError: () => setErrorMessage('unexpected_backend_error'),

        clearMessages: () => {
            setSuccessMessage(undefined)
            setSuccessMessageCustom(undefined)
            setSuccessMessageString(undefined)
            setInfoMessage(undefined)
            setWarnMessage(undefined)
            setErrorMessage(undefined)
            setErrorMessageCustom(undefined)
        }
    }
}

export type StatefulComponent = ReturnType<typeof useStatefulComponent>

export function useScrollToTopEffect(deps?: DependencyList) {
    useEffect(() => window.scrollTo({top: 0, behavior: 'auto'}), deps)
}

export const useMobileSize = () => !useMediaQuery<Theme>(theme => theme.breakpoints.up('mobile'))
export const useTabletSize = () => !useMediaQuery<Theme>(theme => theme.breakpoints.up('tablet'))
export const useLaptopSize = () => !useMediaQuery<Theme>(theme => theme.breakpoints.up('laptop'))

export function useQueryParam(param: string) {
    return new URLSearchParams(useLocation().search).get(param)
}

export function useBodyStyle() {
    const body = useRef(document.body)

    const [paddingRight, setPaddingRight] = useState('')
    const [width, setWidth] = useState('')

    useMutationObserver(body, () => {
        setPaddingRight(document.body.style.paddingRight)
        setWidth(`calc(100% + ${document.body.style.paddingRight || '0px'})`)
    }, {attributeFilter: ['style']})


    return {
        style: {
            width: width,
            paddingRight: paddingRight,
        }
    }
}

export function useAppSnackbar() {
    const {enqueueSnackbar} = useSnackbar()
    const {translate} = useTranslation()

    return {
        showSnackbar: (variant: VariantType, text: TranslationKey) =>
            enqueueSnackbar(translate(text), {
                variant: variant,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            })
    }
}

export function useAppSnackbarCustom() {
    const {enqueueSnackbar} = useSnackbar()

    return {
        showSnackbarCustom: (variant: VariantType, text: string) =>
            enqueueSnackbar(text, {
                variant: variant,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            })
    }
}

export function useDebouncer(cooldown = 0) {
    const [lastChangeTime, setLastChangeTime] = useState(Date.now())

    function isReady() {
        if (Date.now() - lastChangeTime > cooldown) {
            setLastChangeTime(Date.now())
            return true
        } else {
            return false
        }
    }

    return {isReady}
}

export function useUpdater() {
    const [updateFlag, setUpdateFlag] = useState(0)

    return {
        updateFlag: updateFlag,
        update: () => setUpdateFlag(updateFlag + 1)
    }
}
