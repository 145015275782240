import {Column} from '@material-table/core'
import {Button} from '@material-ui/core'
import {JsonDialog} from 'components/dialogs/JsonDialog'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {CustomTable} from 'components/misc/CustomTable'
import {Title} from 'components/misc/Title'
import {IPaymentLog} from 'types/types'
import React, {useState} from 'react'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {useUpdater} from 'utils/hooks'
import {useRequestEffect} from 'utils/request'

export function PaymentLogs() {
    const {user} = useUser()
    const {translate} = useTranslation()
    const page = useStatefulPageState()
    const dialogOpenState = useDialogOpenState(undefined)

    const [logObject, setLogObject] = useState<Record<string, unknown>>({})
    const [logs, setLogs] = useState<IPaymentLog[]>([])

    const {update, updateFlag} = useUpdater()

    useRequestEffect<IPaymentLog[]>({
        url: api.service.payment.read_logs,
        state: page,
        onSuccess: setLogs
    }, [updateFlag])

    return (
        <StatefulPage state={page}>
            <AuthorizedContent when={user?.is_super_admin}>
                <JsonDialog openState={dialogOpenState} logObject={logObject}/>
                <Title titleKey={'payment_logs'}/>
                <CustomTable
                    data={logs}
                    title={
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            children={translate('refresh')}
                            onClick={update}
                        />
                    }
                    columns={[
                        {title: 'ID', field: 'id', defaultSort: 'desc'},
                        {title: translate('date_and_time'), field: 'time'},
                        {title: translate('event'), field: 'event'},
                        {
                            title: translate('data'),
                            render: data =>
                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    children={translate('view')}
                                    onClick={() => {
                                        setLogObject(data.data)
                                        dialogOpenState.setOpen(true)
                                    }}
                                />
                        }
                    ] as Column<IPaymentLog>[]}
                />
            </AuthorizedContent>
        </StatefulPage>
    )
}
