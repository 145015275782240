export type ENVIRONMENT_VARIABLE =
    | 'ENVIRONMENT'
    | 'BACKEND'
    | 'GA_MEASUREMENT_ID'
    | 'FACEBOOK_APP_ID'
    | 'PROJECT_NAME'
    | 'SUPPORT_EMAIL'
    | 'RECAPTCHA_SITE_KEY'

export function getVariable(key: ENVIRONMENT_VARIABLE): string {
    const value = process.env['REACT_APP_' + key]
    if (value) {
        return value
    }
    throw new Error(`Environment variable '${key}' is not set`)
}


// noinspection JSUnusedGlobalSymbols
export function isInDevelopment(): boolean {
    return getVariable('ENVIRONMENT') === 'development'
}

// noinspection JSUnusedGlobalSymbols
export function isInPreview(): boolean {
    return getVariable('ENVIRONMENT') === 'preview'
}

export function isInProduction(): boolean {
    return getVariable('ENVIRONMENT') === 'production'
}
