import {Box, Button} from '@material-ui/core'
import {Close, Done} from '@material-ui/icons'
import {Alert} from '@material-ui/lab'
import {AlertProps} from '@material-ui/lab/Alert/Alert'
import React from 'react'
import {useTranslation} from 'state/translation'
import {TranslationKey} from 'utils/TranslationKey'
import {DialogOpenState, StatefulDialog, useStatefulDialogState} from '../foundation/StatefulDialog'

export function ConfirmationDialog<T>(props: {
    openState: DialogOpenState<T | undefined>
    text: TranslationKey
    onOk: (data: T | undefined) => void
    onCancel?: (data: T | undefined) => void
    titleKey?: TranslationKey
    severity?: AlertProps['color']
}) {
    const {openState, titleKey, text, severity, onOk, onCancel,} = props
    const dialog = useStatefulDialogState()

    const {translate} = useTranslation()

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const clickHandler = (callback: typeof onOk = () => { }) => () => {
        callback(openState.data)
        openState.setOpen(false)
    }

    return (
        <StatefulDialog
            state={dialog}
            openState={openState}
            titleKey={titleKey ?? 'confirm_action'}
            content={
                <Alert
                    severity={severity ?? 'info'}
                    children={translate(text)}
                />
            }
            buttons={
                <Box display={'flex'} alignItems={'end'}>
                    <Button
                        color={'secondary'}
                        startIcon={<Close/>}
                        children={translate('cancel')}
                        onClick={clickHandler(onCancel)}
                    />
                    <Box margin={1}/>
                    <Button
                        color={'secondary'}
                        startIcon={<Done/>}
                        children={translate('ok')}
                        onClick={clickHandler(onOk)}
                    />
                </Box>
            }
        />
    )
}
