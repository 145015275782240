import {createStyles, makeStyles} from '@material-ui/core/styles'
import {AppHelmet} from 'components/util/AppHelmet'
import {ValidArticleResource} from 'pages/admin/website-management/component/ArticleEditor'
import React, {useState} from 'react'
import ReactMarkdown from 'react-markdown'
import {remark} from 'remark'
import {useLang} from 'state/lang'
import {useTranslation} from 'state/translation'
import strip from 'strip-markdown'
import {api} from 'utils/api'
import {useRequestEffect} from 'utils/request'
import {StatefulPage, useStatefulPageState} from './StatefulPage'
import {YoutubeEmbed} from '../misc/YoutubeEmbed'
import {extractYoutubeId} from '../../utils/general'

const useStyles = makeStyles(({typography}) =>
    createStyles({
        md: {
            fontSize: typography.fontSize * 1.25,
        }
    })
)

export function Article(props: {
    resourceId: ValidArticleResource
    mdClassName?: string
}) {
    const {resourceId, mdClassName} = props
    const [text, setText] = useState('')
    const page = useStatefulPageState()
    const {md} = useStyles()
    const {lang} = useLang()
    const {translate} = useTranslation()

    useRequestEffect({
        state: page,
        url: api.article.read,
        data: {
            resource_id: resourceId,
            lang: lang
        },
        onSuccess: setText
    }, [lang])

    return (
        <>
            <AppHelmet
                title={translate(resourceId)}
                // We don't want Markdown tags to be shown in search results, hence we strip them here
                description={String(remark().use(strip).processSync(text).value).substring(0, 200)}
            />
            <StatefulPage state={page}>
                <ReactMarkdown
                    className={[md, mdClassName].join(' ')}
                    components={{
                        a: function ({className, children, href}) {
                            return children == 'yt-embed' && href ?
                                <YoutubeEmbed id={extractYoutubeId(href)}/> :
                                <a className={className} href={href} children={children}/>
                        }
                    }}
                    children={text}
                />
            </StatefulPage>
        </>
    )
}
