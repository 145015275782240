import {Button, createStyles, Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {CauseCard} from 'components/cards/CauseCard'
import {SkeletonCard} from 'components/cards/SkeletonCard'
import {CardSlider} from 'components/foundation/CardSlider'
import {Only} from 'components/util/Only'
import {ICause} from 'types/cause'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {useLang} from 'state/lang'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        featuredCauses: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(2),
        },
        carousel: {
            minHeight: 432, // A hack to fix the jitter
            maxWidth: 1120,
            [theme.breakpoints.down('tablet')]: {maxWidth: 750},
            [theme.breakpoints.down('mobile')]: {maxWidth: 500},

            // Since we cannot directly pass className to carousel's slider, we have to do this
            '& .rec-carousel-item > *': {
                height: '100%'
            }
        },
        card: {
            width: 300,
            maxWidth: 300,
            // height: 400,
            // minHeight: 400,
        },
        button: {
            margin: '32px auto',
            borderColor: theme.palette.primary.main,
            color: '#222'
        }
    })
)

export const FeaturedCauses = () => {
    const {featuredCauses, carousel, card, button} = useStyles()
    const [causes, setCauses] = useState<ICause[]>()
    const [loading, setLoading] = useState(true)
    const {translate} = useTranslation()
    const {lang} = useLang()

    useRequestEffect<ICause[]>({
        url: api.featured_causes.read,
        onStart: () => setLoading(true),
        onSuccess: setCauses,
        onFinish: () => setLoading(false),
    }, [lang])

    return (
        <Only when={loading || (causes && causes.length > 0)}>
            <div className={featuredCauses}>
                <CardSlider
                    className={carousel}
                    breakpoints={[
                        {width: 0, itemsToShow: 1},
                        {width: 600, itemsToShow: 2},
                        {width: 900, itemsToShow: 3},
                    ]}
                    children={
                        loading ?
                            [1, 2, 3].map(index => <SkeletonCard className={card} key={index}/>) :
                            causes?.map(cause =>
                                <CauseCard
                                    className={card}
                                    key={cause.id}
                                    mode={'normal'}
                                    cause={cause}
                                />
                            )

                    }
                />
                <Button
                    className={button}
                    color="primary"
                    variant="outlined"
                    size="large"
                    component={Link}
                    to={route.to.causes({state: 'active'})}
                    children={translate('show_more')}
                />
            </div>
        </Only>
    )
}
