import {useTranslation} from '../../../state/translation'
import {Box, FormControl, FormControlLabel, FormLabel, Icon, Radio, RadioGroup} from '@material-ui/core'
import React from 'react'
import {StateMethods} from '@hookstate/core'
import {TranslationKey} from '../../../utils/TranslationKey'
import {Only} from '../../../components/util/Only'
import {StatefulDialogState} from '../../../components/foundation/StatefulDialog'
import {StringField} from 'components/foundation/EditableField'
import {RichText} from '../../../components/misc/RichText'
import {api} from '../../../utils/api'
import {useRequestEffect} from '../../../utils/request'
import {PaymentMethodType} from '../../../types/types'
import {ICauseType} from '../../../types/cause'

enum SupportStep {
    Result,
    ContactInfo,
    Consent,
    PaymentMethod,
    SmsCheck,
    SmsCheckRequest,
    SmsVerify,
    SmsVerifyRequest,
    SmsCheckRequestAuth,
    SmsVerifyAuth,
    SmsVerifyRequestAuth,
    AuthPhoneCheck,
    TotalDonationCheck,
    AgeVerificationCheck,
    CompetitionInfo,
    LoadingCompetition,
    LoadingCustom,
    Loading
}

export function SmsCheckPostAuth(props: {
    dialog: StatefulDialogState
    amount: number | null
    causeId: number
    sms_phone: StringField
    ref_no: StringField
    paymentMethod: PaymentMethodType
    project: ICauseType
    currentStep:any
}) {
    const {translate} = useTranslation()

    useRequestEffect({
        state: props.dialog,
        url: api.user.otp_phone_verify_auth,
        data: {
                payment_method: props.paymentMethod,
                cause_id: props.causeId,
                amount: props.amount,
                payment_project: props.project,
                phone_number: props.sms_phone.value
            },
        onSuccess: (data: { ref: string }) => {
            props.ref_no.setValue(data.ref)
            props.currentStep.set(SupportStep.SmsVerifyAuth)
        },
        onFail: () => {
            props.dialog.setErrorMessage('verify_phone_error_msg')
            props.currentStep.set(SupportStep.ContactInfo)
        },
        onError: () => {
            props.dialog.setUnexpectedFrontendError()
            props.currentStep.set(SupportStep.ContactInfo)
        },
    })

    return (
        <Only when={props.dialog.loading}>
            <RichText text={translate('processing_your_request')}/>
        </Only>
    )
}
