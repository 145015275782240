import {Box, Button} from '@material-ui/core'
import {VpnKeyOutlined, VpnKeyTwoTone} from '@material-ui/icons'
import {FormField} from 'components/forms/FormField'
import {useStringField} from 'components/foundation/EditableField'
import {DialogOpenState, StatefulDialog, useStatefulDialogState} from 'components/foundation/StatefulDialog'
import {CustomDivider} from 'components/misc/CustomDivider'
import React from 'react'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useRequest} from 'utils/request'
import {ValidateThat} from 'utils/ValidateThat'

export function ChangePasswordDialog(props: { openState: DialogOpenState }) {
    const {openState} = props
    const {translate} = useTranslation()
    const {user} = useUser()
    const {makeRequest} = useRequest()

    const dialog = useStatefulDialogState()

    const password = useStringField(StringLength.SM)
    const newPassword = useStringField(StringLength.SM)
    const newPasswordConfirmation = useStringField(StringLength.SM)
    const fields = [password, newPassword, newPasswordConfirmation]

    const onSubmit = () => makeRequest({
        if: [
            password.validate(),
            newPassword.validate(ValidateThat.passwordIsStrong, 'weak_password_desc'),
            newPasswordConfirmation.validate(value => newPassword.value === value, 'passwords_dont_match')
        ].every(Boolean),
        url: api.auth.change_password,
        state: dialog,
        data: {
            email: user?.email,
            ...password.box('current_password'),
            ...newPassword.box('new_password')
        },
        onSuccess: () => dialog.setSuccessMessage('password_change_success'),
        onFail: resultCode => {
            dialog.setErrorMessage(
                resultCode === 'INVALID_CREDENTIALS' ? 'incorrect_current_password' :
                    resultCode === 'WEAK_PASSWORD' ? 'weak_password_desc' :
                        'unexpected_frontend_error'
            )
        }
    })


    function onClose() {
        openState.setOpen(false)
    }

    return (
        <StatefulDialog
            state={dialog}
            openState={openState}
            titleKey={'change_password'}
            content={
                <div>
                    <FormField
                        label={'current_password'}
                        type={'password'}
                        field={password}
                        icon={<VpnKeyTwoTone/>}
                    />
                    <CustomDivider marginBottom={2}/>
                    <FormField
                        label={'new_password'}
                        type={'password'}
                        field={newPassword}
                        icon={<VpnKeyOutlined/>}
                    />
                    <FormField
                        label={'confirm_new_password'}
                        type={'password'}
                        field={newPasswordConfirmation}
                        icon={<VpnKeyOutlined/>}
                        onEnter={onSubmit}
                    />
                </div>
            }
            buttons={
                <Box display={'flex'}>
                    <Button onClick={onClose} color="primary" variant={'outlined'}>
                        {translate('cancel')}
                    </Button>
                    <Box marginX={1}/>
                    <Button onClick={onSubmit} color="primary" variant={'contained'}>
                        {translate('submit')}
                    </Button>
                </Box>
            }
            fields={fields}
        />
    )
}
