import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {AppHelmet} from 'components/util/AppHelmet'
import {IHome} from 'types/types'
import React, {useState} from 'react'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {Banner} from './components/banner/Banner'
import {Categories} from './components/Categories'
import {FeaturedCauses} from './components/FeaturedCauses'
import {Stats} from './components/Stats'
import {Volunteer} from './components/Volunteer'

export function Home() {
    const [volunteerBgURL, setVolunteerBgURL] = useState('')
    const [videoOne, setVideoOne] = useState('')
    const [videoTwo, setVideoTwo] = useState('')
    const [posterOne, setPosterOne] = useState('')
    const [posterTwo, setPosterTwo] = useState('')
    const page = useStatefulPageState()

    const {translate} = useTranslation()

    useRequestEffect<IHome>({
        url: api.home.page,
        state: page,
        onStart: () => {
            page.setShowChildren(false)
        },
        onSuccess: data => {
            setVolunteerBgURL(data.images.volunteer_bg)
            setVideoOne(data.videos.vid_1)
            setVideoTwo(data.videos.vid_2)
            setPosterOne(data.videos.poster_1)
            setPosterTwo(data.videos.poster_2)
            page.setShowChildren(true)
        },
    }, [])

    return (
        <StatefulPage state={page} usePadding={false} style={{paddingTop: 0, paddingBottom: 0}}>
            <AppHelmet
                title={translate('home_page')}
                description={translate('home_meta_desc')}
                canonicalRoute={route.to.home}
            />
            <main>
                <Banner vidOne={videoOne} vidTwo={videoTwo} posOne={posterOne} posTwo={posterTwo}  />
                <FeaturedCauses/>
                <Stats/>
                <Categories/>
                <Volunteer backgroundURL={volunteerBgURL}/>
            </main>
        </StatefulPage>
    )
}
