import {AppBar, Box, Button, ButtonBase, ButtonGroup, IconButton, Toolbar} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import {SearchBar} from 'app/navigation-header/SearchBar'
import {LoginDialog} from 'components/dialogs/LoginDialog'
import {GbbContainer} from 'components/foundation/GbbContainer'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {Only} from 'components/util/Only'
import React, {useEffect, useState} from 'react'
import AnimateHeight from 'react-animate-height'
import {Link, useLocation} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useBodyStyle, useLaptopSize, useMobileSize, useTabletSize} from 'utils/hooks'
import {route} from 'utils/route'
import {responsivePadding} from 'utils/styles'
import {TranslationKey} from 'utils/TranslationKey'
import {AuthButtons} from './AuthButtons'
import {AuthMenuButton} from './AuthMenuButton'
import siteLogo from './site-logo.png'
import {UserButton} from './UserButton'
import {useAuth} from 'state/auth'
import {useLang} from 'state/lang'

const useStyles = makeStyles(({palette, spacing, breakpoints}) =>
    createStyles({
        appbar: {
            position: 'relative',
            background: palette.secondary.main,
            paddingTop: spacing(1),
            paddingBottom: spacing(1),
        },
        toolbar: {
            ...responsivePadding({spacing, breakpoints}),
            flexGrow: 1,
            overflowX: 'auto',
            overflowY: 'hidden',
            minHeight: spacing(8),
        },
        searchField: {
            maxWidth: spacing(35),
            marginRight: spacing(2)
        },
        searchFieldMobile: {
            width: '100%',
            maxWidth: spacing(40),
            margin: 'auto',
        },
        navButtons: {
            display: 'flex',
            '& *': {
                borderColor: 'rgba(255,255,255,0.75) !important',
                whiteSpace: 'nowrap',
            },
        },
        logoContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        logo: {
            textDecoration: 'none',
            fontStyle: 'italic',
            color: palette.primary.contrastText,
            '& :hover': {
                color: palette.primary.main
            }
        },
        logoImg: {
            width: 'auto',
            height: spacing(6),
            [breakpoints.up('mobile')]: {
                height: spacing(7),
            }
        },
        expandingDrawer: {
            ...responsivePadding({spacing, breakpoints}),
            [breakpoints.up('laptop')]: {
                display: 'none'
            }
        },
    }),
)

export function NavigationHeader() {
    const classes = useStyles()
    const {translate} = useTranslation()
    const [openDrawer, setOpenDrawer] = useState(false)
    const location = useLocation()
    const mobile = useMobileSize()
    const tablet = useTabletSize()
    const laptop = useLaptopSize()
    const {auth} = useAuth()

    const loginDialog = useDialogOpenState(undefined)

    const {lang} = useLang()

    let navItems: Record<string, TranslationKey>

    if(lang === "tr"){
        navItems = {
            [route.to.about_us]: 'about_us',
            [route.to.charities]: 'charities',
            [route.to.causes({state: 'active'})]: 'donate',
            //[route.to.cause(149).page]: 'join_competition_menu',
            //[auth ? route.to.my_causes : route.to.sign_up.page]: 'home_banner_button',
            [route.to.news]: 'news',
            [route.to.faq]: 'faq',
        }
    }
    else{
        navItems = {
            [route.to.about_us]: 'about_us',
            [route.to.charities]: 'charities',
            [route.to.causes({state: 'active'})]: 'donate',
            //[route.to.cause(149).page]: 'join_competition_menu',
            //[auth ? route.to.my_causes : route.to.sign_up.page]: 'home_banner_button',
            [route.to.faq]: 'faq',
        }
    }
    // const navItems: Record<string, TranslationKey> = {
    //     [route.to.about_us]: 'about_us',
    //     [route.to.charities]: 'charities',
    //     [route.to.causes({state: 'active'})]: 'donate',
    //     [auth ? route.to.my_causes : route.to.sign_up.page]: 'home_banner_button',
    //     [route.to.news]: 'news',
    //     [route.to.faq]: 'faq',
    // }

    const NavButtons = () => (
        <div className={classes.navButtons}>
            <ButtonGroup
                variant="text"
                size="large"
                style={mobile ? {
                    flex: 1
                } : {
                    margin: 'auto'
                }}
                orientation={mobile ? 'vertical' : 'horizontal'}
                children={
                    // Object.entries(navItems).map(([path, label]) =>
                    //     <Button
                    //         key={label}
                    //         onClick={() => setOpenDrawer(false)}
                    //         component={Link}
                    //         to={path}
                    //         style={mobile ? {
                    //             height: 48
                    //         } : undefined}
                    //         size={mobile ? 'large' : 'medium'}
                    //         color="inherit"
                    //         children={translate(label)}
                    //     />
                    // )
                    Object.entries(navItems).map(([path, label]) =>
                        <Box key={label}>
                            <Only when = {label == 'join_competition_menu'}>
                                <a className={'static-menu-link'} href='https://www.gonuldenbagliyiz.biz/cause/149'>
                                    <Button
                                        key={label}
                                        onClick={() => setOpenDrawer(false)}
                                        //component={Link}
                                        //to={path}
                                        style={mobile ? {
                                            height: 48,
                                            color: 'white'
                                        } : {color: 'white'}}
                                        size={mobile ? 'large' : 'medium'}
                                        color="inherit"
                                        children={translate(label)}
                                    />
                                </a>
                            </Only>
                            <Only when = {label != 'join_competition_menu'}>
                                <Button
                                    key={label}
                                    onClick={() => setOpenDrawer(false)}
                                    component={Link}
                                    to={path}
                                    style={mobile ? {
                                        height: 48
                                    } : undefined}
                                    size={mobile ? 'large' : 'medium'}
                                    color="inherit"
                                    children={translate(label)}
                                />
                            </Only>
                        </Box>
                    )
                }
            />
        </div>
    )

    useEffect(() => {
        setOpenDrawer(false)
    }, [location])

    return (
        <AppBar
            className={classes.appbar}
            {...useBodyStyle()}
            elevation={2}
            position="static"
        >
            <GbbContainer>
                <LoginDialog openState={loginDialog}/>
                <Toolbar className={classes.toolbar}>
                    <Only when={laptop}>
                        <IconButton
                            edge="start" color="inherit"
                            onClick={() => setOpenDrawer(!openDrawer)}>
                            <MenuIcon/>
                        </IconButton>
                        <Box marginRight={1}/>
                    </Only>
                    <ButtonBase>
                        <Link className={classes.logo} to={route.to.home}>
                            <img alt="site logo" className={classes.logoImg} src={siteLogo}/>
                        </Link>
                    </ButtonBase>
                    <Box marginRight={1}/>
                    <Only when={!laptop}>
                        <NavButtons/>
                    </Only>
                    <Box flex={1}/>
                    <Only when={!tablet}>
                        <SearchBar className={classes.searchField}/>
                    </Only>
                    <Only when={mobile}><AuthMenuButton loginDialog={loginDialog}/></Only>
                    <Only when={!mobile}><AuthButtons loginDialog={loginDialog}/></Only>
                    <UserButton showDrawer={setOpenDrawer}/>
                </Toolbar>
                <AnimateHeight
                    className={classes.expandingDrawer}
                    duration={250}
                    height={openDrawer ? 'auto' : 0}
                >
                    <Box marginY={1}/>
                    <Only when={tablet}>
                        <SearchBar className={classes.searchFieldMobile}/>
                    </Only>
                    <Box marginY={2}>
                        <NavButtons/>
                    </Box>
                </AnimateHeight>
            </GbbContainer>
        </AppBar>
    )
}
