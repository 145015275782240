import {Box, Button} from '@material-ui/core'
import {DialogOpenState, StatefulDialog, useStatefulDialogState} from 'components/foundation/StatefulDialog'
import React from 'react'
import {useTranslation} from 'state/translation'
import {ICompetitionItem} from 'types/types'

export function CompetitionInfoDialog(props: { openState: DialogOpenState<ICompetitionItem> }) {
    const {openState} = props
    const { photo_text, image_path} = openState.data
    const {translate} = useTranslation()
    

    const dialog = useStatefulDialogState()

    function onClose() {
        openState.setOpen(false)
    }

    return (
        <StatefulDialog
            state={dialog}
            openState={openState}
            titleKey={'competition_submission_details'}
            content={
                <div>
                    <div className={'competition-details-wrap'}>
                        <img src={image_path}  />
                        <p>{photo_text}</p>
                    </div>
                </div>
            }
            buttons={
                <Box display={'flex'}>
                    <Button onClick={onClose} color="primary" variant={'contained'}>
                        {translate('close')}
                    </Button>
                </Box>
            }
        />
    )
}
