import {ElevatedCard} from './ElevatedCard'
import {Box, Typography} from '@material-ui/core'
import {RouterLink} from '../util/RouterLink'
import React, {cloneElement, ReactElement} from 'react'
import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles'

const useStyles = makeStyles(({palette}) =>
    createStyles({
        card: {
            '& svg': {
                color: palette.secondary.main,
                transition: 'color 0.25s ease',
            },
            '&:hover svg': {
                color: palette.primary.main,
            },
            '&:hover h6': {
                textDecoration: 'underline'
            }
        },
    }),
)

export function AdminCard(props: {
    className?: string,
    to: string,
    icon: ReactElement,
    title: string,
}) {
    const {className, icon, title, to} = props
    const {spacing} = useTheme()
    const {card} = useStyles()

    return (
        <RouterLink to={to}>
            <ElevatedCard className={className}>
                <Box
                    display={'flex'}
                    className={card}
                    padding={2}
                    style={{backgroundColor: 'rgba(0,0,0,0.1)'}}
                    minHeight={spacing(15)}
                >
                    {
                        cloneElement(icon,
                            {
                                style: {
                                    margin: 'auto 16px',
                                    height: spacing(5),
                                    width: spacing(5),
                                }
                            }
                        )
                    }
                    <Typography
                        variant={'h6'}
                        style={{margin: 'auto auto auto 0'}}
                        children={title}
                    />
                </Box>
            </ElevatedCard>
        </RouterLink>
    )
}
