import {Box, Typography} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {EmailOutlined} from '@material-ui/icons'
import {Alert, AlertTitle} from '@material-ui/lab'
import React, {useRef} from 'react'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useRequest} from 'utils/request'
import {ValidateThat} from 'utils/ValidateThat'
import {FormField} from '../forms/FormField'
import {useStringField} from '../foundation/EditableField'
import {DialogOpenState, StatefulDialog, useStatefulDialogState} from '../foundation/StatefulDialog'
import {getReCaptchaToken, ReCaptchaInvisible} from '../misc/ReCaptchaInvisible'
import ReCaptchaV2 from 'react-google-recaptcha'

export function ForgotPasswordDialog(props: {
    openState: DialogOpenState,
}) {
    const {openState} = props
    const {makeRequest} = useRequest()
    const dialog = useStatefulDialogState()
    const {translate} = useTranslation()

    const email = useStringField(StringLength.MD)

    const reCaptcha = useRef<ReCaptchaV2>(null)

    async function onRequestReset() {
        dialog.clearMessages()

        const reCaptchaToken = await getReCaptchaToken(reCaptcha, dialog)

        const fieldsAreValid = [
            email.validate(),
            email.validate(ValidateThat.emailIsValid, 'invalid_email_format'),
            !!reCaptchaToken
        ].every(Boolean)

        makeRequest({
            if: fieldsAreValid,
            url: api.service.password.forgot_password,
            state: dialog,
            data: {
                email: email.value,
                recaptcha_token: reCaptchaToken
            },
            onSuccess: () => dialog.setSuccessMessage('reset_password_request_success')
        }).then()
    }

    return (
        <StatefulDialog
            titleKey={'confirm_action'}
            state={dialog}
            openState={openState}
            content={
                <Alert severity={'info'}>
                    <AlertTitle>{translate('reset_password')}</AlertTitle>
                    <Typography>{translate('reset_password_confirm')}</Typography>
                    <Box marginY={2}/>
                    <FormField
                        label={'email_address'}
                        field={email}
                        icon={<EmailOutlined/>}
                        type={'email'}
                    />
                    <ReCaptchaInvisible ref={reCaptcha}/>
                </Alert>
            }
            buttons={
                <Box display={'flex'}>
                    <Button onClick={openState.close} variant={'outlined'} color="primary">
                        {translate('cancel')}
                    </Button>
                    <Box marginX={1}/>
                    <Button onClick={onRequestReset} variant={'contained'} color={'primary'}>
                        {translate('reset')}
                    </Button>
                </Box>
            }
        />
    )
}
