import {Box, Button} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {DialogOpenState} from 'components/foundation/StatefulDialog'
import {Only} from 'components/util/Only'
import React from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from 'state/auth'
import {useTranslation} from 'state/translation'
import {route} from 'utils/route'

const useStyles = makeStyles(({breakpoints, palette, spacing}) =>
    createStyles({
        authButtons: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',

            [breakpoints.down('mobile')]: {
                justifyContent: 'center',
                paddingTop: spacing(1)
            },

            '& >*': { // All immediate children
                marginLeft: spacing(2),
                whiteSpace: 'nowrap',
                minWidth: spacing(12),
                color: 'white',
                borderColor: palette.primary.main,

                [breakpoints.down('tablet')]: {
                    minWidth: spacing(12),
                },
            }
        }
    }),
)


export function AuthButtons(props: {
    className?: string,
    loginDialog: DialogOpenState
}) {
    const {authButtons} = useStyles()
    const {className, loginDialog} = props

    const {translate} = useTranslation()
    const {auth} = useAuth()

    return (
        <div className={className}>
            <Only when={!auth?.token}>
                <Box className={authButtons}>
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={() => loginDialog.setOpen(true)}
                        children={translate('login')}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={route.to.sign_up.page}
                        children={translate('sign_up')}
                    />
                </Box>
            </Only>
        </div>
    )
}
