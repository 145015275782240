import {Box, Typography} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {AccountCircleOutlined, EmailOutlined, VpnKeyOutlined} from '@material-ui/icons'
import {ForgotPasswordDialog} from 'components/dialogs/ForgotPasswordDialog'
import {FormField} from 'components/forms/FormField'
import {PhoneField} from 'components/forms/PhoneField'
import {DateField} from 'components/forms/DateField'
import {KeyboardDateField} from 'components/forms/KeyboardDateField'
import {SelectField} from 'components/forms/SelectField'
import {useStringField} from 'components/foundation/EditableField'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import React, {useEffect, useRef, useState} from 'react'
import 'react-phone-input-2/lib/material.css'
import {useHistory} from 'react-router-dom'
import {Language} from 'state/lang'
import {useLangs} from 'state/langs'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useRequest} from 'utils/request'
import {route} from 'utils/route'
import {ValidateThat} from 'utils/ValidateThat'
import {FacebookButtonDialog} from '../../components/dialogs/FacebookButtonDialog'
import {CustomDivider} from '../../components/misc/CustomDivider'
import ReCaptchaV2 from 'react-google-recaptcha'
import {getReCaptchaToken, ReCaptchaInvisible} from '../../components/misc/ReCaptchaInvisible'
import {useAppSnackbar} from 'utils/hooks'
import { isValid } from 'date-fns'

const useStyles = makeStyles(({spacing, breakpoints}: Theme) =>
    createStyles({
        signUp: {
            margin: 'auto',
            width: '100%',
            maxWidth: breakpoints.values['mobile'],
        },
        title: {
            textAlign: 'center',
            marginBottom: spacing(2)
        },
        button: {
            width: spacing(20)
        },
    })
)

export function SignUp() {

    const [date, setDate] = useState("");
    const onDateChange = (event:any) => {
        setDate(event.target.value);
    };

    const {translate} = useTranslation()
    const {signUp, title, button} = useStyles()
    const {user} = useUser()
    const {langs} = useLangs()
    const history = useHistory()
    const {makeRequest} = useRequest()

    const {showSnackbar} = useAppSnackbar()

    const page = useStatefulPageState()
    const forgotPasswordDialog = useDialogOpenState(undefined)

    const email = useStringField(StringLength.MD)
    const password = useStringField(StringLength.SM)
    const passwordConfirm = useStringField(StringLength.SM)
    const fullName = useStringField(StringLength.XS)
    const phoneNumber = useStringField(StringLength.XS)
    const dob = useStringField()
    const preferredLang = useStringField(StringLength.XS, Language.tr)

    const reCaptcha = useRef<ReCaptchaV2>(null)

    useEffect(() => {
        if (user) {history.push(route.to.home)}
    }, [user])

    async function onSignUp() {
        
        const fieldsAreValid = [
            email.validate(ValidateThat.emailIsValid, 'invalid_email_format'),
            password.validate(ValidateThat.passwordIsStrong, 'weak_password_desc'),
            passwordConfirm.validate(confirmValue => confirmValue === password.value, 'passwords_dont_match'),
            fullName.validate(),
            //dob.validate(),
            phoneNumber.validate(ValidateThat.phoneNumberIsValid, 'invalid_phone_format_desc')
        ].every(Boolean)

        if (!fieldsAreValid) {
            showSnackbar('error', 'all_fields_required')
            return
        }

        if (!dob.validate()) {
            showSnackbar('error', 'valid_date_warning')
            return
        }

        const reCaptchaToken = await getReCaptchaToken(reCaptcha, page)

        await makeRequest({
            // if: ValidateThat.allFieldsAreTrue(
            //     email.validate(ValidateThat.emailIsValid, 'invalid_email_format'),
            //     password.validate(ValidateThat.passwordIsStrong, 'weak_password_desc'),
            //     passwordConfirm.validate(confirmValue => confirmValue === password.value, 'passwords_dont_match'),
            //     fullName.validate(),
            //     dob.validate(),
            //     phoneNumber.validate(ValidateThat.phoneNumberIsValid, 'invalid_phone_format_desc')
            // ),
            state: page,
            url: api.auth.register,
            data: {
                ...email.box('email'),
                ...password.box('password'),
                ...fullName.box('name'),
                ...phoneNumber.box('phone_number'),
                ...dob.box('dob'),
                //dob: date,
                ...preferredLang.box('lang'),
                recaptcha_token: reCaptchaToken
            },
            onSuccess: () => {
                history.push(route.to.sign_up.success)
            },
            onFail: resultCode => {
                if (resultCode === 'EMAIL_EXISTS') {
                    email.setHint('email_already_exists')
                    page.setErrorMessage('email_already_exists')
                } else {
                    page.setUnexpectedFrontendError()
                }
            }
        })
    }

    return (
        <StatefulPage state={page}>
            <AppHelmet noIndex/>
            <ForgotPasswordDialog openState={forgotPasswordDialog}/>
            <Box padding={2} className={signUp}>
                <Title titleKey={'sign_up'}/>
                <Typography className={title} variant={'h6'} color={'textSecondary'}>
                    {translate('all_fields_required')}
                </Typography>
                <div>
                    <FormField
                        label={'full_name'}
                        icon={<AccountCircleOutlined/>}
                        field={fullName}
                    />
                    <FormField
                        type={'email'}
                        label={'email_address'}
                        icon={<EmailOutlined/>}
                        field={email}
                    />
                    <FormField
                        type={'password'}
                        label={'password'}
                        icon={<VpnKeyOutlined/>}
                        field={password}
                    />
                    <FormField
                        type={'password'}
                        label={'confirm_password'}
                        icon={<VpnKeyOutlined/>}
                        field={passwordConfirm}
                    />
                    <PhoneField
                        field={phoneNumber}
                    />
                    {/* <Box marginBottom={2}>
                        <DateField
                            label={'date_of_birth'}
                            field={dob}
                        />
                    </Box> */}
                    <Box marginBottom={2}>
                        <KeyboardDateField
                            label={'date_of_birth'}
                            field={dob}
                        />
                    </Box>
                    {/* <Box marginBottom={2} className='custom-date-wrap'>
                        <p className={'custom-date-label'}>{translate('date_of_birth')}</p>
                        <div>
                            <input
                                className={'custom-date sign-up'}
                                type="date"
                                value={date}
                                onChange={onDateChange}
                            />
                        </div>
                    </Box> */}
                    <Only when={{langs}}>{({langs}) =>
                        <SelectField
                            label={'preferred_lang'}
                            itemsAsTuples={Object.entries(langs)
                                .filter(([, lang]) => lang.is_enabled)
                                .map(([code, lang]) => [code, lang.name])
                            }
                            field={preferredLang}
                        />
                    }</Only>

                    <ReCaptchaInvisible ref={reCaptcha}/>
                </div>
                <Box display={'flex'} justifyContent={'space-evenly'} marginY={2}>
                    <Button
                        className={button}
                        variant={'text'}
                        color="primary"
                        onClick={() => forgotPasswordDialog.setOpen(true)}
                        children={translate('forgot_password')}
                    />
                    <Button
                        className={button}
                        onClick={onSignUp}
                        variant={'contained'}
                        color="primary"
                        children={translate('sign_up')}
                    />
                </Box>
                <CustomDivider color={'#8888'} paddingY={2}/>
                <FacebookButtonDialog/>
            </Box>
        </StatefulPage>
    )
}
