import {Box, Button, Container} from '@material-ui/core'
import {Save} from '@material-ui/icons'
import {NumberArrayField, useNumberArrayField} from 'components/foundation/EditableField'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {RichObject, RichObjectSelector} from 'components/misc/RichObjectSelector'
import {Title} from 'components/misc/Title'
import {ICause} from 'types/cause'
import React from 'react'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequest, useRequestEffect} from 'utils/request'
import {TranslationKey} from 'utils/TranslationKey'
import {useLang} from '../../state/lang'
import {useHookstate} from '@hookstate/core'

export function ReviewFeaturedCauses() {
    const page = useStatefulPageState()
    const {lang} = useLang()
    const {translate} = useTranslation()
    const {makeRequest} = useRequest()

    const causes = useHookstate<ICause[]>([])
    const featuredCauseIDs = useNumberArrayField([])

    useRequestEffect<ICause[]>({
        url: api.cause.read_all,
        onSuccess: causes.set,
    }, [lang])

    useRequestEffect<ICause[]>({
        state: page,
        url: api.featured_causes.read,
        onSuccess: (causes) => featuredCauseIDs.setValue(causes.map(cause => cause.id)),
    })

    function onSave() {
        makeRequest({
            state: page,
            url: api.featured_causes.update,
            data: {
                causes: featuredCauseIDs.value
            },
            onSuccess: () => page.setSuccessMessage('featured_causes_update_success'),
            onFail: () => page.setErrorMessage('featured_causes_update_fail'),
        }).then()
    }

    return (
        <StatefulPage state={page}>
            <Container maxWidth="sm">
                <Box marginY={2} display={'flex'} flexDirection={'column'}>
                    <Title titleKey={'featured_causes'}/>
                    <CauseSelector
                        title={'featured_causes_desc'}
                        causes={causes.value}
                        idArrayField={featuredCauseIDs}
                    />
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        startIcon={<Save/>}
                        children={translate('save')}
                        onClick={onSave}
                        style={{
                            marginLeft: 'auto'
                        }}
                    />
                </Box>
            </Container>
        </StatefulPage>
    )
}


function CauseSelector(props: {
    title: TranslationKey
    causes: ICause[]
    idArrayField: NumberArrayField
}) {
    const {title, causes, idArrayField} = props

    return (
        <RichObjectSelector
            title={title}
            imageRatio={16 / 9}
            idArrayField={idArrayField}
            sortable={true}
            objects={causes.map<RichObject>(cause => ({
                id: cause.id,
                name: cause.name,
                image_src: cause.image_url
            }))}
        />
    )
}
