import {createStyles, makeStyles} from '@material-ui/core/styles'
import {KeyboardArrowDown} from '@material-ui/icons'
import {CustomSelect} from 'components/misc/CustomSelect'
import {Only} from 'components/util/Only'
import React from 'react'
import {LanguageKey, useLang} from 'state/lang'
import {useLangs} from 'state/langs'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        langButton: {
            color: 'white',
            textTransform: 'uppercase',
        },
        menuItem: {
            minWidth: spacing(15),
            paddingLeft: spacing(2),
            paddingRight: spacing(2),
        }
    })
)


export function LanguagePicker() {
    const {langButton, menuItem} = useStyles()
    const {langs} = useLangs()
    const {lang, setLang} = useLang()

    return (
        <Only when={{langs}}>{({langs}) =>
            <CustomSelect
                className={langButton}
                variant="text"
                color="secondary"
                endIcon={<KeyboardArrowDown/>}
                menuItemClassName={menuItem}
                onItemSelect={item => setLang(item.key as LanguageKey)}
                items={
                    Object.entries(langs)
                        .filter(([, lang]) => lang.is_enabled)
                        .map(([code, lang]) => ({key: code, text: lang.name}))
                }
                children={lang}
            />
        }
        </Only>
    )
}
