import {Box, InputAdornment, TextField} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles'
import {AccountCircleOutlined, EmailOutlined} from '@material-ui/icons'
import {FormField} from 'components/forms/FormField'
import {PhoneField} from 'components/forms/PhoneField'
import {DateField} from 'components/forms/DateField'
import {KeyboardDateField} from 'components/forms/KeyboardDateField'
import {SelectField} from 'components/forms/SelectField'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {allFieldsDisabled, FieldModeContext, useStringField,} from 'components/foundation/EditableField'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import {IUser} from 'types/user'
import React, {useState}  from 'react'
import {useAuth} from 'state/auth'
import {Language} from 'state/lang'
import {useLangs} from 'state/langs'
import {useTranslation} from 'state/translation'
import {fetchUser, useUser} from 'state/user'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useRequest, useRequestEffect} from 'utils/request'
import {ValidateThat} from 'utils/ValidateThat'
import {ChangePasswordDialog} from './components/ChangePasswordDialog'
import {VerifyPhoneDialog} from './components/VerifyPhoneDialog'
import {ConfirmationDialog} from 'components/dialogs/ConfirmationDialog'
import {useHistory} from 'react-router-dom'
import {route} from 'utils/route'
import {useAppSnackbar} from 'utils/hooks'
import {Link} from 'react-router-dom'
import { isValid } from 'date-fns'

const useStyles = makeStyles(({breakpoints, palette}) =>
    createStyles({
        settings: {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            width: '100%',
            [breakpoints.up('mobile')]: {
                maxWidth: breakpoints.values['mobile']
            }
        },
        delete_btn: {
            color: palette.error.main,
        },
    })
)

let cur_user:any = ''

export function UserSettings() {

    const [date, setDate] = useState("");
    const onDateChange = (event:any) => {
        setDate(event.target.value);
    };

    const {translate} = useTranslation()
    const {settings} = useStyles()
    const {delete_btn} = useStyles()
    const {makeRequest} = useRequest()
    const {setAuth} = useAuth()

    const {showSnackbar} = useAppSnackbar()

    const history = useHistory()
    const email = useStringField(StringLength.MD)
    const fullName = useStringField(StringLength.XS)
    const phoneNumber = useStringField(StringLength.XS)
    const dob = useStringField()
    const oldPhoneNumber = useStringField(StringLength.XS)
    const preferredLang = useStringField(StringLength.XS, Language.tr)

    const fields = [email, fullName, phoneNumber, dob, preferredLang]

    const page = useStatefulPageState()

    const changePasswordDialogOpenState = useDialogOpenState(undefined)
    const verifyPhoneDialogOpenState = useDialogOpenState(undefined)
    const deleteAccountOpenState = useDialogOpenState<IUser | undefined>(undefined)
    const deleteCertificatesOpenState = useDialogOpenState<IUser | undefined>(undefined)
    const verifyPhoneOpenState = useDialogOpenState<IUser | undefined>(undefined)
    const [showSpinner, setShowSpinner] = useState(false)

    const {auth} = useAuth()
    const {setUser} = useUser()

    const {langs} = useLangs()

    const queryParameters = new URLSearchParams(window.location.search)
    const cause_id = queryParameters.get("cause")

    function deleteAccount(user?: IUser) {
        makeRequest({
            state: page,
            if: !!user,
            url: api.user.delete_account,
            data: {
                id: user?.id,
                email: user?.email
            },
            onSuccess: compelete_delete
        }).then()
    }

    const compelete_delete = () => makeRequest({
        url: api.auth.logout,
        onStart: () => setShowSpinner(true),
        onFinish: () => {
            setAuth(null)
            setUser(null)
            setShowSpinner(false)

            history.push(route.to.home)
        }
    })
    
    function deleteCertificates(user?: IUser) {
        makeRequest({
            state: page,
            if: !!user,
            url: api.user.delete_certificates,
            data: {
                id: user?.id,
                email: user?.email
            },
            onSuccess: () => {
                page.setSuccessMessage('delete_certificates_success')
            }
        }).then()
    }

    function sendVerifySms(user?: IUser) {
        makeRequest({
            state: page,
            if: !!user,
            url: api.user.account_phone_verify,
            data: {
                id: user?.id,
                phone: user?.phone_number
            },
            onSuccess: () => {
                verifyPhoneDialogOpenState.setOpen(true)
            },
            onFail: (resultCode) => {
                page.setErrorMessage('verify_phone_error_msg')
                setTimeout(() => {
                    window.location.reload()
                }, 4000);
            }
        }).then()
    }
    
    useRequestEffect<IUser>({
        state: page,
        url: api.user.read,
        if: !!auth?.userID,
        data: {
            id: auth?.userID
        },
        onSuccess: user => {
            email.setInitialValue(user.email)
            fullName.setInitialValue(user.name)
            phoneNumber.setInitialValueCustom(user.phone_number)
            dob.setInitialValueCustom(user.dob)
            oldPhoneNumber.setInitialValue(user.phone_number)
            preferredLang.setInitialValue(user.lang)
            cur_user = user
            page.setShowChildren(true)
            page.stopLoading()
            const p_verify_box = document.getElementById('phone_verify_div');
            p_verify_box?.classList.remove('phone-div-hidden');
            setDate(user.dob)
        }
    }, [])

    const onUpdateClick = () => {
        const fieldsAreValid = [
            fullName.validate(),
            //dob.validateCustom(value => value, 'this_field_is_required'),
            phoneNumber.validate(ValidateThat.phoneNumberIsValid, 'invalid_phone_format_desc'),
        ].every(Boolean)

        if (!fieldsAreValid) {
            showSnackbar('error', 'all_fields_required')
            return
        }

        if (!dob.validateCustom(value => value, 'this_field_is_required')) {
            showSnackbar('error', 'valid_date_warning')
            return
        }

        makeRequest({
            state: page,
            url: api.user.update,
            data: {
                id: Number(auth?.userID),
                ...fullName.box('name'),
                ...phoneNumber.box('phone_number'),
                ...dob.box('dob'),
                //dob: date,
                ...preferredLang.box('lang'),
                old_phone: oldPhoneNumber.value, //send old phone number, set is_phone_verified to 0 if phone number changes
            },
            onSuccess: () => {
                page.setSuccessMessage('user_settings_update_success')
                fetchUser()?.then(setUser)
                window.location.reload()
            }
        }).then()
    }

    return (
        <StatefulPage state={page}>
            <AppHelmet noIndex/>
            <AuthorizedContent>
                <ChangePasswordDialog openState={changePasswordDialogOpenState}/>
                <VerifyPhoneDialog openState={verifyPhoneDialogOpenState}/>
                <ConfirmationDialog
                    openState={deleteAccountOpenState}
                    text={'delete_account_confirm'}
                    onOk={deleteAccount}
                />
                <ConfirmationDialog
                    openState={deleteCertificatesOpenState}
                    text={'delete_certificates_confirm'}
                    onOk={deleteCertificates}
                />
                <ConfirmationDialog
                    openState={verifyPhoneOpenState}
                    text={'phone_verify_confirmation'}
                    onOk={sendVerifySms}
                />
                
                <Box className={settings}>
                    <Title titleKey={'user_settings'}/>
                    <Only when={!!cause_id}>
                        <Button
                            component={Link} to={route.to.cause(cause_id).page}
                            variant={'contained'}
                            color="secondary"
                            children={translate('return_to_cause')}
                            className={'return-cause-btn'}
                        />
                    </Only>
                    <FieldModeContext.Provider value={'edit'}>
                        <Box marginRight={6}>
                            <TextField
                                fullWidth
                                disabled={true}
                                margin="dense"
                                variant="outlined"
                                label={translate('email_address')}
                                value={email.value}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start"><EmailOutlined/></InputAdornment>
                                }}
                            />
                        </Box>
                        <Box margin={1}/>
                        <FormField
                            label={'full_name'}
                            icon={<AccountCircleOutlined/>}
                            field={fullName}
                        />
                        <PhoneField
                            field={phoneNumber}
                        />
                        <Box marginRight={6}>
                            <div id="phone_verify_div" className={'phone-div-hidden'}>
                                <Only when={!!cur_user.phone_number}>
                                    <Only when={!cur_user.is_phone_verified}>
                                        <Box>
                                            <Button
                                                onClick={() => verifyPhoneOpenState.open(cur_user)}
                                                variant={'contained'}
                                                color="secondary"
                                                children={translate('verify_phone_btn')}
                                                className={'account-phone-verify-btn'}
                                            />
                                        </Box>
                                    </Only>
                                    <Only when={cur_user.is_phone_verified}>
                                        <Box> 
                                            <p className={'verified-phone-text'}>{translate('phone_verified_text')}</p>
                                        </Box>
                                    </Only>
                                </Only>
                            </div>
                        </Box>
                        {/* <Box marginBottom={2}>
                            <DateField
                                label={'date_of_birth'}
                                field={dob}
                            />
                        </Box> */}
                        <Box marginBottom={2}>
                            <KeyboardDateField
                                label={'date_of_birth'}
                                field={dob}
                            />
                        </Box>
                        {/* <Box marginBottom={2} className='custom-date-wrap'>
                            <p className={'custom-date-label'}>{translate('date_of_birth')}</p>
                            <div>
                                <input
                                    className={'custom-date'}
                                    type="date"
                                    value={date}
                                    onChange={onDateChange}
                                />
                            </div>
                        </Box> */}
                        <Only when={{langs}}>{({langs}) =>
                            <SelectField
                                label={'preferred_lang'}
                                itemsAsTuples={Object.entries(langs)
                                    .filter(([, lang]) => lang.is_enabled)
                                    .map(([code, lang]) => [code, lang.name])
                                }
                                field={preferredLang}/>
                        }</Only>
                    </FieldModeContext.Provider>
                    <Box display={'flex'} marginY={4} justifyContent={'space-between'} marginRight={6}>
                        <Button
                            onClick={() => changePasswordDialogOpenState.setOpen(true)}
                            variant={'outlined'}
                            color="secondary"
                            children={translate('change_password')}
                        />
                        <Button
                            onClick={onUpdateClick}
                            variant={'contained'}
                            color="primary"
                            disabled={allFieldsDisabled(fields)}
                            children={translate('update')}
                        />
                    </Box>
                    <Box display={'flex'} marginY={4} justifyContent={'space-between'} marginRight={6}>
                        <Button className={delete_btn}
                            variant={'outlined'}
                            children={translate('delete_my_certificates')}
                            onClick={() => deleteCertificatesOpenState.open(cur_user)}
                        />
                        <Button className={delete_btn}
                            variant={'contained'}
                            style={{backgroundColor: '#f44336', color: '#fff'}}
                            children={translate('delete_my_account')}
                            onClick={() => deleteAccountOpenState.open(cur_user)}
                        />
                    </Box>
                </Box>
            </AuthorizedContent>
        </StatefulPage>
    )
}
