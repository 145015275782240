import Button from '@material-ui/core/Button'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Edit} from '@material-ui/icons'
import {FormField} from 'components/forms/FormField'
import {useStringField} from 'components/foundation/EditableField'
import {DialogOpenState, StatefulDialog, useStatefulDialogState} from 'components/foundation/StatefulDialog'
import {ICreateResponse} from 'types/types'
import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useRequest} from 'utils/request'
import {route} from 'utils/route'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        button: {
            marginLeft: spacing(1)
        }
    }),
)

export function NewCharityDialog(props: { openState: DialogOpenState, onSuccess?: () => void }) {
    const {openState, onSuccess} = props
    const {button} = useStyles()
    const {translate} = useTranslation()
    const history = useHistory()
    const {makeRequest} = useRequest()

    const dialog = useStatefulDialogState()

    const charityName = useStringField(StringLength.SM)
    const fields = [charityName]

    const [charityID, setCharityID] = useState(0)

    const onCreate = () => makeRequest<ICreateResponse>({
        if: charityName.validate(value => value, 'this_field_is_required'),
        url: api.charity.create,
        state: dialog,
        data: {
            ...charityName.box('name')
        },
        onSuccess: data => {
            dialog.setSuccessMessage('create_charity_success')
            setCharityID(data.id)
            onSuccess?.()
        }
    })


    return (
        <StatefulDialog
            titleKey={'create_new_charity'}
            state={dialog}
            openState={openState}
            fields={fields}
            content={
                <div>
                    <FormField
                        label={'charity_name'}
                        type={'text'}
                        field={charityName}
                        onEnter={onCreate}
                    />
                </div>
            }
            buttons={
                <div>
                    <Button className={button} onClick={openState.close} variant={'outlined'} color="primary">
                        {translate('cancel')}
                    </Button>
                    <Button className={button} onClick={onCreate} color="primary" variant={'contained'}>
                        {translate('create')}
                    </Button>
                </div>
            }
            successActions={
                <Button
                    variant={'outlined'}
                    onClick={() => history.push(route.to.charity(charityID).edit)}
                    startIcon={<Edit/>}
                    children={translate('proceed_to_edit')}
                />
            }
        />
    )
}
