import {ECauseType} from 'types/cause'
import {EResult} from './enums'

export class ValidateThat {
    public static emailIsValid(email: string) {
        return /\S+@\S+\.\S+/.test(email)
    }

    public static passwordIsStrong(password: string) {
        const hasLetter = () => /[A-Za-z]/.test(password)
        const hasDigit = () => /\d/.test(password)
        const hasAtLeast8Chars = () => password.length >= 8

        return hasAtLeast8Chars() && hasLetter() && hasDigit()
    }

    public static phoneNumberIsValid(phoneNumber: string) {
        const hasAtLeast11Chars = () => phoneNumber.length >= 11
        const hasAtMost16Chars = () => phoneNumber.length <= 16

        return hasAtLeast11Chars() && hasAtMost16Chars()
    }

    public static websiteIsValid(website: string) {
        // noinspection HttpUrlsUsage
        return website.startsWith('http://') || website.startsWith('https://')
    }

    public static stringIsNotEmpty(value: string) {
        return value.length !== 0
    }

    public static resultIsValid(result: string | null) {
        return Object.values(EResult).includes(result as EResult)
    }

    public static projectIsValid(project?: string | null) {
        return Object.values(ECauseType).includes(project as ECauseType)
    }

    public static allFieldsAreTrue(...fields: boolean[]) {
        return fields.every(Boolean)
    }
}
