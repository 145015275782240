import {Column} from '@material-table/core'
import {Box, Button, Typography, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@material-ui/core'
import {Add, Delete, Edit} from '@material-ui/icons'
import {ConfirmationDialog} from 'components/dialogs/ConfirmationDialog'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {RichText} from 'components/misc/RichText'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {ICause} from 'types/cause'
import {ICertificate} from 'types/types'
import React, {useState} from 'react'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useUpdater} from 'utils/hooks'
import {useHistory, Link} from 'react-router-dom'
import {useRequest, useRequestEffect} from 'utils/request'
import {useLang} from '../../../state/lang'
import {route} from 'utils/route'
import {Only} from 'components/util/Only'
import {FormField} from 'components/forms/FormField'
import {SelectField} from 'components/forms/SelectField'
import {Save, AccountCircleOutlined, EmailOutlined} from '@material-ui/icons'
import { useStringField} from 'components/foundation/EditableField'
import { StringLength } from 'utils/enums'
import {PhoneField} from 'components/forms/PhoneField'
import cert_bg_tr from 'pages/cause/support-dialog/media/cert_bg_tr.png'
import cert_bg_en from 'pages/cause/support-dialog/media/cert_bg_en.png'
import DOMPurify from 'dompurify'
import {IntField} from 'components/forms/IntField'
import {useNumberField} from 'components/foundation/EditableField'
import {ResponsiveButton} from 'components/foundation/ResponsiveButton'
import { ValidateThat } from 'utils/ValidateThat'
import { useHookstate } from '@hookstate/core'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {useAuth} from 'state/auth'
import {useUser} from 'state/user'


export function FourFiveCert() {

    const page = useStatefulPageState()
    const {translate} = useTranslation()
    const {lang} = useLang()

    const [cause, setCause] = useState<ICause | undefined>(undefined)
    const [certificate, setCertificate] = useState<ICertificate>()

    const {makeRequest} = useRequest()
    const {auth} = useAuth()
    const {user} = useUser()

    const show_link = useHookstate<boolean>(false)
    const cert_link = useStringField(StringLength.MD)

    const amount_options = [
        ['1' ,translate('show_amount')],
        ['0' ,translate('hide_amount')]
    ]

    const lang_options = [
        ['tr' ,translate('turkish')],
        ['en' ,translate('english')]
    ]

    const name = useStringField(StringLength.XS)
    const email = useStringField(StringLength.MD)
    const phone = useStringField(StringLength.MD)
    const cert_lang = useStringField(StringLength.XS)
    const show_amount = useStringField(StringLength.XS)
    const cert_id = useStringField()
    const amount = useNumberField(0)
    const donation_type = useStringField(StringLength.XS)
    const userInfo = {
        name: name.value,
        email: email.value,
        phone: phone.value,
        donation_type: donation_type.value,
        show_amount: show_amount.value,
        cert_lang: cert_lang.value,
        cert_id: cert_id.value,
    }

    let sel_certificate:any = ''
    let filtered_content:any = ''
    let user_name:any = ''
    const icon_path = useStringField(StringLength.MD)

    useRequestEffect({
        url: api.cause.read_four_five_launch,
        state: page,
        if: !!auth?.userID,
        data: {
            id: 1
        },
        onStart: () => {
            page.setShowNotFound(false)
            page.setShowChildren(false)
        },
        onFinish: () => page.setShowChildren(true),
        onSuccess: (cause: ICause) => {
            page.setShowChildren(true)
            setCause(cause)
            show_amount.setInitialValueCustom('0')
            donation_type.setInitialValueCustom('gift')
            cert_lang.setInitialValueCustom('tr')
        },
        onFail: resultCode => {
            if (resultCode === 'CAUSE_NOT_FOUND' || resultCode === 'UNAUTHORIZED') {
                page.setShowNotFound(true)
            } else {
                page.setUnexpectedFrontendError()
            }
        }
    }, [lang])

    useRequestEffect({
        url: api.certificate.read_four_five_launch,
        state: page,
        if: !!auth?.userID,
        data: {
            id: 1
        },
        onSuccess: (certificate: ICertificate) => {
            setCertificate(certificate)
            cert_id.setInitialValueCustom(certificate.id)
            icon_path.setValue(certificate.icon_path?certificate.icon_path : "")
        },
    }, [lang])


    function number_format(number:any, decimals:any, dec_point:any, thousands_sep:any) {
        // Strip all characters but numerical ones.
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        const n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            
            toFixedFix = function (n:any, prec:any) {
                const k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        let s = [];
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    const PrintPreview = (content:any) => {
        sel_certificate = certificate
        if(sel_certificate){
            user_name = name.value? name.value.replace(/\s+/g, "&nbsp;") : translate('full_name')
            
            if(cert_lang.value === "tr"){
                if(show_amount.value === '1'){
                    filtered_content = sel_certificate.content_tr
                }else{
                    filtered_content = sel_certificate.content_alt_tr
                }
                filtered_content = filtered_content.replace("{{$fee_quantity}}", number_format(amount, 0, ',','.') + "&nbsp;TL")
            }else if(cert_lang.value === "en"){
                if(show_amount.value === '1'){
                    filtered_content = sel_certificate.content_en
                }else{
                    filtered_content = sel_certificate.content_alt_en
                }
                filtered_content = filtered_content.replace("{{$fee_quantity}}", number_format(amount, 0, '.',',') + "&nbsp;TL")
            }

            if(cert_lang.value === lang){
                filtered_content = filtered_content.replace("{{$charity_name}}", cause?.charity.name)
                filtered_content = filtered_content.replace("{{$project_name}}", cause?.name)
            }else{
                filtered_content = filtered_content.replace("{{$charity_name}}", cause?.charity.name_alt)
                filtered_content = filtered_content.replace("{{$project_name}}", cause?.name_alt)
            }
            filtered_content = filtered_content.replace("{{$name_surname}}", user_name)
        }
        return (
            <div className={'cert-preview-content'} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(filtered_content || '', {
                USE_PROFILES: { html: true },
              }) }}></div>
        )
    }

    async function createCert() {
        page.clearMessages()
        show_link.set(false)
        cert_link.setValue('')

        let valid = false;
        valid = ValidateThat.allFieldsAreTrue(
            name.validateCustom(value => value, 'this_field_is_required'),
        )
        if(valid){
            makeRequest({
                state: page,
                url: api.cause.support.four_five_manual,
                data: {
                    cause_id: cause?.id,
                    ...({amount: amount}),
                    ...({
                        user_info: userInfo,
                    })
                },
                onSuccess: (data: { url: string }) => {
                    //clear form values on success
                    name.setValue('')
                    email.setValue('')
                    phone.setValue('+90')
                    //end of clear form data
                    cert_link.setValue(data.url)
                    show_link.set(true)
                    page.setSuccessMessageString(translate('manual_cert_success') + " " + translate('manual_cert_success_desc'))
                },
                onFail: resultCode => {
                    page.setUnexpectedFrontendError()
                }
            }).then()
        }
    }

    return (
        <>
            <AppHelmet noIndex/>
            <StatefulPage state={page} className='four-five-launch-page'>
                <AuthorizedContent>
                    
                    <Only when={!!cause && !!certificate}>
                        <Title titleKey={'add_four_five_cert'}/>
                        <Box className={'cert-module-parent manual-cert-parent'} marginTop={3}>
                            <FormControl component="fieldset" className={'cert-form-wrap'}>
                                <Box marginTop={2}>
                                    {/* <SelectField
                                        label={'certificate_language'}
                                        field={cert_lang}
                                        itemsAsTuples={lang_options.map(option => [option[0], option[1]])}
                                    /> */}
                                    {/* <IntField
                                        label={'amount'} field={amount} totalSign={'TL'}
                                        style={{margin: 'auto'}}/>
                                    <Box margin={1}/> */}
                                    {/* <SelectField
                                        label={'donation_amount'}
                                        field={show_amount}
                                        itemsAsTuples={amount_options.map(option => [option[0], option[1]])}
                                    /> */}
                                    <Box> 
                                        <p style={{color : 'dimgrey', marginTop: 0}}>{translate('recipient_information')}</p>
                                    </Box>
                                    <FormField
                                        label={'full_name'}
                                        icon={<AccountCircleOutlined/>}
                                        field={name}
                                    />
                                    <FormField
                                        type={'email'}
                                        label={'email'}
                                        icon={<EmailOutlined/>}
                                        field={email}
                                    />
                                    <Box marginTop={1}>
                                        <PhoneField
                                            field={phone}
                                        />
                                    </Box>
                                </Box>
                            </FormControl>
                            <Box marginLeft={3} className={'cert-preview-wrap'}>
                                <div className={'cert-bg-wrap'}> 
                                    <Only when={cert_lang.value == 'tr'}>
                                        <img src={cert_bg_tr} className={'cert-bg-img'} />
                                    </Only>
                                    <Only when={cert_lang.value == 'en'}>
                                        <img src={cert_bg_en} className={'cert-bg-img'}/>
                                    </Only>
                                    <Only when={icon_path.value}>
                                        <div className={'cert-preview-img'}>
                                            <img src={icon_path.value} width={100} style={{margin: 'auto'}}/>
                                        </div>
                                    </Only>
                                    <div className={'cert-preview-parent'}>
                                        <PrintPreview />
                                    </div>
                                </div>
                            </Box>
                        </Box>
                        <Box display={'flex'} marginY={4} className={'four-five-btn-wrap'}>
                            <ResponsiveButton
                                label={'create'}
                                icon={<Save/>}
                                onClick={createCert}
                                variant={'contained'}
                                color="primary"
                            />
                        </Box>
                        <Only when={show_link && !!cert_link.value}>
                            <Box className='four-five-view-btn-wrap'>
                                <a href={cert_link.value} className={'four-five-view-btn'} target="_blank" rel="noreferrer">{translate('view_certificate')}</a>
                            </Box>
                            <Box className='four-five-view-link-wrap'>
                                <a href={cert_link.value} className={'four-five-view-link'} target="_blank" rel="noreferrer">{cert_link.value}</a>
                            </Box>
                        </Only>
                    </Only>
                </AuthorizedContent>
            </StatefulPage>
        </>
    )
}