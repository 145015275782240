import {Column} from '@material-table/core'
import {Box, Button} from '@material-ui/core'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'

import {Title} from 'components/misc/Title'
import {IProjectReport} from 'types/types'
import {ICharity} from 'types/charity'
import React, {useState} from 'react'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {useUpdater} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'
import {Only} from 'components/util/Only'
import { ThemeProvider, createTheme } from '@mui/material'
import { useNumberField, useStringField } from 'components/foundation/EditableField'
import {DateField} from 'components/forms/DateField'
import {SelectField} from 'components/forms/SelectField'
import {StringLength} from 'utils/enums'
import {useAppSnackbar} from 'utils/hooks'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

import MaterialTable, {Icons, MaterialTableProps} from '@material-table/core'
import AddBox from '@material-ui/icons/AddBox'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import {forwardRef} from 'react'

export function ProjectsCurrentReport() {
    const {user} = useUser()
    const {translate} = useTranslation()
    const page = useStatefulPageState()
    const [reports, setReports] = useState<IProjectReport[]>([])

    const {update, updateFlag} = useUpdater()

    const {showSnackbar} = useAppSnackbar()
    const {makeRequest} = useRequest()

    const charity_id = useNumberField(-1)
    const [charities, setCharities] = useState<ICharity[]>([])
    const reportDate = useStringField()
    let curDate:any = new Date()

    useRequestEffect<ICharity[]>({
        url: api.charity.read_public_report,
        onSuccess: setCharities,
        onFinish: () => {
            getFilteredData()
        }
    })

    function getFilteredData() {
        if (!charity_id.value) {
            showSnackbar('error', 'all_fields_required')
            return
        }


        makeRequest({
            url: api.donation.read_projects_current_report,
            state: page,
            data: {
                charity_id: charity_id.value,
            },
            onSuccess: (reports: IProjectReport[]) => {
                setReports(reports)
                curDate = new Date()
                reportDate.setValue(formatDateReport(curDate))
                fileName.setValue('GBB_Proje_Guncel_Raporu_'+formatDate(curDate))
            }
        }).then()
    }

    function padTo2Digits(num: number) {
        return num.toString().padStart(2, '0');
    }

    function formatDateReport(date: Date) {
        return (
          [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
          ].join('/') +
          ' ' +
          [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
          ].join(':')
        );
    }

    function formatDate(date: Date) {
        return (
          [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
          ].join('-') +
          '_' +
          [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
          ].join('')
        );
    }

    function number_format(number:any, decimals:any, dec_point:any, thousands_sep:any) {
        // Strip all characters but numerical ones.
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        const n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            
            toFixedFix = function (n:any, prec:any) {
                const k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        let s = [];
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = ".xlsx"
    const fileName = useStringField()

    const customExport = async(cols:any, datas:any) => {
        //const ws = XLSX.utils.json_to_sheet(datas) // previous method of creating sheet
        //const wb = { Sheets: { data: ws }, SheetNames: ["data"] } // previous method of creating sheet

        const headers = [[translate('report_charity_name'), translate('project_name'), translate('category'), translate('start_date'), translate('end_date'), translate('goal') + " (TL)", translate('donation_current_text') + " (TL)"]]
        
        //create a new workbook and then add the header
        const wb = XLSX.utils.book_new()
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, headers)
        //XLSX.utils.sheet_add_aoa(ws, [["Total Donation Amount (TL)", "1.050,145"]], { origin: "A1" }) // if needed, to add custom info to sheet
        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, datas, { origin: "A2", skipHeader: true })

        XLSX.utils.book_append_sheet(wb, ws, 'Projeler_Guncel_Raporu');

        // set column widths - for each column accordingly
        const wscols = [
            {wpx: 280},
            {wpx: 280},
            {wpx: 150},
            {wpx: 100},
            {wpx: 100},
            {wpx: 100},
            {wpx: 100}
        ];
        ws['!cols'] = wscols;

        //style all cells in loop dynamically
        for (const i in ws) {
            if (typeof(ws[i]) != "object") continue
            const cell = XLSX.utils.decode_cell(i)
            
            //border for every cell
            ws[i].s = {
                border: {
                    right: {
                        style: "thin",
                        color: "000000"
                    },
                    left: {
                        style: "thin",
                        color: "000000"
                    },
                    top: {
                        style: "thin",
                        color: "000000"
                    },
                    bottom: {
                        style: "thin",
                        color: "000000"
                    },
                }
            }

            //first row/table headers - make font bold
            if (cell.r == 0 )
            {
                ws[i].s = {
                    font: {
                        bold: true,
                    },
                    border: {
                        right: {
                            style: "thin",
                            color: "000000"
                        },
                        left: {
                            style: "thin",
                            color: "000000"
                        },
                        top: {
                            style: "thin",
                            color: "000000"
                        },
                        bottom: {
                            style: "thin",
                            color: "000000"
                        },
                    }
                }
            }

            //number field
            if((cell.c == 5 || cell.c == 6) && cell.r > 0){
                ws[i].s = { 
                    numFmt : "00" // other numbers
                }
                ws[i].s = { 
                    alignment : {
                        horizontal : "right"
                    },
                    border: {
                        right: {
                            style: "thin",
                            color: "000000"
                        },
                        left: {
                            style: "thin",
                            color: "000000"
                        },
                        top: {
                            style: "thin",
                            color: "000000"
                        },
                        bottom: {
                            style: "thin",
                            color: "000000"
                        },
                    }
                }
            }
            //date field
            if((cell.c == 3 || cell.c == 4) && cell.r > 0){
                ws[i].s = { 
                    //numFmt : "dd/mm/yyyy hh:mm", // for dates
                    alignment : {
                        horizontal : "right"
                    },
                    border: {
                        right: {
                            style: "thin",
                            color: "000000"
                        },
                        left: {
                            style: "thin",
                            color: "000000"
                        },
                        top: {
                            style: "thin",
                            color: "000000"
                        },
                        bottom: {
                            style: "thin",
                            color: "000000"
                        },
                    }
                }
               ws[i].z = "dd/mm/yyyy"
               ws[i].t = "d" //force cells to display in excel date format
            }
        }
        
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, fileName.value + fileExtension)
    }

    return (
        <StatefulPage state={page}>
            <AuthorizedContent when={user?.is_super_admin}>
                <Title titleKey={'projects_current_report'}/>
                <Box className={'report-charity-parent'}>
                    <div>
                        <SelectField
                            label={'select_charity'}
                            field={charity_id}
                            itemsAsTuples={charities?.map(option => [option.id, option.name])}
                        />
                    </div>
                    <div className={'update-button-wrap'}>
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            children={translate('filter')}
                            onClick={getFilteredData}
                        />
                    </div>
                </Box>
                <Box className={'report-info-parent'}>
                    <Only when={!!reportDate.value}>
                        <div className={'report-info-date-wrap'}>
                            <p>{translate('report_date')} : </p>
                            <p>{reportDate.value}</p>
                        </div>
                    </Only>
                    <Only when={!!reports && reports[0]?.total > 0}>
                        <div className={'report-info-total-wrap'}>
                            <p>{translate('total_current_amount')} : </p>
                            <p>{number_format(reports[0]?.total, 0, ',','.')} TL</p>
                        </div>
                    </Only>
                </Box>
                <MaterialTable
                    title={''}
                    //icons={tableIcons} - works fine without including table icons for some reason
                    options={{
                        emptyRowsWhenPaging: false,
                        draggable: false,
                        pageSize: 25,
                        pageSizeOptions: [10, 25, 50, 100, 200],
                        actionsColumnIndex: -1,
                        exportAllData: true,
                        exportMenu: [
                            {
                              label: translate('export_to_excel'),
                              exportFunc: (cols, datas) => customExport(cols, datas)//console.log({ cols, datas }), //custom excel export function here
                            },
                          ],
                    }}
                    localization={{
                        toolbar: {
                            searchPlaceholder: translate('search'),
                            searchTooltip: translate('search')
                        },
                    }}
                    data={reports}
                    columns={[
                        {title: translate('report_charity_name'), field: 'charity_name'},
                        {title: translate('project_name'), field: 'project_name'},
                        {title: translate('category'), field: 'category'},
                        {title: translate('start_date'), field: 'start_date', defaultSort: 'desc'},
                        {title: translate('end_date'), field: 'end_date'},
                        {title: translate('goal') + " (TL)", field: 'goal'},
                        {title: translate('donation_current_text') + " (TL)", field: 'amount'},
                        
                    ] as Column<IProjectReport>[]}
                />
            </AuthorizedContent>
        </StatefulPage>
    )
}