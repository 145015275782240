import {useTranslation} from '../../../state/translation'
import {Box, FormControl, FormControlLabel, FormLabel, Icon, Radio, RadioGroup} from '@material-ui/core'
import React from 'react'
import {StatefulDialogState} from '../../../components/foundation/StatefulDialog'
import {StringField} from 'components/foundation/EditableField'
import {PhoneField} from 'components/forms/PhoneField'

export function SmsCheckStep(props: {
    dialog: StatefulDialogState
    amount: number | null
    causeId: number
    sms_phone: StringField
}) {
    const {translate} = useTranslation()

    return (
        <FormControl component="fieldset" className={'fc-w-100'}>
            <FormLabel
                component="legend"
                color={'primary'}
                children={translate('enter_phone_desc')}
            />
            <Box marginTop={2}>
                <PhoneField
                    field={props.sms_phone}
                />
            </Box>
        </FormControl>
    )
}
