import {Box, Fade} from '@material-ui/core'
import React from 'react'
import {TranslationKey} from 'utils/TranslationKey'
import {Title} from '../misc/Title'

export function EmojiWithHeader(props: { emoji: string, header: TranslationKey }) {
    const {emoji, header} = props

    return (
        <Fade in={true}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                marginY={4}
                padding={2}
            >
                <Box style={{fontSize: 128, userSelect: 'none'}} marginBottom={4}>{emoji}</Box>
                <Title titleKey={header}/>
            </Box>
        </Fade>
    )
}
