import {Link} from 'react-router-dom'
import React, {PropsWithChildren} from 'react'

export function RouterLink(props: PropsWithChildren<{ to: string }>) {
    return (
        <Link to={props.to} style={{textDecoration: 'inherit', color: 'inherit'}}>
            {props.children}
        </Link>
    )
}
