import {useTranslation} from '../../../state/translation'
import {Box, FormControl, FormControlLabel, FormLabel, Icon, Radio, RadioGroup} from '@material-ui/core'
import React from 'react'
import {Only} from '../../../components/util/Only'
import {DialogOpenState, StatefulDialog, useStatefulDialogState, StatefulDialogState} from '../../../components/foundation/StatefulDialog'
import {StringField} from 'components/foundation/EditableField'
import {RichText} from '../../../components/misc/RichText'
import {api} from '../../../utils/api'
import {useRequest, useRequestEffect} from '../../../utils/request'
import { useAuth } from '../../../state/auth'
import {SupportDialogData} from 'pages/cause/support-dialog/SupportDialog'
import {VerifyPhoneDialogAlt} from 'pages/user/settings/components/VerifyPhoneDialogAlt'
import {ConfirmationDialog} from 'components/dialogs/ConfirmationDialog'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import { IUser } from 'types/user'
import {ICause} from '../../../types/cause'

enum SupportStep {
    Result,
    ContactInfo,
    Consent,
    PaymentMethod,
    SmsCheck,
    SmsCheckRequest,
    SmsVerify,
    SmsVerifyRequest,
    SmsCheckRequestAuth,
    SmsVerifyAuth,
    SmsVerifyRequestAuth,
    AuthPhoneCheck,
    TotalDonationCheck,
    AgeVerificationCheck,
    CompetitionInfo,
    LoadingCompetition,
    LoadingCustom,
    Loading
}

export function TotalDonationCheckStep(props: {
    dialog: StatefulDialogState
    openState: DialogOpenState<SupportDialogData>
    cause: ICause
    currentStep:any
    cur_user:any
}) {
    const {translate} = useTranslation()
    const { auth } = useAuth()

    useRequestEffect({
        state: props.dialog,
        url: api.user.total_donation_check,
        data: {
                id: auth?.userID,
                min_donation_amount: props.cause.projects.competition.min_donation_amount
            },
        onSuccess: () => {
            props.dialog.setLoading(false)
            props.currentStep.set(SupportStep.CompetitionInfo)
        },
        onFail: () => {
            props.dialog.setErrorMessageCustom(translate('competition_min_donation_warning') + " " + props.cause.projects.competition.min_donation_amount + " TL")
            setTimeout(() => {
                props.openState.close()
            }, 4000);
        },
        onError: () => {
            props.dialog.setUnexpectedFrontendError()
            props.currentStep.set(SupportStep.Consent)
        },
    })



    return (
        <Box>
            <Only when={props.dialog.loading}>
                <RichText text={translate('processing_your_request')}/>
            </Only>
        </Box>
    )
}
