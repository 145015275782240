import {Box, CircularProgress, Fade} from '@material-ui/core'
import React, {PropsWithChildren} from 'react'
import {createStyles, makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
    createStyles({
        overlay: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(255,255,255,0.5)',
            zIndex: 100,
        },
        spinner: {
            zIndex: 100,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
        }
    })
)


export function LoadingOverlay(props: PropsWithChildren<{
    showContent?: boolean,
    loading: boolean,
    onExited?: (node: HTMLElement) => void,
    size?: number,
}>) {
    const {overlay, spinner} = useStyles()

    return (
        <Fade in={props.showContent ?? true} onExited={props.onExited}>
            <Box position={'relative'}>
                <Fade in={props.loading}>
                    <div className={overlay}>
                        <div className={spinner} style={{height: props.size}}>
                            <CircularProgress color={'secondary'} size={props.size}/>
                        </div>
                    </div>
                </Fade>
                <div style={{position: 'relative'}}>
                    {props.children}
                </div>
            </Box>
        </Fade>
    )
}
