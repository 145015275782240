import {Typography} from '@material-ui/core'
import React, {CSSProperties, ReactText} from 'react'

export function RichText(props: {
    className?: string,
    bold?: boolean
    size?: 'small' | 'normal' | 'large' | 'huge',
    text?: ReactText | null
} & CSSProperties) {
    const {className, bold, size, text, ...style} = props

    const fontWeight = bold ? 'bold' : 'normal'
    const fontSize = {
        small: '0.8rem',
        normal: '1.0rem',
        large: '1.2rem',
        huge: '1.4rem'
    }[size ?? 'normal']

    return (
        <Typography
            children={text}
            className={className}
            style={{
                fontWeight: fontWeight,
                fontSize: fontSize,
                ...style
            }}
        />
    )
}
