import {Box, Button, Divider, Typography} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Security} from '@material-ui/icons'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {ImageWithFallback} from 'components/misc/ImageWithFallback'
import {YoutubeEmbed} from 'components/misc/YoutubeEmbed'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import {ICharity} from 'types/charity'
import React, {useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {useTabletSize} from 'utils/hooks'
import {useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {ActiveCauses} from './components/ActiveCauses'
import {SummaryAndContact} from './components/SummaryAndContact'
import {useLang} from '../../state/lang'

const useStyles = makeStyles(({spacing, breakpoints}) =>
    createStyles({
        title: {
            marginBottom: spacing(2),
            fontWeight: 'bold',
        },
        desc: {
            textAlign: 'justify',
            whiteSpace: 'pre-line',
            fontWeight: 'lighter',
        },
        img: {
            margin: `auto auto ${spacing(2)}px auto`,
            color: 'gray',
        },
        divider: {
            marginTop: spacing(1),
            marginBottom: spacing(1),
        },
        youtube: {
            [breakpoints.up('tablet')]: {
                width: 580
            }
        }
    }),
)

export function Charity() {
    const {id} = useParams<{ id: string }>()
    const {img, title, divider, desc, youtube} = useStyles()

    const page = useStatefulPageState()

    const {user} = useUser()
    const {translate} = useTranslation()
    const {lang} = useLang()

    const tablet = useTabletSize()
    const [charity, setCharity] = useState<ICharity>()

    useRequestEffect<ICharity>({
        if: !!id && !!Number(id),
        url: api.charity.read,
        state: page,
        data: {
            id: Number(id)
        },
        onStart: () => {
            page.setShowNotFound(false)
            page.setShowChildren(false)
        },
        onSuccess: charity => {
            setCharity(charity)
            page.setShowChildren(true)
        },
        onFail: resultCode => {
            if (resultCode === 'UNAUTHORIZED' || resultCode === 'CHARITY_NOT_FOUND') {
                page.setShowNotFound(true)
                page.setShowChildren(false)
            } else {
                page.setUnexpectedFrontendError()
            }
        }
    }, [lang])

    const CharityTitle = () => <Typography variant={'h4'} className={title}>{charity?.name}</Typography>
    const Description = () => <Typography className={desc}>{charity?.description}</Typography>

    return (
        <StatefulPage state={page}>
            <AppHelmet title={charity?.name} description={charity?.summary ?? charity?.description ?? charity?.name}/>
            <Only when={{charity}}>{({charity}) =>
                <Box>
                    <Only when={tablet}>
                        <Box display={'flex'} flexDirection={'column'}>
                            <ImageWithFallback
                                className={img}
                                src={charity?.logo_url}
                            />
                            <CharityTitle/>
                            <SummaryAndContact charity={charity}/>
                            <Divider className={divider}/>
                            <Description/>
                        </Box>
                    </Only>
                    <Only when={!tablet}>
                        <Box display={'flex'}>
                            <Box width={'60%'}>
                                <CharityTitle/>
                                <Description/>
                            </Box>
                            <Box width={'40%'} marginLeft={8}>
                                <Box maxHeight={200} maxWidth={200} height={'100%'}>
                                    <ImageWithFallback
                                        className={img}
                                        src={charity?.logo_url}
                                        style={{
                                            objectFit: 'contain'
                                        }}
                                    />
                                </Box>
                                <SummaryAndContact charity={charity}/>
                            </Box>
                        </Box>
                    </Only>
                    <Box className={youtube} marginTop={2}>
                        <YoutubeEmbed id={charity?.youtube_id}/>
                    </Box>
                    <ActiveCauses charity_id={charity?.id}/>
                    <Only when={user?.is_admin}>
                        <Box marginTop={2}/>
                        <Button
                            component={Link}
                            to={route.to.charity(id).edit}
                            startIcon={<Security/>}
                            variant={'contained'}
                            children={translate('edit')}
                            color={'secondary'}
                        />
                    </Only>
                </Box>
            }</Only>
        </StatefulPage>
    )
}
