import {Box} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {EmailOutlined, VpnKeyOutlined} from '@material-ui/icons'
import {Only} from 'components/util/Only'
import React, {useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useAuth} from 'state/auth'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useRequest} from 'utils/request'
import {route} from 'utils/route'
import {ValidateThat} from 'utils/ValidateThat'
import {FormField} from 'components/forms/FormField'
import {useStringField} from 'components/foundation/EditableField'
import {DialogOpenState, StatefulDialog, useDialogOpenState, useStatefulDialogState} from '../foundation/StatefulDialog'
import {ForgotPasswordDialog} from './ForgotPasswordDialog'
import {IUser} from '../../types/user'
import {FacebookButtonDialog} from './FacebookButtonDialog'
import {useMobileSize} from '../../utils/hooks'
import {getReCaptchaToken, ReCaptchaInvisible} from '../misc/ReCaptchaInvisible'
import ReCaptchaV2 from 'react-google-recaptcha'
import {CustomDivider} from '../misc/CustomDivider'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        loginButton: {
            marginLeft: spacing(1)
        }
    }),
)

export type IAuth = {
    token: string
    user: IUser
}

export function LoginDialog(props: { openState: DialogOpenState }) {
    const {openState} = props
    const {loginButton} = useStyles()
    const {translate} = useTranslation()
    const history = useHistory()

    const mobile = useMobileSize()

    const {setAuth} = useAuth()
    const {makeRequest} = useRequest()
    const {setUser} = useUser()

    const email = useStringField(StringLength.MD)
    const password = useStringField(StringLength.SM)
    const fields = [email, password]

    const [showResendButton, setShowResendButton] = useState(false)

    const reCaptcha = useRef<ReCaptchaV2>(null)

    const dialog = useStatefulDialogState()
    const forgotPasswordDialog = useDialogOpenState(undefined)

    async function onLogin() {
        setShowResendButton(false)

        const reCaptchaToken = await getReCaptchaToken(reCaptcha, dialog)

        const fieldsAreValid = [
            email.validate(ValidateThat.emailIsValid, 'invalid_email_format'),
            password.validate(ValidateThat.stringIsNotEmpty, 'password_cant_be_empty'),
            !!reCaptchaToken
        ].every(Boolean)

        await makeRequest<IAuth>({
            if: fieldsAreValid,
            url: api.auth.login,
            state: dialog,
            data: {
                email: email.value,
                password: password.value,
                recaptcha_token: reCaptchaToken,
            },
            onSuccess: ({user, token}) => {
                dialog.setSuccessMessage(() => () =>
                    translate('welcome_user_placeholder').replace('%s', user.name)
                )
                setAuth({
                    token: token,
                    userID: user.id
                })
                setUser(user)
                history.push(route.to.home)
            },
            onFail: resultCode => {
                switch (resultCode) {
                    case 'INVALID_CREDENTIALS':
                        dialog.setErrorMessage('invalid_login_credentials')
                        break
                    case 'UNVERIFIED_EMAIL':
                        dialog.setErrorMessage('unverified_email_desc')
                        setShowResendButton(true)
                        break
                    case 'DEACTIVATED_USER':
                        dialog.setErrorMessage('deactivated_user_desc')
                        break
                    default:
                        dialog.setUnexpectedFrontendError()
                }
            }
        })
    }

    const onResendActivation = async () => {
        setShowResendButton(false)

        const reCaptchaToken = await getReCaptchaToken(reCaptcha, dialog)

        await makeRequest({
            url: api.service.email.resend_activation,
            state: dialog,
            data: {
                email: email.value,
                password: password.value,
                recaptcha_token: reCaptchaToken,
            },
            onSuccess: () => dialog.setInfoMessage('resend_activation_email_success'),
            onFail: resultCode => {
                if (resultCode === 'EMAIL_ALREADY_VERIFIED') {
                    dialog.setErrorMessage('email_already_verified')
                    setShowResendButton(false)
                } else {
                    dialog.setUnexpectedFrontendError()
                }
            }
        }).then()
    }

    function onClose() {
        openState.setOpen(false)
        // reCaptcha.current?.reset()
    }

    return (
        <>
            <ForgotPasswordDialog openState={forgotPasswordDialog}/>
            <StatefulDialog
                state={dialog}
                openState={openState}
                maxWidth={'xs'}
                fullScreen={mobile}
                titleKey={'login'}
                content={
                    <Box>
                        <form id={'login'}>
                            <FormField
                                label={'email_address'}
                                type={'email'}
                                name={'email'}
                                field={email}
                                autoComplete={'current-password'}
                                icon={<EmailOutlined/>}
                            />
                            <FormField
                                label={'password'}
                                name={'password'}
                                type={'password'}
                                field={password}
                                autoComplete={'current-password'}
                                icon={<VpnKeyOutlined/>}
                                onEnter={onLogin}
                            />

                            <ReCaptchaInvisible ref={reCaptcha}/>

                            <Box marginBottom={2}/>
                        </form>
                    </Box>
                }
                buttons={
                    <Box flex={1}>
                        <Box display={'flex'} flexWrap={'wrap'} flex={1}>
                            <Button
                                variant={'text'}
                                color="primary"
                                children={translate('forgot_password')}
                                onClick={() => {
                                    onClose()
                                    forgotPasswordDialog.setOpen(true)
                                }}
                            />
                            <Box flex={1} display={'flex'} justifyContent={'flex-end'}>
                                <Button
                                    onClick={onClose}
                                    variant={'outlined'}
                                    color="primary"
                                    children={translate('cancel')}
                                />
                                <Button
                                    className={loginButton}
                                    onClick={onLogin}
                                    color="primary"
                                    variant={'contained'}
                                    children={translate('login')}
                                />
                            </Box>
                        </Box>
                        <CustomDivider color={'#8888'} paddingY={2}/>
                        <FacebookButtonDialog onClick={onClose}/>
                    </Box>

                }
                fields={fields}
                errorAlertContent={
                    <Only when={showResendButton}>
                        <Button onClick={onResendActivation} variant={'contained'} color={'primary'}>
                            {translate('resend_activation_email')}
                        </Button>
                    </Only>
                }
            />
        </>
    )
}
