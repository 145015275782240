import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography
} from '@material-ui/core'
import {DialogProps} from '@material-ui/core/Dialog/Dialog'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Close, Done} from '@material-ui/icons'
import {Alert, AlertTitle} from '@material-ui/lab'
import React, {ReactElement, ReactNode, useState} from 'react'
import {useTranslation} from 'state/translation'
import {useStatefulComponent} from 'utils/hooks'
import {TranslationKey} from 'utils/TranslationKey'
import {Only} from '../util/Only'
import {clearFieldHints, clearFieldValues, Field} from './EditableField'
import {LoadingOverlay} from './LoadingOverlay'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        actionButtons: {
            display: 'flex',
            marginRight: spacing(2),
            marginLeft: spacing(2),
            marginBottom: spacing(1),
        },
        closeButton: {
            margin: 'auto',
            marginRight: spacing(1)
        }
    }),
)

export interface DialogOpenState<T = undefined> {
    isOpen: boolean
    setOpen: (open: boolean) => void,
    close: () => void,
    open: (data: T) => void,
    data: T,
    setData: (data: T) => void
}

export function useDialogOpenState<T = undefined>(
    initialData: T,
    initialOpen = false,
): DialogOpenState<T> {
    const [open, setOpen] = useState<boolean>(initialOpen)
    const [data, setData] = useState<T>(initialData)

    return {
        isOpen: open,
        setOpen: setOpen,
        close: () => setOpen(false),
        open: data => {
            setOpen(true)
            if (data) {
                setData(data)
            }
        },
        data: data,
        setData: setData
    }
}

export function useStatefulDialogState() {
    return useStatefulComponent()
}

export type StatefulDialogState = ReturnType<typeof useStatefulDialogState>

export function StatefulDialog<T>(props: {
    state: StatefulDialogState
    openState: DialogOpenState<T>
    content: ReactElement
    buttons?: ReactElement
    fields?: Field[]
    errorAlertContent?: ReactElement
    titleKey?: TranslationKey
    successActions?: ReactNode
    showCloseButton?: boolean
    onExit?: () => void
    maxWidth?: DialogProps['maxWidth']
    fullScreen?: boolean
    preventClosingWhileLoading?: boolean
}) {
    const {titleKey} = props
    const {actionButtons, closeButton} = useStyles()

    const {translate} = useTranslation()

    function onClose() {
        if (props.preventClosingWhileLoading && props.state.loading) {
            return
        }

        props.openState.setOpen(false)
    }

    function onExited() {
        clearFieldValues(props.fields)
        clearFieldHints(props.fields)

        props.state.clearMessages()

        props.onExit?.()
    }

    return (
        <Dialog
            fullWidth={true}
            fullScreen={props.fullScreen}
            open={props.openState.isOpen}
            keepMounted={true}
            maxWidth={props.maxWidth ?? 'sm'}
            onClose={onClose}
            TransitionProps={{onExited}}
        >
            <LoadingOverlay loading={props.state.loading}>
                <Box display={'flex'}>
                    <Only when={{titleKey}}>
                        <DialogTitle style={{flex: 1}}>{translate(titleKey)}</DialogTitle>
                    </Only>
                    <Only when={props.showCloseButton}>
                        <IconButton className={closeButton} onClick={onClose} children={<Close/>}/>
                    </Only>
                </Box>
                <Only when={props.state.successMessage}>
                    <DialogContent>
                        <Alert severity="success">
                            <AlertTitle>{translate('success')}</AlertTitle>
                            {props.state.successMessage}
                        </Alert>
                    </DialogContent>
                    <DialogActions className={actionButtons}>
                        {props.successActions}
                        <Button
                            onClick={onClose}
                            variant={'contained'}
                            color={'primary'}
                            startIcon={<Done/>}
                            children={translate('ok')}
                        />
                    </DialogActions>
                </Only>
                <Only when={!props.state.successMessage}>
                    <DialogContent>
                        {props.content}
                        <Only when={props.state.successMessageCustom}>
                            <Divider style={{marginTop: 8, marginBottom: 8}}/>
                            <Alert severity="success">
                                <AlertTitle>{translate('success')}</AlertTitle>
                                {props.state.successMessageCustom}
                            </Alert>
                        </Only>
                        <Only when={props.state.errorMessage}>
                            <Divider style={{marginTop: 8, marginBottom: 8}}/>
                            <Alert severity="error">
                                <AlertTitle>
                                    <Typography variant={'button'}>{translate('error')}</Typography>
                                </AlertTitle>
                                <Typography variant={'body2'}>{props.state.errorMessage}</Typography>
                                {props.errorAlertContent}
                            </Alert>
                        </Only>
                        <Only when={props.state.errorMessageCustom}>
                            <Divider style={{marginTop: 8, marginBottom: 8}}/>
                            <Alert severity="error">
                                <AlertTitle>
                                    <Typography variant={'button'}>{translate('error')}</Typography>
                                </AlertTitle>
                                <Typography variant={'body2'}>{props.state.errorMessageCustom}</Typography>
                                {props.errorAlertContent}
                            </Alert>
                        </Only>
                        <Only when={props.state.infoMessage}>
                            <Divider style={{marginTop: 8, marginBottom: 8}}/>
                            <Alert severity="info">
                                <AlertTitle>
                                    <Typography variant={'button'}>{translate('info')}</Typography>
                                </AlertTitle>
                                <Typography variant={'body2'}>{props.state.infoMessage}</Typography>
                            </Alert>
                        </Only>
                        <Only when={{warnMessage: props.state.warnMessage}}>{({warnMessage}) => <>
                            <Divider style={{marginTop: 8, marginBottom: 8}}/>
                            <Alert severity="warning">
                                <AlertTitle>
                                    <Typography variant={'button'}>{translate('warning')}</Typography>
                                </AlertTitle>
                                <Typography variant={'body2'} children={warnMessage}/>
                            </Alert>
                        </>}</Only>
                    </DialogContent>
                    <DialogActions className={actionButtons} children={props.buttons}/>
                </Only>
            </LoadingOverlay>
        </Dialog>
    )
}
