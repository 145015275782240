import {Box, Button, Card, Typography} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Security} from '@material-ui/icons'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {ImageWithFallback} from 'components/misc/ImageWithFallback'
import {RichText} from 'components/misc/RichText'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import {ICause} from 'types/cause'
import React, {useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {route} from 'utils/route'
import {Description} from './components/Description'
import {ExtraInfo} from './components/ExtraInfo'
import {ProjectActions} from './components/ProjectActions'
import {useRequestEffect} from '../../utils/request'
import {useLang} from '../../state/lang'

const useStyles = makeStyles(({breakpoints, spacing}) =>
    createStyles({
        imageWithAction: {
            display: 'flex',
            width: '100%',
            borderBottom: '1px solid black',
            paddingBottom: spacing(3),
            flexDirection: 'column',
            [breakpoints.up('tablet')]: {
                flexDirection: 'row',
            }
        },
        image: {
            marginBottom: -spacing(1),
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            // maxHeight: '400px',
            color: 'gray'
        },
        imageWithInfo: {
            width: '100%',
            [breakpoints.up('tablet')]: {
                width: '60%',
            }
        },
        extraInfo: {
            display: 'none',
            [breakpoints.up('tablet')]: {
                display: 'flex',
            }
        },
        extraInfoMobile: {
            display: 'flex',
            [breakpoints.up('tablet')]: {
                display: 'none',
            }
        },
        actionWithInfo: {
            marginLeft: 0,
            width: '100%',
            [breakpoints.up('tablet')]: {
                width: '40%',
                marginLeft: spacing(4),
            }
        },
        description: {
            width: '100%',
            marginTop: spacing(3),
            [breakpoints.up('tablet')]: {
                width: '80%',
            },
            [breakpoints.up('laptop')]: {
                width: '60%',
            },
        }
    }),
)

export function Cause() {
    const {imageWithAction, imageWithInfo, image, extraInfo, extraInfoMobile, actionWithInfo, description} = useStyles()
    const {translate} = useTranslation()
    const {lang} = useLang()
    const {user} = useUser()
    const {id} = useParams<{ id: string }>()

    const [cause, setCause] = useState<ICause | undefined>(undefined)

    const page = useStatefulPageState()

    useRequestEffect({
        url: api.cause.read,
        state: page,
        if: !!id && !!Number(id),
        data: {
            id: Number(id)
        },
        onStart: () => {
            page.setShowNotFound(false)
            page.setShowChildren(false)
        },
        onFinish: () => page.setShowChildren(true),
        onSuccess: (cause: ICause) => {
            page.setShowChildren(true)
            setCause(cause)
        },
        onFail: resultCode => {
            if (resultCode === 'CAUSE_NOT_FOUND' || resultCode === 'UNAUTHORIZED') {
                page.setShowNotFound(true)
            } else {
                page.setUnexpectedFrontendError()
            }
        }
    }, [lang])

    return (
        <StatefulPage state={page}>
            <AppHelmet
                title={cause?.name}
                description={cause?.summary ?? cause?.description ?? cause?.name}
                image={cause?.image_url }
                url={window.location.href.split('?')[0] }
            />
            <Only when={{cause}}>{({cause}) =>
                <Box marginX={1} display={'flex'} flexDirection={'column'}>
                    <RichText bold text={cause.charity.name} margin={'0 auto 16px'} size={'huge'}/>
                    <div className={imageWithAction}>
                        <div className={imageWithInfo}>
                            <Card style={{marginBottom: 16}}>
                                <ImageWithFallback src={cause.image_url} className={image}/>
                            </Card>
                            <ExtraInfo className={extraInfo} cause={cause}/>
                        </div>
                        <div className={actionWithInfo}>
                            <Typography variant={'h5'} children={<b>{cause.name}</b>}/>
                            <Box marginY={1}/>
                            <Typography variant={'body1'} children={cause.summary}/>
                            <Box marginY={2}/>
                            <ExtraInfo className={extraInfoMobile} cause={cause}/>
                            <Box marginY={2}/>
                            <Typography variant={'subtitle2'} children={translate('support_cause_desc')}/>
                            <Box marginY={2}/>
                            <ProjectActions {...{cause}}/>
                        </div>
                    </div>
                    <Description className={description} cause={cause}/>
                    <Only when={user?.is_admin}>
                        <Box marginTop={2}/>
                        <Button
                            component={Link}
                            to={route.to.cause(id).edit}
                            startIcon={<Security/>}
                            variant={'contained'}
                            children={translate('edit')}
                            color={'secondary'}
                            style={{
                                marginLeft: 'auto'
                            }}
                        />
                    </Only>
                </Box>
            }</Only>
        </StatefulPage>
    )
}
