import {useState} from '@hookstate/core'
import {Button, createStyles, IconButton, Typography} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {route} from 'utils/route'
import {IHomeBanner} from 'types/types'
import {ButtonBack, ButtonNext, CarouselProvider, DotGroup, Slide, Slider} from 'pure-react-carousel'
import {YoutubeEmbed} from 'components/misc/YoutubeEmbed'
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
// City
import '@videojs/themes/dist/city/index.css';

// Fantasy
import '@videojs/themes/dist/fantasy/index.css';

// Forest
import '@videojs/themes/dist/forest/index.css';

// Sea
import '@videojs/themes/dist/sea/index.css';

const useStyles = makeStyles(({palette}) =>
    createStyles({
        carousel: {
            position: 'relative'
        },
        slider: {
            backgroundColor: palette.secondary.main,
            outline: 'none',
            '& *': {
                outline: 'none',
            }
        },
    })
)


const VideoJS = (props:any) => {
    const videoRef = useRef<any | null>(null);
    const playerRef = useRef<any | null>(null);
    const {options, onReady} = props;
  
    useEffect(() => {
  
      // Make sure Video.js player is only initialized once
      if (!playerRef.current) {
        const videoElement = videoRef.current;
  
        if (!videoElement) return;
  
        const player = playerRef.current = videojs(videoElement, options, () => {
          //player.log('player is ready');
          onReady && onReady(player);
        });
  
      // You can update player in the `else` block here, for example:
      } else {
        // player.autoplay(options.autoplay);
        // player.src(options.sources);
      }
    }, [options, videoRef]);
  
    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
      const player = playerRef.current;
  
      return () => {
        if (player) {
          player.dispose();
          playerRef.current = null;
        }
      };
    }, [playerRef]);
  
    return (
    
      <div data-vjs-player>
        <video ref={videoRef} className='video-js vjs-custom vjs-theme-forest vjs-big-play-centered' />
      </div>
    );
}

export function BannerCarouselStatic(props: {
    className?: string, videoOne: string, videoTwo: string, posterOne: string, posterTwo: string
}) {
    const {className, videoOne, videoTwo, posterOne, posterTwo} = props
    const {carousel, slider} = useStyles()
    const {translate} = useTranslation()
    const {spacing} = useTheme()

    const wrapperRef = useRef<HTMLElement>()
    const dimensions = useState({width: 0, height: 0})
    const slideIndex = useState(0)

    const playerRef = useRef<any | null>(null);
    const prevVid = useRef<any | null>(null);

    const handlePlayerReady = (player:any) => {
        //assign previous video ref to variable
        prevVid.current = playerRef.current
        playerRef.current = player;
        // You can handle player events here, for example:
        player.on('waiting', () => {
            //player.log('player is waiting');
        });

        player.on('dispose', () => {
            //player.log('player will dispose');
        });
        //open all video references in full screen on play
        player.on('firstplay', () => {
            if(!player.isFullscreen()){
                if(player.requestFullscreen){
                    player.requestFullscreen();
                }else if (player.webkitRequestFullscreen) { /* Safari */
                    player.webkitRequestFullscreen();
                } else if (player.msRequestFullscreen) { /* IE11 */
                    player.msRequestFullscreen();
                }
            } 
        });
    };
    
    function pauseVideo(){
        const vref = prevVid.current;
        vref.pause();
        const vref_2 = playerRef.current;
        vref_2.pause();
    }

    const videoJsOptions_1 = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        poster: posterOne,
        sources: [{
          src: videoOne,
          type: 'video/mp4'
        }]
    };
    
    const videoJsOptions_2 = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        poster: posterTwo,
        sources: [{
          src: videoTwo,
          type: 'video/mp4'
        }]
    };

    function updateSize() {
        const rect = wrapperRef.current?.getBoundingClientRect()

        dimensions.width.set(rect?.width ?? 0)
        dimensions.height.set(rect?.height ?? 0)
    }

    useLayoutEffect(() => {
        updateSize()
        window.addEventListener('resize', updateSize)
        return () => window.removeEventListener('resize', updateSize)
    }, [])

    const NavButton = (props: { type: 'prev' | 'next' }) =>
        <IconButton
            size={'small'}
            component={{
                // Produces findDOMNode warning
                'prev': ButtonBack,
                'next': ButtonNext,
            }[props.type]}
            children={{
                'prev': <ChevronLeft style={{fontSize: '2.5rem'}}/>,
                'next': <ChevronRight style={{fontSize: '2.5rem'}}/>,
            }[props.type]}
            style={{
                margin: 'auto',
                color: 'white',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                ...({
                    'prev': {left: spacing(1)},
                    'next': {right: spacing(1)},
                }[props.type])
            }}
            onClick={pauseVideo}
        />

    let index = 0
    return (
        <Box 
            className={className} 
            {...{ref: wrapperRef}}
        >
            <div className="static-banner-wrapper">
                <CarouselProvider
                    className={carousel}
                    naturalSlideWidth={dimensions.width.value}
                    naturalSlideHeight={dimensions.height.value}
                    dragEnabled={false}
                    touchEnabled={false}
                    totalSlides={2}
                    infinite={true}
                    currentSlide={slideIndex.value}
                    isPlaying={false}
                >
                    <Slider className={slider}>
                        <Slide index={index++} key={index} className="static-slide">
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                objectFit: 'cover',
                                padding: `0 ${spacing(8)}px`
                            }}>
                                <VideoJS options={videoJsOptions_1} onReady={handlePlayerReady} />
                            </Box>
                        </Slide>
                        <Slide index={index++} key={index} className="static-slide">
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                objectFit: 'cover',
                                padding: `0 ${spacing(8)}px`
                            }}>
                                <VideoJS options={videoJsOptions_2} onReady={handlePlayerReady} />
                            </Box>
                        </Slide>
                    </Slider>
                    <NavButton type={'prev'}/>
                    <NavButton type={'next'}/>
                </CarouselProvider>
            </div>
            {/*<div className="static-banner-wrapper">
                <CarouselProvider
                    className={carousel}
                    naturalSlideWidth={dimensions.width.value}
                    naturalSlideHeight={dimensions.height.value}
                    dragEnabled={false}
                    touchEnabled={false}
                    totalSlides={2}
                    infinite={true}
                    currentSlide={slideIndex.value}
                    isPlaying={false}
                >
                    <Slider className={slider}>
                        <Slide index={index++} key={index} className="static-slide">
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                objectFit: 'cover',
                                padding: `0 ${spacing(8)}px`
                            }}>
                                <YoutubeEmbed id="UngFEKJnxLE"/>
                            </Box>
                        </Slide>
                        <Slide index={index++} key={index} className="static-slide">
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                objectFit: 'cover',
                                padding: `0 ${spacing(8)}px`
                            }}>
                                <YoutubeEmbed id="CYPnUD9kXfA"/>
                            </Box>
                        </Slide>
                    </Slider>
                    <NavButton type={'prev'}/>
                    <NavButton type={'next'}/>
                </CarouselProvider>
            </div>*/}
        </Box>
    )
}
