import {Box, Button} from '@material-ui/core'
import {Save} from '@material-ui/icons'
import {ImageField} from 'components/forms/ImageField'
import {allFieldsDisabled, FieldModeContext, useImageBase64Field} from 'components/foundation/EditableField'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {IHome} from 'types/types'
import React from 'react'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useMobileSize} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'

export function HomeManagement() {
    const {translate} = useTranslation()
    const page = useStatefulPageState()
    const mobile = useMobileSize()
    const {makeRequest} = useRequest()

    const volunteerBg = useImageBase64Field()

    const fields = [volunteerBg]

    useRequestEffect<IHome>({
        state: page,
        url: api.home.page,
        onSuccess: data => {
            volunteerBg.setInitialValue(data.images.volunteer_bg)
        }
    })

    function onUpdate() {
        makeRequest({
            url: api.home.update,
            state: page,
            data: {
                ...volunteerBg.box('volunteer_bg_base64'),
            },
            onStart: page.clearMessages,
            onSuccess: () => page.setSuccessMessage('website_update_success'),
            onFail: resultCode => {
                if (resultCode === 'INVALID_IMAGE_TYPE') {
                    page.setErrorMessage('unsupported_image_format_desc')
                } else {
                    page.setUnexpectedFrontendError()
                }
            }
        }).then()
    }

    return (
        <StatefulPage state={page}>
            <FieldModeContext.Provider value={'edit'}>
                <ImageField
                    label={'home_volunteer_banner'}
                    field={volunteerBg}
                    disableDelete={true}
                    aspectRatio={1920 / 512}
                    preferredDimensions={'1920x512'}
                />
            </FieldModeContext.Provider>
            <Box display={'flex'} justifyContent={'flex-end'} marginY={mobile ? 2 : 4}>
                <Button
                    children={translate('update')}
                    startIcon={<Save/>}
                    onClick={onUpdate}
                    variant={'contained'}
                    color="primary"
                    disabled={allFieldsDisabled(fields)}
                />
                <Box marginX={3}/>
            </Box>
        </StatefulPage>
    )
}
