import {makeStyles} from '@material-ui/core/styles'
import {createStyles, Typography, TypographyProps} from '@material-ui/core'
import React, {CSSProperties, PropsWithChildren, useRef} from 'react'

const useStyles = (lines: number) => makeStyles(() =>
    createStyles({
        text: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': lines,
            '-webkit-box-orient': 'vertical'
        },
    }),
)()

export function OverflowText(props: PropsWithChildren<{
    lines: number
    style?: CSSProperties
} & TypographyProps>) {
    const {lines, style, children, ...rest} = props
    const {text} = useStyles(lines)
    const ref = useRef<HTMLElement>(null)

    return <Typography
        className={text}
        innerRef={ref}
        style={style}
        children={children}
        {...rest}
    />
}
