import {Column} from '@material-table/core'
import {Box, Button} from '@material-ui/core'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
//import {CustomTable} from 'components/misc/CustomTable'
//import {CustomTableAlt} from 'components/misc/CustomTableAlt'
//import {CustomTableExcel} from 'components/misc/CustomTableExcel'
//import MaterialTable from 'material-table'
import {Title} from 'components/misc/Title'
import {IDonationReport} from 'types/types'
import {ICharity} from 'types/charity'
import React, {useState} from 'react'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {useUpdater} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'
import {Only} from 'components/util/Only'
import { ThemeProvider, createTheme } from '@mui/material'
import { useNumberField, useStringField } from 'components/foundation/EditableField'
import {DateField} from 'components/forms/DateField'
import {SelectField} from 'components/forms/SelectField'
import {StringLength} from 'utils/enums'
import {useAppSnackbar} from 'utils/hooks'
import {useAuth} from 'state/auth'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

import MaterialTable, {Icons, MaterialTableProps} from '@material-table/core'
import AddBox from '@material-ui/icons/AddBox'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import {forwardRef} from 'react'

// const tableIcons: Icons = {
//     Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
//     Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
//     Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
//     Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
//     DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
//     Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
//     Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
//     Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
//     FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
//     LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
//     NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
//     PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
//     ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
//     Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
//     SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
//     ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
//     ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>),
// }

export function MyDonationReport() {
    const {user} = useUser()
    const {auth} = useAuth()
    const {translate} = useTranslation()
    const page = useStatefulPageState()
    const [reports, setReports] = useState<IDonationReport[]>([])

    const {update, updateFlag} = useUpdater()

    const {showSnackbar} = useAppSnackbar()
    const {makeRequest} = useRequest()

    const startDate = useStringField(StringLength.MD, formatDateDefaultStart(new Date()))
    const endDate = useStringField(StringLength.MD, formatDateToday(new Date()))
    const charity_id = useNumberField(-1)
    const [charities, setCharities] = useState<ICharity[]>([])
    const reportDate = useStringField()
    let curDate:any = new Date()

    useRequestEffect<ICharity[]>({
        url: api.charity.read_public_for_user,
        if: !!auth?.userID,
        data: {
            user_id: Number(auth?.userID)
        },
        onSuccess: setCharities,
        onFinish: () => {
            getFilteredData()
        }
    })

    // useRequestEffect<IDonationReport[]>({
    //     url: api.donation.read_donation_report_charity,
    //     state: page,
    //     if: !!auth?.userID,
    //     data: {
    //         start_date: startDate.value,
    //         end_date: endDate.value,
    //         user_id: Number(auth?.userID)
    //     },
    //     onSuccess: (reports: IDonationReport[]) => {
    //         setReports(reports)
    //         curDate = new Date()
    //         reportDate.setValue(formatDateReport(curDate))
    //         fileName.setValue('GBB_Dernek_Bagis_Raporu_'+formatDate(curDate))
    //     }
    // }, [])

    function getFilteredData() {
        //console.log('in function')
        const fieldsAreValid = [
            endDate.validateCustom(value => value, 'this_field_is_required'),
            startDate.validateCustom(value => value, 'this_field_is_required'),
        ].every(Boolean)

        if (!fieldsAreValid || !charity_id.value) {
            showSnackbar('error', 'all_fields_required')
            return
        }

        //console.log('before request start')

        makeRequest({
            url: api.donation.read_donation_report_charity,
            state: page,
            if: !!auth?.userID,
            data: {
                start_date: startDate.value,
                end_date: endDate.value,
                charity_id: charity_id.value,
                user_id: Number(auth?.userID)
            },
            onSuccess: (reports: IDonationReport[]) => {
                setReports(reports)
                curDate = new Date()
                reportDate.setValue(formatDateReport(curDate))
                fileName.setValue('GBB_Dernek_Bagis_Raporu_'+formatDate(curDate))
            }
        }).then()
    }
    
    function padTo2Digits(num: number) {
        return num.toString().padStart(2, '0');
    }

    function formatDateReport(date: Date) {
        return (
          [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
          ].join('/') +
          ' ' +
          [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
          ].join(':')
        );
    }

    function formatDate(date: Date) {
        return (
          [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
          ].join('-') +
          '_' +
          [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
          ].join('')
        );
    }

    function formatDateDefaultStart(date: Date) {
        return (
          [
            date.getFullYear(),
            '01',
            '01',
          ].join('-')
        );
    }

    function formatDateToday(date: Date) {
        return (
          [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
          ].join('-')
        );
    }

    function number_format(number:any, decimals:any, dec_point:any, thousands_sep:any) {
        // Strip all characters but numerical ones.
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        const n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            
            toFixedFix = function (n:any, prec:any) {
                const k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        let s = [];
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = ".xlsx"
    const fileName = useStringField()

    const customExport = async(cols:any, datas:any) => {
        //const ws = XLSX.utils.json_to_sheet(datas) // previous method of creating sheet
        //const wb = { Sheets: { data: ws }, SheetNames: ["data"] } // previous method of creating sheet

        const headers = [[translate('date'), translate('reference_no'), translate('payment_channel'), translate('amount') + " (TL)", translate('report_charity_name'), translate('project_name'), translate('donor_id')]]
        
        //create a new workbook and then add the header
        const wb = XLSX.utils.book_new()
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, headers)
        //XLSX.utils.sheet_add_aoa(ws, [["Total Donation Amount (TL)", "1.050,145"]], { origin: "A1" }) // if needed, to add custom info to sheet
        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, datas, { origin: "A2", skipHeader: true })

        XLSX.utils.book_append_sheet(wb, ws, 'Dernek_Bagis_Raporu');

        // set column widths - for each column accordingly
        const wscols = [
            {wpx: 120},
            {wpx: 80},
            {wpx: 100},
            {wpx: 80},
            {wpx: 280},
            {wpx: 280},
            {wpx: 200}
        ];
        ws['!cols'] = wscols;

        //style all cells in loop dynamically
        for (const i in ws) {
            if (typeof(ws[i]) != "object") continue
            const cell = XLSX.utils.decode_cell(i)
            
            //border for every cell
            ws[i].s = {
                border: {
                    right: {
                        style: "thin",
                        color: "000000"
                    },
                    left: {
                        style: "thin",
                        color: "000000"
                    },
                    top: {
                        style: "thin",
                        color: "000000"
                    },
                    bottom: {
                        style: "thin",
                        color: "000000"
                    },
                }
            }

            //first row/table headers - make font bold
            if (cell.r == 0 )
            {
                ws[i].s = {
                    font: {
                        bold: true,
                    },
                    border: {
                        right: {
                            style: "thin",
                            color: "000000"
                        },
                        left: {
                            style: "thin",
                            color: "000000"
                        },
                        top: {
                            style: "thin",
                            color: "000000"
                        },
                        bottom: {
                            style: "thin",
                            color: "000000"
                        },
                    }
                }
            }

            //number field
            if(cell.c == 3 && cell.r > 0){
                ws[i].s = { 
                    numFmt : "00" // other numbers
                }
                ws[i].s = { 
                    alignment : {
                        horizontal : "right"
                    },
                    border: {
                        right: {
                            style: "thin",
                            color: "000000"
                        },
                        left: {
                            style: "thin",
                            color: "000000"
                        },
                        top: {
                            style: "thin",
                            color: "000000"
                        },
                        bottom: {
                            style: "thin",
                            color: "000000"
                        },
                    }
                }
                //ws[i].z = "00"
                //ws[i].t = "n"
            }
            //date field
            if(cell.c == 0 && cell.r > 0){
                ws[i].s = { 
                    //numFmt : "dd/mm/yyyy hh:mm", // for dates
                    alignment : {
                        horizontal : "right"
                    },
                    border: {
                        right: {
                            style: "thin",
                            color: "000000"
                        },
                        left: {
                            style: "thin",
                            color: "000000"
                        },
                        top: {
                            style: "thin",
                            color: "000000"
                        },
                        bottom: {
                            style: "thin",
                            color: "000000"
                        },
                    }
                }
               ws[i].z = "dd/mm/yyyy hh:mm"
               ws[i].t = "d" //force cells to display in excel date format
            }
        }
        
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, fileName.value + fileExtension)
    }

    return (
        <StatefulPage state={page}>
            <AuthorizedContent>
                <Title titleKey={'my_charity_donation_report'}/>
                <Only when={charities.length > 1}>
                    <Box className={'report-date-parent'}>
                        <div>
                            <DateField
                                label={'start_date'}
                                field={startDate}/>
                        </div>
                        <div>
                            <DateField
                                label={'end_date'}
                                field={endDate}/>
                        </div>
                    </Box>
                    <Box marginTop={2} className={'report-charity-parent'}>
                        <div>
                            <SelectField
                                label={'select_charity'}
                                field={charity_id}
                                itemsAsTuples={charities?.map(option => [option.id, option.name])}
                            />
                        </div>  
                        <div className={'update-button-wrap'}>
                            <Button
                                color={'primary'}
                                variant={'contained'}
                                children={translate('filter')}
                                onClick={getFilteredData}
                            />
                        </div>
                    </Box>
                    <Box className={'report-info-parent charity-reportt'}>
                        <Only when={!!reportDate.value}>
                            <div className={'report-info-date-wrap'}>
                                <p>{translate('report_date')} : </p>
                                <p>{reportDate.value}</p>
                            </div>
                        </Only>
                        <Only when={!!reports && reports[0]?.total > 0}>
                            <div className={'report-info-total-wrap'}>
                                <p>{translate('total_donation_amount')} : </p>
                                <p>{number_format(reports[0]?.total, 0, ',','.')} TL</p>
                            </div>
                        </Only>
                    </Box>
                    <MaterialTable
                        title={''}
                        //icons={tableIcons} - works fine without including table icons for some reason
                        options={{
                            emptyRowsWhenPaging: false,
                            draggable: false,
                            pageSize: 25,
                            pageSizeOptions: [10, 25, 50, 100, 200],
                            actionsColumnIndex: -1,
                            exportAllData: true,
                            exportMenu: [
                                {
                                label: translate('export_to_excel'),
                                exportFunc: (cols, datas) => customExport(cols, datas)//console.log({ cols, datas }), //custom excel export function here
                                },
                            ],
                        }}
                        localization={{
                            toolbar: {
                                searchPlaceholder: translate('search'),
                                searchTooltip: translate('search')
                            },
                        }}
                        data={reports}
                        columns={[
                            {title: translate('date'), field: 'date', defaultSort: 'desc'},
                            {title: translate('reference_no'), field: 'ref_no'},
                            {title: translate('payment_channel'), field: 'payment_method'},
                            {title: translate('amount') + " (TL)", field: 'amount'},
                            {title: translate('report_charity_name'), field: 'charity_name'},
                            {title: translate('project_name'), field: 'project_name'},
                            {title: translate('donor_id'), field: 'donor_id'},
                        ] as Column<IDonationReport>[]}
                    />
                </Only>
            </AuthorizedContent>
        </StatefulPage>
    )
}
