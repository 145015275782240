import {Box} from '@material-ui/core'
import {Facebook, Link, Twitter} from '@material-ui/icons'
import {SocialButton} from 'components/misc/SocialButton'
import {ICause} from 'types/cause'
import React from 'react'
import {useAppSnackbar} from 'utils/hooks'
import {buildUrl} from 'utils/general'

export function SocialShare(props: {
    cause: ICause
}) {
    const {cause} = props
    const {showSnackbar} = useAppSnackbar()

    const pageURL = window.location.origin + window.location.pathname

    const links = {
        facebook: buildUrl('https://facebook.com/sharer/sharer.php', {
            u: pageURL,
            t: cause.name,
        }),
        twitter: buildUrl('https://twitter.com/intent/tweet', {
            url: pageURL,
            text: cause.name,
        }),
    }

    return (
        <Box display={'flex'}>
            <SocialButton size={'normal'} link={links.facebook}>
                <Facebook style={{color: 'gray'}}/>
            </SocialButton>
            <Box margin={1}/>
            <SocialButton size={'normal'} link={links.twitter}>
                <Twitter style={{color: 'gray'}}/>
            </SocialButton>
            <Box margin={1}/>
            <SocialButton size={'normal'} onClick={async () => {
                // Works only on https or localhost
                if (navigator.clipboard) {
                    await navigator.clipboard.writeText(pageURL)
                    showSnackbar('info', 'copy_to_clipboard')
                } else {
                    showSnackbar('error', 'no_https_action_error')
                }
            }}>
                <Link style={{color: 'gray'}}/>
            </SocialButton>
        </Box>
    )
}
