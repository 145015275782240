import Button from '@material-ui/core/Button'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Edit} from '@material-ui/icons'
import {FormField} from 'components/forms/FormField'
import {SelectField} from 'components/forms/SelectField'
import {useNumberField, useStringField} from 'components/foundation/EditableField'
import {DialogOpenState, StatefulDialog, useStatefulDialogState} from 'components/foundation/StatefulDialog'
import {Only} from 'components/util/Only'
import {ICharity} from 'types/charity'
import {ICreateResponse} from 'types/types'
import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useAuth} from 'state/auth'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useRequest, useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {ValidateThat} from 'utils/ValidateThat'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        button: {
            marginLeft: spacing(1)
        },
    }),
)

export function NewCauseDialog(props: { openState: DialogOpenState, onSuccess?: () => void }) {
    const {openState, onSuccess} = props
    const {button} = useStyles()
    const {auth} = useAuth()
    const {translate} = useTranslation()
    const history = useHistory()
    const {makeRequest} = useRequest()

    const dialog = useStatefulDialogState()

    const [charities, setCharities] = useState<ICharity[]>()
    const [causeID, setCauseID] = useState(0)

    const causeName = useStringField(StringLength.SM)
    const charityID = useNumberField()

    const fields = [causeName, charityID]

    useRequestEffect<ICharity[]>({
        if: openState.isOpen,
        url: api.user.read_charities,
        data: {
            user_id: Number(auth?.userID)
        },
        onSuccess: data => {
            setCharities(data)

            if (data.length === 0){
                dialog.setWarnMessage('no_charities_desc')
            }
        }
    }, [openState.isOpen])

    const onCreate = () => makeRequest<ICreateResponse>({
        if: ValidateThat.allFieldsAreTrue(
            causeName.validate(),
            charityID.validate(value => value),
        ),
        url: api.cause.create,
        state: dialog,
        data: {
            name: causeName.value,
            charity_id: Number(charityID.value),
        },
        onStart: dialog.clearMessages,
        onSuccess: data => {
            dialog.setSuccessMessage('cause_create_success')
            onSuccess?.()
            setCauseID(data.id)
        }
    })

    const onClose = () => openState.setOpen(false)

    return (
        <StatefulDialog
            titleKey={'create_new_cause'}
            state={dialog}
            openState={openState}
            fields={fields}
            content={
                <Only when={{charities}}>{({charities}) =>
                    <>
                        <FormField
                            label={'cause_name'}
                            type={'text'}
                            field={causeName}
                            onEnter={onCreate}
                        />
                        <SelectField
                            label={'charity'}
                            itemsAsTuples={charities.map(charity => [charity.id, charity.name])}
                            field={charityID}
                        />
                    </>
                }</Only>
            }
            buttons={
                <div>
                    <Button className={button} onClick={onClose} variant={'outlined'} color="primary">
                        {translate('cancel')}
                    </Button>
                    <Button className={button} onClick={onCreate} color="primary" variant={'contained'}>
                        {translate('create')}
                    </Button>
                </div>
            }
            successActions={
                <Button
                    variant={'outlined'}
                    onClick={() => history.push(route.to.cause(causeID).edit)}
                    startIcon={<Edit/>}
                    children={translate('proceed_to_edit')}
                />
            }
        />
    )
}
