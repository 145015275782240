import {Box, Button} from '@material-ui/core'
import {VpnKeyOutlined, VpnKeyTwoTone} from '@material-ui/icons'
import {FormField} from 'components/forms/FormField'
import {useStringField} from 'components/foundation/EditableField'
import {DialogOpenState, StatefulDialog, useStatefulDialogState} from 'components/foundation/StatefulDialog'
import {CustomDivider} from 'components/misc/CustomDivider'
import React from 'react'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useRequest} from 'utils/request'
import {ValidateThat} from 'utils/ValidateThat'

export function VerifyPhoneDialog(props: { openState: DialogOpenState }) {
    const {openState} = props
    const {translate} = useTranslation()
    const {user} = useUser()
    const {makeRequest} = useRequest()

    const dialog = useStatefulDialogState()

    const sms_code = useStringField(StringLength.XXS)
    const fields = [sms_code]

    const onSubmit = () => makeRequest({
        if: [
            sms_code.validate(value => value, 'this_field_is_required'),
        ].every(Boolean),
        url: api.user.check_verification_code,
        state: dialog,
        data: {
            id: user?.id,
            phone: user?.phone_number,
            ...sms_code.box('confirmation_code'),
        },
        onSuccess: () => {
            dialog.setSuccessMessage('phone_verify_success')
            window.location.reload()
        },
        onFail: resultCode => {
            dialog.setErrorMessage('verify_phone_error_msg')
        }
    })


    function onClose() {
        openState.setOpen(false)
    }

    return (
        <StatefulDialog
            state={dialog}
            openState={openState}
            titleKey={'sms_confirmation_text'}
            content={
                <div>
                    <FormField
                        label={'confirmation_code'}
                        type={'text'}
                        field={sms_code}
                        icon={<VpnKeyOutlined/>}
                        onEnter={onSubmit}
                    />
                </div>
            }
            buttons={
                <Box display={'flex'}>
                    <Button onClick={onClose} color="primary" variant={'outlined'}>
                        {translate('cancel')}
                    </Button>
                    <Box marginX={1}/>
                    <Button onClick={onSubmit} color="primary" variant={'contained'}>
                        {translate('continue')}
                    </Button>
                </Box>
            }
            fields={fields}
        />
    )
}
