import {Box, Typography} from '@material-ui/core'
import React, {ReactElement} from 'react'
import {useTheme} from '@material-ui/core/styles'

export function IconWithText(props: { className?: string, icon: ReactElement, text?: ReactElement | string | null }) {
    const {className, icon, text} = props
    const {palette} = useTheme()

    return (
        <Box className={className} display={'flex'}>
            <Box display={'flex'} marginRight={1} style={{color: palette.secondary.main}}>
                {icon}
            </Box>
            <Typography>{text}</Typography>
        </Box>
    )
}
