import {useState} from '@hookstate/core'
import {Box, Button, Paper, Tab, Tabs} from '@material-ui/core'
import {Save} from '@material-ui/icons'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Only} from 'components/util/Only'
import {Extends} from 'types/types'
import React from 'react'
import Editor from 'rich-markdown-editor'
import {LanguageKey} from 'state/lang'
import {useLangs} from 'state/langs'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequest, useRequestEffect} from 'utils/request'
import {TranslationKey} from 'utils/TranslationKey'

export type ValidArticleResource = Extends<TranslationKey,
    | 'about_us'
    | 'how_it_works'
    | 'privacy_and_security'
    | 'faq'>;

type IArticle = {
    resource_id: ValidArticleResource,
} & Record<LanguageKey, string | null>

export function ArticleEditor(props: {
    resourceId: ValidArticleResource
}) {
    const {resourceId} = props
    const page = useStatefulPageState()

    const {makeRequest} = useRequest()
    const {translate} = useTranslation()
    const {langs} = useLangs()

    const defaultArticle = useState<IArticle | undefined>(undefined)
    const article = useState<IArticle | undefined>(undefined)
    const editLang = useState<LanguageKey>('tr')

    useRequestEffect<IArticle>({
        state: page,
        url: api.article.read,
        data: {
            resource_id: resourceId,
        },
        onSuccess: data => {
            defaultArticle.set(data)
            article.set(data)
        }
    })

    const onUpdate = () => makeRequest({
        if: !!article.value,
        url: api.article.update,
        state: page,
        data: {
            resource_id: resourceId,
            lang: editLang.value,
            text: article?.value?.[editLang.value]
        },
        onStart: page.clearMessages,
        onSuccess: () => page.setSuccessMessage('website_update_success'),
        onFail: resultCode => {
            if (resultCode === 'INVALID_IMAGE_TYPE') {
                page.setErrorMessage('unsupported_image_format_desc')
            } else {
                page.setUnexpectedFrontendError()
            }
        }
    })

    return (
        <StatefulPage state={page}>
            <Only when={{defaultArticle: defaultArticle.ornull}}>{({defaultArticle}) =>
                <Only when={{article: article.ornull}}>{({article}) =>
                    <Only when={{langs}}>{({langs}) =>
                        <Box padding={2}>
                            <Box display={'flex'}>
                                <Tabs
                                    orientation={'vertical'}
                                    value={editLang.value}
                                    indicatorColor="primary"
                                    textColor="secondary"
                                    onChange={(_, value) => editLang.set(value)}
                                    children={Object.entries(langs).map(
                                        ([key, lang]) => <Tab key={key} value={key} label={lang.name}/>
                                    )}
                                />
                                <Box marginLeft={2} flex={1} children={
                                    Object.keys(langs).map((key) =>
                                        <EditorPanel
                                            key={key}
                                            lang={key as LanguageKey}
                                            editLang={editLang.value}
                                            defaultValue={defaultArticle[key as LanguageKey].get() ?? ''}
                                            onChange={value => article[key as LanguageKey].set(value)}
                                        />
                                    )
                                }/>
                            </Box>
                            <Box display={'flex'} justifyContent={'flex-end'} marginY={4}>
                                <Button
                                    children={translate('update')}
                                    startIcon={<Save/>}
                                    onClick={onUpdate}
                                    variant={'contained'}
                                    color="primary"
                                />
                            </Box>
                        </Box>
                    }</Only>
                }</Only>
            }</Only>
        </StatefulPage>
    )
}

function EditorPanel(props: {
    lang: LanguageKey,
    editLang: LanguageKey,
    defaultValue: string,
    onChange: (value: string) => void
}) {
    const {lang, editLang, defaultValue, onChange} = props

    // The Editor breaks when Dark Reader extension is on
    return (
        <Only when={lang === editLang}>
            <Paper style={{padding: '16px 32px'}}>
                <Editor
                    defaultValue={defaultValue}
                    disableExtensions={['container_notice', 'highlight']}
                    onChange={callback => onChange(callback().replaceAll(/\\/gm, ''))}
                />
            </Paper>
        </Only>
    )
}
