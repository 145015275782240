import {Box, Button} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import {Cancel, Crop169, Save, Subtitles, TextFieldsOutlined} from '@material-ui/icons'
import {FormField} from 'components/forms/FormField'
import {ImageField} from 'components/forms/ImageField'
import {SelectField} from 'components/forms/SelectField'
import {
    FieldModeContext,
    useImageBase64Field,
    useNumberField,
    useStringField
} from 'components/foundation/EditableField'
import {DialogOpenState, StatefulDialog, useStatefulDialogState} from 'components/foundation/StatefulDialog'
import {ICause} from 'types/cause'
import {IHomeBanner} from 'types/types'
import React, {useEffect} from 'react'
import {useLang} from 'state/lang'
import {useRawTranslation, useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {StringLength} from 'utils/enums'
import {useRequest} from 'utils/request'

export function EditBannerSliderDialog(props: {
    openState: DialogOpenState<IHomeBanner | undefined>
    causes: ICause[]
    onEdited: () => void
}) {
    const {openState, causes, onEdited} = props
    const {translate} = useTranslation()
    const {refreshTranslations} = useRawTranslation()
    const {lang} = useLang()
    const theme = useTheme()
    const dialog = useStatefulDialogState()
    const {makeRequest} = useRequest()

    const slide = openState.data
    const imageField = useImageBase64Field()
    const causeIdField = useNumberField(slide?.cause_id)
    const titleField = useStringField(StringLength.TEXT)
    const subtitleField = useStringField(StringLength.TEXT)
    const buttonTextField = useStringField(StringLength.TEXT)

    useEffect(() => {
        imageField.setInitialValue(slide?.image_path)
        causeIdField.setInitialValue(slide?.cause_id)
        titleField.setInitialValue(translate(slide?.title_key))
        subtitleField.setInitialValue(translate(slide?.subtitle_key))
        buttonTextField.setInitialValue(translate(slide?.button_key))
    }, [slide])

    async function onEdit() {
        makeRequest({
            state: dialog,
            url: api.home.banner.update,
            data: {
                banner_id: slide?.id,
                lang: lang,
                ...(await imageField.box('image_base64', true)),
                ...causeIdField.box('cause_id'),
                ...titleField.box('title'),
                ...subtitleField.box('subtitle'),
                ...buttonTextField.box('button_text'),
            },
            onSuccess: () => {
                openState.close()
                refreshTranslations()
                onEdited()
            }
        }).then()
    }

    return (
        <StatefulDialog
            state={dialog}
            showCloseButton={true}
            titleKey={'banner_slide_editor'}
            maxWidth={'md'}
            openState={openState}
            content={
                <FieldModeContext.Provider value={'edit'}>
                    <Box>
                        <ImageField
                            field={imageField}
                            disableDelete={true}
                            disableCropper={true}
                            preferredDimensions={'512x512'}
                            background={theme.palette.secondary.main}
                        />
                        <SelectField
                            label={'cause'}
                            field={causeIdField}
                            itemsAsTuples={causes.map(cause => [cause.id, cause.name])}
                        />
                        <FormField
                            label={'title'}
                            field={titleField}
                            icon={<TextFieldsOutlined/>}
                        />
                        <FormField
                            label={'subtitle'}
                            field={subtitleField}
                            icon={<Subtitles/>}
                        />
                        <FormField
                            label={'button_text'}
                            field={buttonTextField}
                            icon={<Crop169/>}
                        />
                    </Box>
                </FieldModeContext.Provider>
            }
            buttons={
                <Box display={'flex'} alignItems={'end'}>
                    <Button
                        color={'secondary'}
                        startIcon={<Save/>}
                        children={translate('save')}
                        onClick={onEdit}
                    />
                    <Box margin={1}/>
                    <Button
                        color={'secondary'}
                        startIcon={<Cancel/>}
                        children={translate('cancel')}
                        onClick={() => openState.setOpen(false)}
                    />
                </Box>
            }
        />
    )
}