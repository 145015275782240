import {Box, CircularProgress} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {ICause} from 'types/cause'
import {ICompetitionItem, ISupportedCause} from 'types/types'
import React, {useState} from 'react'
import {useAuth} from 'state/auth'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useUpdater} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'
import {Only} from 'components/util/Only'
import {ProjectAction} from './ProjectAction'
import {CompetitionInfoDialog} from './CompetitionInfoDialog'
import {SupportDialog, SupportDialogData} from '../support-dialog/SupportDialog'
import {ConfirmationDialog} from 'components/dialogs/ConfirmationDialog'
import {IUser} from 'types/user'
import {useAppSnackbar} from 'utils/hooks'

export function ProjectActions(props: {
    cause: ICause
    className?: string
}) {
    const {cause, className} = props
    const {translate} = useTranslation()
    const [supported, setSupported] = useState<ISupportedCause | undefined>()

    const {auth} = useAuth()
    const {makeRequest} = useRequest()
    const {showSnackbar} = useAppSnackbar()

    const supportDialogOpenState = useDialogOpenState<SupportDialogData>({
        project: 'donation',
        action: 'show_result',
        amount: 0
    })

    const InfoDialogOpenState = useDialogOpenState<ICompetitionItem>({
        photo_text: '',
        image_path: '',
        can_delete: "false",
    })

    const InfoDefault:ICompetitionItem = {
        photo_text: '',
        image_path: '',
        can_delete: "false",
    }

    const {updateFlag, update} = useUpdater()

    useRequestEffect({
        if: !!cause.id && !!Number(cause.id) && !!auth,
        url: api.cause.read_support,
        data: {
            id: Number(cause.id),
            min_amount : props.cause.projects.competition.min_donation_amount
        },
        onSuccess: setSupported,
    }, [updateFlag])

    const [showSpinner, setShowSpinner] = useState(false)
    const deleteSubmissionOpenState = useDialogOpenState(undefined)

    function deleteSubmission() {
        makeRequest({
            //state: page,
            url: api.cause.delete_competition_submission,
            data: {
                id: Number(cause.id)
            },
            onStart: () => setShowSpinner(true),
            onFinish: () => setShowSpinner(false),
            onSuccess: () => {
                update()
                showSnackbar('success', 'delete_competition_msg')
            },
            onFail: () => {
                showSnackbar('error', 'unexpected_backend_error')
            }
        }).then()
    }

    return (
        <>
            <CompetitionInfoDialog openState={InfoDialogOpenState}/>
            <ConfirmationDialog
                    openState={deleteSubmissionOpenState}
                    text={'delete_competition_submission_confirm'}
                    onOk={deleteSubmission}
                />
            <SupportDialog
                openState={supportDialogOpenState}
                causeId={cause.id}
                causeName={cause.name}
                causeOwner={cause.charity.name}
                causeNameAlt={cause.name_alt}
                causeOwnerAlt={cause.charity.name_alt}
                {...{
                    update,
                    cause
                }}
            />
            <Box className={className}>
                <ProjectAction
                    project={'donation'}
                    when={cause.projects.donation.is_active}
                    cause={cause}
                    title={'cause_support_donation'}
                    currentValue={cause.projects.donation.sum}
                    totalValue={cause.projects.donation.goal}
                    // keywords_en={cause.projects.donation.keywords_en}
                    // keywords_tr={cause.projects.donation.keywords_tr}
                    // short_number={cause.projects.donation.short_number}
                    // enable_sms={cause.projects.donation.enable_sms}
                    currentText={'donation_current_text'}
                    totalText={'donation_total_text'}
                    sign={'TL'}
                    donation_check={false}
                    age_check={false}
                    buttonText={'cause_button_donation'}
                    withAmountInput={true}
                    formatNumber={true}
                    onAction={amount => supportDialogOpenState.open({
                        amount, action: 'support_cause', project: 'donation'
                    })}
                />
                <ProjectAction
                    project={'event'}
                    when={cause.projects.event.is_active}
                    cause={cause}
                    title={'cause_support_event'}
                    currentValue={cause.projects.event.count}
                    totalValue={cause.projects.event.capacity}
                    currentText={'event_current_text'}
                    totalText={'event_total_text'}
                    sign={translate('tickets')}
                    donation_check={false}
                    age_check={false}
                    buttonText={'cause_button_event'}
                    statusText={
                        cause.projects.event.count >= cause.projects.event.capacity
                            ? 'no_available_tickets'
                            : undefined}
                    withAmountInput={true}
                    datetime={cause.projects.event.datetime}
                    ticketPrice={cause.projects.event.ticket_price}
                    onAction={amount => supportDialogOpenState.open({
                        amount, action: 'support_cause', project: 'event'
                    })}
                />
                <ProjectAction
                    project={'activity'}
                    when={cause.projects.activity.is_active}
                    cause={cause}
                    disabled={!supported || supported['activity']}
                    title={'cause_support_activity'}
                    currentValue={cause.projects.activity.count}
                    totalValue={cause.projects.activity.capacity}
                    currentText={'activity_current_text'}
                    totalText={'activity_total_text'}
                    sign={translate('people')}
                    donation_check={false}
                    age_check={false}
                    buttonText={'cause_button_activity'}
                    //statusText={supported?.activity ? 'already_joined_cause' : undefined}
                    statusText={supported?.activity ? 'already_joined_cause' : cause.projects.activity.count >= cause.projects.activity.capacity ? 'cause_not_active' : undefined}
                    withAmountInput={false}
                    datetime={cause.projects.activity.datetime}
                    onAction={amount => supportDialogOpenState.open({
                        amount: amount, action: 'support_cause', project: 'activity'
                    })}
                />
                <ProjectAction
                    project={'volunteering'}
                    when={cause.projects.volunteering.is_active}
                    cause={cause}
                    disabled={!supported || supported['volunteering']}
                    title={'cause_support_volunteering'}
                    currentValue={cause.projects.volunteering.count}
                    totalValue={cause.projects.volunteering.capacity}
                    currentText={'volunteering_current_text'}
                    totalText={'volunteering_total_text'}
                    //statusText={supported?.volunteering ? 'already_volunteered_cause' : undefined}
                    statusText={supported?.volunteering ? 'already_volunteered_cause' : cause.projects.volunteering.count >= cause.projects.volunteering.capacity ? 'cause_not_active' : undefined}
                    sign={translate('people')}
                    donation_check={false}
                    age_check={false}
                    buttonText={'cause_button_volunteering'}
                    withAmountInput={false}
                    onAction={amount => supportDialogOpenState.open({
                        amount: amount, action: 'support_cause', project: 'volunteering'
                    })}
                />
                <ProjectAction
                    project={'competition'}
                    when={cause.projects.competition.is_active}
                    cause={cause}
                    disabled={!supported || supported['competition']}
                    title={'cause_support_competition'}
                    currentValue={cause.projects.competition.count}
                    totalValue={cause.projects.competition.capacity}
                    currentText={'competition_current_text'}
                    totalText={'competition_total_text'}
                    //statusText={supported?.competition ? 'already_participated_cause' : undefined}
                    statusText={supported?.competition ? 'already_participated_cause' : cause.projects.competition.count >= cause.projects.competition.capacity ? 'cause_not_active' : undefined}
                    sign={translate('people')}
                    donation_check={supported?.donation_check ? true : false}
                    age_check={false}
                    buttonText={'cause_button_competition'}
                    withAmountInput={false}
                    onAction={amount => supportDialogOpenState.open({
                        amount: amount, action: 'support_cause', project: 'competition'
                    })}
                />
                <Only when={!!supported && supported['competition'] && supported['competition_details']}>
                    <Box className={'comp-btns-wrap'}>
                        <Button
                            onClick={() => InfoDialogOpenState.open(supported?.competition_details ? supported.competition_details : InfoDefault)}
                            variant={'contained'}
                            color="secondary"
                            children={translate('view_competition_submission')}
                        />
                        <Only when={supported?.competition_details.can_delete == "true"}>
                            <Button className={''}
                                variant={'contained'}
                                style={{backgroundColor: '#f44336', color: '#fff'}}
                                children={translate('delete_competition_submission')}
                                onClick={() => deleteSubmissionOpenState.setOpen(true)}
                            />
                        </Only>
                    </Box>
                    <Box className='comp-spinner-wrap'>
                        {showSpinner ? <CircularProgress size={32}/> : '' }
                    </Box>
                </Only>
            </Box>
        </>
    )
}
