import {DatePicker} from '@material-ui/pickers'
import {format} from 'date-fns'
import React from 'react'
import {useTranslation} from 'state/translation'
import {TranslationKey} from 'utils/TranslationKey'
import {EditableField, StringField} from '../foundation/EditableField'
import {DateContextProvider} from '../misc/DateContextProvider'

export function DateField(props: {
    className?: string,
    label: TranslationKey,
    field: StringField
}) {
    const {translate} = useTranslation()
    const {className, label, field} = props

    return (
        <EditableField field={field} marginTop={1}>
            <DateContextProvider>
                <DatePicker
                    className={className}
                    views={['year', 'month', 'date']}
                    openTo={'date'}
                    label={translate(label)}
                    fullWidth={true}
                    clearable={true}
                    clearLabel={translate('clear')}
                    okLabel={translate('ok')}
                    cancelLabel={translate('cancel')}
                    margin={'dense'}
                    inputVariant="outlined"
                    disabled={field.disabled}
                    variant={'dialog'}
                    format="MMMM dd, yyyy"
                    value={field.value ? field.value : null}
                    onChange={date => {
                        field.setValue(date ? format(date, 'yyyy-MM-dd') : '')
                        field.clearHint()
                    }}
                />
            </DateContextProvider>
        </EditableField>
    )
}
