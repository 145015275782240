import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import {App} from 'app/App'

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
)
