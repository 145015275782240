import {Box, ButtonBase, Typography} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {ElevatedCard} from 'components/cards/ElevatedCard'
import {ImageWithFallback} from 'components/misc/ImageWithFallback'
import {RichText} from 'components/misc/RichText'
import {YoutubeEmbed} from 'components/misc/YoutubeEmbed'
import {Only} from 'components/util/Only'
import {RouterLink} from 'components/util/RouterLink'
import {ICause} from 'types/cause'
import {ICharityCard} from 'types/charity'
import React from 'react'
import {useTranslation} from 'state/translation'
import {route} from 'utils/route'
import DOMPurify from 'dompurify'
//import { JSDOM } from 'jsdom'

//const window: any = (new JSDOM('')).window
//const DOMPurify = createDOMPurify(window)

const useStyles = makeStyles(() =>
    createStyles({
        image: {
            maxHeight: 128,
            maxWidth: 128,
        }
    })
)

function SponsorCard(props: {
    charity: ICharityCard
}) {
    const {charity} = props
    const {image} = useStyles()

    return (
        <ElevatedCard style={{width: 'max-content'}}>
            <RouterLink to={route.to.charity(charity.id).page}>
                <ButtonBase>
                    <ImageWithFallback
                        className={image}
                        alt={charity.name}
                        src={charity.logo_url}
                        width={'auto'}
                        height={'auto'}
                    />
                </ButtonBase>
            </RouterLink>
        </ElevatedCard>
    )
}

export function Description(props: { className: string, cause: ICause }) {
    const {className, cause} = props
    const {translate} = useTranslation()
    const cleanHTML = DOMPurify.sanitize(cause.description || '', {
        USE_PROFILES: { html: true },
      });
    return (
        <Box className={className} marginTop={2}>
            <RichText size={'huge'} bold text={translate('cause_description')} marginBottom={16}/>
            <Typography style={{textAlign: 'justify', fontWeight: 'lighter'}}>
                { <span dangerouslySetInnerHTML={{ __html: cleanHTML }} /> }
            </Typography>
            <Box marginTop={2}>
                <YoutubeEmbed id={cause.youtube_id}/>
            </Box>
            <RichText size={'huge'} bold text={translate('cause_owner')} marginTop={16}/>
            <Box marginTop={2}>
                <SponsorCard charity={cause.charity}/>
            </Box>
            <Only when={cause.sponsors.length > 0}>
                <RichText size={'huge'} bold text={translate('sponsors')} marginTop={16}/>
                <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>{
                    cause.sponsors.map(sponsor =>
                        <Box key={sponsor.id} marginTop={2} marginRight={2}>
                            <SponsorCard charity={sponsor}/>
                        </Box>
                    )
                }</Box>
            </Only>
        </Box>
    )
}
