import {Box, Button} from '@material-ui/core'
import {Language} from '@material-ui/icons'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Title} from 'components/misc/Title'
import React from 'react'
import {useTranslation} from 'state/translation'
import {LanguageToggleDialog} from './components/LanguageToggleDialog'
import {TranslationTable} from './components/TranslationTable'

export function Translation() {
    const page = useStatefulPageState()
    const {translate} = useTranslation()
    const languageDialog = useDialogOpenState(undefined)

    return (
        <StatefulPage state={page}>
            <LanguageToggleDialog openState={languageDialog}/>
            <Title titleKey={'translation'}/>
            <Button
                style={{margin: 'auto'}}
                startIcon={<Language/>}
                variant={'outlined'}
                color={'secondary'}
                onClick={() => languageDialog.setOpen(true)}
                children={translate('change_enabled_languages')}
            />
            <Box marginTop={2}>
                <TranslationTable/>
            </Box>
        </StatefulPage>
    )
}
