import React, {useState} from 'react'
import {Box} from '@material-ui/core'
import {AppHelmet} from '../../components/util/AppHelmet'
import {route} from '../../utils/route'
import {Title} from '../../components/misc/Title'
import {StatefulPage, useStatefulPageState} from '../../components/foundation/StatefulPage'
import {ICause} from '../../types/cause'
import {useRequestEffect} from '../../utils/request'
import {api} from '../../utils/api'
import {Only} from '../../components/util/Only'
import {CauseGridWithPagination} from './CauseGridWithPagination'

export function ExpiredCauses() {
    const page = useStatefulPageState()

    const [causes, setCauses] = useState<ICause[]>([])

    useRequestEffect<ICause[]>({
        state: page,
        url: api.cause.read_expired,
        onSuccess: setCauses
    })

    return (
        <StatefulPage state={page}>
            <AppHelmet canonicalRoute={route.to.expired_causes}/>
            <Box display={'flex'} flexDirection={'column'}>
                <Title titleKey={'expired_projects'}/>
                <Only when={!page.loading}>
                    <CauseGridWithPagination causes={causes}/>
                </Only>
            </Box>
        </StatefulPage>
    )
}
