import React, {CSSProperties} from 'react'
import {PanoramaOutlined} from '@material-ui/icons'

export function ImageWithFallback(props: {
    className?: string
    src?: string | null
    alt?: string
    width?: number | string
    height?: number | string
    style?: CSSProperties
}) {
    const {className, src, alt, width, height, style} = props

    return src ?
        <img
            className={className}
            src={src}
            alt={alt}
            width={width ?? '100%'}
            height={height ?? '100%'}
            style={{
                objectFit: 'cover',
                ...style
            }}
        /> :
        <PanoramaOutlined
            className={className}
            style={{
                width: width ?? '100%',
                height: height ?? '100%',
                color: 'gray',
                ...style
            }}
        />

}
