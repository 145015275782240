import {useTranslation} from '../../../state/translation'
import React, {useState, useEffect} from 'react'
import {StringField} from '../../../components/foundation/EditableField'
import {Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@material-ui/core'
import {Only} from '../../../components/util/Only'
import {FormField} from '../../../components/forms/FormField'
import {SelectField} from 'components/forms/SelectField'
import {AccountCircleOutlined, EmailOutlined} from '@material-ui/icons'
import {StateMethods} from '@hookstate/core'
import {useLang} from 'state/lang'
import cert_bg_tr from './media/cert_bg_tr.png'
import cert_bg_en from './media/cert_bg_en.png'
import DOMPurify from 'dompurify'

let sel_certificate:any = ''
let filtered_content:any = ''
let user_name:any = ''
let receiver_name:any = ''

export function ContactInfoStep(props: {
    sendCertificate: StateMethods<boolean>
    name: StringField
    email: StringField
    receiver_name: StringField
    receiver_email: StringField
    donation_type: StringField
    show_amount: StringField
    cert_lang: StringField
    cert_id: StringField
    causeName: string
    causeOwner: string
    causeNameAlt: string
    causeOwnerAlt: string
    amount: number | null
    filtered_certificates: any
}) {
    const {translate} = useTranslation()
    const {lang} = useLang()

    const group_options = [
        ['individual',translate('individual_donation')],
        ['gift',translate('gift')],
        ['org',translate('org')]
    ]

    const amount_options = [
        ['1' ,translate('show_amount')],
        ['0' ,translate('hide_amount')]
    ]

    const lang_options = [
        ['tr' ,translate('turkish')],
        ['en' ,translate('english')]
    ]

    if(props.cert_id.value){
        for(let i=0; i<props.filtered_certificates.length; i++){
            if(props.cert_id.value == props.filtered_certificates[i].id){
                sel_certificate = props.filtered_certificates[i]; break
            }
        }
    }else{
        sel_certificate = ''
    }
    
    function number_format(number:any, decimals:any, dec_point:any, thousands_sep:any) {
        // Strip all characters but numerical ones.
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        const n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            
            toFixedFix = function (n:any, prec:any) {
                const k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        let s = [];
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    const PrintPreview = (content:any) => {
        if(props.cert_id.value){
            for(let i=0; i<props.filtered_certificates.length; i++){
                if(content.preview_content == props.filtered_certificates[i].id){
                    sel_certificate = props.filtered_certificates[i]; break
                }
            }
        }else{
            sel_certificate = ''
        }
        if(sel_certificate){
            if(props.donation_type.value == 'org'){
                user_name = props.name.value? props.name.value.replace(/\s+/g, "&nbsp;") : translate('org_name')
            }else{
                user_name = props.name.value? props.name.value.replace(/\s+/g, "&nbsp;") : translate('your_name_surname')
            }
            receiver_name = props.receiver_name.value? props.receiver_name.value.replace(/\s+/g, "&nbsp;") : translate('full_name')
            
            if(props.cert_lang.value === "tr"){
                if(props.show_amount.value === '1'){
                    filtered_content = sel_certificate.content_tr
                }else{
                    filtered_content = sel_certificate.content_alt_tr
                }
                filtered_content = filtered_content.replace("{{$fee_quantity}}", number_format(props.amount, 0, ',','.') + "&nbsp;TL")
            }else if(props.cert_lang.value === "en"){
                if(props.show_amount.value === '1'){
                    filtered_content = sel_certificate.content_en
                }else{
                    filtered_content = sel_certificate.content_alt_en
                }
                filtered_content = filtered_content.replace("{{$fee_quantity}}", number_format(props.amount, 0, '.',',') + "&nbsp;TL")
            }

            if(props.cert_lang.value === lang){
                filtered_content = filtered_content.replace("{{$charity_name}}", props.causeOwner)
                filtered_content = filtered_content.replace("{{$project_name}}", props.causeName)
            }else{
                filtered_content = filtered_content.replace("{{$charity_name}}", props.causeOwnerAlt)
                filtered_content = filtered_content.replace("{{$project_name}}", props.causeNameAlt)
            }
            filtered_content = filtered_content.replace("{{$name_surname}}", user_name)
            //filtered_content = filtered_content.replace("{{$fee_quantity}}", props.amount + " TL")
            filtered_content = filtered_content.replace("{{$to_name_surname}}", receiver_name)
        }
        return (
            <div className={'cert-preview-content'} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(filtered_content || '', {
                USE_PROFILES: { html: true },
              }) }}></div>
        )
    }

    return (
        <Box className={'cert-module-parent'}>
            <FormControl component="fieldset" className={'cert-form-wrap'}>
                <FormLabel
                    component="legend"
                    color={'primary'}
                    children={translate('general_donation_desc')}
                />
                <Box marginTop={1}/>
                <RadioGroup
                    value={props.sendCertificate.value}
                    onChange={e => props.sendCertificate.set(e.target.value === 'true')}
                >
                    <FormControlLabel
                        control={<Radio/>}
                        value={false}
                        label={translate('general_donation_do_not_send_certificate')}/>
                    <FormControlLabel
                        control={<Radio/>}
                        value={true}
                        label={translate('general_donation_send_certificate')}/>
                </RadioGroup>
                <Only when={props.sendCertificate.value}>
                    
                    <Box marginTop={2}>
                        <SelectField
                            label={'donation_type'}
                            field={props.donation_type}
                            itemsAsTuples={group_options.map(option => [option[0], option[1]])}
                        />
                        <SelectField
                            label={'certificate_type'}
                            field={props.cert_id}
                            itemsAsTuples={props.filtered_certificates.map(
                                (certificate:any) => [certificate.id, lang=='tr'? certificate.name_tr : certificate.name_en])
                            }
                        />
                        <FormField
                            label={props.donation_type.value == 'org'? 'org_name' : 'your_name_surname'}
                            icon={<AccountCircleOutlined/>}
                            field={props.name}
                        />
                        <FormField
                            type={'email'}
                            label={'your_email'}
                            icon={<EmailOutlined/>}
                            field={props.email}
                        />
                        <SelectField
                            label={'certificate_language'}
                            field={props.cert_lang}
                            itemsAsTuples={lang_options.map(option => [option[0], option[1]])}
                        />
                        <SelectField
                            label={'donation_amount'}
                            field={props.show_amount}
                            itemsAsTuples={amount_options.map(option => [option[0], option[1]])}
                        />
                        <Only when={props.donation_type.value == 'gift'}>
                            <Box> 
                                <p style={{color : 'dimgrey'}}>{translate('recipient_information')}</p>
                            </Box>
                            <FormField
                                label={'full_name'}
                                icon={<AccountCircleOutlined/>}
                                field={props.receiver_name}
                            />
                            <FormField
                                type={'email'}
                                label={'email'}
                                icon={<EmailOutlined/>}
                                field={props.receiver_email}
                            />
                        </Only>
                    </Box>
                    
                </Only>
            </FormControl>
            <Only when={props.sendCertificate.value}>
                <Only when={!!sel_certificate && !!props.cert_id.value}>
                    <Box marginLeft={3} className={'cert-preview-wrap'}>
                        <div className={'cert-bg-wrap'}> 
                            <Only when={props.cert_lang.value == 'tr'}>
                                <img src={cert_bg_tr} className={'cert-bg-img'} />
                            </Only>
                            <Only when={props.cert_lang.value == 'en'}>
                                <img src={cert_bg_en} className={'cert-bg-img'}/>
                            </Only>
                            <Only when={!!sel_certificate.icon_path}>
                                <div className={'cert-preview-img'}>
                                    <img src={sel_certificate.icon_path} width={100} style={{margin: 'auto'}}/>
                                </div>
                            </Only>
                            <div className={'cert-preview-parent'}>
                                <PrintPreview preview_content={props.cert_id.value}/>
                            </div>
                        </div>
                    </Box>
                </Only>
            </Only>
        </Box>
    )
}
