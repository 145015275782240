import {Box} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {ResponsiveGrid} from '../../components/foundation/ResponsiveGrid'
import {CauseCard} from '../../components/cards/CauseCard'
import {Pagination} from '@material-ui/lab'
import {ICause} from 'types/cause'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Only} from '../../components/util/Only'
import {RichText} from '../../components/misc/RichText'
import {useTranslation} from '../../state/translation'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        causeCard: {
            width: spacing(35),
        },
        pagination: {
            margin: 'auto'
        }
    })
)

const CARDS_PER_PAGE = 6

export function CauseGridWithPagination(props: {
    causes: ICause[]
}) {
    const {causes} = props
    const classes = useStyles()

    const {translate} = useTranslation()

    const [paginatedCauses, setPaginatedCauses] = useState<ICause[]>([])
    const [pageNumber, setPageNumber] = useState(1)

    useEffect(() => {
        setPageNumber(1)
    }, [causes])

    useEffect(() => {
        const start = (pageNumber - 1) * CARDS_PER_PAGE
        const end = start + CARDS_PER_PAGE

        setPaginatedCauses(causes.slice(start, end))
    }, [causes, pageNumber])

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Only when={causes.length > 0}>
                <ResponsiveGrid
                    breakpointColumns={{
                        mobile: 1,
                        tablet: 2,
                        laptop: 3,
                    }}
                    children={
                        paginatedCauses.map(cause =>
                            <CauseCard
                                key={cause.id}
                                cause={cause}
                                className={classes.causeCard}
                                mode={'normal'}
                            />
                        )
                    }
                />
                <Box display={'flex'} marginTop={4}>
                    <Pagination
                        className={classes.pagination}
                        count={Math.ceil(causes.length / CARDS_PER_PAGE)}
                        page={pageNumber}
                        onChange={(_, value) => setPageNumber(value)}
                        variant="outlined"
                        shape="rounded"
                    />
                </Box>
            </Only>
            <Only when={causes.length == 0}>
                <RichText
                    text={translate('not_found')}
                    size={'huge'}
                    color={'gray'}
                    textAlign={'center'}
                />
            </Only>
        </Box>
    )
}