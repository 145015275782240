import {RefObject} from 'react'

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export function scrollIntoView(ref: RefObject<HTMLElement>, predicate: boolean) {
    if (predicate) {ref.current?.scrollIntoView({behavior: 'smooth'})}
}

export function propsAreEqual<T extends Record<string, unknown>, S extends Pick<T, K>, K extends keyof T & keyof S>(
    first: T, second: S, props: K[]
) {
    return props.every(prop => first[prop] === (second as Pick<T, K>)[prop])
}

export function cloneObject<T extends Record<string, unknown>>(obj: T): T
export function cloneObject<T extends Record<string, unknown>>(obj: T | null): T | null
export function cloneObject<T extends Record<string, unknown>>(obj: T | null): T | null {
    return obj ? JSON.parse(JSON.stringify(obj)) : null
}

export function getFormattedNumber(number: number | string | null) {
    if (number === null || number === undefined) {
        number = 'null'
    }
    if (typeof number === 'number') {
        number = Number(number).toLocaleString('de-DE', {maximumFractionDigits: 2})
    }
    return number
}

export function buildUrl(baseUrl: string, queryParams: Record<string, string>) {
    const url = new URL(baseUrl)
    for (const param of Object.keys(queryParams)) {
        url.searchParams.append(param, queryParams[param])
    }
    return url.href
}

export function getQueryParam(param: string) {
    return new URLSearchParams(window.location.search).get(param)
}

export function openInNewTab(url: string) {
    window.open(url, '_blank')?.focus()
}

export function extractYoutubeId(value: string) {
    const regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?vi?=|&vi?=))([^#&?]*).*/
    const match = value.match(regExp)
    return (match && match[1].length === 11) ? match[1] : value
}
