import React, {ComponentProps} from 'react'
import {Helmet} from 'react-helmet-async'

export function AppHelmet({canonicalRoute, description, noIndex, themeColor, title, image, image_width, image_height, url, type, keywords, ...helmetProps}: {
    canonicalRoute?: string
    description?: string | null
    noIndex?: boolean | null
    themeColor?: string | null,
    title?: string | null,
    image?: string | null,
    image_width?: string | null,
    image_height?: string | null,
    url?: string | null,
    type?: string | null,
    keywords?: string | null
} & ComponentProps<typeof Helmet>) {
    return (
        <Helmet {...helmetProps} >
            {canonicalRoute && <link rel="canonical" href={window.location.origin + canonicalRoute}/>}
            {url && <meta name="url" content={url}/>}
            {url && <meta name="og:url" content={url}/>}
            {type && <meta name="type" content={type}/>}
            {type && <meta name="og:type" content={type}/>}
            {title &&  <meta name="og:title" content={title}/>}
            {description && <meta name="description" content={description}/>}
            {description && <meta name="og:description" content={description}/>}
            {image && <meta name="og:image" content={image}/>}
            {image_width && <meta name="og:image:width" content={image_width}/>}
            {image_height && <meta name="og:image:height" content={image_height}/>}
            {noIndex && <meta name="robots" content="noindex"/>}
            {themeColor && <meta name="theme-color" content={themeColor}/>}
            {title && <title children={title}/>}
        </Helmet>
    )
}