import {Box, Paper, Tab, Tabs} from '@material-ui/core'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import {Extends} from 'types/types'
import React, {ReactElement, useState} from 'react'
import {useTranslation} from 'state/translation'
import {TranslationKey} from 'utils/TranslationKey'
import {ArticleEditor, ValidArticleResource} from './component/ArticleEditor'
import {HomeManagement} from './component/HomeManagement'

type TabKeys = Extends<TranslationKey, 'home_page' | ValidArticleResource>

const tabs: Record<TabKeys, ReactElement> = {
    'home_page': <HomeManagement/>,
    'about_us': <ArticleEditor resourceId={'about_us'}/>,
    'how_it_works': <ArticleEditor resourceId={'how_it_works'}/>,
    'faq': <ArticleEditor resourceId={'faq'}/>,
    'privacy_and_security': <ArticleEditor resourceId={'privacy_and_security'}/>,
}

export function WebsiteManagement() {
    const [currentTab, setCurrentTab] = useState<TabKeys>('home_page')
    const {translate} = useTranslation()


    return (
        <Box paddingTop={2}>
            <AppHelmet title={translate('manage_website')}/>
            <Paper style={{
                margin: 'auto',
                maxWidth: 'max-content'
            }}>
                <Tabs
                    value={currentTab}
                    variant={'scrollable'}
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="secondary"
                    onChange={(_, tab) => setCurrentTab(tab)}
                    children={Object.keys(tabs).map(key =>
                        <Tab key={key} value={key} label={translate(key as TranslationKey)}/>
                    )}
                />
            </Paper>
            <Box>{
                Object.entries(tabs).map(([key, component]) =>
                    <Only key={key} when={key === currentTab} children={component}/>
                )
            }</Box>
        </Box>
    )
}
