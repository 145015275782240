import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'state/translation'
import {TranslationKey} from 'utils/TranslationKey'
import {EditableField, NumberField, StringField} from '../foundation/EditableField'

export function SelectField<T extends string | number>(props: {
    label: TranslationKey,
    items?: T[]
    itemsAsTuples?: [T, string][]
    field: T extends string ? StringField : NumberField
    margin?: 'dense' | 'none'
}) {
    const {translate} = useTranslation()
    const {label, items, itemsAsTuples, field, margin} = props

    return (
        <EditableField field={field} marginTop={1}>
            <FormControl
                margin={'dense'}
                variant="outlined"
                fullWidth
                error={!!field.hint}
                disabled={field.disabled}
                style={{marginBottom: 24}}
            >
                <InputLabel children={translate(label)}/>
                <Select
                    autoWidth={true}
                    value={field.value ?? ''}
                    margin={margin ?? 'dense'}
                    label={translate(label)}
                    onChange={event => {
                        field.setValue(event.target.value as string & number)
                        field.clearHint()
                        event.preventDefault()
                    }}>
                    {
                        items?.map(item =>
                            <MenuItem
                                key={item}
                                value={item}
                                children={translate(item as TranslationKey)}
                            />
                        )
                    }
                    {
                        itemsAsTuples?.map(([value, label]) =>
                            <MenuItem
                                key={value}
                                value={value}
                                children={label}
                            />
                        )
                    }
                </Select>
                <FormHelperText>{field.hint}</FormHelperText>
            </FormControl>
        </EditableField>
    )
}
