import {Box, Button, Menu, MenuItem} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {AccountCircleOutlined, KeyboardArrowDown} from '@material-ui/icons'
import {DialogOpenState} from 'components/foundation/StatefulDialog'
import {Only} from 'components/util/Only'
import {bindMenu, bindTrigger, usePopupState} from 'material-ui-popup-state/hooks'
import React from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from 'state/auth'
import {useTranslation} from 'state/translation'
import {route} from 'utils/route'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        buttonIcon: {
            fontSize: '32px !important'
        },
        button: {
            color: 'rgba(255,255,255,0.9)',
            fontSize: '1.1rem'
        },
        menuItem: {
            minWidth: spacing(15),
            paddingLeft: spacing(2),
            paddingRight: spacing(2),
        }
    }),
)

export function AuthMenuButton(props: { loginDialog: DialogOpenState }) {
    const {loginDialog} = props
    const {buttonIcon, button, menuItem} = useStyles()
    const {auth} = useAuth()

    const popupState = usePopupState({variant: 'popover', popupId: 'avatar'})
    const {translate} = useTranslation()

    return (
        <Only when={!auth}>
            <Box display={'flex'} alignItems={'flex-end'}>
                <Button
                    className={button}
                    size={'large'}
                    startIcon={<KeyboardArrowDown/>}
                    endIcon={<AccountCircleOutlined className={buttonIcon}/>}
                    {...bindTrigger(popupState)}
                />
                <Menu {...bindMenu(popupState)} keepMounted={true}>
                    <MenuItem
                        className={menuItem}
                        onClick={() => {
                            loginDialog.setOpen(true)
                            popupState.close()
                        }}
                        children={translate('login')}
                    />
                    <MenuItem
                        className={menuItem}
                        component={Link}
                        to={route.to.sign_up.page}
                        onClick={popupState.close}
                        children={translate('sign_up')}
                    />
                </Menu>
            </Box>
        </Only>
    )
}
