import {Box, Button} from '@material-ui/core'
import {DialogOpenState, StatefulDialog, useStatefulDialogState} from 'components/foundation/StatefulDialog'
import React from 'react'
import {useTranslation} from 'state/translation'
import {ICause, ICauseType} from 'types/cause'
import {IUser} from 'types/user'
import {Only} from 'components/util/Only'
import {EmailOutlined, PersonOutlined, PhoneOutlined} from '@material-ui/icons'

type IContribution = {
    project: ICauseType
    datetime: string,
    photo_text: string,
    image_path: string,
    cause: Pick<ICause, 'id' | 'name'>,
    user?: Pick<IUser, 'id' | 'name' | 'email' | 'phone_number'>
}

export function CompetitionInfoAdminDialog(props: { 
    openState: DialogOpenState<IContribution> 
    adminUser: boolean
}) {
    const {openState, adminUser} = props
    const { project, photo_text, image_path, cause, user} = openState.data
    const {translate} = useTranslation()
    
    const dialog = useStatefulDialogState()

    function onClose() {
        openState.setOpen(false)
    }

    return (
        <StatefulDialog
            state={dialog}
            openState={openState}
            titleKey={'competition_submission_details'}
            content={
                <div>
                    <div className={'competition-details-wrap'}>
                        <p><b>{translate('cause')} : </b>{cause.name}</p>
                        <Only when={adminUser}>
                            <p className={'text-underline'}><b>{translate('user_info')} : </b></p>
                            <p className={'admin-uinfo-wrap'}>
                                <span><PersonOutlined/>{user?.name}</span>
                                <span><EmailOutlined/>{user?.email}</span>
                                <span><PhoneOutlined/>{user?.phone_number}</span>
                            </p>
                        </Only>
                        <Only when={!adminUser}>
                            <p><b>{translate('user_info')} : </b>{user?.id}</p>
                        </Only>
                        <p className={'text-underline'}><b>{translate('contribution_info')} : </b></p>
                        <a href={image_path} target="_blank" rel="noreferrer"><img src={image_path} /></a>
                        <p>{photo_text}</p>
                    </div>
                </div>
            }
            buttons={
                <Box display={'flex'}>
                    <Button onClick={onClose} color="primary" variant={'contained'}>
                        {translate('close')}
                    </Button>
                </Box>
            }
        />
    )
}
