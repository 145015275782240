import {Column} from '@material-table/core'
import {Button, CircularProgress} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Save, Visibility} from '@material-ui/icons'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {CustomSelect} from 'components/misc/CustomSelect'
import {CustomTable} from 'components/misc/CustomTable'
import {Title} from 'components/misc/Title'
import {IUser} from 'types/user'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {useUser} from 'state/user'
import {api} from 'utils/api'
import {useAppSnackbar} from 'utils/hooks'
import {useRequest, useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {propsAreEqual} from 'utils/general'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        menuItem: {
            minWidth: spacing(10),
            paddingLeft: spacing(2),
            paddingRight: spacing(2),
        }
    })
)

type StateField = 'is_active' | 'is_admin' | 'is_super_admin'
type StateProps = Pick<IUser, StateField>
type StateLoadingProps = StateProps & { loading: boolean }
type UserWithState = IUser & { state: StateLoadingProps }

export function ReviewUsers() {
    const {menuItem} = useStyles()
    const page = useStatefulPageState()
    const {translate} = useTranslation()
    const {user} = useUser()
    const {showSnackbar} = useAppSnackbar()
    const {makeRequest} = useRequest()

    const [users, setUsers] = useState<UserWithState[]>([])

    function updateUserProps(id: number, props: Partial<UserWithState>) {
        setUsers(users.map(it => it.id === id ? {...it, ...props} : it))
    }

    function updateUserState(id: number, props: Partial<StateLoadingProps>) {
        setUsers(users.map(it => it.id === id ? {...it, state: {...it.state, ...props}} : it))
    }

    useRequestEffect<IUser[]>({
        state: page,
        url: api.user.read_all,
        onSuccess: data => setUsers(data.map(it => ({
            ...it, state: {
                loading: false,
                is_active: it.is_active,
                is_admin: it.is_admin,
                is_super_admin: it.is_super_admin,
            }
        })))
    })

    const lookup = {lookup: {false: translate('no'), true: translate('yes')}}

    function CustomBooleanSelect(props: { userData: UserWithState, field: StateField }) {
        const {userData, field} = props
        return (
            <CustomSelect
                variant={'outlined'}
                disabled={userData.id === user?.id}
                endIcon={<KeyboardArrowDownIcon/>}
                menuItemClassName={menuItem}
                onItemSelect={item => {
                    if (userData.state[field] !== item.key) {updateUserState(userData.id, {[field]: item.key})}
                }}
                items={[
                    {key: true, text: lookup.lookup.true},
                    {key: false, text: lookup.lookup.false},
                ]}
                children={lookup.lookup[userData.state[field] ? 'true' : 'false']}
            />
        )
    }

    return (
        <StatefulPage state={page}>
            <Title titleKey={'review_users'}/>
            <CustomTable
                data={users}
                columns={[
                    {title: translate('full_name'), field: 'name'},
                    {title: translate('email_address'), field: 'email'},
                    {title: translate('is_verified'), field: 'is_verified', ...lookup},
                    {
                        title: translate('is_active'),
                        render: data => <CustomBooleanSelect userData={data} field={'is_active'}/>
                    },
                    {
                        title: translate('is_admin'), field: 'is_admin', ...lookup,
                        ...(user?.is_super_admin && {
                            render: data => <CustomBooleanSelect userData={data} field={'is_admin'}/>
                        })
                    },
                    {
                        title: translate('is_super_admin'), field: 'is_super_admin', ...lookup,
                        ...(user?.is_super_admin && {
                            render: data => <CustomBooleanSelect userData={data} field={'is_super_admin'}/>
                        })
                    },
                    {
                        title: translate('details'),
                        render: data =>
                            <Button
                                variant="outlined"
                                color={'secondary'}
                                startIcon={<Visibility/>}
                                children={translate('view')}
                                component={Link}
                                to={route.to.user(data.id).page}
                            />
                    },
                ] as Column<UserWithState>[]}
                actions={[
                    data => {
                        const disabled = propsAreEqual(data, data.state,
                            ['is_active', 'is_admin', 'is_super_admin'])

                        return {
                            icon: () => data.state.loading ?
                                <CircularProgress color={'primary'} size={24}/> :
                                <Save color={disabled ? 'disabled' : 'secondary'}/>,
                            disabled: disabled || data.state.loading,
                            onClick: () => {
                                // return object only if the given property was changed
                                const param = (prop: keyof StateProps) =>
                                    data[prop] !== data.state[prop] ? {[prop]: data.state[prop]} : {}

                                makeRequest({
                                    url: api.user.update_status,
                                    data: {
                                        id: Number(data.id),
                                        ...param('is_active'),
                                        ...param('is_admin'),
                                        ...param('is_super_admin'),
                                    },
                                    onStart: () => updateUserState(data.id, {loading: true}),
                                    onFinish: success => {
                                        updateUserProps(data.id, {
                                            ...(success && data.state),
                                            state: {
                                                ...data.state,
                                                loading: false
                                            }
                                        })
                                        showSnackbar(success ? 'success' : 'error',
                                            success ? 'user_update_success' : 'user_update_fail')
                                    },
                                }).then()
                            }
                        }
                    }
                ]}
            />
        </StatefulPage>
    )
}
