import React, {useState} from 'react'
import {Box} from '@material-ui/core'
import {StatefulPage, useStatefulPageState} from '../../components/foundation/StatefulPage'
import {route} from 'utils/route'
import {AppHelmet} from 'components/util/AppHelmet'
import {api} from 'utils/api'
import {Only} from 'components/util/Only'
import {useLang} from 'state/lang'
import {useTranslation} from 'state/translation'
import {useRequestEffect} from 'utils/request'
import {INews} from 'types/types'
// import news_1 from './news-1.jpg'
// import news_2 from './news-2.jpg'
// import news_3 from './news-3.jpg'
// import news_4 from './news-4.jpg'

export function News() {
    const page = useStatefulPageState()
    const {lang} = useLang()
    const {translate} = useTranslation()

    const [news, setNews] = useState<INews[]>([])


    useRequestEffect<INews[]>({
        //state: page,
        url: api.news.read_public,
        onSuccess: setNews
    })

    return (
        <Box>
            <Only when={lang === 'tr'}>
                <StatefulPage state={page}>
                    <AppHelmet 
                        title = {translate('news')}
                        canonicalRoute={route.to.news}
                    />
                    <Only when={news.length > 0}>
                        <Box display={'flex'} flexDirection={'column'}>
                            <h1 className={'news-heading'}>{translate('news')}</h1>
                            <div className={'news-container'}>
                                <div className={'news-row'}>
                                    {news.map((news_item, index) =>
                                        <div key={index} className={'news-wrap'}>
                                            <div className={'news-image'}>
                                                <a href={news_item.url} target="_blank" rel="noreferrer">
                                                    <img src={news_item.image_path} />
                                                </a>
                                            </div>
                                            <div className={'news-info'}>
                                                <a className={'news-link'} href={news_item.url} target="_blank" rel="noreferrer">
                                                    <h1>{news_item.title}</h1>
                                                </a>
                                                <p>{news_item.description}</p>
                                                <a className={'news-read-more'} href={news_item.url} target="_blank" rel="noreferrer">Devamını Oku</a>
                                            </div>
                                        </div>
                                    )}

                                    {/* <div className={'news-wrap'}>
                                        <div className={'news-image'}>
                                            <a href="https://www.kktcell.com/hakkimizda/haberler/kuzey-kibris-turkcell-calisanlarindan-cocuklara-gonulden-paylasim" target="_blank" rel="noreferrer">
                                                <img src={news_1} />
                                            </a>
                                        </div>
                                        <div className={'news-info'}>
                                            <a className={'news-link'} href="https://www.kktcell.com/hakkimizda/haberler/kuzey-kibris-turkcell-calisanlarindan-cocuklara-gonulden-paylasim" target="_blank" rel="noreferrer">
                                                <h1>Kuzey Kıbrıs Turkcell çalışanlarından çocuklara gönülden paylaşım!</h1>
                                            </a>
                                            <p>Girne Bellapais Inner Wheel Derneği tarafından başlatılan ve gonuldenbagliyiz.biz platformu üzerinde yer alan “Dilek Ağacı” projesi, Kuzey Kıbrıs Turkcell çalışanlarının bağışlarıyla hedefine ulaştı. Proje kapsamında Zümrütköy İlkokulu’nda okuyan öğrencilerin tamamı dilek ağacına diledikleri hed...</p>
                                            <a className={'news-read-more'} href="https://www.kktcell.com/hakkimizda/haberler/northernland-grouptan-gonuldenbagliyizbiz-ve-efidankibriscom-siteleri-uzerinden-100-bin-tllik-destek" target="_blank" rel="noreferrer">Devamını Oku</a>
                                        </div>
                                    </div>

                                    <div className={'news-wrap'}>
                                        <div className={'news-image'}>
                                            <a href="https://www.kktcell.com/hakkimizda/haberler/northernland-grouptan-gonuldenbagliyizbiz-ve-efidankibriscom-siteleri-uzerinden-100-bin-tllik-destek" target="_blank" rel="noreferrer">
                                                <img src={news_2} />
                                            </a>
                                        </div>
                                        <div className={'news-info'}>
                                            <a className={'news-link'} href="https://www.kktcell.com/hakkimizda/haberler/northernland-grouptan-gonuldenbagliyizbiz-ve-efidankibriscom-siteleri-uzerinden-100-bin-tllik-destek" target="_blank" rel="noreferrer">
                                                <h1>NorthernLAND Group’tan gonuldenbagliyiz.biz ve efidankibris.com siteleri üzerinden 100 bin TL’lik destek!</h1>
                                            </a>
                                            <p>Kuzey Kıbrıs Turkcell tarafından hayata geçirilen dijital sosyal sorumluluk platformları gonuldenbagliyiz.biz ve efidankibris.com bağışlarla büyüyor. NorthernLAND Group, yeni yıl dolayısı ile gonuldenbagliyiz.biz ve efidankibris.com sosyal sorumluluk platformları üzerinden yaptığı bağışlarla iyil...</p>
                                            <a className={'news-read-more'} href="https://www.kktcell.com/hakkimizda/haberler/northernland-grouptan-gonuldenbagliyizbiz-ve-efidankibriscom-siteleri-uzerinden-100-bin-tllik-destek" target="_blank" rel="noreferrer">Devamını Oku</a>
                                        </div>
                                    </div>

                                    <div className={'news-wrap'}>
                                        <div className={'news-image'}>
                                            <a href="https://www.kktcell.com/hakkimizda/haberler/kibris-hayvan-haklari-derneginin-bir-el-uzat-bir-can-kurtar-projesi-kuzey-kibris-turkcell-ile-hedefine-ulasti" target="_blank" rel="noreferrer">
                                                <img src={news_3} />
                                            </a>
                                        </div>
                                        <div className={'news-info'}>
                                            <a className={'news-link'} href="https://www.kktcell.com/hakkimizda/haberler/kibris-hayvan-haklari-derneginin-bir-el-uzat-bir-can-kurtar-projesi-kuzey-kibris-turkcell-ile-hedefine-ulasti" target="_blank" rel="noreferrer">
                                                <h1>Kıbrıs Hayvan Hakları Derneği’nin “Bir el uzat bir can kurtar” projesi Kuzey Kıbrıs Turkcell ile hedefine ulaştı</h1>
                                            </a>
                                            <p>gonuldenbagliyiz.biz platformu iyiliği çoğaltıyor Kuzey Kıbrıs Turkcell, Kıbrıs Hayvan Hakları Derneği tarafından gonuldenbagliyiz.biz platformunda yer alan “Onların tek sesi biziz! Gelin birlikte ikinci şansları olalım. Bir el uzat bir can kurtar” projesinin hedefine ulaşması için gereken katkı...</p>
                                            <a className={'news-read-more'} href="https://www.kktcell.com/hakkimizda/haberler/northernland-grouptan-gonuldenbagliyizbiz-ve-efidankibriscom-siteleri-uzerinden-100-bin-tllik-destek" target="_blank" rel="noreferrer">Devamını Oku</a>
                                        </div>
                                    </div>

                                    <div className={'news-wrap'}>
                                        <div className={'news-image'}>
                                            <a href="https://www.kktcell.com/hakkimizda/haberler/gonuldenbagliyizbiz-platformunda-yer-alan-projeler-hedeflerine-ulasiyor" target="_blank" rel="noreferrer">
                                                <img src={news_4} />
                                            </a>
                                        </div>
                                        <div className={'news-info'}>
                                            <a className={'news-link'} href="https://www.kktcell.com/hakkimizda/haberler/gonuldenbagliyizbiz-platformunda-yer-alan-projeler-hedeflerine-ulasiyor" target="_blank" rel="noreferrer">
                                                <h1>gonuldenbagliyiz.biz platformunda yer alan projeler hedeflerine ulaşıyor</h1>
                                            </a>
                                            <p>Kuzey Kıbrıs Turkcell tarafından hayata geçirilen dijital sosyal sorumluluk platformu gonuldenbagliyiz.biz, açılan projeleri hedeflerine taşıyor. Kıbrıs Türk Eğitim Vakfı tarafından açılan ve gonuldenbagliyiz.biz platformu içerisinde yer alan, “Suya erişim, her çocuğun hakkıdır” projesi, Cypri Co...</p>
                                            <a className={'news-read-more'} href="https://www.kktcell.com/hakkimizda/haberler/northernland-grouptan-gonuldenbagliyizbiz-ve-efidankibriscom-siteleri-uzerinden-100-bin-tllik-destek" target="_blank" rel="noreferrer">Devamını Oku</a>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </Box>
                    </Only>
                </StatefulPage>
            </Only>
            <Only when={lang === 'en'}>
                <div className='news-empty-space'>

                </div>
            </Only>
        </Box>
    )
}