import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {JsonDialog} from 'components/dialogs/JsonDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Title} from 'components/misc/Title'
import {Only} from 'components/util/Only'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {useLang} from '../../state/lang'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'

const useStyles = makeStyles(({breakpoints, spacing}) =>
    createStyles({
        table: {
            margin: 'auto',
            marginBottom: spacing(2),
            maxWidth: breakpoints.values.md,
        },
    })
)

interface BaseInfo {
    id: number,
    name: string
}

interface BaseInfoWithValue extends BaseInfo {
    value: number
}

interface BaseInfoDetails {
    cause: BaseInfoWithValue | null,
    data: Record<string, unknown>
}

interface DashboardPrimitiveData {
    active_project_count: number
    total_project_count: number
    total_amount_raised: number
    total_paynet_donations: number
    total_invoice_donations: number
    total_sms_donations: number
    total_localpay_donations: number
    total_manual_donations: number
}

interface DashboardStringData {
    total_registered_donations: string
    total_unregistered_donations: string
}

interface DashboardLinkData {
    //top_supported_cause_by_count: BaseInfoWithValue | null
    top_supported_cause_by_amount: BaseInfoWithValue | null
    //cause_with_most_volunteers: BaseInfoWithValue | null
    //charity_with_most_causes: BaseInfoWithValue | null
}

interface DashboardArrayData {
    completely_funded_causes: Array<BaseInfo>
    not_completely_funded_causes: Array<BaseInfo>
    top_3_funded_causes: Array<BaseInfo>
}

interface DashboardArrayDataWithValue {
    cause_with_most_volunteers: Array<BaseInfoWithValue>
    charity_with_most_causes: Array<BaseInfoWithValue>
}

interface DashboardLinkDataWithDetails {
    top_supported_cause_by_count: BaseInfoDetails
    top_supported_cause_by_amount_one: BaseInfoDetails
    top_supported_cause_by_amount_two: BaseInfoDetails
    top_supported_cause_by_amount_three: BaseInfoDetails
}

type DashboardData = DashboardPrimitiveData & DashboardStringData & DashboardLinkData & DashboardArrayData & DashboardArrayDataWithValue & DashboardLinkDataWithDetails

export function Dashboard() {
    const classes = useStyles()
    const page = useStatefulPageState()
    const {translate} = useTranslation()
    const {lang} = useLang()

    const [logObject, setLogObject] = useState<Record<string, unknown>>({})
    const dialogOpenState = useDialogOpenState(undefined)

    const [dashboardData, setDashboardData] = useState<DashboardData | undefined>()

    useRequestEffect<DashboardData>({
        state: page,
        url: api.dashboard.read,
        onSuccess: setDashboardData
    }, [lang])

    return (
        <StatefulPage state={page}>
            <JsonDialog openState={dialogOpenState} logObject={logObject}/>
            <Title titleKey={'dashboard'}/>
            <Only when={{dashboardData}}>{({dashboardData}) =>
                <TableContainer component={Paper} className={classes.table}>
                    <Table className={'dashboard-table'}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{translate('metric')}</TableCell>
                                <TableCell>{translate('link')}</TableCell>
                                <TableCell>{translate('value')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <SimpleRow dashboardData={dashboardData} metric="active_project_count"/>
                            <RowWithArrayLinks
                                dashboardData={dashboardData}
                                metric="completely_funded_causes"
                            />
                            <SimpleRow dashboardData={dashboardData} metric="total_project_count"/>
                            <SimpleRow dashboardData={dashboardData} metric="total_amount_raised" sign={'₺'}/>
                            <SimpleRow dashboardData={dashboardData} metric="total_paynet_donations" sign={'₺'}/>
                            <SimpleRow dashboardData={dashboardData} metric="total_invoice_donations" sign={'₺'}/>
                            <SimpleRow dashboardData={dashboardData} metric="total_sms_donations" sign={'₺'}/>
                            <SimpleRow dashboardData={dashboardData} metric="total_localpay_donations" sign={'₺'}/>
                            <SimpleRow dashboardData={dashboardData} metric="total_manual_donations" sign={'₺'}/>
                            {/* <RowWithLink
                                dashboardData={dashboardData}
                                metric="top_supported_cause_by_count"
                                sign={translate('supporters')}
                            /> */}
                            {/*New Type - value with details button*/}
                            <TableRow>
                                <TableCell>{translate('top_supported_cause_by_count')}</TableCell>
                                <TableCell>
                                    <Only when={{info: dashboardData['top_supported_cause_by_count']['cause']}}>{({info}) =>
                                        <Link className ={'dashboard-link'}
                                            to={route.to['cause'](info.id).page}
                                            children={info.name}
                                        />
                                    }</Only>
                                </TableCell>
                                <TableCell align="right">
                                    <Only when={{info: dashboardData?.['top_supported_cause_by_count']['cause']}}>{({info}) =>
                                        `${info.value} ${translate('supporters')}`
                                    }</Only>
                                    <Only when={{info: dashboardData?.['top_supported_cause_by_count']['data']}}>{({info}) =>
                                        <Button
                                            className={'dashboard-detail-btn'}
                                            color={'primary'}
                                            variant={'contained'}
                                            children={translate('details')}
                                            onClick={() => {
                                                console.log('clicked' + info)
                                                setLogObject(info)
                                                dialogOpenState.setOpen(true)
                                            }}
                                        />
                                    }</Only>
                                </TableCell>
                            </TableRow>
                            {/* <RowWithLink
                                dashboardData={dashboardData}
                                metric="top_supported_cause_by_amount"
                                sign={'₺'}
                            /> */}
                            <TableRow>
                                <TableCell>{translate('top_supported_cause_1')}</TableCell>
                                <TableCell>
                                    <Only when={{info: dashboardData['top_supported_cause_by_amount_one']['cause']}}>{({info}) =>
                                        <Link className ={'dashboard-link'}
                                            to={route.to['cause'](info.id).page}
                                            children={info.name}
                                        />
                                    }</Only>
                                </TableCell>
                                <TableCell align="right">
                                    <Only when={{info: dashboardData?.['top_supported_cause_by_amount_one']['cause']}}>{({info}) =>
                                        `${info.value} ${'₺'}`
                                    }</Only>
                                    <Only when={{info: dashboardData?.['top_supported_cause_by_amount_one']['data']}}>{({info}) =>
                                        <Button
                                            className={'dashboard-detail-btn'}
                                            color={'primary'}
                                            variant={'contained'}
                                            children={translate('details')}
                                            onClick={() => {
                                                console.log('clicked' + info)
                                                setLogObject(info)
                                                dialogOpenState.setOpen(true)
                                            }}
                                        />
                                    }</Only>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{translate('top_supported_cause_2')}</TableCell>
                                <TableCell>
                                    <Only when={{info: dashboardData['top_supported_cause_by_amount_two']['cause']}}>{({info}) =>
                                        <Link className ={'dashboard-link'}
                                            to={route.to['cause'](info.id).page}
                                            children={info.name}
                                        />
                                    }</Only>
                                </TableCell>
                                <TableCell align="right">
                                    <Only when={{info: dashboardData?.['top_supported_cause_by_amount_two']['cause']}}>{({info}) =>
                                        `${info.value} ${'₺'}`
                                    }</Only>
                                    <Only when={{info: dashboardData?.['top_supported_cause_by_amount_two']['data']}}>{({info}) =>
                                        <Button
                                            className={'dashboard-detail-btn'}
                                            color={'primary'}
                                            variant={'contained'}
                                            children={translate('details')}
                                            onClick={() => {
                                                console.log('clicked' + info)
                                                setLogObject(info)
                                                dialogOpenState.setOpen(true)
                                            }}
                                        />
                                    }</Only>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{translate('top_supported_cause_3')}</TableCell>
                                <TableCell>
                                    <Only when={{info: dashboardData['top_supported_cause_by_amount_three']['cause']}}>{({info}) =>
                                        <Link className ={'dashboard-link'}
                                            to={route.to['cause'](info.id).page}
                                            children={info.name}
                                        />
                                    }</Only>
                                </TableCell>
                                <TableCell align="right">
                                    <Only when={{info: dashboardData?.['top_supported_cause_by_amount_three']['cause']}}>{({info}) =>
                                        `${info.value} ${'₺'}`
                                    }</Only>
                                    <Only when={{info: dashboardData?.['top_supported_cause_by_amount_three']['data']}}>{({info}) =>
                                        <Button
                                            className={'dashboard-detail-btn'}
                                            color={'primary'}
                                            variant={'contained'}
                                            children={translate('details')}
                                            onClick={() => {
                                                console.log('clicked' + info)
                                                setLogObject(info)
                                                dialogOpenState.setOpen(true)
                                            }}
                                        />
                                    }</Only>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell children={translate('total_registered_donations')}/>
                                <TableCell
                                    align="right"
                                    colSpan={2}
                                    children={`${dashboardData['total_registered_donations'] ?? ''}`}
                                />
                            </TableRow>
                            <TableRow>
                                <TableCell children={translate('total_unregistered_donations')}/>
                                <TableCell
                                    align="right"
                                    colSpan={2}
                                    children={`${dashboardData['total_unregistered_donations'] ?? ''}`}
                                />
                            </TableRow>
                            <RowWithArrayLinksAndValue
                                dashboardData={dashboardData}
                                metric="charity_with_most_causes"
                                sign={translate('causes')}
                                isCharity
                            />
                            <RowWithArrayLinksAndValue
                                dashboardData={dashboardData}
                                metric="cause_with_most_volunteers"
                                sign={translate('volunteers')}
                            />
                            <RowWithArrayLinks
                                dashboardData={dashboardData}
                                metric="not_completely_funded_causes"
                            />
                            {/* <RowWithArrayLinks
                                dashboardData={dashboardData}
                                metric="top_3_funded_causes"
                            /> */}
                        </TableBody>
                    </Table>
                </TableContainer>
            }</Only>
        </StatefulPage>
    )
}

function SimpleRow(props: {
    dashboardData: DashboardData,
    metric: keyof DashboardPrimitiveData
    sign?: string
}) {
    const {dashboardData, metric, sign} = props
    const {translate} = useTranslation()

    return (
        <TableRow>
            <TableCell children={translate(props.metric)}/>
            <TableCell
                align="right"
                colSpan={2}
                children={`${dashboardData[metric] ?? ''} ${sign ?? ''}`}
            />
        </TableRow>
    )
}

function RowWithLink(props: {
    dashboardData: DashboardData,
    metric: keyof DashboardLinkData
    sign: string
    isCharity?: boolean
}) {
    const {dashboardData, metric, sign, isCharity} = props
    const {translate} = useTranslation()

    return (
        <TableRow>
            <TableCell>{translate(metric)}</TableCell>
            <TableCell>
                <Only when={{info: dashboardData[metric]}}>{({info}) =>
                    <Link className ={'dashboard-link'}
                        to={route.to[isCharity ? 'charity' : 'cause'](info.id).page}
                        children={info.name}
                    />
                }</Only>
            </TableCell>
            <TableCell align="right">
                <Only when={{info: dashboardData?.[metric]}}>{({info}) =>
                    `${info.value} ${sign ?? ''}`
                }</Only>
            </TableCell>
        </TableRow>
    )
}

function RowWithArrayLinksAndValue(props: {
    dashboardData: DashboardData
    metric: keyof DashboardArrayDataWithValue
    sign: string
    isCharity?: boolean
}) {
    const {dashboardData, metric, sign, isCharity} = props
    const {translate} = useTranslation()

    return (
        <TableRow>
            <TableCell>{translate(metric)}</TableCell>
            <TableCell>
                <ul className={'dashboard-list'}>{
                    dashboardData?.[metric].map(data =>
                        <li key={data.id}>
                            <Link className ={'dashboard-link'}
                                to={route.to[isCharity ? 'charity' : 'cause'](data.id).page}
                                children={data.name}
                            />
                        </li>
                    )
                }</ul>
            </TableCell>
            <TableCell align="right">
                <ul className={'dashboard-false-list'}>{   
                    dashboardData?.[metric].map(data =>
                        <li key={data.id}>
                            {data.value} {sign ?? ''}
                        </li>
                    )
                }</ul>
            </TableCell>
        </TableRow>
    )
}

function RowWithArrayLinks(props: {
    dashboardData: DashboardData
    metric: keyof DashboardArrayData
}) {
    const {dashboardData, metric} = props
    const {translate} = useTranslation()

    return (
        <TableRow>
            <TableCell>{translate(metric)}</TableCell>
            <TableCell colSpan={2}>
                <ul className={'dashboard-list'}>{
                    dashboardData?.[metric].map(data =>
                        <li key={data.id}>
                            <Link className ={'dashboard-link'}
                                to={route.to.cause(data.id).page}
                                children={data.name}
                            />
                        </li>
                    )
                }</ul>
            </TableCell>
        </TableRow>
    )
}