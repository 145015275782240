import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import {el, enUS, ru, tr} from 'date-fns/locale'
import React, {PropsWithChildren} from 'react'
import {useLang} from 'state/lang'

export function DateContextProvider(props: PropsWithChildren<unknown>) {
    const {lang} = useLang()
    const localeMap = {
        el: el,
        en: enUS,
        ru: ru,
        tr: tr,
    }
    const locale = lang in localeMap ? localeMap[lang as keyof typeof localeMap] : enUS

    return <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={locale}
        children={props.children}
    />

}
