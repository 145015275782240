import React from 'react'
import {useRequestEffect} from '../../../utils/request'
import {api} from '../../../utils/api'
import {RichText} from '../../../components/misc/RichText'
import {useTranslation} from '../../../state/translation'
import {ICauseType} from '../../../types/cause'
import {useAuth} from '../../../state/auth'
import {useHistory} from 'react-router-dom'
import {ResultData} from './ResultStep'
import {StatefulDialogState} from '../../../components/foundation/StatefulDialog'
import {Only} from '../../../components/util/Only'
import {StateMethods} from '@hookstate/core'
import {PaymentMethodType} from '../../../types/types'

export function LoadingStep(props: {
    dialog: StatefulDialogState
    loadingNewPage: StateMethods<boolean>
    project: ICauseType
    paymentMethod: PaymentMethodType
    amount: number | null
    causeId: number
    sendCertificate: boolean
    update: () => void
    userInfo: {
        name: string
        email: string
        receiver_name : string
        receiver_email : string
        donation_type : string
        show_amount : string
        cert_lang : string
        cert_id : string
    }
}) {
    const {translate} = useTranslation()
    const {auth} = useAuth()
    const history = useHistory()

    const financial = ['donation', 'event'].includes(props.project)
    const anonymous = !auth

    useRequestEffect({
        state: props.dialog,
        url: api.cause.support[financial ? 'financial' : 'non_financial'],
        data: financial
            ? {
                payment_method: props.paymentMethod,
                payment_project: props.project,
                cause_id: props.causeId,
                ...(props.project === 'donation' && {amount: props.amount}),
                ...(props.project === 'event' && {ticket_count: props.amount}),
                anonymous,
                ...({
                    send_certificate: props.sendCertificate,
                    user_info: props.userInfo,
                })
            }
            : {
                cause_id: props.causeId,
                project: props.project,
            },
        onSuccess: financial
            ? (data: { url: string }) => {
                props.loadingNewPage.set(true)
                window.location.assign(data.url)
            }
            : () => {
                const url = new URL(window.location.origin)
                url.searchParams.append(ResultData.result, 'success')
                url.searchParams.append(ResultData.project, props.project)

                props.update()

                history.push(url.search)
            },
    })

    return (
        <Only when={props.dialog.loading}>
            <RichText text={translate('processing_your_request')}/>
        </Only>
    )
}

