import {TranslationKey} from 'utils/TranslationKey'
import {ICharityCard} from './charity'
import {IStatus} from './types'
import {IUser} from './user'

export enum ECauseType {
    donation = 'donation',
    event = 'event',
    activity = 'activity',
    volunteering = 'volunteering',
    competition = 'competition',
}

export type ICauseType = keyof typeof ECauseType
export const ACauseTypes = Object.keys(ECauseType)

export enum ECauseState {
    active = 'active',
    completed = 'completed',
    pending = 'pending',
}

export type ICauseState = keyof typeof ECauseState
export const ACauseState = Object.keys(ECauseType)

export type IAbstractCauseProject = {
    is_active: boolean
    datetime: string | null
    capacity: number
    count: number
    goal: number
    ticket_price: number | null
    sum: number
    enable_invoice: boolean
    enable_sms: boolean
    keywords_tr: string | null
    keywords_en: string | null
    enable_invoice_initial: boolean
    enable_sms_initial: boolean
    keywords_tr_initial: string | null
    keywords_en_initial: string | null
    min_web_amount: number | null
    max_web_amount: number | null
    min_sms_amount: number | null
    max_sms_amount: number | null
    min_donation_amount: number | null //minimum donations for competition projects
    short_number: string | null
    file_path: string
}

export type ICausePaymentMethods = {
    paynet: boolean,
    localpay: boolean,
    kktcell_invoice: boolean,
    kktcell_sms: boolean,
    // hepi: boolean,
}

export type ICauseInfo = {
    id: number
    name: string
}

export type ICause = {
    id: number
    name: string
    name_alt: string
    summary: string | null
    description: string | null
    category: TranslationKey | null
    start_date: string | null
    end_date: string | null
    youtube_id: string | null
    image_url: string | null
    state: ICauseState
    status: IStatus
    charity: ICharityCard & { payment_methods: ICausePaymentMethods },
    owner: Pick<IUser, 'id'>
    sponsors: ICharityCard[],
    projects: {
        activity: Pick<IAbstractCauseProject, 'is_active' | 'datetime' | 'capacity' | 'count'>,
        donation: Pick<IAbstractCauseProject, 'is_active' | 'goal' | 'sum' | 'enable_sms' | 'enable_invoice' | 'keywords_tr' | 'keywords_en' | 'enable_sms_initial' | 'enable_invoice_initial' | 'keywords_tr_initial' | 'keywords_en_initial' | 'min_web_amount' | 'max_web_amount' | 'short_number'>,
        event: Omit<IAbstractCauseProject, 'goal'>,
        volunteering: Pick<IAbstractCauseProject, 'is_active' | 'capacity' | 'count'>,
        competition: Pick<IAbstractCauseProject, 'is_active' | 'capacity' | 'count' | 'min_donation_amount' | 'file_path'>,
    }
}
