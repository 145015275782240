import {createStyles, makeStyles} from '@material-ui/core/styles'
import {ICause} from 'types/cause'
import React, {CSSProperties} from 'react'
import {useTranslation} from 'state/translation'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        causeProjects: {
            margin: 0,
            paddingLeft: spacing(2),
        },
    }),
)

export function CauseProjects(props: {
    projects: ICause['projects'];
    style?: CSSProperties
}) {
    const {causeProjects} = useStyles()
    const {projects, style} = props
    const {translate} = useTranslation()

    return (
        <ul className={causeProjects} style={style} children={
            Object.entries(projects).map(([type, project]) =>
                project.is_active ? <li key={type}><b>{translate(type as keyof typeof projects)}</b></li> : null
            )
        }/>
    )
}
