import {Typography} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Alert, AlertTitle} from '@material-ui/lab'
import React, {CSSProperties, PropsWithChildren, ReactNode, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'state/translation'
import {useScrollToTopEffect, useStatefulComponent} from 'utils/hooks'
import {responsivePadding} from 'utils/styles'
import {scrollIntoView} from 'utils/general'
import {NotFound} from '../mini-pages/NotFound'
import {CertNotFound} from '../mini-pages/CertNotFound'
import {CustomDivider} from '../misc/CustomDivider'
import {Only} from '../util/Only'
import {LoadingOverlay} from './LoadingOverlay'

const useStyles = (usePadding: boolean) => makeStyles(({spacing, breakpoints}) =>
    createStyles({
        statefulPage: {
            ...(usePadding && responsivePadding({spacing, breakpoints})),
            display: 'flex',
            flexDirection: 'column',
            minHeight: 300,
            paddingTop: spacing(4),
            paddingBottom: spacing(4),
        },
    }),
)()

export function useStatefulPageState() {
    const state = useStatefulComponent()

    const [showChildren, setShowChildren] = useState(true)
    const [showNotFound, setShowNotFound] = useState(false)
    const [showCertNotFound, setShowCertNotFound] = useState(false)

    return {
        ...state,
        showChildren: showChildren,
        showNotFound: showNotFound,
        showCertNotFound: showCertNotFound,

        setShowChildren: setShowChildren,
        setShowNotFound: setShowNotFound,
        setShowCertNotFound: setShowCertNotFound,
    }
}

export function StatefulPage(props: PropsWithChildren<{
    className?: string,
    successActions?: ReactNode
    state: ReturnType<typeof useStatefulPageState>,
    usePadding?: boolean,
    style?: CSSProperties,
}>) {
    const {className, successActions, state, usePadding, style, children} = props
    const {statefulPage} = useStyles(usePadding ?? true)
    const {translate} = useTranslation()

    const successRef = useRef<HTMLElement>(null)
    const infoRef = useRef<HTMLElement>(null)
    const errorRef = useRef<HTMLElement>(null)

    useScrollToTopEffect([])

    useEffect(() => {
        scrollIntoView(successRef, !!state.successMessage)
        scrollIntoView(infoRef, !!state.infoMessage)
        scrollIntoView(errorRef, !!state.errorMessage)
        scrollIntoView(errorRef, !!state.errorMessageCustom)
    }, [state.successMessage, state.infoMessage, state.errorMessage, state.errorMessageCustom])

    return (
        <LoadingOverlay loading={state.loading}>
            <div className={[statefulPage, className].join(' ')} style={style}>
                <Only when={state.showChildren}>{children}</Only>
                <Only when={state.showNotFound}><NotFound/></Only>
                <Only when={state.showCertNotFound}><CertNotFound/></Only>
                <Only when={state.successMessage}>
                    <Alert ref={successRef} style={{margin: 'auto'}} severity={'success'}>
                        <AlertTitle>{translate('success')}</AlertTitle>
                        <Typography>{state.successMessage}</Typography>
                        {successActions && <CustomDivider marginY={1}/>}
                        {successActions}
                    </Alert>
                </Only>
                <Only when={state.successMessageString}>
                    <Alert ref={successRef} style={{margin: 'auto'}} severity={'success'}>
                        <AlertTitle>{translate('success')}</AlertTitle>
                        <Typography>{state.successMessageString}</Typography>
                        {successActions && <CustomDivider marginY={1}/>}
                        {successActions}
                    </Alert>
                </Only>
                <Only when={state.infoMessage}>
                    <Alert ref={infoRef} style={{margin: 'auto'}} severity={'info'}>
                        <AlertTitle>{translate('info')}</AlertTitle>
                        <Typography>{state.infoMessage}</Typography>
                    </Alert>
                </Only>
                <Only when={state.errorMessage}>
                    <Alert ref={infoRef} style={{margin: 'auto'}} severity={'error'}>
                        <AlertTitle>{translate('error')}</AlertTitle>
                        <Typography>{state.errorMessage}</Typography>
                    </Alert>
                </Only>
                <Only when={state.errorMessageCustom}>
                    <Alert ref={infoRef} style={{margin: 'auto'}} severity={'error'}>
                        <AlertTitle>{translate('error')}</AlertTitle>
                        <Typography>{state.errorMessageCustom}</Typography>
                    </Alert>
                </Only>
            </div>
        </LoadingOverlay>
    )
}
