import {Box, Button} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {Add} from '@material-ui/icons'
import {CharityCard} from 'components/cards/CharityCard'
import {AuthorizedContent} from 'components/foundation/AuthorizedContent'
import {ResponsiveGrid} from 'components/foundation/ResponsiveGrid'
import {useDialogOpenState} from 'components/foundation/StatefulDialog'
import {StatefulPage, useStatefulPageState} from 'components/foundation/StatefulPage'
import {Title} from 'components/misc/Title'
import {AppHelmet} from 'components/util/AppHelmet'
import {Only} from 'components/util/Only'
import {ICharity} from 'types/charity'
import React from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {useAuth} from 'state/auth'
import {useTranslation} from 'state/translation'
import {api} from 'utils/api'
import {useRequestEffect} from 'utils/request'
import {route} from 'utils/route'
import {NewCharityDialog} from './components/NewCharityDialog'
import {useHookstate} from '@hookstate/core'
import {useUpdater} from '../../../utils/hooks'
import {useLang} from '../../../state/lang'

const useStyles = makeStyles(({spacing}) =>
    createStyles({
        grid: {
            columnGap: spacing(4),
        },
        charityCard: {
            width: spacing(32),
        }
    }),
)

export function UserCharities() {
    const {grid, charityCard} = useStyles()
    const {translate} = useTranslation()
    const {lang} = useLang()
    const {id} = useParams<{ id: string }>()
    const {pathname} = useLocation()
    const {auth} = useAuth()
    const {updateFlag, update} = useUpdater()


    const newCharityDialog = useDialogOpenState(undefined)
    const page = useStatefulPageState()

    const charities = useHookstate<ICharity[]>([])

    const mode = pathname === route.to.my_charities ? 'user' : 'admin'

    useRequestEffect<ICharity[]>({
        url: api.user.read_charities,
        state: page,
        data: {
            user_id: Number(mode === 'user' ? auth?.userID : id)
        },
        onSuccess: charities.set,
        onFail: resultCode => {
            if (resultCode === 'USER_NOT_FOUND' || resultCode === 'UNAUTHENTICATED') {
                page.setShowNotFound(true)
                page.setShowChildren(false)
            } else {
                page.setUnexpectedFrontendError()
            }
        }
    }, [lang, updateFlag])

    return (
        <StatefulPage state={page}>
            <AppHelmet noIndex/>
            <AuthorizedContent>
                <Title titleKey={mode === 'user' ? 'my_charities' : 'user_charities'}/>
                <Only when={mode === 'user'}>
                    <NewCharityDialog openState={newCharityDialog} onSuccess={update}/>
                    <Box display="flex" marginBottom={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            size={'large'}
                            style={{margin: 'auto'}}
                            startIcon={<Add/>}
                            onClick={() => newCharityDialog.setOpen(true)}
                            children={translate('create_new')}
                        />
                    </Box>
                </Only>
                <ResponsiveGrid
                    className={grid}
                    breakpointColumns={{
                        'laptop': 3,
                        'tablet': 2,
                    }}
                    children={
                        charities.value.map(charity =>
                            <CharityCard
                                key={charity.id}
                                className={charityCard}
                                charity={charity}
                                mode={'detailed'}
                            />
                        )
                    }
                />
            </AuthorizedContent>
        </StatefulPage>
    )
}
